import { useEffect, Component } from "react";
import { Axios, ApiUrl } from "../../components";
import { userSignOut } from "../../actions/Auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const { API_INSTALLATION_SETTINGS } = ApiUrl;

class LoginChecker extends Component {
  componentDidMount() {
    if (localStorage.getItem("user_id")) {
      Axios.get(API_INSTALLATION_SETTINGS).then((response) => {
        if (response.data && response.data.error_code === 5004) {
          localStorage.clear();
          window.location.replace("/");
        }
      });
    }
  }
  render() {
    return null;
  }
}

export default connect(null, { userSignOut })(withRouter(LoginChecker));
