// src/components/ReCaptcha.js
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({ onVerify }) => {
  const handleVerify = (token) => {
    onVerify(token);
  };

  return (
    <ReCAPTCHA
      sitekey="6LeV5v8pAAAAACSPI1KytXZhckOpFr549ziTwQkG"
      onChange={handleVerify}
    />
  );
};

export default ReCaptcha;
