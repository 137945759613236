import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  toast,
  SUBMIT,
} from "../../../../../../components";
import Checkbox from "../Components/checkbox";
import "./revenueTable.css";

import Editor from "./editor";
import { onlyNumber } from "../../../../../../util/onlyNumber";
import filter from "../../../../../../assets/images/icone-de-filtre-grise.png";

const {
  ALL_CASES,
  FILTER_CASE,
  FILTER_DATA,
  ADD_NOTES,
  VIEW_NOTES,
  DELETE_NOTES,
  SELECTED_CASE_IDS,
} = ApiUrl;

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
}
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [month, day, year] = formattedDate.split("/");
  return `${day}.${month}.${year}`;
};

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [showVenue, setVenue] = useState(false);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesData, setNotesData] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [notesText, setNotesText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewNotesModalOpen, setViewNotesModalOpen] = useState(false);
  const [addNotes, setAddNotes] = useState("");
  const [lotData, setLotData] = useState([]);

  const [topic, setTopic] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [noteAddedBy, setNoteAddedBy] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [newTopic, setNewTopic] = useState("");
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [employee_id, setEmployee_id] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const [newNoteAddedBy, setNewNoteAddedBy] = useState("");
  const [newUploadFiles, setNewUploadFiles] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [stagesData, setStagesData] = useState([]);
  const [arbitratorData, setArbitratorData] = useState([]);
  const [lawFirmClientData, setLawFirmClientData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [stagingData, setStagingData] = useState([]);

  const [formData, setFormData] = useState({
    status: "",
    created_at: "",
    created_at_from: "",
    created_at_to: "",
    arbitrator: "",
    hearing_date: "",
    state: "",
    venue: "",
    client: "",
    lot: "",
  });

  const handleNewFileChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setNewUploadFiles(filesArray);
  };

  const filterData = async (type) => {
    try {
      const response = await Axios.get(`${FILTER_DATA}/${type}`);
      if (type === "state") {
        setStateData(response.data.data.map((item) => ({ value: item.state })));
      } else if (type === "venue") {
        setVenueData(response.data.data.map((item) => ({ value: item.venue })));
      } else if (type === "stages") {
        setStagingData(response.data.data);
      } else if (type === "stages") {
        setStagesData(response.data.data.map((item) => item.stage));
        value;
      } else if (type === "arbitrator") {
        setArbitratorData(
          response.data.data.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        );
      } else if (type === "lot") {
        setLotData(
          response.data.data.map((item) => ({
            value: item.lot,
            label: item.lot,
          }))
        );
      } else if (type === "seat") {
        setSeatData(
          response.data.data.map((item) => ({
            value: item.seat,
            label: item.seat,
          }))
        );
      } else if (type === "lawFirmClient") {
        setLawFirmClientData(
          response.data.data.map((item) => ({ value: item.client }))
        );
      }
    } catch (error) {
      console.error(`Error filtering ${type} data:`, error);
    }
  };

  const handlenotassignChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      not_have_hearing_date: value,
    }));
  };

  const handleChangeHearingDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    filterData("state");
    filterData("venue");
    filterData("stages");
    filterData("arbitrator");
    filterData("lawFirmClient");
    filterData("seat");
    filterData("lot");
  }, []);

  useEffect(() => {
    getTableRecords();
    // setList(data);
  }, []);

  const getTableRecords = async (order, orderBy) => {
    setLoading(true);
    Axios.get(ALL_CASES + "/mediation")
      .then((res) => {
        const list = res.data;
        setList(list.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("");
      });
  };
  const handleOpenModal1 = () => {
    setShowModal(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;

    const formattedDate = value
      ? new Date(value).toISOString().split("T")[0]
      : "";
    setFormData({ ...formData, [name]: formattedDate });
  };
  const handleHearingDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      hearing_date: value,
    }));
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      state: value,
    });
  };

  const handleChangeSeat = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      seat: value,
    });
  };

  const handleChangeClient = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      lot: value,
    }));
  };
  const handleChangeDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const refresh = () => {
    getTableRecords();
    setCount();
    setResponseData([]);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmSelection = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== "")
    );
    Axios.post(FILTER_CASE, filteredFormData)
      .then((response) => {
        setResponseData(response.data);
        setCount(response.data.data.length);
        setShowModal(false);
        setFormData({});
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const toggleRev = (index) => {
    setShow((prev) => (prev === index ? null : index));
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  const handleViewNotes = async (itemId) => {
    try {
      const response = await Axios.get(`${VIEW_NOTES}${itemId}`);
      setNotesData(response.data);
      console.log(response.data, "response.dataresponse.data");
      setViewNotesModalOpen(true);
    } catch (error) {
      console.error("Error fetching notes for case", itemId, ":", error);
    }
  };

  const toggleViewNotesModal = () => {
    setViewNotesModalOpen(!viewNotesModalOpen);
  };

  const handleDeleteNotes = async (caseId, noteId) => {
    try {
      const response = await Axios.get(`${DELETE_NOTES}/${caseId}/${noteId}`);
      if (response.data.success) {
        setNotesData((prevNotesData) => ({
          ...prevNotesData,
          data: prevNotesData.data.filter((note) => note.id !== noteId),
        }));
        toast.success("Note deleted successfully.");
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      toast.error("Failed to delete note.");
    }
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!topic.trim()) {
      errors.topic = "Topic is required";
      isValid = false;
    }

    if (!mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
      isValid = false;
    }

    if (!designation.trim()) {
      errors.designation = "Designation is required";
      isValid = false;
    }

    if (!noteAddedBy.trim()) {
      errors.noteAddedBy = "Note Added By is required";
      isValid = false;
    }

    if (!addNotes.trim()) {
      errors.addNotes = "Add Notes is required";
      isValid = false;
    }

    // if (uploadFiles.length === 0) {
    //   errors.uploadFiles = "At least one file must be uploaded";
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const toggleModal = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const formData = new FormData();
      formData.append("addNotes", addNotes);
      formData.append("topic", topic);
      formData.append("mobileNumber", mobileNumber);
      formData.append("designation", designation);
      formData.append("noteAddedBy", noteAddedBy);
      formData.append("employee", employee);

      uploadFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Submit the form
      const url = `${ADD_NOTES}${selectedItemId}`;
      await Axios.post(url, formData);
      setIsModalOpen(false);
      setTopic("");
      setMobileNumber("");
      setDesignation("");
      setNoteAddedBy("");
      setAddNotes("");
      setUploadFiles([]);
      setFormErrors({});
      setEmployee("");
      toast.success("Notes added successfully!");
    } catch (error) {
      console.error("Error sending addNotes data:", error);
      toast.error("Failed to add notes.");
    }
  };
  const handleOpenModalAndViewNotes = async (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  console.log(isCheck, "isCheckAll", typeof isCheck);

  const handleAddNotes = async () => {
    try {
      const errors = {};
      if (!newTopic) errors.newTopic = "Topic is required";
      if (!newMobileNumber)
        errors.newMobileNumber = "Mobile Number is required";
      if (!newDesignation) errors.newDesignation = "Designation is required";
      if (!newNoteAddedBy) errors.newNoteAddedBy = "Note Added By is required";
      if (!notesText) errors.notesText = "Add Notes is required";
      if (newUploadFiles.length === 0)
        errors.newUploadFiles = "Upload Files is required";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const data = {
        notesText: notesText,
        newTopic: newTopic,
        newMobileNumber: newMobileNumber,
        newDesignation: newDesignation,
        newNoteAddedBy: newNoteAddedBy,
      };

      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      newUploadFiles.forEach((file) => {
        formData.append("newUploadFiles", file);
      });
      formData.append("case_id[]", caseIds);
      // let $formData = { formData, case_ids: isCheck };

      const response = await Axios.post(SELECTED_CASE_IDS, formData);

      if (response.status === 200) {
        handleCloseNotesModal();
        setNotesText("");
        setNewTopic("");
        setNewMobileNumber("");
        setNewDesignation("");
        setNewNoteAddedBy("");
        setNewUploadFiles([]);
        toast.success("Notes added successfully!");
      } else {
        toast.error("Failed to add notes.");
      }
    } catch (error) {
      console.error("Error adding notes:", error);
      toast.error("Failed to add notes.");
    }
  };

  // const handleAddNotes = async () => {
  //   try {
  //     const errors = {};
  //     if (!newTopic) errors.newTopic = 'Topic is required';
  //     if (!newMobileNumber) errors.newMobileNumber = 'Mobile Number is required';
  //     if (!newDesignation) errors.newDesignation = 'Designation is required';
  //     if (!newNoteAddedBy) errors.newNoteAddedBy = 'Note Added By is required';
  //     if (!notesText) errors.notesText = 'Add Notes is required';
  //     if (newUploadFiles.length === 0) errors.newUploadFiles = 'Upload Files is required';

  //     // Validate file types
  //     const allowedTypes = ['jpg', 'jpeg', 'png', 'docx', 'doc', 'pdf'];
  //     const invalidFiles = newUploadFiles.filter(file => {
  //       const fileType = file.name.split('.').pop().toLowerCase();
  //       return !allowedTypes.includes(fileType);
  //     });

  //     if (invalidFiles.length > 0) {
  //       errors.newUploadFiles = `Invalid file type. Allowed types: ${allowedTypes.join(', ')}`;
  //     }

  //     if (Object.keys(errors).length > 0) {
  //       setErrors(errors);
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append('notesText', notesText);
  //     formData.append('newTopic', newTopic);
  //     formData.append('newMobileNumber', newMobileNumber);
  //     formData.append('newDesignation', newDesignation);
  //     formData.append('newNoteAddedBy', newNoteAddedBy);
  //     newUploadFiles.forEach(file => {
  //       formData.append('newUploadFiles', file);
  //     });

  // const dataone = {
  //   case_ids: isCheck,
  // };

  //     const formDataObject = {};
  //     for (let [key, value] of formData.entries()) {
  //       formDataObject[key] = value;
  //     }
  //     const mergedData = { ...dataone, ...formDataObject };

  //     console.log(mergedData, "mergedDatamergedData");
  //     const response = await Axios.post(SELECTED_CASE_IDS, mergedData);

  //     if (response.status === 200) {
  //       handleCloseNotesModal();
  //       setNotesText("");
  //       setNewTopic("");
  //       setNewMobileNumber("");
  //       setNewDesignation("");
  //       setNewNoteAddedBy("");
  //       setNewUploadFiles([]);
  //       toast.success("Notes added successfully!");
  //     } else {
  //       toast.error("Failed to add notes.");
  //     }
  //   } catch (error) {
  //     console.error("Error adding notes:", error);
  //     toast.error("Failed to add notes.");
  //   }
  // };

  // const handleAddNotes = async () => {
  //   try {
  //     const errors = {};

  //     if (!newTopic) errors.newTopic = 'Topic is required';
  //     if (!newMobileNumber) errors.newMobileNumber = 'Mobile Number is required';
  //     if (!newDesignation) errors.newDesignation = 'Designation is required';
  //     if (!newNoteAddedBy) errors.newNoteAddedBy = 'Note Added By is required';
  //     if (!notesText) errors.notesText = 'Add Notes is required';
  //     if (!employee_id) errors.employee_id = 'Employee ID is required';
  //     if (newUploadFiles.length === 0) errors.newUploadFiles = 'Upload Files is required';

  //     if (Object.keys(errors).length > 0) {
  //       setErrors(errors);
  //       return;
  //     }

  //     const data = {
  //       notesText: notesText,
  //       newTopic: newTopic,
  //       newMobileNumber: newMobileNumber,
  //       newDesignation: newDesignation,
  //       newNoteAddedBy: newNoteAddedBy,
  //       employee: employee_id,
  //       case_ids: isCheck,
  //     };

  //     const response = await Axios.post(SELECTED_CASE_IDS, data);

  //     if (response.status === 200) {
  //       handleCloseNotesModal();
  //       setNotesText("");
  //       setNewTopic("");
  //       setNewMobileNumber("");
  //       setEmployee_id("");
  //       setNewDesignation("");
  //       setNewNoteAddedBy("");
  //       setNewUploadFiles([]);
  //       toast.success("Notes added successfully!");
  //     } else {
  //       toast.error("Failed to add notes.");
  //     }
  //   } catch (error) {
  //     console.error("Error adding notes:", error);
  //     toast.error("Failed to add notes.");
  //   }
  // };

  const handleOpenNotesModal = () => {
    setShowNotesModal(true);
    setNotesText("");
    setShowNotesModal(true);
  };

  const handleCloseNotesModal = () => {
    setShowNotesModal(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setUploadFiles(filesArray);
  };

  const filteredCatalogue =
    responseData?.data &&
    responseData?.data?.length > 0 &&
    responseData?.data?.map((item, index) => {
      return (
        <div className="single-revenue-conatiner" key={item.id}>
          <div className="revenue-table-heading">
            <p className="checkbox-revenue">
              {" "}
              {/* <span>

                <Checkbox
                  key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClick(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                />
              </span> */}
              <Link
                to={`/app/mediation/case-detail/${item.hash_id}`}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Case No."
                className="d-flex"
              >
                {item.case_master_users.map((i) => {
                  return (
                    <div className="ml-2 text-capitalize">
                      <span>
                        {i.related_user_role == "Client" &&
                        i.related_user_type == "claimant"
                          ? `${i.related_user?.name}   vs`
                          : ""}
                      </span>

                      <span>
                        {i.related_user_role == "Party" &&
                        i.related_user_type == "respondent"
                          ? i.related_user?.name
                          : ""}
                      </span>
                    </div>
                  );
                })}
              </Link>
            </p>
            <p className="checkbox-revenue-subtext">
              <span
                className="revenue-status mx-2"
                onClick={() => handleOpenModal(item.id)}
              >
                Add Notes
              </span>
              <span
                className="revenue-status view-notes"
                onClick={() => handleViewNotes(item.id)}
              >
                View Notes
              </span>
              <span> {item.date} </span>
              <span className="revenue-status status-pending">
                {item.case_status}
              </span>
            </p>
          </div>
          <Link to={`/app/mediation/case-detail/${item.hash_id}`}>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>

              <div className="filesrevenue-subconatinerpov">
                {item.hearing && item.hearing.length > 0 ? (
                  item.hearing.map((hearing) => {
                    const startDate = new Date(hearing.hearing.start_date);
                    const currentDate = new Date();

                    if (startDate >= currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Next</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    } else if (startDate < currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Previous</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="filesrevenue-povBoxes">
                    <p>Previous</p>
                    {!item.hearing && <p></p>}
                  </div>
                )}
              </div>
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {showVenue === index &&
            item.metas.map((m) => {
              return (
                <>
                  <div className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}

          {show === index ? (
            <div onClick={() => toggleRev(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {show === index && (
            <div className="revenue-state2">
              <div className="revenue-state-box">
                <p className="text-court"> Usergroup: </p>
                <p className="text-state"> No user group added yet </p>
              </div>
              <div className="revenue-state-box">
                <p className="text-court">Team:</p>
                <p className="text-state"> No user added yet </p>
              </div>
            </div>
          )}
        </div>
      );
    });

  const cataloge = list.slice(0, limit).map((item, index) => {
    const partyRespondentVs = item.case_master_users?.find(
      (i) =>
        i.related_user_role === "Client" && i.related_user_type === "claimant"
    );
    const respondentNameVs = partyRespondentVs
      ? partyRespondentVs.related_user.name
      : null;

    const respondentNames = item.case_master_users?.reduce((acc, i) => {
      if (
        i.related_user_role === "Party" &&
        i.related_user_type === "respondent"
      ) {
        acc.push(i.related_user.name);
      }
      return acc;
    }, []);
    return (
      <div className="single-revenue-conatiner mt-2" key={item.id}>
        <div className="revenue-table-heading">
          <p className="checkbox-revenue">
            {" "}
            {/* <span>
              <Checkbox
                key={item.id}
                type="checkbox"
                name={item.label}
                id={item.id}
                handleClick={(e) => handleClick(e, item.id)}
                isChecked={isCheck.includes(item.id)}
              />
            </span> */}
            <Link
              to={`/app/mediation/case-detail/${item.hash_id}`}
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Case No."
              className="d-flex"
            >
              <div className="ml-2 text-capitalize">
                <span>{respondentNameVs} and </span>
                <span>
                  {respondentNames?.length === 1
                    ? respondentNames
                    : respondentNames?.length === 2
                    ? respondentNames[0] + " & another"
                    : respondentNames?.length === 3 ||
                      respondentNames?.length > 3
                    ? respondentNames[0] + " & others"
                    : ""}
                </span>
              </div>
            </Link>
          </p>
          <p className="checkbox-revenue-subtext">
            <span
              className="revenue-status mx-2"
              onClick={() => handleOpenModal(item.id)}
            >
              Add Notes
            </span>
            <span
              className="revenue-status view-notes"
              onClick={() => handleViewNotes(item.id)}
            >
              View Notes
            </span>
            <span> {item.date} </span>
            <span className="revenue-status status-pending">
              {item.case_status}
            </span>
          </p>

          {/*  <button className="add-notess-btn"></button>
          <Button className="mx-2" onClick={() => handleViewNotes(item.id)}>View Notes</Button>
            */}
        </div>
        <Link to={`/app/mediation/case-detail/${item.hash_id}`}>
          <div className="filesrevenue-conatiner">
            <div className="filesrevenue-subconatiner">
              <div className="pov123 mt-3">
                <p>
                  <span className="pov123Text">Case No.</span>
                  {item.case_number}
                </p>
                <p>
                  <span className="pov123Text">Filed On:</span>
                  {moment(item.created_at).format("DD.MM.YYYY")}
                </p>
              </div>
            </div>

            <div className="filesrevenue-subconatinerpov">
              {item.hearing &&
                item.hearing.length > 0 &&
                item.hearing.map((hearing) => {
                  const startDate = new Date(hearing.hearing.start_date);
                  const currentDate = new Date();

                  if (startDate >= currentDate) {
                    return (
                      <div
                        className="filesrevenue-povBoxes"
                        key={hearing.hearing_id}
                      >
                        <p>Next</p>
                        <p>{formatDate(startDate)}</p>
                      </div>
                    );
                  } else if (startDate < currentDate) {
                    return (
                      <div
                        className="filesrevenue-povBoxes"
                        key={hearing.hearing_id}
                      >
                        <p>Previous</p>
                        <p>{formatDate(startDate)}</p>
                      </div>
                    );
                  }
                })}

              {!item.hearing ||
                (item.hearing.every(
                  (hearing) =>
                    new Date(hearing.hearing.start_date) >= new Date()
                ) && <></>)}
            </div>
          </div>
        </Link>

        {showVenue === index ? (
          <div onClick={() => toggleRev1(index)}>
            <h2 class="hr-lines">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h2>
          </div>
        ) : (
          <div onClick={() => toggleRev1(index)} className="text-center">
            <span className="arrow-conainer">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                  stroke="#42526E"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        )}
        {showVenue === index &&
          item.metas.map((m) => {
            return (
              <>
                <div className="revenue-state">
                  <div className="revenue-state-box">
                    <p className="text-court text-capitalize">{m.meta_key}: </p>
                    <p className="text-state text-capitalize">
                      {" "}
                      {m.meta_value}{" "}
                    </p>
                  </div>
                </div>
              </>
            );
          })}

        {show === index ? (
          <div onClick={() => toggleRev(index)}>
            <h2 class="hr-lines">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h2>
          </div>
        ) : (
          <div onClick={() => toggleRev(index)} className="text-center"></div>
        )}
      </div>
    );
  });

  return (
    <section className="Sales_analytics">
      <div fluid>
        <div className="revenueTable-conatiner">
          <div className="revenueTable-pagination-box mb-4">
            <div className="pgination-1st">
              {" "}
              {/* <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </span> */}
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
              {/* <span className="check-po-icon" onClick={handleOpenNotesModal}>
                <i className="zmdi zmdi-calendar-note"></i>
              </span> */}
              {/* <span className="check-po-icon" onClick={handleOpenModal1}>
                <i class="zmdi zmdi-filter-list"></i>
              </span> */}
              <span className="check-po-icon" onClick={handleOpenModal1}>
                <span className="mr-2 mb-1">Filter</span>

                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
                {/* <i class="zmdi zmdi-filter-list"></i> */}
              </span>
              <Modal isOpen={showModal} toggle={handleCloseModal}>
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                  >
                    <span>×</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="4" xs="12">
                      <FormControl className="select-multi">
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Status
                        </InputLabel>
                        <Select
                          fullWidth
                          id="demo-mutiple-name"
                          onChange={handleChange}
                          name="status"
                        >
                          <option value="pending">Pending</option>
                          <option value="registered">Registered</option>
                          {stagingData &&
                            stagingData.map((e, key) => {
                              console.log(e, "checkingstatus");
                              return (
                                <option key="abc" value={e.stage}>
                                  {e.stage}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md="4" xs="12">
                      <TEXTFIELD
                        type="date"
                        label="Created At"
                        onChange={handleDateChange}
                        value={formData.created_at}
                        name="created_at"
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.arbitrator}
                        name="arbitrator"
                        label="Arbitrator"
                        data={arbitratorData}
                      />
                    </Col>

                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Hearing Date"
                        onChange={handleHearingDateChange}
                        value={formData.hearing_date}
                        name="hearing_date"
                      />
                    </Col>
                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Hearing not assigned"
                        onChange={handlenotassignChange}
                        value={formData.not_have_hearing_date}
                        name="hearing_date"
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        onChange={handleChangeState}
                        value={formData.state}
                        name="state"
                        label="State"
                        data={stateData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChangeSeat}
                        value={formData.seat}
                        name="seat"
                        label="Seat"
                        data={seatData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.venue}
                        name="venue"
                        id="variation-select-6"
                        label="Venue"
                        data={venueData}
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        name="lot"
                        onChange={handleChangeClient}
                        value={formData.lot}
                        id="variation-select-8"
                        label="LOT"
                        data={lotData}
                      />
                    </Col>

                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Created Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="created_at_from"
                            value={formData.created_at_from}
                            onChange={handleChangeDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="created_at_to"
                            value={formData.created_at_to}
                            onChange={handleChangeDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Hearing Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="hearing_date_from"
                            value={formData.hearing_date_from}
                            onChange={handleChangeHearingDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="hearing_date_to"
                            value={formData.hearing_date_to}
                            onChange={handleChangeHearingDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="4" xs="12">
                      <TEXTFIELD
                        type="text"
                        label="Client"
                        onChange={handleChange}
                        value={formData.client}
                        name="client"
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleConfirmSelection}
                        className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal isOpen={showNotesModal} toggle={handleCloseNotesModal}>
                <div className="modal-header">
                  <h1 className="modal-title">Add Bulk Notes</h1>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseNotesModal}
                  >
                    <span>×</span>
                  </button>
                </div>
                <ModalBody>
                  <Editor cases={isCheck} />
                  {/* <div className="from-control" style={{ padding: "20px 10px" }}>
                    <Row>
                      <Col md="12">
                        <TEXTFIELD
                          label="Subject"
                          value={newTopic}
                          onChange={(e) => setNewTopic(e.target.value)}
                        />

                        {errors.newTopic && <div className="error-message">{errors.newTopic}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Add Notes"
                          value={notesText}
                          onChange={(e) => setNotesText(e.target.value)}
                        />
                        {errors.notesText && <div className="error-message">{errors.notesText}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Note added by/Name:"
                          value={newNoteAddedBy}
                          onChange={(e) => setNewNoteAddedBy(e.target.value)}
                        />
                        {errors.newNoteAddedBy && <div className="error-message">{errors.newNoteAddedBy}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Designation"
                          value={newDesignation}
                          onChange={(e) => setNewDesignation(e.target.value)}
                        />
                        {errors.newDesignation && <div className="error-message">{errors.newDesignation}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Mobile Number"
                          value={newMobileNumber}
                          onChange={(e) => setNewMobileNumber(e.target.value)}
                        />
                        {errors.newMobileNumber && <div className="error-message">{errors.newMobileNumber}</div>}

                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Employee Id"
                          value={employee_id}
                          onChange={(e) => setEmployee_id(e.target.value)}
                        />
                        {errors.employee_id && <div className="error-message">{errors.employee_id}</div>}

                      </Col>

                      <Col md="12">
                        <input type="file" onChange={handleNewFileChange} multiple />

                        {errors.newUploadFiles && <div className="error-message">{errors.newUploadFiles}</div>}
                      </Col>
                    </Row>
                    <div className="text-center">

                      <button
                        type="button"
                        className="btn btn-warning jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-50"
                        onClick={handleAddNotes}
                      >
                        Add Notes
                      </button>
                    </div>
                  </div> */}
                </ModalBody>
              </Modal>
            </div>
            <div className="pagination-conatinerr">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option selected>20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value={list.length}>{list.length}</option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from{" "}
                  {count == 0
                    ? 0
                    : filteredCatalogue && filteredCatalogue.length > 0
                    ? count
                    : list.length}{" "}
                  results{" "}
                </span>
              </div>
              {/* <nav>
                <ul className="pagination position-po pagination-text">
                  <span className="pto-5">|</span>
                  <li className="page-item pto-5">
                    <span onClick={prevList}> Prev</span>
                  </li>

                  <li key="" className="">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li className="page-item pto-5">
                    <span>Next</span>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
          {loading === false && list.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          {filteredCatalogue && filteredCatalogue.length > 0 ? (
            <div className="revenue-scroll-container">{filteredCatalogue}</div>
          ) : count == 0 ? (
            <p className="n--data text-center mt-5">No data found</p>
          ) : (
            <div className="revenue-scroll-container">{cataloge}</div>
          )}
        </div>
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
          <div className="modal-header">
            <h1 className="modal-title mt-3 mb-3 mx-2">
              <b>Add Notes Single</b>
            </h1>
            <button
              type="button"
              className="close"
              onClick={() => setIsModalOpen(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="" style={{ padding: "20px 20px" }}>
            <Row>
              <Col md="12">
                <TEXTFIELD
                  label="Subject"
                  value={topic}
                  onKeyUp={validateForm}
                  onChange={(e) => setTopic(e.target.value)}
                />
                {formErrors.topic && (
                  <div className="error-message ">{formErrors.topic}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Add Notes"
                  value={addNotes}
                  onKeyUp={validateForm}
                  onChange={(e) => setAddNotes(e.target.value)}
                />
                {formErrors.addNotes && (
                  <div className="error-message">{formErrors.addNotes}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Note Added By"
                  value={noteAddedBy}
                  onKeyUp={validateForm}
                  onChange={(e) => setNoteAddedBy(e.target.value)}
                />
                {formErrors.noteAddedBy && (
                  <div className="error-message">{formErrors.noteAddedBy}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Designation"
                  value={designation}
                  onKeyUp={validateForm}
                  onChange={(e) => setDesignation(e.target.value)}
                />
                {formErrors.designation && (
                  <div className="error-message">{formErrors.designation}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Employee Id:"
                  value={employee}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmployee(e.target.value)}
                />
                {formErrors.employee && (
                  <div className="error-message">{formErrors.employee}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Mobile Number:"
                  value={mobileNumber}
                  onKeyUp={validateForm}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={onlyNumber}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
            </Row>
            <input
              className="my-3"
              type="file"
              onChange={handleFileChange}
              multiple
            />

            <div className="modal-submit-btn text-center">
              <button
                type="button"
                className="btn btn-warning jr-btn CBmargin CBmarginT M add-notes w-50"
                onClick={toggleModal}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>

        <Modal isOpen={viewNotesModalOpen} toggle={toggleViewNotesModal}>
          <div className="modal-header">
            <h1 className="modal-title">Notes</h1>
            <button
              type="button"
              className="close"
              onClick={toggleViewNotesModal}
            >
              <span>×</span>
            </button>
          </div>
          <ModalBody>
            {notesData.data && notesData.data.length > 0 ? (
              notesData.data.map((note) => (
                <div key={note.id}>
                  <Row>
                    <Col md="12">
                      <p>
                        <b>Name :</b> {note.added_by.noteAddedBy}
                      </p>

                      <p>
                        <b>Designation : </b> {note.added_by.designation}
                      </p>
                      <p>
                        <b>Mobile Number :</b> {note.added_by.mobileNumber}{" "}
                      </p>
                      <Row>
                        <Col md="8">
                          <div>
                            <p>
                              <b>Topic:</b> {note.description.topic}{" "}
                            </p>
                            <p>
                              {" "}
                              <b>Notes:</b> {note.description.taddNotes} on{" "}
                              {formatTimestamp(note.created_at)}
                            </p>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="delete-btn">
                            <i
                              className="zmdi zmdi-delete delete-icn"
                              style={{ fontSize: "20px" }}
                              onClick={() =>
                                handleDeleteNotes(note.case_id, note.id)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                </div>
              ))
            ) : (
              <p>No notes available.</p>
            )}
            <Button color="secondary" onClick={toggleViewNotesModal}>
              Close
            </Button>
          </ModalBody>
        </Modal>
      </div>
    </section>
  );
};

export default Revenuetable;
