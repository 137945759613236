import React from 'react'

const CollectionConciliation = () => {
    return (
        <div className='mt-5 text-center'>
            Conciliation coming soon
        </div>
    )
}

export default CollectionConciliation
