import React, { useEffect, useState } from "react";
import "./ClientMeeting.css";
import { Link, Axios, toast, ApiUrl, moment } from "../../../../components";

const { MEETING_DATA, JOIN_MEETING } = ApiUrl;

const ClientMeeting = () => {
  const [list, setList] = useState([]);
  const [topic, setTopic] = useState("");
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const name = localStorage.getItem("name");
    if (name) {
      setClientName(name);
    }
  }, []);

  const handleMeetings = async () => {
    try {
      const response = await Axios.get(MEETING_DATA);
      if (response.status === 200) {
        setList(response.data.data);
        setTopic(response.data.topic);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleMeetings();
  }, []);

  const joinMeeting = async (meetingId) => {
    try {
      const response = await Axios.get(`${JOIN_MEETING}/${meetingId}`);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        window.open(response.data.data, "_blank");
      } else {
        toast.error("Failed to join meeting");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const role = localStorage.getItem("role")
  return (
    <div>
      {loading ? (
        <div className="timline-content d-flex justify-content-center align-items-center mt-5">
          No data found
        </div>
      ) : (
        <div>
          {list && list.length === 0 ? (
            <div className="timline-content d-flex justify-content-center align-items-center mt-5">
              No meeting available
            </div>
          ) : (
            
                <div
                  className="timline-content d-flex justify-content-center align-items-center mr-3"
                 
                >
                  <div className=" mr-auto mx-3">
                    <div>
                      <p className="table-notes mt-2">
                        <b>Note:</b> VC maybe joined after clicking the link.
                        The “Link to Join VC’’ will get activated on the date of
                        hearing only. It mandatory to text your case no
                        (ARBXXXX) in the chat-box for marking appearance at the
                        time of VC meeting.{" "}
                      </p>
                    </div>

                    <table class="table table-striped mt-5 table-box">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Purpose</th>
                          <th scope="col">Zoom VC Id</th>
                          <th scope="col">Password</th>
                          <th scope="col">Link to join VC</th>
                          <th scope="col">VC Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => {
                          const startDate = new Date(item.start_date);
                          const currentDate = new Date();
                          const currentTime = new Date().toLocaleTimeString();
                          console.log(currentTime,"currentTime")
                          const shouldShowLink =
                            startDate.getFullYear() ===
                              currentDate.getFullYear() &&
                            startDate.getMonth() === currentDate.getMonth() &&
                            startDate.getDate() === currentDate.getDate();
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  {moment(item.start_date).format("DD.MM.YYYY")}{" "}
                                </td>
                                <td>
                                  {moment(item.start_time, "HH:mm:ss").format(
                                    "h:mm A"
                                  )}
                                </td>
                                <td>{item.agenda}</td>

                                <td>{item.zoom_host_id}</td>
                                <td>{item.zoom_password}</td>
                                <td>
                                  {/* {item.zoom_join_url} */}

                                  <div className="date">
                                    {shouldShowLink ? (
                                      <div className="d-flex">
                                        <a
                                          href={
                                            role === "Arbitrator"
                                              ? item.zoom_start_url
                                              : item.zoom_join_url
                                          }
                                          target="_blank"
                                        >
                                          Click here to join meeting
                                        </a>
                                      </div>
                                    ) : (
                                      <span>--</span>
                                    )}
                                  </div>
                                </td>
                                {/* <button
                                        onClick={() => joinMeeting(item.id)}
                                      >
                                        click here to join
                                      </button> */}
                                <td>
                                  <span className="status-container-text14">
                                    {currentDate > startDate && currentTime > item.start_time
                                      ? "Completed"
                                      : "Yet to start"}
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* <div className="date-added">
                      <b>Case Title :</b>
                      {clientName} vs{" "}
                      {item.relation_table.map((relation, index) => {
                        if (
                          relation.case_user &&
                          relation.case_user.related_user_type ===
                            "respondent" &&
                          relation.case_user.related_user_role === "Party"
                        ) {
                          return (
                            <span key={index}>
                              {relation.case_user.related_user.name}
                              {index < item.relation_table.length - 1
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                        return null;
                      })}
                    </div> */}
                  </div>
                </div>
              
          )}
        </div>
      )}
    </div>
  );
};

export default ClientMeeting;
