import React from "react";
import {
  withRouter,
  BUTTON,
  CircularProgress,
  moment,
  Axios,
} from "../../../components/";
import CsvDownloader from "react-csv-downloader";

class ExportCsv extends React.Component {
  csvLink = React.createRef();
  constructor(props) {
    super(props);
    const { file, columns, url, id, params } = this.props;
    let column = columns
      .map((key) => {
        let no = ["_id", "action"];
        if (!no.includes(key.id)) {
          return {
            id: key.id,
            displayName: key.label,
          };
        }
      })
      .filter((key) => key !== undefined);

    this.state = {
      url: url,
      params: params,
      columns: column,
      file: file,
      loading: false,
      data: [],
    };
  }
  fetchData = async () => {
    this.setState({ loading: true });
    const { url, id, params, method } = this.props;
    let body = {
      orderBy: "createdBy",
      order: -1,
      page: 0,
      limit: 1000,
    };
    let newBody = { ...body, ...params };
    // if (params.datefilter) {
    //   newBody.startDate = moment(params.startDate).format("YYYY-MM-DD");
    //   newBody.endDate = moment(params.endDate).format("YYYY-MM-DD");
    // } else {
    //   delete newBody.startDate;
    //   delete newBody.endDate;
    // }

    if (method == "get") {
      Axios.get(url + id)
        .then(async (response) => {
          console.log(response.data.data.saleAmountBilling, "response123");
          response = response.data.data.saleAmountBilling;
          response = response.map((k) => {
            console.log(k, "kkkkkkk");
            return {
              ...k,
              // [dateFl]: moment(k[dateFl]).format("YYYY-MM-DD"),
            };
          });
          response = await response;
          this.setState(
            {
              data: response,
            },
            () => {
              setTimeout(() => {
                this.csvLink.current.handleClick();
                this.setState({ loading: false });
              }, 1000);
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      Axios.post(url, newBody)
        .then(async (response) => {
          response = response.data.data;
          let dateFl = params.datefield ? params.datefield : "createdAt";
          response = response.map((k) => {
            console.log(k,"kjhkhjkhkhkh")
            return {
              ...k,
              [dateFl]: moment(k[dateFl]).format("YYYY-MM-DD"),
              tripConfirmedAt: k["tripConfirmedAt"]
                ? moment(k["tripConfirmedAt"]).format("YYYY-MM-DD")
                : "",
              tripStartedAt: k["tripStartedAt"]
                ? moment(k["tripStartedAt"]).format("YYYY-MM-DD")
                : "",
              tripEndedAt: k["tripEndedAt"]
                ? moment(k["tripEndedAt"]).format("YYYY-MM-DD")
                : "",
              orderCreatedAt: k["orderCreatedAt"]
                ? moment(k["orderCreatedAt"]).format("YYYY-MM-DD")
                : "",
              created_at: k["created_at"]
                ? moment(k["created_at"]).format("YYYY-MM-DD")
                : "",
              billingDetails: k["billingDetails"]
                ? k["billingDetails"]["address"].replace(
                    /[^a-z0-9]+|\s+/gim,
                    " "
                  )
                : "",
              address: k.address
                ? k.address.replace(/[^a-z0-9]+|\s+/gim, " ")
                : "",
              startLocationAddr: k.startLocationAddr
                ? k.startLocationAddr.replace(/[^a-z0-9]+|\s+/gim, " ")
                : "",
              endLocationAddr: k.endLocationAddr
                ? k.endLocationAddr.replace(/[^a-z0-9]+|\s+/gim, " ")
                : "",
            };
          });
          response = await response;
          this.setState(
            {
              data: response,
            },
            () => {
              setTimeout(() => {
                this.csvLink.current.handleClick();
                this.setState({ loading: false });
              }, 1000);
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { data, columns, file, loading } = this.state;
    console.log(data, "data");
    return (
      <div>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        <BUTTON
          onClick={this.fetchData}
          disabled={false}
          title="Download"
          icon="zmdi-download"
        />
        <div className="d-none">
          <CsvDownloader
            filename={file}
            style={{ display: "none" }}
            separator=";"
            columns={columns}
            datas={data}
            ref={this.csvLink}
            text="DOWNLOAD"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ExportCsv);
