import React, { Component } from "react";
import {
  withRouter,
  CardBox,
  ContainerHeader,
  Paper,
  IntlMessages,
} from "../../../components/";

const Wrapper = (props) => {
  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      {/* <ContainerHeader
        match={props.match}
        title={<IntlMessages id={props.title} />}
        icon={props.icon}
        id={props.id}
      /> */}
      <Paper className="mt-2">
        <CardBox styleName="col-12" cardStyle=" p-0">
          {props.children}
        </CardBox>
      </Paper>
    </div>
  );
};

export default withRouter(Wrapper);
