import React, { Fragment, useState } from "react";

import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
} from "../../../../../../components";

const { SELECTED_CASE_IDS, Numeric } = ApiUrl;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      listId: this.props.listId,
      process: this.props.role,
      notesText: "",
      newTopic: "",
      newNoteAddedBy: "",
      newDesignation: "",
      newMobileNumber: "",
      files: {},
      errors: {
        files: "",
      },
      disabled: true,
      loading: false,
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "notesText":
        errors.notesText = value.length < 1 ? "Required" : "";
        break;
      case "newTopic":
        errors.newTopic = value.length < 1 ? "Required" : "";
        break;

      case "newMobileNumber": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.newMobileNumber = err;

        break;
      }

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };

    this.setState(
      {
        files: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ promotionImageName: file.name });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });

    const {
      notesText,
      newTopic,
      newNoteAddedBy,
      newDesignation,
      newMobileNumber,
      files,
      errors,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      notesText: notesText,
      newTopic: newTopic,
      newNoteAddedBy: newNoteAddedBy,
      newDesignation: newDesignation,
      newMobileNumber: newMobileNumber,
      newUploadFiles: files,
      "case_ids[]": this.props.cases,
    };
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    // let formData = new FormData();
    // for (var key in data) {
    //   formData.append(key, data[key]);
    // }

    const formData = new FormData();

    // Assuming cases is an object with multiple case IDs
    const caseIds = Object.values(this.props.cases);
    console.log(this.props.cases,caseIds,"caseIds")
    // Append each case ID as an array directly
    formData.append("case_ids[]", this.props.cases);
    formData.append("notesText", notesText);
    formData.append("newTopic", newTopic);
    formData.append("newNoteAddedBy", newNoteAddedBy);
    formData.append("newDesignation", newDesignation);
    formData.append("newMobileNumber", newMobileNumber);
    formData.append("newUploadFiles", files);

    data = valid.data;

    let url = SELECTED_CASE_IDS;

    Axios.Post(url, formData)
      .then((res) => {
        this.setState({ loading: false, disabled: false });

        if (res.data.success === true) {
          toast.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error, "res.message");
      });
  };

  handleSubmit11 = () => {
    if (!documentType) {
      setDocumentTypeError("Please select a document type");
      return;
    }
    if (!file) {
      setFileError("Please choose a file");
      return;
    }

    const formData = new FormData();

    // Assuming cases is an object with multiple case IDs
    const caseIds = Object.values(this.props.cases);

    // Append each case ID as an array directly
    formData.append("case_id[]", caseIds);

    formData.append("document_type", documentType);
    formData.append("file", file);

    Axios.post(API_UPLOAD_DOCUMENT, formData)
      .then((response) => {
        if (response.data && response.data.success) {
          toast.success("Document uploaded successfully");
          toggleModal();
        } else {
          toast.error("Error uploading document");
        }
      })
      .catch((error) => {
        console.error("Error uploading document: ", error);
        toast.error("Failed to upload document");
      });
  };

  render() {
    const {
      loading,
      notesText,
      newTopic,
      newNoteAddedBy,
      newDesignation,
      newMobileNumber,
      promotionImageName,
      files,
      errors,
      disabled,
    } = this.state;

    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            padding: "20px 60px",
            border: "1px solid grey",
            boxShadow: "2px 2px 3px -3px",
            margin: "20px",
          }}
        >
          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="New Topic"
                  value={newTopic}
                  name={"newTopic"}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="New Designation"
                  value={newDesignation}
                  name={"newDesignation"}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Mobile"
                  value={newMobileNumber}
                  name={"newMobileNumber"}
                  onChange={this.handleChange}
                  errors={errors}
                />
              </Col>
              <Col md="12" xs="12">
                <TEXTFIELD
                  label="Notes"
                  value={notesText}
                  name={"notesText"}
                  onChange={this.handleChange}
                />
              </Col>

              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Note Added By"
                  value={newNoteAddedBy}
                  name={"newNoteAddedBy"}
                  onChange={this.handleChange}
                />
              </Col>

              <Col md="6" xs="12">
                <div class="file-upload-cus mt-4">
                  <div class="file-select">
                    <div class="file-select-button" id="fileName">
                      Choose File
                    </div>
                    <div class="file-select-name" id="noFile">
                      {promotionImageName
                        ? promotionImageName
                        : "No file chosen..."}
                    </div>
                    <div class="importcus">
                      <input
                        type="file"
                        name={"files"}
                        id="chooseFile"
                        onChange={this.handleFile}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="3" xs="12">
                <SUBMIT title="Submit" disabled={disabled} />
              </Col>
            </Row>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(UploadFile);
