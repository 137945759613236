// import React from "react";

// const data = [
//   {
//     heading: "Expeditious: ",
//     text:
//       "We prioritize timely resolution, ensuring that the dispute resolution process is concluded within a specified timeframe on Just Settle.",
//   },
//   {
//     heading: "Cost-effective:",
//     text:
//       "Our streamlined process saves litigants significant costs typically incurred in litigation, allowing them to allocate resources towards more economically productive pursuits.",
//   },
//   {
//     heading: "Enforceable:",
//     text:
//       "Arbitral awards issued through our online dispute resolution mechanism are legally enforceable as decrees by civil courts, providing assurance and legitimacy to the resolution process.",
//   },
//   {
//     heading: "Conducive to business:",
//     text:
//       "We recognize the importance of preserving business relationships. Our platform enables parties to resolve disputes amicably without compromising their ongoing business engagements.",
//   },
//   {
//     heading: "Paperless:",
//     text:
//       "Committed to environmental sustainability, we conduct the entire arbitration and mediation process without using a single piece of paper, minimizing our ecological footprint.",
//   },
// ];

// const Whychoose = () => {
//   return (
//     <>
//       {/* <div class="ourmissiondiv" style={{margin:"100px"}}>
//         <h2 class="mb-md-3 mb-1 mt-3 mt-md-0 text-center" style={{color:"#1c1819"}}>OUR VALUES</h2>
//         <p className="text-center" style={{fontSize:"22px"}}>
//           We live up to our principles of humanity, independence, tenacity, and
//           transformation in everything we do.
//           <span id="about_us"></span>
//         </p>
//       </div> */}

//       <div
//         className="categories-section d-flex justify-content-center align-items-center"
//         style={{
//           marginTop: "30px",
//         }}
//       >
//         <div className="container categories-section-p">
//           <div>
//             <p className="categories-heading text-center">
//               Why Choose Us <br />
//             <span style={{fontSize:"17px"}}> At Just Settle, we pride ourselves on offering an efficient,
//               cost-effective, and environmentally sustainable approach to
//               dispute resolution. Here's why you should choose us:​​ </span> 
//             </p>
//           </div>
//           <div>
//             <ul className="categories-list mt-4">
//               {data.map((item, index) => {
//                 return (
//                   <li key={index}>
//                     <h5 className="categories-list-heading">{item.heading}</h5>
//                     <p className="categories-text">{item.text}</p>
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div style={{height:"38px"}}></div>
//     </>
//   );
// };

// export default Whychoose;
import React from 'react'

const choose = () => {
  return (
    <div></div>
  )
}

export default choose