import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  toast,
  SUBMIT,
} from "../../../../../../../components";
import "../../../../../Client/cases/routes/Components/revenueTable.css";
import { onlyNumber } from "../../../../../../../util/onlyNumber";
const {
  API_EDIT_SINGLE_NOTES,
  API_GET_FOLLOW_UP_DETAILS,
  API_EDIT_FOLLOW_UP,
  API_VIEW_FOLLOW_UP
} = ApiUrl;

const EditFollow = (props) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNotes, setAddNotes] = useState("");
  const [details, setDetails] = useState("");
  const [topic, setTopic] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [noteAddedBy, setNoteAddedBy] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [employee, setEmployee] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [statusCode, setStatusCode] = useState("");
  const [followup, setFollowup] = useState([]);
  const [amount, setAmount] = useState([]);
  const [prioriti, setPriority] = useState("");
  const [calltime, setCalltime] = useState([]);
  const [duration, setDuration] = useState([]);
  const [email, setEmail] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [codes, setCodes] = useState([]);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!topic.trim()) {
      errors.topic = "Topic is required";
      isValid = false;
    }

    if (!mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
      isValid = false;
    }

    if (!designation.trim()) {
      errors.designation = "Designation is required";
      isValid = false;
    }

    if (!addNotes.trim()) {
      errors.addNotes = "Add Notes is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const getTableRecords = async () => {
    try {
      const res = await Axios.get(`${API_GET_FOLLOW_UP_DETAILS}${props.noteIds}`);
      const data = res?.data?.data;

      console.log(data, "2222");
      console.log(data.status_code, "Statauscode");

      if (data) {
        setTopic(data.topic || "");
        setMobileNumber(data.new_phone || "");
        setDesignation(data.designation || "");
        setNoteAddedBy(data.note_added_by || "");
        setAddNotes(data.add_notes || "");
        console.log(data.status_code?.name || "", "000000")
        setFollowup(data.followup_datetime || "");
        setAmount(data.offer_price || "");
        setPriority(data.priority_flag || "");
        setCalltime(data.call_date || "");
        setDuration(data.call_duration || "");
        setEmail(data.new_email || "");
        setRemarks(data.followup_remarks || "");
        setFileNames(data.attachments?.map((attachment) => attachment?.fileName) || []);
        setStatusCode(data.status_code || "");
      }
    } catch (error) {
      console.error("Error fetching follow-up details:", error);
    }
  };

  const handleViewNotes = async () => {
    try {
      const response = await Axios.Get(
        `${API_VIEW_FOLLOW_UP}${props.detailId}`
      );
      console.log(response.data, "response.dataresponse.data");
      setNotesData(response.data);
    } catch (error) {
      console.error("Error fetching notes for case", ":", error);
    }
  };

  useEffect(() => {
    getTableRecords();
  }, []);
  console.log(props.editTd, "IDDDDD")
  console.log(duration, remarks, ",nbmmnmm,");
  const toggleModal = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      const formData = new FormData();
      formData.append("addNotes", addNotes);
      formData.append("topic", topic);
      formData.append("mobileNumber", mobileNumber);
      formData.append("designation", designation);
      formData.append("noteAddedBy", noteAddedBy);
      formData.append("caseId", props.editTd);
      formData.append("noteId", props.noteIds);
      // formData.append("employee", employee);
      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });
      const url = API_EDIT_SINGLE_NOTES;
      const res = await Axios.Post(url, formData);
      console.log(res, "res,mn,n,mn,m");
      if (res.data.success === true) {
        toast.success(res?.data?.message);
        props.setopenEditNotes(false);
        setTopic("");
        setMobileNumber("");
        setDesignation("");
        setNoteAddedBy("");
        setAddNotes("");
        setUploadFiles([]);
        setFormErrors({});
      } else {
        toast.success("Does not updated");
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
    }
  };

  const handleChangeCode = (e) => {
    setStatusCode(e.target.value);
  };
  const handleFollowDateChange = (e) => {
    const { value } = e.target;
    setFollowup(value);
  };
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };
  const handleCallduration = (e) => {
    const { value } = e.target;
    setDuration(value);
  };

  const handleChangeCalltime = (e) => {
    const { value } = e.target;
    setCalltime(value);
  };

  const handleChangeS = (e) => {
    setPriority(e.target.value);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFileNames = Array.from(files).map(file => file.name);
    setFileNames(newFileNames);
  };

  const handleSubmit = async () => {
    // if (!validateForm()) {
    //   return;
    // }
    try {
      const formData = new FormData();
      formData.append("new_mobileNumber", mobileNumber);
      formData.append("status_code", statusCode);
      formData.append("priority", prioriti);
      formData.append("follow_up", followup);
      formData.append("amount", amount);
      formData.append("duration", duration);
      formData.append("calltime", calltime);
      formData.append("new_email", email);
      formData.append("remarks", remarks);
      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const url = `${API_EDIT_FOLLOW_UP}${props.noteIds}`;
      const response = await Axios.post(url, formData);

      console.log("Add Notes API Response:", response.data);

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
        setStatusCode(" ");
        setPriority(" ");
        // setFollowUp([]);
        setDuration(" ");
        setCalltime(" ");
        setRemarks(" ");
        setEmail(" ");
        setAmount(" ");
        setUploadFiles([]);
        setFormErrors({});
        setViewAddForm(false), setViewList(true);
        setMobileNumber("");
        handleViewNotes();
        props.notesList();
        props.setopenEditNotes(false);
        props.handleViewNotes();

      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
      // toast.error("Failed to add notes.");
    }
  };

  return (
    <section className="Sales_analytics">
      <div fluid>
        <div className="revenueTable-conatiner">
          <div className="modal-header">
            <h1 className="modal-title mt-3 mb-3 mx-2">
              <b>Edit Follow Up</b>
            </h1>
            <button
              type="button"
              className="close"
              onClick={() => props.setopenEditNotes(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="" style={{ padding: "20px 20px" }}>
            <Row>
              <Col md="6" xs="12" className="mt-a-5">
                {/* {console.log(statusCode, "statusCode0000")}
                {console.log(props.codes, "statusCode111")}
                {console.log(typeof statusCode.id, statusCode.name, props.codes, statusCode, "statusCode0000")} */}
                <SELECT
                  name="statusCode"
                  value={statusCode.id}
                  onChange={handleChangeCode}
                  label="Status Code"
                  data={props.codes}
                />
              </Col>
              {statusCode === "12" && (
                <Col md="6">
                  <TEXTFIELD
                    type="text"
                    label="Amount"
                    onChange={handleAmountChange}
                    value={amount}
                    name="amount"
                  />
                </Col>
              )}
              <Col md="6">
                <TEXTFIELD
                  type="datetime-local"
                  label="Follow Up Date & Time"
                  onChange={handleFollowDateChange}
                  value={followup}
                  name="followup"
                />
              </Col>
              <Col md="6" xs="12" className="mt-a-5">
                <SELECT
                  name="prioriti"
                  value={prioriti}
                  onChange={handleChangeS}
                  label="Priority"
                  data={[
                    { moderate: "Moderate" },
                    { high: "High" },
                    { low: "Low" },
                  ]}
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="datetime-local"
                  label="Call Time Date"
                  onChange={handleChangeCalltime}
                  value={calltime}
                  name="calltime"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="text"
                  label="Call Duration"
                  onChange={handleCallduration}
                  value={duration}
                  name="duration"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Mobile Number:"
                  value={mobileNumber}
                  onKeyUp={validateForm}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={onlyNumber}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Email:"
                  value={email}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="12">
                <TEXTFIELD
                  label="Follow up & Remarks"
                  value={remarks}
                  onKeyUp={validateForm}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {formErrors.noteAddedBy && (
                  <div className="error-message">{formErrors.noteAddedBy}</div>
                )}
              </Col>
            </Row>
            {console.log(fileNames, "fileNames")}
            { /* <input
              className="my-3"
              type="file"
              value={fileNames}
              onChange={handleFileChange}
              multiple
            /> */}
            <input
              className="my-3"
              type="file"
              onChange={handleFileChange}
              multiple
            />
            {fileNames.length > 0 && (
              <div>
                {fileNames.map((fileName, index) => (
                  <div key={index}>{fileName}</div>
                ))}
              </div>
            )}
            <div className="modal-submit-btn text-center">
              <button
                type="button"
                className="btn jr-btn CBmargin CBmarginT M add-notes w-50"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditFollow;
