import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { API_URL, API_VERSION } from "../../../../../../ApiUrl";
import DashboardChart from "./dashboard";

import EditNotes from "./editNotes";

import { Button, Modal, ModalBody } from "reactstrap";

import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  toast,
  SUBMIT,
} from "../../../../../../components";
import Checkbox from "../Components/checkbox";
import "./revenueTable.css";

import filter from "../../../../../../assets/images/icone-de-filtre-grise.png";
import Editor from "./editor";
import { onlyNumber } from "../../../../../../util/onlyNumber";
import { set } from "nprogress";

const {
  API_GET_ALL_CASE_MANAGER_CASES,
  API_CASE_MANAGER_FILTER_CASES,
  API_GET_ALL_CASE_MANAGER_CHART,
  FILTER_DATA,
  ADD_NOTES,
  API_VIEW_FOLLOW_UP,
  API_DELETE_FOLLOW_UP,
  SELECTED_CASE_IDS,
  API_FILTER_SEARCH_COLLECTION,
  API_GET_FOLLOW_UP_CODES,
  API_ADD_FOLLOW_UP,
  API_SEND_REPLY,
} = ApiUrl;

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
}
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [month, day, year] = formattedDate.split("/");
  return `${day}.${month}.${year}`;
};

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [showVenue, setVenue] = useState(false);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [codes, setCodes] = useState([]);
  const [chartList, setChart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showfollowup, setShowFollowup] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [notesText, setNotesText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewNotesModalOpen, setViewNotesModalOpen] = useState(false);
  const [openEditNotes, setopenEditNotes] = useState(false);
  const [addNotes, setAddNotes] = useState("");
  const [lotData, setLotData] = useState([]);
  const [stagingData, setStagingData] = useState([]);
  const [itemId, setItemId] = useState("");

  const [topic, setTopic] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [noteAddedBy, setNoteAddedBy] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);

  const [reply, setReply] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [newTopic, setNewTopic] = useState("");
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [employee_id, setEmployee_id] = useState("");
  const [editTd, setSelectedEditId] = useState("");
  const [noteIds, setSelectedNoteId] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const [newNoteAddedBy, setNewNoteAddedBy] = useState("");
  const [newUploadFiles, setNewUploadFiles] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [stagesData, setStagesData] = useState([]);
  const [arbitratorData, setArbitratorData] = useState([]);
  const [lawFirmClientData, setLawFirmClientData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [statusCode, setStatusCode] = useState([]);
  const [followup, setFollowup] = useState([]);
  const [amount, setAmount] = useState([]);
  const [prioriti, setPriority] = useState([]);
  const [calltime, setCalltime] = useState([]);
  const [duration, setDuration] = useState([]);
  const [email, setEmail] = useState([]);
  const [remarks, setRemarks] = useState([]);

  const [showSearch, setShowSearch] = useState(false);
  const [replyBox, setreplyBox] = useState(false);

  const [formData, setFormData] = useState({
    status: "",
    created_at: "",
    created_at_from: "",
    created_at_to: "",
    arbitrator: "",
    hearing_date: "",
    state: "",
    venue: "",
    client: "",
    lot: "",
    notes: "",
    msg_type: "",
    msg_status: "",
    priority: "",
    follow_up: "",
    status_code: "",
    amount: "",
    call_time: "",
    duration: "",
    email: "",
    remarks: "",
    follow_up_call_date: "",
    follow_up_followup_datetime: "",
    follow_up_new_phone: "",
    follow_up_new_email: "",
    follow_up_offer_price: "",
    follow_up_priority_flag: "",
    follow_up_status_code: "",
  });

  const adminId = localStorage.getItem("_id");

  console.log(adminId, "adminId");
  // const handleNewFileChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setNewUploadFiles(filesArray);
  // };
  const handleNewFileChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setNewUploadFiles(filesArray);
  };
  const removefilter = () => {
    setFormData((prev) => ({ ...prev, fieldValue: "", fieldName: "" }));
    setShowSearch(!showSearch);
    refresh();
  };

  const handleChangeKey = (e) => {
    const { name, value } = e.target;

    // Update formData state based on the name of the input field
    if (name === "fieldName") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        fieldValue: "", // Reset fieldValue when fieldName changes
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // Call searchFilters with the correct data
    const searchData =
      name === "fieldName"
        ? { fieldName: value, fieldValue: "" }
        : { fieldName: formData.fieldName, fieldValue: value };

    // Ensure value is not empty before calling searchFilters
    if (value.trim() !== "") {
      searchFilters(searchData);
    }
  };

  const searchFilters = (value) => {
    console.log(value, formData.fieldName, "filterMetrics");
    setLoading(true);
    let data = {};
    let url = API_FILTER_SEARCH_COLLECTION;
    if (value.fieldName == "client") {
      data = `?client_name=${value.fieldValue}`;
    } else if (value.fieldName == "respondent_name") {
      data = `?respondent_name=${value.fieldValue}`;
    } else if (value.fieldName == "LAN") {
      data = `?LAN=${value.fieldValue}`;
    } else {
      data = `?case_number=${value.fieldValue}`;
    }

    Axios.get(url + data)
      .then((response) => {
        console.log(response, "responsexzcxzczxc");
        var data = response.data.data;
        console.log(data, "xzcxzczxc");
        setList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("");
      });
  };

  const getCodes = async () => {
    Axios.get(API_GET_FOLLOW_UP_CODES)
      .then((response) => {
        const codes = response.data;
        const stages = codes.data.map((s) => {
          return { [s.id]: s.name };
        });
        setCodes(stages);
      })
      .catch((err) => {
        console.log("");
      });
  };

  const filterData = async (type) => {
    try {
      const response = await Axios.get(`${FILTER_DATA}/${type}` + "/mediation");
      if (type === "state") {
        setStateData(response.data.data.map((item) => ({ value: item.state })));
      } else if (type === "venue") {
        setVenueData(response.data.data.map((item) => ({ value: item.venue })));
      } else if (type === "stages") {
        console.log(response.data, "datachecking");
        setStagingData(response.data.data);
      } else if (type === "stages") {
        setStagesData(response.data.data.map((item) => item.stage));
        value;
      } else if (type === "arbitrator") {
        setArbitratorData(
          response.data.data.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        );
      } else if (type === "lot") {
        console.log(response.data.data, "response.data.data");
        setLotData(
          response.data.data.map((item) => ({
            value: item.lot,
            label: item.lot,
          }))
        );
      } else if (type === "seat") {
        setSeatData(
          response.data.data.map((item) => ({
            value: item.seat,
            label: item.seat,
          }))
        );
      } else if (type === "lawFirmClient") {
        setLawFirmClientData(
          response.data.data.map((item) => ({ value: item.client }))
        );
      }
    } catch (error) {
      console.error(`Error filtering ${type} data:`, error);
    }
  };

  const handlenotassignChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      not_have_hearing_date: value,
    }));
  };

  const handleChangeHearingDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    filterData("state");
    filterData("venue");
    filterData("stages");
    filterData("arbitrator");
    filterData("lawFirmClient");
    filterData("seat");
    filterData("lot");
  }, []);

  useEffect(() => {
    getTableRecords();
    getChartRecords();
    getCodes();
    // setList(data);
  }, []);

  const getTableRecords = async (order, orderBy) => {
    setLoading(true);
    Axios.get(API_GET_ALL_CASE_MANAGER_CASES)
      .then((res) => {
        const list = res.data;
        setList(list.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("");
      });
  };
  const getChartRecords = async (order, orderBy) => {
    setLoading(true);
    Axios.get(API_GET_ALL_CASE_MANAGER_CHART)
      .then((res) => {
        const list = res.data;
        console.log("checkimngggg", list);
        setChart(list);
        setLoading(false);
      })
      .catch((err) => {
        console.log("");
      });
  };
  const handleOpenModal1 = () => {
    setShowModal(true);
  };
  const handleFollowupModal = () => {
    setShowFollowup(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeS = (e) => {
    const { name, value } = e.target;
    setPriority(e.target.value);
  };
  const handleReply = (e) => {
    setReply(e.target.value);
  };
  const handleChangeCode = (e) => {
    const { name, value } = e.target;

    console.log(e.target, "e.targetkjhk");
    setStatusCode(e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;

    const formattedDate = value
      ? new Date(value).toISOString().split("T")[0]
      : "";
    setFormData({ ...formData, [name]: formattedDate });
  };
  const handleHearingDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      hearing_date: value,
    }));
  };
  const handleFollowDateChange = (e) => {
    const { value } = e.target;
    setFollowup(value);
  };
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };
  const handleCallduration = (e) => {
    const { value } = e.target;
    setDuration(value);
  };
  const handleChangeCalltime = (e) => {
    const { value } = e.target;
    setCalltime(value);
  };
  // const handleChangeState = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     state: value,
  //   }));
  // };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      state: value,
    });
  };

  const handleChangeVenue = (event) => {
    const { value } = event.target;

    setFormData({
      ...formData,
      venue: value,
    });
  };

  const handleChangeSeat = (event) => {
    const { value } = event.target;

    setFormData({
      ...formData,
      seat: value,
    });
  };

  const handleChangeClient = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      lot: value,
    }));
  };
  const handleChangeNotes = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      notes: value,
    }));
  };
  const handleChangeDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const refresh = () => {
    getTableRecords();
    setCount();
    setResponseData([]);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleCloseFollowup = () => setShowFollowup(false);

  const handleConfirmSelection = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== "")
    );
    Axios.Post(API_CASE_MANAGER_FILTER_CASES, filteredFormData)
      .then((response) => {
        console.log(response, "ree");
        setList(response?.data?.data);
        setCount(response?.data?.data?.length);
        setShowModal(false);
        setShowFollowup(false);
        setFormData({});
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.onFormSubmit(event);
    }
  };

  const toggleRev = (index) => {
    setShow((prev) => (prev === index ? null : index));
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const handleOpenModal = (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };
  const openEditModal = (caseId, noteId) => {
    console.log(caseId, noteId, "checking no");
    setSelectedEditId(caseId);
    setSelectedNoteId(noteId);
    setViewNotesModalOpen(false);
    setopenEditNotes(true);
  };

  const submitReply = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("reply", reply);

      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const res = await Axios.post(API_SEND_REPLY + noteIds, formData);
      if (res.data.success === true) {
        toast.success(res?.data?.message);
        setReply("");
        setreplyBox(false);
        setUploadFiles([]);
        handleViewNotes();
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
    }
  };

  const toggleViewNotes = (itemId) => {
    setItemId(itemId);
    setViewNotesModalOpen(true);
    setTimeout(() => {
      handleViewNotes();
    }, 1000);
  };
  useEffect(() => {
    if (itemId !== null) {
      handleViewNotes();
    }
  }, [itemId]);

  const handleViewNotes = async () => {
    console.log(itemId, "itemId");

    try {
      const response = await Axios.Get(`${API_VIEW_FOLLOW_UP}${itemId}`);
      console.log(response.data, "response.dataresponse.data");
      setNotesData(response.data);
    } catch (error) {
      console.error("Error fetching notes for case", itemId, ":", error);
    }
  };

  const toggleViewNotesModal = () => {
    setViewNotesModalOpen(!viewNotesModalOpen);
  };

  const downloadFile = async (files) => {
    console.log(files, "files checgggggkinggggg");

    try {
      for (let i = 0; i < files.length; i++) {
        console.log("checgggggkinggggg");
        const file = files[i];
        const url = `${API_URL}${API_VERSION}/get-file/link/${file.fileHashId}`;
        const response = await fetch(url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", file.fileName); // Set custom file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  const handleDeleteNotes = async (caseId, noteId) => {
    try {
      const response = await Axios.Post(`${API_DELETE_FOLLOW_UP}/${noteId}`);
      if (response.data.success) {
        setNotesData((prevNotesData) => ({
          ...prevNotesData,
          data: prevNotesData.data.filter((note) => note.id !== noteId),
        }));
        toast.success("Note deleted successfully.");
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      toast.error("Failed to delete note.");
    }
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // if (!topic.trim()) {
    //   errors.topic = "Topic is required";
    //   isValid = false;
    // }

    // if (!mobileNumber.trim()) {
    //   errors.mobileNumber = "Mobile Number is required";
    //   isValid = false;
    // }

    // if (!designation.trim()) {
    //   errors.designation = "Designation is required";
    //   isValid = false;
    // }

    // if (!noteAddedBy.trim()) {
    //   errors.noteAddedBy = "Note Added By is required";
    //   isValid = false;
    // }

    // if (!addNotes.trim()) {
    //   errors.addNotes = "Add Notes is required";
    //   isValid = false;
    // }

    // if (uploadFiles.length === 0) {
    //   errors.uploadFiles = "At least one file must be uploaded";
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      status: selectedValue,
    }));
  };

  const handleArbitratorChange = (e) => {
    const selectedValue = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      arbitrator: selectedValue,
    }));
  };

  const toggleModal = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const formData = new FormData();
      formData.append("new_mobileNumber", mobileNumber);
      formData.append("status_code", statusCode);
      formData.append("priority", prioriti);
      formData.append("follow_up", followup);
      formData.append("amount", amount);
      formData.append("duration", duration);
      formData.append("calltime", calltime);
      formData.append("new_email", email);
      formData.append("remarks", remarks);
      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      // Submit the form
      const url = `${API_ADD_FOLLOW_UP}${selectedItemId}`;
      const response = await Axios.post(url, formData); // Changed from Axios.Post to Axios.post

      // Log the response to console
      console.log("Add Notes API Response:", response.data);
      if (response.data.success == true) {
        toast.success(response.data.message);

        // setTopic("");
        // setDesignation("");
        // setNoteAddedBy("");
        // setAddNotes("");
        // setEmployee("");
        // setMobileNumber("");
        setUploadFiles([]);
        setFormErrors({});
        setIsModalOpen(false);
        setStatusCode("");
        setPriority("");
        setFollowUp("");
        setAmount("");
        setDuration("");
        setCalltime("");
        setRemarks("");
        setEmail("");
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
      // toast.error("Failed to add notes.");
    }
  };

  const handleOpenModalAndViewNotes = async (itemId) => {
    setSelectedItemId(itemId);
    setIsModalOpen(true);
  };

  console.log(isCheck, "isCheckAll", typeof isCheck);

  const handleAddNotes = async () => {
    try {
      const errors = {};
      if (!newTopic) errors.newTopic = "Topic is required";
      if (!newMobileNumber)
        errors.newMobileNumber = "Mobile Number is required";
      if (!newDesignation) errors.newDesignation = "Designation is required";
      // if (!newNoteAddedBy) errors.newNoteAddedBy = 'Note Added By is required';
      if (!notesText) errors.notesText = "Add Notes is required";
      if (newUploadFiles.length === 0)
        errors.newUploadFiles = "Upload Files is required";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      // let caseIdsArray = [];
      // if (typeof isCheck === 'object' && isCheck !== null) {
      //   caseIdsArray = Object.values(isCheck);
      // } else if (Array.isArray(isCheck)) {
      //   caseIdsArray = isCheck;
      // }

      // console.log(typeof caseIdsArray, "LLLLL");
      const data = {
        notesText: notesText,
        newTopic: newTopic,
        newMobileNumber: newMobileNumber,
        newDesignation: newDesignation,
        newNoteAddedBy: newNoteAddedBy,
      };

      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      newUploadFiles.forEach((file) => {
        formData.append("newUploadFiles", file);
      });
      formData.append("case_id[]", caseIds);
      // let $formData = { formData, case_ids: isCheck };

      const response = await Axios.post(SELECTED_CASE_IDS, formData);

      if (response.status === 200) {
        handleCloseNotesModal();
        setNotesText("");
        setNewTopic("");
        setNewMobileNumber("");
        setNewDesignation("");
        setNewNoteAddedBy("");
        setNewUploadFiles([]);
        toast.success("Notes added successfully!");
      } else {
        toast.error("Failed to add notes.");
      }
    } catch (error) {
      console.error("Error adding notes:", error);
      toast.error("Failed to add notes.");
    }
  };

  const handleCancel = () => {
    setShow(false);
  };

  const handlePreview = (id) => {
    setShow(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenNotesModal = () => {
    setShowNotesModal(true);
    setNotesText("");
    setShowNotesModal(true);
  };

  const handleCloseNotesModal = () => {
    setShowNotesModal(false);
  };

  const handleNotesChange = (event) => {
    setNotesText(event.target.value);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setUploadFiles(filesArray);
  };

  console.log(uploadFiles[0]?.name, "filesArray");


  const cataloge =
    list?.length > 0 &&
    list?.slice(0, limit).map((item, index) => {
      const partyRespondentVs = item?.case_master_users?.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
      const respondentNameVs = partyRespondentVs
        ? partyRespondentVs.related_user.name
        : null;

      const respondentNames = item?.case_master_users?.reduce((acc, i) => {
        if (
          i.related_user_role === "Party" &&
          i.related_user_type === "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);
      console.log(item.id, "item.id");
      return (
        <div className="single-revenue-conatiner mt-2" key={item.id}>
          <div className="revenue-table-heading">
            <p className="checkbox-revenue">
              {" "}
              <span>
                <Checkbox
                  key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClick(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                />
              </span>
              <Link
                to={`/app/casemanager/case-detail/${item.hash_id}`}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Case No."
                className="d-flex"
              >
                <div className="ml-2 text-capitalize">
                  <span>{respondentNameVs} </span>
                  <span>
                    {respondentNames?.length === 1
                      ? `${"and" + `${" "}` + respondentNames}`
                      : respondentNames?.length === 2
                      ? respondentNames[0] + " & another"
                      : respondentNames?.length === 3 ||
                        respondentNames?.length > 3
                      ? respondentNames[0] + " & others"
                      : ""}
                  </span>
                </div>
              </Link>
            </p>
            <p className="checkbox-revenue-subtext">
              <span
                className="revenue-status"
                onClick={() => handleOpenModal(item.id)}
              >
                Add Follow Up
              </span>
              <span
                className="revenue-status view-notes"
                onClick={() => toggleViewNotes(item.id)}
              >
                View Follow Up
              </span>
            </p>

            {/*  <button className="add-notess-btn"></button>
          <Button className="mx-2" onClick={() => handleViewNotes(item.id)}>View Notes</Button>
            */}
          </div>
          <Link to={`/app/casemanager/case-detail/${item.hash_id}`}>
            <div>
              {item?.managers?.manager || item?.managers?.manager != null ? (
                <p>
                  <span className="pov123Text">Manager : </span>

                  {item.managers?.manager?.name}
                </p>
              ) : (
                ""
              )}

              {item?.managers?.casemanager ||
              item?.managers?.casemanager != null ? (
                <p>
                  <span className="pov123Text">Case Manager :</span>

                  {item?.managers?.casemanager?.name}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <p>
                  <span className="pov123Text">Case Status:</span>
                  <span className="pov123Text-status">{item.case_status}</span>
                </p>
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>

              <div className="filesrevenue-subconatinerpov">
                {item?.hearing &&
                  item?.hearing?.length > 0 &&
                  (() => {
                    const currentDate = new Date();

                    // Filter out previous and next hearings
                    const previousHearings = item?.hearing?.filter(
                      (hearing) => {
                        const startDate = new Date(
                          hearing?.hearing?.start_date
                        );
                        return startDate < currentDate;
                      }
                    );

                    const nextHearings = item.hearing.filter((hearing) => {
                      const startDate = new Date(hearing?.hearing?.start_date);
                      return startDate >= currentDate;
                    });

                    // Sort previous hearings by start_date in descending order
                    previousHearings.sort(
                      (a, b) =>
                        new Date(b.hearing?.start_date) -
                        new Date(a.hearing?.start_date)
                    );

                    // Sort next hearings by start_date in ascending order
                    nextHearings.sort(
                      (a, b) =>
                        new Date(a.hearing?.start_date) -
                        new Date(b.hearing?.start_date)
                    );

                    // Get the latest previous hearing and the earliest next hearing
                    const latestPreviousHearing = previousHearings[0];
                    const nextHearing = nextHearings[0];

                    return (
                      <>
                        {latestPreviousHearing && (
                          <div
                            className="filesrevenue-povBoxes"
                            key={latestPreviousHearing?.hearing_id}
                          >
                            <p>Previous</p>
                            <p>
                              {formatDate(
                                new Date(
                                  latestPreviousHearing?.hearing?.start_date
                                )
                              )}
                            </p>
                          </div>
                        )}
                        <div
                          className="filesrevenue-povBoxes"
                          key={
                            nextHearing ? nextHearing?.hearing_id : "no-next"
                          }
                        >
                          <p>Next</p>
                          <p>
                            {nextHearing
                              ? formatDate(
                                  new Date(nextHearing?.hearing?.start_date)
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </>
                    );
                  })()}

                {!item.hearing ||
                  (item?.hearing.every(
                    (hearing) =>
                      new Date(hearing?.hearing?.start_date) >= new Date()
                  ) && <></>)}
              </div>
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {/* {showVenue === index &&
          item.metas.map((m) => {
            return (
              <>
                <div className="revenue-state">
                  <div className="revenue-state-box">
                    <p className="text-court text-capitalize">{m.meta_key}: </p>
                    <p className="text-state text-capitalize">
                      {" "}
                      {m.meta_value}{" "}
                    </p>
                  </div>
                </div>
              </>
            );
          })} */}
          {showVenue === index &&
            item.metas
              .filter(
                (m) =>
                  m.meta_key !== "seat" &&
                  m.meta_key !== "venue" &&
                  m.meta_key !== "section_17"
              )
              .map((m) => {
                return (
                  <div className="revenue-state" key={m.meta_key}>
                    {console.log(item.metas, "TEsting")}
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {m.meta_value}
                      </p>
                    </div>
                  </div>
                );
              })}

          {show === index ? (
            <div onClick={() => toggleRev(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev(index)} className="text-center"></div>
          )}
        </div>
      );
    });

  return (
    <section className="Sales_analytics">
      <DashboardChart List={chartList} />
      <div fluid>
        <div className="revenueTable-conatiner">
          <div className="revenueTable-pagination-box mb-4">
            <div className="pgination-1st">
              {" "}
              <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </span>
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
              {/* <span className="check-po-icon" onClick={handleOpenNotesModal}>
                <i className="zmdi zmdi-calendar-note"></i>
              </span> */}
              {/* <span className="check-po-icon" onClick={handleOpenModal1}>
                <i class="zmdi zmdi-filter-list"></i>
              </span> */}
              <span className="check-po-icon" onClick={handleOpenModal1}>
                <span className="mr-2 mb-1">Filter</span>
                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
              </span>
              <span className="check-po-icon" onClick={handleFollowupModal}>
                <span className="mr-2 mb-1">Follow Up Filter</span>
                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
              </span>
              <span className="mr-2 mb-1 se12">Search</span>
              <span
                className="search56"
                onClick={() => setShowSearch(!showSearch)}
              >
                <i class="zmdi zmdi-search"></i>
              </span>
              <Modal isOpen={showModal} toggle={handleCloseModal}>
                <div className="modal-header">
                  <span className="folo-filter">Filters</span>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                  >
                    <span>×</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="4" xs="12">
                      {/* <SELECT
                        onChange={handleChange}
                        name="status"
                        label="Stages"
                        value={formData.status}
                        data={[
                          { registered: "Registered" },
                          { open: "Open" },
                          { closed: "Closed" },
                        ]}
                      /> */}
                      <FormControl className="select-multi">
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Stages
                        </InputLabel>
                        <Select
                          fullWidth
                          id="demo-mutiple-name"
                          onChange={handleChange}
                          name="status"
                        >
                          {/* <option value="pending" style={{ cursor: "pointer" }}>
                            Pending
                          </option>
                          <option
                            value="registered"
                            style={{ cursor: "pointer" }}
                          >
                            Registered
                          </option> */}
                          {stagingData &&
                            stagingData.map((e, key) => {
                              console.log(e, "checkingstatus");
                              return (
                                <option
                                  key="abc"
                                  value={e.stage}
                                  style={{ cursor: "pointer" }}
                                >
                                  {e.stage}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                    {/* <Col md="4" xs="12">
                      <SELECT
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        label="Status"
                        data={[
                          { pending: "Pending" },
                          { disposed: "disposed" },
                          { registered: "Registered" },
                        ]}
                      />
                    </Col> */}
                    <Col md="4" xs="12">
                      <TEXTFIELD
                        type="date"
                        label="Created At"
                        onChange={handleDateChange}
                        value={formData.created_at}
                        name="created_at"
                      />
                    </Col>

                    {/* <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.arbitrator}
                        name="arbitrator"
                        label="Arbitrator"
                        data={arbitratorData}
                      />
                    </Col> */}

                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Meeting Date"
                        onChange={handleHearingDateChange}
                        value={formData.hearing_date}
                        name="hearing_date"
                      />
                    </Col>
                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Meeting not assigned"
                        onChange={handlenotassignChange}
                        value={formData.not_have_hearing_date}
                        name="hearing_date"
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        onChange={handleChangeState}
                        value={formData.state}
                        name="state"
                        label="State"
                        data={stateData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChangeSeat}
                        value={formData.seat}
                        name="seat"
                        label="Seat"
                        data={seatData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.venue}
                        name="venue"
                        id="variation-select-6"
                        label="Venue"
                        data={venueData}
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        name="lot"
                        onChange={handleChangeClient}
                        value={formData.lot}
                        id="variation-select-8"
                        label="LOT"
                        data={lotData}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <SELECT
                        name="notes"
                        onChange={handleChangeNotes}
                        value={formData.notes}
                        id="variation-select-8"
                        label="Notes"
                        data={[{ yes: "Yes" }]}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <FormControl className="select-multi mt-0">
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Notice
                        </InputLabel>
                        <Select
                          fullWidth
                          id="demo-mutiple-name"
                          onChange={handleChange}
                          name="notice"
                        >
                          <option value="pending" style={{ cursor: "pointer" }}>
                            Pending
                          </option>
                          <option
                            value="registered"
                            style={{ cursor: "pointer" }}
                          >
                            Registered
                          </option>
                          {stagingData &&
                            stagingData.map((e, key) => {
                              return (
                                <option
                                  key="abc"
                                  value={e.stage}
                                  style={{ cursor: "pointer" }}
                                >
                                  {e.stage}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md="4" xs="12" style={{ marginTop: "3px" }}>
                      <SELECT
                        name="msg_type"
                        value={formData.msg_type}
                        onChange={handleChange}
                        label="Notice Type"
                        data={[
                          { whatsapp: "Whatsapp" },
                          { sms: "SMS" },
                          { email: "Mail" },
                        ]}
                      />
                    </Col>
                    <Col md="4" xs="12" style={{ marginTop: "4px" }}>
                      <SELECT
                        name="msg_status"
                        value={formData.msg_status}
                        onChange={handleChange}
                        label="Status"
                        data={[
                          { sent: "Sent" },
                          { delivered: "Delivered" },
                          { read: "Read" },
                          { UNDELIV: "Undelivered" },
                          { failed: "Failed" },
                        ]}
                      />
                    </Col>

                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Created Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="created_at_from"
                            value={formData.created_at_from}
                            onChange={handleChangeDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="created_at_to"
                            value={formData.created_at_to}
                            onChange={handleChangeDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Meeting Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="hearing_date_from"
                            value={formData.hearing_date_from}
                            onChange={handleChangeHearingDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="hearing_date_to"
                            value={formData.hearing_date_to}
                            onChange={handleChangeHearingDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="4" xs="12">
                      <TEXTFIELD
                        type="text"
                        label="Client"
                        onChange={handleChange}
                        value={formData.client}
                        name="client"
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleConfirmSelection}
                        className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal isOpen={showfollowup} toggle={handleCloseFollowup}>
                <div className="modal-header">
                <span className="folo-filter">Follow Up Filters</span>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseFollowup}
                  >
                    <span>×</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="6" xs="12">
                      <SELECT
                        name="follow_up_status_code"
                        value={formData.follow_up_status_code}
                        onChange={handleChangeCode}
                        label="Status Code"
                        data={codes}
                      />
                    </Col>

                    <Col md="6">
                      <SELECT
                        name="follow_up_offer_price"
                        value={formData.follow_up_offer_price}
                        onChange={handleChangeCode}
                        label="Offer Amount"
                        data={[{ yes: "Yes" }, { no: "No" }]}
                      />
                    </Col>

                    <Col md="6">
                      <TEXTFIELD
                        type="date"
                        label="Follow Up Date"
                        onChange={handleDateChange}
                        value={formData.follow_up_followup_datetime}
                        name="follow_up_followup_datetime"
                      />
                    </Col>
                    <Col md="6">
                      <TEXTFIELD
                        type="date"
                        label="Call Date"
                        onChange={handleDateChange}
                        value={formData.follow_up_call_date}
                        name="follow_up_call_date"
                      />
                    </Col>

                    <Col md={6}>
                      <SELECT
                        name="follow_up_priority_flag"
                        value={formData.follow_up_priority_flag}
                        onChange={handleChange}
                        label="Priority"
                        data={[
                          { moderate: "Moderate" },
                          { high: "High" },
                          { low: "Low" },
                        ]}
                      />
                    </Col>

                    <Col md="6">
                      <SELECT
                        name="follow_up_new_phone"
                        value={formData.follow_up_new_phone}
                        onChange={handleChange}
                        label="New Mobile Number"
                        data={[{ yes: "Yes" }, { no: "No" }]}
                      />
                    </Col>

                    <Col md="6">
                      <SELECT
                        name="follow_up_new_email"
                        value={formData.follow_up_new_email}
                        onChange={handleChange}
                        label="New Email"
                        data={[{ yes: "Yes" }, { no: "No" }]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleConfirmSelection}
                        className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal isOpen={showNotesModal} toggle={handleCloseNotesModal}>
                <div className="modal-header">
                  <h1 className="modal-title">Add Bulk Notes</h1>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseNotesModal}
                  >
                    <span>×</span>
                  </button>
                </div>
                <ModalBody>
                  <Editor cases={isCheck} />
                  {/* <div className="from-control" style={{ padding: "20px 10px" }}>
                    <Row>
                      <Col md="12">
                        <TEXTFIELD
                          label="Subject"
                          value={newTopic}
                          onChange={(e) => setNewTopic(e.target.value)}
                        />

                        {errors.newTopic && <div className="error-message">{errors.newTopic}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Add Notes"
                          value={notesText}
                          onChange={(e) => setNotesText(e.target.value)}
                        />
                        {errors.notesText && <div className="error-message">{errors.notesText}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Note added by/Name:"
                          value={newNoteAddedBy}
                          onChange={(e) => setNewNoteAddedBy(e.target.value)}
                        />
                        {errors.newNoteAddedBy && <div className="error-message">{errors.newNoteAddedBy}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Designation"
                          value={newDesignation}
                          onChange={(e) => setNewDesignation(e.target.value)}
                        />
                        {errors.newDesignation && <div className="error-message">{errors.newDesignation}</div>}
                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Mobile Number"
                          value={newMobileNumber}
                          onChange={(e) => setNewMobileNumber(e.target.value)}
                        />
                        {errors.newMobileNumber && <div className="error-message">{errors.newMobileNumber}</div>}

                      </Col>
                      <Col md="12">
                        <TEXTFIELD
                          label="Employee Id"
                          value={employee_id}
                          onChange={(e) => setEmployee_id(e.target.value)}
                        />
                        {errors.employee_id && <div className="error-message">{errors.employee_id}</div>}

                      </Col>

                      <Col md="12">
                        <input type="file" onChange={handleNewFileChange} multiple />

                        {errors.newUploadFiles && <div className="error-message">{errors.newUploadFiles}</div>}
                      </Col>
                    </Row>
                    <div className="text-center">

                      <button
                        type="button"
                        className="btn btn-warning jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-50"
                        onClick={handleAddNotes}
                      >
                        Add Notes
                      </button>
                    </div>
                  </div> */}
                </ModalBody>
              </Modal>
            </div>
            <div className="pagination-conatinerr">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option selected>20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value={list?.length}>{list?.length}</option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from {count == 0 ? 0 : list?.length} results{" "}
                </span>
              </div>
              {/* <nav>
                <ul className="pagination position-po pagination-text">
                  <span className="pto-5">|</span>
                  <li className="page-item pto-5">
                    <span onClick={prevList}> Prev</span>
                  </li>

                  <li key="" className="">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li className="page-item pto-5">
                    <span>Next</span>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
          {showSearch === true && (
            <div className="box-serch-filter0">
              <Row className="ml-2">
                <Col md={3}>
                  <SELECT
                    label="Filter Name"
                    name="fieldName"
                    onChange={handleChangeKey}
                    value={formData.fieldName}
                    data={[
                      { respondent_name: "Respondent Name" },
                      { case_number: "Case Number" },
                      { LAN: "LAN" },
                    ]}
                  />
                </Col>
                <Col md={3}>
                  <div className="form-search-header">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={formData.fieldValue}
                      name="fieldValue"
                      onChange={handleChangeKey}
                    />

                    {/* <button onClick={searchFilters} className="f-srch-i">
                        <i class="zmdi zmdi-search"></i>
                      </button> */}
                    {formData.fieldValue && (
                      <span
                        onClick={removefilter}
                        // style={disabled ? { display: "none" } : {}}
                      >
                        <i class="zmdi zmdi-close"></i>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {loading === false && list?.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          {/* {filteredCatalogue && filteredCatalogue?.length > 0 ? (
            <div className="revenue-scroll-container">{cataloge}</div>
          ) : count == 0 ? (
            <p className="n--data text-center mt-5">No data found</p>
          ) : (
            <div className="revenue-scroll-container">{cataloge}</div>
          )} */}
          <div className="revenue-scroll-container">{cataloge}</div>
        </div>
        <Modal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          size="md"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <div className="modal-header">
            <h1 className="modal-title mt-3 mb-3 mx-2">
              <b>Add Follow Up</b>
            </h1>
            <button
              type="button"
              className="close"
              onClick={() => setIsModalOpen(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="" style={{ padding: "20px 20px" }}>
            <Row>
              {/* <Col md="12">
                <TEXTFIELD
                  label="Subject"
                  value={topic}
                  onKeyUp={validateForm}
                  onChange={(e) => setTopic(e.target.value)}
                />
                {formErrors.topic && (
                  <div className="error-message ">{formErrors.topic}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Add Notes"
                  value={addNotes}
                  onKeyUp={validateForm}
                  onChange={(e) => setAddNotes(e.target.value)}
                />
                {formErrors.addNotes && (
                  <div className="error-message">{formErrors.addNotes}</div>
                )}
              </Col> */}

              {/* <Col md="12">

                    <TEXTFIELD
                      label="Note Added By"
                      value={noteAddedBy}
                      onKeyUp={validateForm}
                      onChange={(e) => setNoteAddedBy(e.target.value)}
                    />
                    {formErrors.noteAddedBy && <div className="error-message">{formErrors.noteAddedBy}</div>}
                  </Col> */}

              {/* <Col md="12">
                <TEXTFIELD
                  label="Designation"
                  value={designation}
                  onKeyUp={validateForm}
                  onChange={(e) => setDesignation(e.target.value)}
                />
                {formErrors.designation && (
                  <div className="error-message">{formErrors.designation}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Employee Id:"
                  value={employee}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmployee(e.target.value)}
                />
                {formErrors.employee && (
                  <div className="error-message">{formErrors.employee}</div>
                )}
              </Col> */}

              <Col md="6" xs="12" className="mt-a-5">
                <SELECT
                  name="statusCode"
                  value={statusCode}
                  onChange={handleChangeCode}
                  label="Status Code"
                  data={codes}
                />
              </Col>
              {statusCode == 12 && (
                <Col md="6">
                  <TEXTFIELD
                    type="text"
                    label="Amount"
                    onChange={handleAmountChange}
                    value={amount}
                    name="amount"
                  />
                </Col>
              )}
              <Col md="6">
                <TEXTFIELD
                  type="datetime-local"
                  label="Follow Up Date & Time"
                  onChange={handleFollowDateChange}
                  value={followup}
                  name="followup"
                />
              </Col>

              <Col md="6" xs="12" className="mt-a-5">
                <SELECT
                  name="prioriti"
                  value={prioriti}
                  onChange={handleChangeS}
                  label="Priority"
                  data={[
                    { moderate: "Moderate" },
                    { high: "High" },
                    { low: "Low" },
                  ]}
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="date"
                  label="Call Time Date"
                  onChange={handleChangeCalltime}
                  value={calltime}
                  name="calltime"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="text"
                  label="Call Duration"
                  onChange={handleCallduration}
                  value={duration}
                  name="duration"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Mobile Number:"
                  value={mobileNumber}
                  onKeyUp={validateForm}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={onlyNumber}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Email:"
                  value={email}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="12">
                <TEXTFIELD
                  label="Follow up & Remarks"
                  value={remarks}
                  onKeyUp={validateForm}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {formErrors.noteAddedBy && (
                  <div className="error-message">{formErrors.noteAddedBy}</div>
                )}
              </Col>
            </Row>
            <input
              className="my-3"
              type="file"
              onChange={handleFileChange}
              multiple
            />

            <div className="modal-submit-btn text-center">
              <button
                type="button"
                className="btn jr-btn CBmargin CBmarginT M add-notes w-50"
                onClick={toggleModal}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={openEditNotes}
          toggle={() => setopenEditNotes(false)}
          size="md"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <EditNotes
            setopenEditNotes={setopenEditNotes}
            editTd={editTd}
            noteIds={noteIds}
            codes={codes}
          />
        </Modal>

        <Modal isOpen={viewNotesModalOpen} toggle={toggleViewNotesModal}>
          <div className="modal-header">
            <h1 className="modal-title" style={{ fontWeight: "500" }}>
              Follow Up
            </h1>
            <button
              type="button"
              className="close"
              onClick={toggleViewNotesModal}
            >
              <span>×</span>
            </button>
          </div>
          <ModalBody>
            {notesData.data && notesData.data?.length > 0 ? (
              notesData.data.map((note) => (
                <div key={note.id}>
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Status :</b>

                            <span className="status-code11">
                              {" "}
                              {note?.status_code?.name}{" "}
                            </span>
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Follow Up Date & Time : </b>
                            {moment(note.followup_datetime).format(
                              "DD.MM.YYYY, HH:mm"
                            )}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Priority : </b>{" "}
                            <span
                              class={
                                note.priority_flag == "low"
                                  ? "red-dot-b red-dot-b-l"
                                  : note.priority_flag == "high"
                                  ? "red-dot-b red-dot-b-h"
                                  : note.priority_flag == "moderate"
                                  ? "red-dot-b red-dot-b-m"
                                  : "red-dot-b"
                              }
                            >
                              {" "}
                            </span>
                            <span className="status-code11 text-capitalize">
                              {note.priority_flag}
                            </span>
                          </p>
                        </Col>

                        <Col md="6">
                          {" "}
                          <p>
                            <b>Call Date : </b>
                            {moment(note.call_date).format("DD.MM.YYYY")}
                          </p>
                        </Col>

                        <Col md="6">
                          {" "}
                          <p>
                            <b>Call Duration :</b> {note.call_duration}{" "}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>New Mobile Number :</b> {note.new_phone}{" "}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>New Email :</b> {note.new_email}{" "}
                          </p>
                        </Col>

                        {/* <Col md="6">
                          {" "}
                          <p>
                            <b>Priority :</b>{" "}
                            {note.priority_flag == 1
                              ? "High"
                              : note.priority_flag == 2
                              ? "Moderate"
                              : "Low"}{" "}
                          </p>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md="12">
                          <div>
                            <p>
                              {" "}
                              <b>Remarks :</b> {note.followup_remarks}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="delete-btn"
                        style={{ textAlign: "right" }}
                      >
                        {" "}
                        {adminId == note?.created_by && (
                          <>
                            <span>
                              <i
                                className="zmdi zmdi-edit"
                                style={{
                                  fontSize: "20px",
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openEditModal(note.case_id, note.id)
                                }
                              />
                            </span>

                            <i
                              className="zmdi zmdi-delete delete-icn"
                              style={{
                                fontSize: "20px",
                                marginRight: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteNotes(note.case_id, note.id)
                              }
                            />
                          </>
                        )}
                        {note?.attachments?.length > 0 && (
                          <span>
                            <i
                              className="zmdi zmdi-download"
                              onClick={() => downloadFile(note?.attachments)}
                              style={{
                                fontSize: "25px",
                                cursor: "pointer",
                                marginTop: "4px",
                              }}
                            ></i>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="chkre">
                    {note?.replies?.length > 0 &&
                      note?.replies?.map((data, index) => {
                        const firstLetter = data?.replied_by?.name.charAt(0);
                        return (
                          <div key={index}>
                            <div>
                              <span
                                className={
                                  data?.replied_by?.role == "Chief Manager-L1"
                                    ? "firstletter-box color-piu"
                                    : data?.replied_by?.role == "casemanager"
                                    ? "firstletter-box color-oiu"
                                    : data?.replied_by?.role ==
                                      "lawfirm-manager"
                                    ? "firstletter-box color-giu"
                                    : data?.replied_by?.role == "Mediator"
                                    ? "firstletter-box color-miu"
                                    : "firstletter-box color-giu"
                                }
                              >
                                {firstLetter}
                              </span>
                              <span className="sdfs">
                                {" "}
                                {data?.replied_by?.name}
                              </span>
                              <span className="SfeR">
                                {" "}
                                {moment(data.created_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                              </span>
                            </div>
                            <div className="reply-list-box">
                              <p className="mb-0">
                                {" "}
                                {data.reply_text}
                                <span>
                                  <i
                                    className="zmdi zmdi-download"
                                    onClick={() =>
                                      downloadFile(data?.attachment)
                                    }
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                  ></i>
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div>
                    {replyBox == true && (
                      <div>
                        <form onSubmit={submitReply} noValidate>
                          <div className="mt-1 p-r-div">
                            <div class="file-upload-r reply-box-span">
                              <label for="fileInput" class="custom-file-upload">
                                <i class="zmdi zmdi-attachment-alt reply-box-span-i"></i>
                              </label>
                              <input
                                id="fileInput"
                                class="hidden-file"
                                type="file"
                                onChange={handleFileChange}
                                accept=".jpg,.png,.doc,.docx,.pdf"
                                multiple
                              />
                            </div>
                            <input
                              placeholder="Write Us"
                              name="reply"
                              value={reply}
                              onChange={handleReply}
                              onKeyDown={handleKeyDown}
                              className="reply-msg-box"
                            ></input>
                          </div>
                          <p className="m-1">{uploadFiles[0]?.name}</p>
                          <button className="reply-send" type="submit">
                            Send
                          </button>
                        </form>
                      </div>
                    )}
                    {replyBox == false && (
                      <span
                        className="reply"
                        onClick={() => {
                          setreplyBox(true), setSelectedNoteId(note.id);
                        }}
                      >
                        Reply
                      </span>
                    )}
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <p>No follow up available.</p>
            )}
            <button
              type="button"
              className="btn jr-btn CBmargin CBmarginT"
              onClick={toggleViewNotesModal}
              style={{ width: "20%" }}
            >
              Close
            </button>
          </ModalBody>
        </Modal>
      </div>
    </section>
  );
};

export default Revenuetable;
