import React, { Fragment, useState } from "react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  TEXTFIELD,
} from "../../../../../../components";
import send from "../../../../../../assets/images/send1.png";

const { API_SEND_MESSAGE, API_EDIT_TEMPLATE, API_GET_MESSAGES } = ApiUrl;

const data = [
  { message: "View your new message list View your new message list" },
  { message: "View your new message list" },
];
class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.editId,
      templateId: this.props.templateId,
      // id: 33,
      name: "",
      description: "",
      file: [],
      document_name: "",
      editid: "",
      tab: "",
      message: "",
      chatMessages: [],
      currentActiveTab: "1",
      adminId: localStorage.getItem("_id"),
      errors: {
        name: "",
        description: "",
        file: {},
        document_name: "",
      },
      process: this.props.role,
      loading: false,
    };
  }

  async componentDidMount() {
    console.log(this.props.templateId, "stateid");
    this.getMessages();
  }

  getMessages = () => {
    this.setState({ loading: true });

    let url = API_GET_MESSAGES;
    Axios.Get(url)
      .then((res) => {
        console.log(res, "true123");
        let response = res.data;

        if (res.data.success == true) {
          this.setState({ loading: false });

          this.setState({ chatMessages: response.data, message: "" });
        } else {
          console.log();
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        // toast.error(error);
      });
  };

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;
      case "document_name":
        errors.document_name = value.length < 1 ? "Required" : "";
        break;

      case "respondent":
        errors.respondent = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.onFormSubmit(event);
    }
  };
  toggle = (tab) => {
    if (this.state.currentActiveTab !== tab)
      this.setState({ currentActiveTab: tab });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { process, message } = this.state;

    this.setState({ loading: true });
    const data = {
      message: message,
    };

    // check validations
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);
    let url = API_SEND_MESSAGE;
    if (process == "edit") {
      url = API_EDIT_TEMPLATE;
    }

    Axios.Post(url, data)
      .then((res) => {
        console.log(res, "true123");
        let response = res.data;

        if (res.data.success == true) {
          // this.setState({ loading: false });
          // toast.success(response.message);
          this.setState({ message: "" });
          this.getMessages();
        } else {
          console.log();
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        // toast.error(error);
      });
    this.setState({ loading: false });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };

  render() {
    const {
      loading,
      document_name,
      fileName,
      disabled,
      currentActiveTab,
      errors,
      message,
      chatMessages,
      adminId,
    } = this.state;
    console.log(chatMessages, adminId, "chatMessages");
    return (
      <Fragment>
        <div style={{ minHeight: "300px", padding: "20px" }}>
          {loading && (
            <div className="text-center w-100">
              <CircularProgress />
            </div>
          )}

          <div className="message-box-header">
            <span>Inbox</span>
            {/* <p>All of your messages from Admin</p> */}
          </div>

          <div>
            <Nav tabs>
              {/* <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Messages
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  // onClick={() => {
                  //   this.toggle("2");
                  // }}
                >
                  Send Message
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="2">
                <div className="message-us-list-box">
                  <div className="message-us-list-sub-box">
                    <span className="message-us-list-icon-bx">
                      <i class="zmdi zmdi-info-outline"></i>
                    </span>
                    <span className="message-us-list-bold-text">
                      A new order list
                    </span>
                    <span className="message-us-list-simple-text">
                      {" "}
                      - View your order list
                    </span>
                    <span className="message-us-list-simple-text-right">
                      4 Jun
                    </span>
                  </div>
                </div>
                <div className="message-us-list-box">
                  <div className="message-us-list-sub-box">
                    <span className="message-us-list-icon-bx">
                      <i class="zmdi zmdi-info-outline"></i>
                    </span>
                    <span className="message-us-list-bold-text">
                      A new order list
                    </span>
                    <span className="message-us-list-simple-text">
                      {" "}
                      - View your order list
                    </span>
                    <span className="message-us-list-simple-text-right">
                      5 Jun
                    </span>
                  </div>
                </div>
              </TabPane>
            </TabContent>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <div>
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={this.onFormSubmit.bind(this)}
                    style={{ padding: "20px" }}
                  >
                    <Row className="chat-box-blank">
                      {chatMessages.map((sender, index) => {
                        return (
                          <Col md="12">
                            <div key={index}>
                              <p
                                className={
                                  adminId != sender.sender_id
                                    ? "sender-msg"
                                    : "receiver-msg"
                                }
                              >
                                {sender.text}
                              </p>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col md="12">
                        <input
                          placeholder="Write Us"
                          name="message"
                          value={message}
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeyDown}
                          className="write-msg-box"
                        ></input>
                        <span onClick={this.onFormSubmit.bind(this)}>
                          <img
                            src={send}
                            alt="send"
                            className="write-msg-box-span"
                          />
                        </span>
                      </Col>

                      {/* <Col md="2" className="mt-3">
                   
                      <SUBMIT title="Send" disabled={disabled} />
                    </Col> */}
                    </Row>
                  </form>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
