import React, { useState } from 'react';
import './HomeCarousel.css';

const HomeCarousel = () => {
    window.scroll(0, 0);
    return (
        <>
            <div className="row ">

                <div className='col-md-12'>
                    <div className='text-center'>
                        <h2 className='dispute' style={{ color: "goldenrod" }}>Got a Dispute? We've Got You!</h2> <br />
                        <p className="text-white fs-5  mt-2 mb-4 adipisicing">
                            Have a dispute with someone? Whether it's a family argument, a challenging business partner, a troublesome loan, or issues with your mobile service provider, we are here to assist you.
                        </p></div>
                </div>
            </div>
        </>
    );
};

export default HomeCarousel;
