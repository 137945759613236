import React, { Fragment, useState } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  Link,
} from "../../../../../components";
const { API_ADD_TEMPLATE, API_GET_TEMPLATE, API_EDIT_TEMPLATE } = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      editT: this.props.show,
      templateId: this.props.templateId,
      editTempId:this.props.hashhid,
      name: "",
      description: "",
      file: {},
      editid: "",
      errors: {
        name: "",
        description: "",
        file: {},
      },
      process: this.props.role,
      loading: false,
    };
  }

  async componentDidMount() {
    const { id, editT,editTempId } = this.state;
    this.setState({ loading: true });

    if (editT == true && id) {
      Axios.get(API_GET_TEMPLATE + editTempId)
        .then((response) => {
          var data = response.data.data;
          this.setState({
            name: data.name,
            description: data.description,
            editid: data.id,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error");
        });
    }
    this.setState({ loading: false });
  }

  redirectPage = () => {
    const { editid, process, templateId } = this.state;
    if (process == "edit") {
      this.props.history.push(`/app/template/list/${editid}`);
    } else {
      this.props.history.push(`/app/template/list/${templateId}`);
    }
  };

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value, validations, email } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;

      case "respondent":
        errors.respondent = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    const {
      editT,
      id,
      name,
      description,
      file,
      editTempId,
    } = this.state;
    const lawMediation = localStorage.getItem("lawMediation");

    console.log(id,editTempId,editT, "dddddddddd");

    this.setState({ loading: true });
    const data = {
      name: name,
      description: description,
      template: file,
    };

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);
    let url = lawMediation === "true" || lawMediation === null ? API_ADD_TEMPLATE + id :
    API_ADD_TEMPLATE + id + "/mediation"
  
    
    Axios.Post(url, formData)
      .then((res) => {
        let response = res.data;
        if (response.status == 200) {
          toast.success(response.message);
          this.setState({ show: true });
          this.props.hide(false);
          this.props.handleCancelT(false);
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    this.setState({ loading: false });
  };
  onFormUpdate = (e) => {
    e.preventDefault();
    const {
      editT,
      id,
      name,
      description,
      file,
      editid,
      editTempId,
    } = this.state;
    console.log(id,editTempId,editT, "dddddddddd");

    this.setState({ loading: true });
    const data = {
      name: name,
      description: description,
      template: file,
    };

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);
    let url = API_EDIT_TEMPLATE;
   

    Axios.Post( url + editid , formData)
      .then((res) => {
        console.log(res, "tghgytyres");
        let response = res.data;
        if (response.status == 200) {
          toast.success(response.message);
          this.setState({ show: true });
          this.props.hide(false);
          this.props.handleCancelT(false);
          // window.location.reload()
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    this.setState({ loading: false });
  };
  // onChange(evt) {
  //   const newAnswer = evt.editor.getData();
  //   this.setState({
  //     answer: newAnswer,
  //   });
  // }

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };
  render() {
    const {
      loading,
      name,
      description,
      template,
      fileName,
      errors,
      editT,
      editid,
      templateId,
      process,
    } = this.state;
    console.log(fileName,name,
      description, "fileName");

    var disabled = !name || !description ? true : "";

    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        {/* <Link
          to={`/app/client/case-list/${process == "edit" ? editid : templateId}`}
          style={{
            color: "grey",
            fontSize: "32px",
          }}
        >
          <i
            class="zmdi zmdi-arrow-left"
            style={{ fontSize: "22px", marginTop: "2px" }}
          ></i>
        </Link> */}
        <form
          noValidate
          autoComplete="off"
          onSubmit={editT == true && editid ? this.onFormUpdate.bind(this)  :this.onFormSubmit.bind(this) }
        >
          <Row>
            <Col md="4">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="8">
              <TEXTFIELD
                label="Description"
                value={description}
                name={"description"}
                onChange={this.handleChange}
                errors={errors}
              />
              {/* <CKEditor
                activeClass="mt-4 w-100"
                content={answer}
                events={{
                  change: this.onChange.bind(this),
                }}
              /> */}
            </Col>
            <Col md="6" xs="12" className="mt-2">
              <div class="file-upload-cus">
                <div class="file-select">
                  <div class="file-select-button" id="fileName">
                    Choose File
                  </div>
                  {fileName ? fileName : ""}
                  <div class="importcus">
                    <input
                      type="file"
                      name={"file"}
                      id="chooseFile"
                      accept=".docx, .doc, .pdf"
                      onChange={this.handleFile}
                    />
                  </div>
                </div>
              </div>
              
            </Col>
            {/* {fileName &&
            <Col md="4" className="mt-2">
            <div class="file-select-name">
                {fileName ? fileName : ""}
              </div>
            </Col>
            } */}

            <Col md="4" className="mt-2">
              <SUBMIT title="Save" disabled={disabled} />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
