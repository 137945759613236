import React, { Fragment } from 'react';
import { Card, CardBody} from "reactstrap";

const NotFound = () => (
    <Fragment>
      <img
        alt='No data available'
        style={{width: "3%"}}
        src={require('../../../assets/images/loading.gif') }
      />
    </Fragment>
);

export default NotFound;
