import React, { useState } from "react";

const Profile = () => {
 
  return (
 <div>Profile</div>
  );
};

export default Profile;
