import React, { Fragment, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FreshCaseForm from "./FreshCaseForm";
import ExistingCase from "./ExistingCase";

import {
  withRouter,
  Row,
  Col,
  TEXTFIELD,
  SUBMIT,
  RESET,
  Link,
  Loading
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const SignUp = ({ editId, role, history }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "5em" }} />
      <div style={{ paddingTop: "17vh" }}></div>
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-login-content w-100">
              <div className="text-center">
                <h1>Sign Up as Party</h1>
              </div>

              <div className="app-login-forma">
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  style={{ width: "600px" }}
                >
                  <Row className="mt-4">
                    <Col md="6">
                      <input
                        type="radio"
                        id="option1"
                        name="options"
                        value="option1"
                        checked={selectedOption === 1}
                        onChange={() => setSelectedOption(1)}
                      />
                      <label
                        style={{ fontSize: "21px", marginLeft: " 8px" }}
                        htmlFor="option1"
                      >
                        Fresh Case
                      </label>
                    </Col>
                    <Col md="6">
                      <input
                        type="radio"
                        id="option2"
                        name="options"
                        value="option2"
                        checked={selectedOption === 2}
                        onChange={() => setSelectedOption(2)}
                      />
                      <label
                        style={{ fontSize: "21px", marginLeft: " 8px" }}
                        htmlFor="option2"
                      >
                        Already existing case
                      </label>
                    </Col>
                  </Row>
                  {selectedOption === 1 && <FreshCaseForm />}
                  {selectedOption === 2 && <ExistingCase />}
                  <Row>
                    <Col md="12">
                      <div
                        className="text-center mt-3"
                        style={{ fontSize: "18px" }}
                      >
                        <Link to="/signin">Already have an account</Link>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default withRouter(SignUp);
