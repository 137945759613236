import React, { Fragment } from "react";

import {
    withRouter,
    Row,
    Col,
    toast,
    Axios,
    TEXTFIELD,
    SELECT,
    SUBMIT,
    ApiUrl,
    checkValidations,
    checkError,
    Loading,
    Link,
} from "../../../../../../../components";

import Addons from "./Addons";

const {
    GET_MAPPING_FIELDS,
    API_ADD_CASE_FILE,
    API_GET_BORROWER_TYPE
} = ApiUrl;

const itemsOBJ = {
    name: "",
    email: "",
    phone: "",
    address: "",
    borrower_type: ""
};
const itemsOBJ_new = Object.assign({}, itemsOBJ);

class AddEditForm extends React.Component {
    constructor(props) {
        super(props);
        console.log("Constructor: ", this.props.location.clientId);
        this.state = {
            editId: this.props.editId,
            noteId: 33,
            listId: this.props.listId,
            process: this.props.role,
            file: {},
            headerList: [],
            respondent: [{ ...itemsOBJ_new }],
            venue: "",
            seat: "",
            state: "",
            LAN: "",
            claim_amt: "",
            section_17: "",
            promotionImage: "",
            promotionImageName: "",
            type: "",
            data: {},
            name: "",
            description: "",
            email: "",
            phone: "",
            address: "",
            borrower_type: "",
            clientId: this.props.location.clientId,
            show: false,
            clientName: "",
            clientEmail: "",
            borowerList: "",
            errors: {
                file: "",
                respondent: [],
            },
            disabled: true,
        };
    }

    componentDidMount() {
        if (this.props.location.name) {
            localStorage.setItem("cname", this.props.location.name);

        }
        if (this.props.location.email) {
            localStorage.setItem("cEmail", this.props.location.email);
        }
        this.setState((prev) => ({
            ...prev,
            clientName: localStorage.getItem("cname"),
            clientEmail: localStorage.getItem("cEmail"),
        }));
        this.getType()
    }


    getType = async () => {
        Axios.get(API_GET_BORROWER_TYPE)
            .then((response) => {
                const res = response.data;
                const types = res.data.map((s) => {
                    return { [s.id]: s.name };
                });
                this.setState({ borowerList: types });
            })
            .catch((err) => {
                console.log("");
            });
    };


    updateAddons = (respondent) => {
        this.setState({ respondent, disabled: false });
    };
    handleChange = (event) => {
        console.log(event, "asdasdasdasd");
        event.preventDefault();
        const { name, value, validations, email } = event.target;
        let errors = this.state.errors;
        let finalVal = value;

        switch (name) {
            case "file":
                errors.file = value.length < 1 ? "Required" : "";
                break;

            case "respondent":
                errors.respondent = value.length < 1 ? "Required" : "";
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: finalVal }, () => {
            let err = checkError(errors);
            this.setState({ disabled: err.disabled });
        });
    };

    handleFile = (event) => {
        event.preventDefault();
        const { errors } = this.state;
        let file = event.target.files[0];
        let newErr = { ...errors, file: "" };

        this.setState(
            {
                file: file,
                errors: newErr,
            },
            () => {
                let err = checkError(newErr);
                this.setState({ disabled: err.disabled });
            }
        );
        console.log(file, "filefile");
        this.setState({ promotionImageName: file.name });
    };
    redirectPage = () => {
        this.props.history.push("/app/client/list");
    };

    handleUpload = (event) => {
        event.preventDefault();
        this.setState({ loading: true, disabled: true });

        const { errors, clientId, file, show } = this.state;
        let err = checkError(errors);
        if (err.disabled === true) {
            toast.error(err.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }
        let data = {
            case_file: file,
        };

        let valid = checkValidations(data);
        console.log(valid, "valid");
        if (valid.error) {
            toast.error(valid.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        data = valid.data;
        console.log(data, "datavalid");

        let url = GET_MAPPING_FIELDS;
        if (show == true) {
            url = API_ADD_CASE_FILE;
        }
        let formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        Axios.Post(url + clientId, formData)
            .then((res) => {
                let response = res.data;

                this.setState({ loading: false, disabled: false });

                if (["success", 200].includes(response.status)) {
                    toast.success(response.message);
                    this.setState({ show: true });
                } else {
                    {
                        res.data.error.case_file.map((er) => {
                            toast.error(er);
                        });
                    }
                }
                const data =
                    response.data.length > 0 &&
                    response.data.map((h, id) => {
                        console.log(h, "hjg");
                        return { [h]: h };
                    });
                this.setState({ headerList: data });
            })
            .catch((error) => {
                console.log(error, "errorrrrrr");
            });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("checking");
        this.setState({ loading: true, disabled: true });
        const {
            errors,
            clientId,
            file,
            show,
            respondent,
            venue,
            seat,
            state,
            LAN,
            claim_amt,
            section_17,
            name,
            description,
        } = this.state;
        let err = checkError(errors);
        if (err.disabled === true) {
            toast.error(err.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        let data = {
            case_file: file,
            respondent: JSON.stringify(respondent),
            name: name,
            description: description,
            venue: venue,
            seat: seat,
            state: state,
            LAN: LAN,
            claim_amt: claim_amt,
            section_17: section_17
        };

        data = data;
        console.log(data, "datavalid");

        let url = GET_MAPPING_FIELDS;
        if (show == true) {
            url = API_ADD_CASE_FILE;
        }
        let formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }

        Axios.Post(url + clientId, formData)
            .then((res) => {
                console.log(res, "41sdsds");
                let response = res.data;
                this.setState({ loading: false, disabled: false });

                if (["success", 200].includes(response.status)) {
                    toast.success(response.message);
                    this.setState({ show: true });
                    if (show == true) {
                        setTimeout(() => {
                            toast.warning("Need to approve case");
                            this.redirectPage();
                        }, 1000);
                    }
                } else {
                    toast.error(res.data.error);
                }
            })
            .catch((error) => {
                console.log(error, "errorrrrrr");
                this.setState({ loading: false, disabled: false });
            });
    };

    addRow = (e, type, i) => {
        e.preventDefault();

        this.setState((pre) => ({
            ...pre,
            respondent: [...pre.respondent, { ...AddonObj_new }],
        }));
        setIsChange(true);
    };

    deleteRow = (e, index, type, rIndex) => {
        e.preventDefault();
        const addons = state.respondent.filter((e, i) => i !== index);
        this.setState({
            respondent: [...addons],
        });
        setIsChange(true);
    };

    render() {
        const {
            errors,
            promotionImageName,
            disabled,
            loading,
            show,
            headerList,
            name,
            description,
            respondent,
            venue,
            seat,
            state,
            LAN,
            claim_amt,
            section_17,
            clientName,
            clientEmail,
            borowerList
        } = this.state;
        console.log(loading, borowerList, "loading");

        return (
            <Fragment>
                {loading && (
                    <div className="text-center w-100">
                        <Loading />
                    </div>
                )}

                <div
                    style={{
                        border: "1px solid #80808038",
                        boxShadow: "2px 2px 3px -3px",
                        margin: "8px 20px 28px",
                    }}
                >
                    <Col md={12} className="mt-2">
                        <Link
                            to="/app/client/list"
                            style={{
                                height: "26px",
                                marginLeft: "18px",
                                color: "#000",
                            }}
                        >
                            <i
                                class="zmdi zmdi-arrow-left"
                                style={{ fontSize: "22px", marginTop: "2px", color: "grey" }}
                            ></i>
                        </Link>
                    </Col>
                    <div className="add-case-header-text">

                        <Row>
                            <Col md={10} style={{ margin: "auto" }}>
                                <div className="excel-heading1-con1">
                                    <div>
                                        <p className="upload-text34h">
                                            Upload bulk data excel for generating new cases
                                        </p>
                                    </div>
                                    <div>
                                        <p className="excel-heading1"> Name : {clientName}</p>
                                        <p className="excel-heading1"> Email : {clientEmail}</p>
                                    </div>
                                </div>
                                <form
                                    onSubmit={show == false ? this.handleUpload : this.handleSubmit}
                                    noValidate
                                >
                                    <Row>
                                        <Col md="12" xs="12">
                                            <div class="file-upload-cus">
                                                <div class="file-select">
                                                    <div class="file-select-button" id="fileName">
                                                        Choose File
                                                    </div>
                                                    <div class="file-select-name" id="noFile">
                                                        {promotionImageName
                                                            ? promotionImageName
                                                            : "No file chosen..."}
                                                    </div>
                                                    <div class="importcus">
                                                        <input
                                                            type="file"
                                                            name={"file"}
                                                            id="chooseFile"
                                                            onChange={this.handleFile}
                                                            accept=".csv , .xlsx , .xls"
                                                            errors={errors}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <FILEUPLOAD
                label="Choose File"
                value={file}
                name={"file"}
                onChange={this.handleFile}
              /> */}
                                        </Col>
                                    </Row>

                                    {show == true && (
                                        <>
                                            <Row>
                                                <Col md="4" xs="12">
                                                    <TEXTFIELD
                                                        label="Batch Name"
                                                        value={name}
                                                        name={"name"}
                                                        onChange={this.handleChange}
                                                        errors={errors}
                                                    />
                                                </Col>

                                                <Col md="8" xs="12">
                                                    <TEXTFIELD
                                                        label="Batch Description"
                                                        value={description}
                                                        name={"description"}
                                                        onChange={this.handleChange}
                                                        errors={errors}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"venue"}
                                                        value={venue}
                                                        onChange={this.handleChange}
                                                        label="Venue"
                                                        data={headerList}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"seat"}
                                                        value={seat}
                                                        onChange={this.handleChange}
                                                        label="Seat"
                                                        data={headerList}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"state"}
                                                        value={state}
                                                        onChange={this.handleChange}
                                                        label="State"
                                                        data={headerList}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"LAN"}
                                                        value={LAN}
                                                        onChange={this.handleChange}
                                                        label="Loan Agreement Number"
                                                        data={headerList}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"claim_amt"}
                                                        value={claim_amt}
                                                        onChange={this.handleChange}
                                                        label="Claim Amount"
                                                        data={headerList}
                                                    />
                                                </Col>
                                                <Col md="4" xs="12">
                                                    <SELECT
                                                        name={"section_17"}
                                                        value={section_17}
                                                        onChange={this.handleChange}
                                                        label="Section 17"
                                                        data={headerList}
                                                    />
                                                </Col>
                                            </Row>
                                            <Addons
                                                data={respondent}
                                                headerList={headerList}
                                                updateAddons={this.updateAddons}
                                                borowerList={borowerList}
                                                errors={errors}
                                            />
                                        </>
                                    )}

                                    <Row>
                                        <Col md="12" xs="12">
                                            {show == false ? (
                                                <SUBMIT title="Upload" disabled={disabled} />
                                            ) : (
                                                <SUBMIT title="SUBMIT" disabled={disabled} />
                                            )}
                                            {/* <SUBMIT title="SUBMIT" disabled={disabled} /> */}
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(AddEditForm);