import React from "react";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  Axios,
  PASSWORDFIELD,
  Row,
  Col,
  SUBMIT,
} from "../components";
import axios from "axios";
import { API_LOGIN } from "../ApiUrl";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "../actions/Auth";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const tabsnav = [
  {
    name: "Arbitrator",
    value: "Arbitrator",
  },
  {
    name: "Mediator",
    value: "Mediator",
  },
  {
    name: "Advocate",
    value: "Advocate",
  },
  {
    name: "Party",
    value: "Party",
  },
];

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errorMessage: "",
      role: "Party",
      activeRole: 0,
      // disable: true,
    };
  }

  componentDidUpdate(e) {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  changeText = (text, index) => {
    this.setState({ role: text });
    this.setState({ activeRole: index });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // if (!phone) {
    //   this.setState({ disable: true })
    // } else if (!password) {
    //   this.setState({ disable: true })
    // }
  };
  registerFunction = (e) => {
    const message = "register";
    postMessage(message)
  };
  
  loginUser = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { phone, password, role } = this.state;

    const formData = {
      phone: phone.trim(),
      password: password.trim(),
      role: role,
    };
    // toast.success("Login successfully.");
    // setTimeout(() => {
    //   this.props.history.push("/app/dashboard/index");
    // }, 1000);

    // axios
    // .post("https://reqres.in/api/login", userData)
    // .then((response) => {
    //   console.log(response);
    // })
    // .catch((error) => {
    //   if (error.response) {
    //     console.log(error.response);
    //     console.log("server responded");
    //   } else if (error.request) {
    //     console.log("network error");
    //   } else {
    //     console.log(error);
    //   }
    // });

    axios
      .post("https://justsettle.in/backend/api/v1/login", formData)
      .then((response) => {
        const data = response.data;
        console.log(data, data.error, data.success, "dsddddsdsdd>>>>>>>>>");

        if (data.success == false) {
          toast.error(data.error);
        } else {
          console.log(data, "dsddddsdsdd");
          localStorage.setItem("token", data.authorization.access_token);
          localStorage.setItem("_id", data.user.id);
          localStorage.setItem("name", data.user.name);
          localStorage.setItem("role", data.role);
          // if (data.data.permissions && data.data.permissions.length > 0) {
          //   localStorage.setItem(
          //     "permissions",
          //     JSON.stringify(data.data.permissions)
          //   );
          // } else {
          //   localStorage.setItem("permissions", JSON.stringify([]));
          // }
          localStorage.setItem("user_id", data.authorization.access_token);
          this.props.userSignInSuccess(data.authorization.access_token);
          toast.success("Login successfully.");
          setTimeout(() => {
            const role = localStorage.getItem("role");
            {
              role == "Party"
                ? this.props.history.push("/app/party/dashboard/")
                : role == "Mediator"
                ? this.props.history.push("/app/arbitrator/dashboard/index")
                : role == "Advocate"
                ? this.props.history.push("/app/advocate/dashboard/index")
                : role == "Arbitrator"
                ? this.props.history.push("/app/arbitrator/dashboard/index")
                : role == "LawFirm"
                ? this.props.history.push("/app/lawfirm/dashboard/index")
                : role == "Client"
                ? this.props.history.push("/app/client/dashboard/")
                : role == "collection"
                ? this.props.history.push("/app/collection/dashboard/")
                : role == "Superadmin"
                ? this.props.history.push("/app/users/list")
                : "";
              // this.props.history.push("/app/dashboard/index")
            }
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err, "asasaas");
        // let error = err.response;
        // if (error !== undefined) {
        //   toast.error(error.data.message);
        // } else {
        //   toast.error("server error, Please try after some time.");
        // }
        this.props.hideAuthLoader();
      });
  };

  render() {
    const { phone, password, role, activeRole } = this.state;
    const { loader } = this.props;
    var diabled = !phone || !password ? true : "";
    return (
      <>
        <div className="party-signin-mob pb-4">
          <div className="">
            <p className="party-signin-text">SignIn as Party to Mediation</p>
          </div>
          <div className="">
            <div className="party-signin-div">
              <form>
                <Col xm="12">
                  <TEXTFIELD
                    label="Enter Phone No."
                    fullWidth
                    name="phone"
                    onChange={this.handleChange}
                    value={phone}
                    errors={{}}
                  />
                </Col>
                <Col xm="12">
                  <PASSWORDFIELD
                    label="Password"
                    fullWidth
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    errors={{}}
                  />
                </Col>
                <div className="mt-3">
                  <button
                    disabled={diabled}
                    className="party-login-mob"
                    onClick={(e) => this.loginUser(e)}
                  >
                    LogIn
                  </button>
                  <Link to="/forget-password" className="party-forget-mob">
                    Forgot Password?
                  </Link>
                </div>

                <div class="headline">
                  <p className="or-line">OR</p>
                </div>
              </form>

              <div className="text-center mt-4">
              <button
                    className="party-login-mob"
                    onClick={(e) => this.registerFunction(e)}
                  >
                    Register Now
                  </button>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  console.log(auth, "auth90890");
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);
