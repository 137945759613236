import React, { Fragment } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import {
  withRouter,
  Row,
  Col,
  Axios,
  TEXTFIELD,
  Link,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Loading,
  ImagePreview,
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const {
  API_ADVOCATE_REGISTER,
  EMAIL_REGEX,
  Numeric,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
  API_SEND_OTP,
  API_VERIFY_OTP,
} = ApiUrl;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      phone: "",
      enrollment_number: "",
      case_number: "",
      gender: "",
      city: "",
      state: "",
      pin_code: "",
      bar_license: "",
      address: "",
      status: "Active",
      bar_license: "",
      promotionImageName: "",
      otpShow: false,
      verifyOtp: false,
      otp: "",
      successMessage: "Mobile number will be verified",
      showModal: false,
      otpSent: false,
      mobileValidated: false,
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        enrollment_number: "",
        case_number: "",
        gender: "",
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
        address: "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process } = this.state;
  }
  handleFile = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let file = event.target.files[0];

    switch (name) {
      case "dl":
        if (value.length > 0 && !file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          errors.dl = "Image Format is not valide";
        } else {
          errors.dl = "";
        }
        break;
      default:
        break;
    }
    this.setState({ [name]: file }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
    console.log(file, "file");
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;

      case "role":
        errors.role = value.length < 1 ? "Required" : "";
        break;

      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = "Invalid email";
          }
        }
        errors.email = err;
        break;
      }
      case "phone": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.phone = err;

        break;
      }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid format";
          }
        }
        errors.password = err;

        break;
      }
      case "password_confirmation": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          // if (!PASS_REGEX.test(value)) {
          //   err = "Invalid format";
          // }
          if (value !== this.state.password) {
            err = "Not match";
          }
        }
        errors.password_confirmation = err;

        break;
      }
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  // handlMobileChange = (e) => {
  //   const { value } = e.target;
  //   console.log(value,"value132")
  //   let errors = this.state.errors;
  //   let finalVal = value;
  //   let err = "";
  //   // if (value.length < 1) {
  //   //   err = "Required";
  //   // } else {
  //   //   if (!Numeric.test(value)) {
  //   //     err = "Only numeric allowed";
  //   //     finalVal = "";
  //   //   }

  //   //   if (value.length <= 9 && value.length >= 0) {
  //   //     err = "Minimum 10 digits required";
  //   //   }
  //   // }
  //   // errors.phone = err;

  //    if (value.length === 10 && !this.state.mobileValidated) {
  //     this.setState({
  //       phone: value,
  //       successMessage: "Mobile number is valid",
  //       mobileValidated: true // Update flag to indicate mobile number is validated
  //     });
  //     this.sendOtp();
  //   }
  //   else if (!Numeric.test(value)) {
  //     let err = "";
  //     err = "Only numeric allowed";
  //     finalVal = "";
  //   }

  //   else if (value.length !== 10) {
  //     this.setState({
  //       phone: value,
  //       successMessage: "",
  //       mobileValidated: false, // Reset the flag if the mobile number is not valid
  //       otpSent: false // Reset the flag if the mobile number changes
  //     });
  //   }
  //   else if (value.length > 10) {
  //     this.setState({
  //       phone: "",
  //       mobileValidated: false, // Reset the flag if the mobile number is not valid
  //       otpSent: false // Reset the flag if the mobile number changes
  //     });
  //   }

  //   else {
  //     this.setState({
  //       phone: value,
  //       successMessage: "Mobile number is valid",
  //       mobileValidated: true // Update flag if the mobile number remains valid
  //     });
  //   }
  //   errors.phone = err;
  // };

  // handlMobileChange = (e) => {
  //   const { value } = e.target;
  //   let errors = { ...this.state.errors }; // Make a copy of errors object
  //   let err = "";

  //   if (!Numeric.test(value)) {
  //     err = "Only numeric allowed";
  //   } else if (value.length < 10) {
  //     this.setState({
  //       phone: value,
  //       successMessage: "",
  //       mobileValidated: false,
  //       otpSent: false,
  //     });
  //   } else if (value.length > 10) {
  //     this.setState({
  //       phone: "",
  //       mobileValidated: false,
  //       otpSent: false,
  //     });
  //   } else {
  //     // value.length === 10
  //     // Validate the format of the phone number here if needed
  //     this.setState(
  //       {
  //         phone: value,
  //         successMessage: "Mobile number is valid",
  //         mobileValidated: true,
  //         otpSent: false,
  //       },
  //       () => {
  //         // Call sendOtp only when the phone number becomes valid
  //         this.sendOtp();
  //       }
  //     );
  //   }

  //   // Update errors in state
  //   errors.phone = err;
  //   this.setState({ errors });
  // };

  handlMobileChange = (e) => {
    const { value } = e.target;

    // Validate input and set error message if necessary
    let err = "";
    if (!Numeric.test(value)) {
        err = "Only numeric allowed";
    }

    // Update state based on input value
    let newState = {};
    if (value.length === 10 && this.state.mobileValidated == false) {
        newState = {
            phone: value,
            successMessage: "Mobile number is valid",
            mobileValidated: true,
            otpSent: false
        };
    } else if (value.length < 10) {
        newState = {
            phone: value,
            successMessage: "",
            mobileValidated: false,
            otpSent: false
        };
    } else { // value.length > 10
        newState = {
            phone: "",
            mobileValidated: false,
            otpSent: false
        };
    }

    // Update state and errors
    this.setState(prevState => ({
        ...prevState,
        ...newState,
        errors: { ...prevState.errors, phone: err }
    }), () => {
        // Call sendOtp only when the phone number becomes valid
        if (newState.mobileValidated) {
            this.sendOtp();
        }
    });
};


  handleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  redirectPage = () => {
    this.props.history.push("/signin");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      editId,
      process,
      errors,
      role,
      email,
      address,
      password,
      password_confirmation,
      phone,
      enrollment_number,
      case_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      status,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      enrollment_number: enrollment_number,
      case_number: case_number,
      gender: gender,
      city: city,
      state: state,
      pin_code: pin_code,
    };
    if (password) {
      data.password = password;
    }
    if (password_confirmation) {
      data.password_confirmation = password_confirmation;
    }
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_ADVOCATE_REGISTER;
    if (process == "edit") {
      data.userId = editId;
      url = API_EDIT_ADMIN;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.Post(url, formData)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;
        toast.success(response.message);
        this.setState({ loading: false, disabled: false });

        if (response.status === 200) {
          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        // toast.error(res.message);
        // this.setState({ loading: false, disabled: false });
      });
  };
  verifyOtp = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { otp, phone, errors } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      otp: otp,
      phone: phone,
    };

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_VERIFY_OTP;

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.Post(url, data)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;
        toast.success(response.message);
        this.setState({ loading: false, disabled: false });

        if (response.success === true) {
            this.setState({ otpShow: false, showModal:false });
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        // toast.error(res.message);
        // this.setState({ loading: false, disabled: false });
      });
  };
  sendOtp = (event) => {
    // event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { phone, errors } = this.state;
    console.log(phone, "check phone");
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      phone: phone,
    };

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_SEND_OTP;

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.Post(url, data)
      .then((res) => {
        let response = res.data;
        console.log(response, response.error, "reskjkj");
        // toast.success(response.message);
        this.setState({ loading: false, disabled: false });

        if (response.success === true) {
          toast.success(response.message);
          setTimeout(() => {
            this.setState({ showModal: true });
          }, 1000);
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        let res = error.response;
      });
  };

  reset = () => {
    this.setState({
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      phone: "",
      address: "",
      enrollment_number: "",
      case_number: "",
      gender: "",
      city: "",
      state: "",
      pin_code: "",
      bar_license: "",
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        address: "",
        enrollment_number: "",
        gender: [],
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
      },
      disabled: true,
    });
  };

  render() {
    const {
      errors,
      name,
      email,
      password,
      password_confirmation,
      enrollment_number,
      case_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      address,
      phone,
      disabled,
      process,
      loading,
      otpShow,
      otp,
      verifyOtp,
      successMessage,
      showModal,
      mobileValidated
    } = this.state;
    console.log(showModal, "showModal");
    return (
      <>
        <Header />
        <ToastContainer style={{ top: "5em" }} />

        <div style={{ paddingTop: "17vh" }}></div>
        <Fragment>
          {loading && (
            <div className="text-center w-100">
              <Loading />
            </div>
          )}
          <div className="w-100 app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content w-100">
              <div className="app-login-content w-100">
                <div className="">
                  <h1>Sign Up as Advocate</h1>
                </div>

                <div className="app-login-forma">
                  {otpShow == false ? (
                    <form onSubmit={this.handleSubmit} noValidate>
                      <Row>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Name"
                            value={name}
                            name={"name"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        {/* <Col md="6" xs="12">
                      <TEXTFIELD
                        label="User Name"
                        value={name}
                        name={"name"}
                        onChange={this.handleChange}
                        errors={errors}
                      />
                    </Col> */}

                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Email"
                            value={email}
                            name={"email"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        {mobileValidated === false &&
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Mobile No."
                            name="phone"
                            onChange={this.handlMobileChange}
                            value={phone}
                            errors={errors}
                            max="10"
                          />
                          {successMessage}
                        </Col>
                      }
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Address"
                            value={address}
                            name={"address"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>

                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="City"
                            value={city}
                            name={"city"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Pin Code"
                            value={pin_code}
                            name={"pin_code"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="State"
                            value={state}
                            name={"state"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12" className="mt-3">
                          <SELECT
                            label="Gender"
                            value={gender}
                            name={"gender"}
                            onChange={this.handleChange}
                            errors={errors}
                            data={[{ 1: "Male" }, { 0: "Female" }]}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Enrolment No."
                            value={enrollment_number}
                            name={"enrollment_number"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Case No."
                            value={case_number}
                            name={"case_number"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>

                        <Col md="6" xs="12">
                          <PASSWORDFIELD
                            label="Password"
                            value={password}
                            name={"password"}
                            edit={process == "edit" ? true : false}
                            onChange={this.handleChange}
                            note={PASS_VALIDATION_MSG}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <PASSWORDFIELD
                            label="Confirm Password"
                            value={password_confirmation}
                            name={"password_confirmation"}
                            edit={process == "edit" ? true : false}
                            onChange={this.handleChange}
                            note={PASS_VALIDATION_MSG}
                            errors={errors}
                          />
                        </Col>
                        <Col md="6" xs="12">
                          <FILEUPLOAD
                            label="Image"
                            value={bar_license}
                            name={"bar_license"}
                            onChange={this.handleFile}
                            errors={errors}
                          />
                          {/* <ImagePreview
                                style={{ width: "125px", height: "125px", }}
                                src={bar_license}
                            /> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" xs="12">
                          <SUBMIT title="Submit" disabled={disabled} />
                        </Col>
                        <Col md="4" xs="12">
                          <RESET onClick={this.reset} />
                        </Col>
                      </Row>
                      <Link to="/signin">Already have an account</Link>
                    </form>
                  ) : (
                    <form
                      onSubmit={
                        verifyOtp === true ? this.verifyOtp : this.sendOtp
                      }
                      noValidate
                    >
                      <Row>
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Otp"
                            value={otp}
                            name={"otp"}
                            onChange={this.handleChange}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      {/* {verifyOtp === false ? (
                        <Row>
                          <Col md="8" xs="12">
                            <TEXTFIELD
                              label="Mobile No."
                              name="phone"
                              onChange={this.handleChange}
                              value={phone}
                              errors={errors}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md="6" xs="12">
                            <TEXTFIELD
                              label="Otp"
                              value={otp}
                              name={"otp"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                        </Row>
                      )} */}
                      <Row>
                        <Col md="4" xs="12">
                          <SUBMIT title="Submit" disabled={disabled} />
                        </Col>
                      </Row>
                      <Link to="/signin">Already have an account</Link>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <Modal isOpen={showModal} toggle={this.handleModal}>
              <p className="text-right m-0">
                <span onClick={this.handleModal} className="modal-cancel12">
                  <i class="zmdi zmdi-close"></i>
                </span>
              </p>
              <ModalBody>
                <form onSubmit={this.verifyOtp} noValidate>
                  <Row>
                    <Col md="6" xs="12">
                      <TEXTFIELD
                        label="Otp"
                        value={otp}
                        name={"otp"}
                        onChange={this.handleChange}
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4" xs="12">
                      <SUBMIT title="Submit" disabled={disabled} />
                    </Col>
                  </Row>
                  <Link to="/signin">Already have an account</Link>
                </form>
              </ModalBody>
            </Modal>
          </div>
        </Fragment>
        <div style={{ paddingTop: "17vh" }}></div>
        <Footer />
      </>
    );
  }
}

export default withRouter(SignUp);
