import React, { Fragment, useState } from "react";

const Aboutus = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Fragment>
        <section
          className="missionsec container m-agr"
          id="about"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          <div className="" style={{ margin: "20px 0px" }}>
            <h3>
              This Mediation Initiation Agreement ("Agreement") is entered into
              on [Date] by and between:
            </h3>
            <p>
              Details of Party A (Initiating Party/Claimant) and Details of
              Party B (Respondent Party) Collectively referred to as the
              "Parties."
            </p>
            <p>
              IN WITNESS WHEREOF, the Parties have executed this Agreement as of
              the date first written above.
            </p>
            <div>
              <p>
                {" "}
                1. The parties to this agreement have a dispute, arising out of
                agreement no. dated executed at _ and would like to resolve the
                dispute by mediation under the CHAMBER OF ARBITRATION AND
                MEDIATION’s Mediation Rules.
              </p>
              <p>
                {" "}
                2. The parties hereby understand and confirm that:', style:
                TextStyle(fontWeight: FontWeight.bold),
              </p>
            </div>
            <div>
              <p>
                {" "}
                (i) The dispute shall be mediated by the following Mediator(s),
                appointed by CHAMBER OF ARBITRATION AND MEDIATION by consent of
                parties, after the parties have verified the disclosure
                statement of the mediator(s) and satisfied that there are no
                circumstances likely to create a presumption of bias or prevent
                a prompt resolution of the dispute; Name of mediator(s):,
              </p>
              <p>
                {" "}
                (ii) Mediation will be a private and confidential process and
                will be conducted with the purpose of arriving at an acceptable
                resolution by settling the dispute by consensus; In the event
                that mediation fails to resolve the dispute, the parties agree
                and consent (out of their freewill) that any unresolved dispute
                will be resolved through binding institutionalized arbitration
                conducted by CHAMBER OF ARBITRATION AND MEDIATION (using the
                Just Settle interface) and that the decision of the arbitrator
                shall be final and binding, and judgment on the arbitration
                award may be entered in any court having jurisdiction thereof.,
              </p>
              <p>
                {" "}
                (iii) Mediation is a voluntary process and only the parties can
                reach a resolution and that the mediator(s) will not and cannot
                force a resolution on the parties;,
              </p>
              <p>
                {" "}
                (iv) Neither CHAMBER OF ARBITRATION AND MEDIATION nor the
                mediator(s) have guaranteed that the mediation will result in a
                settlement and until the parties reach settlement and sign the
                settlement agreement, any party is free to opt out of the
                process;,
              </p>
              <p>
                (v) In executing this agreement, the parties expressly agree to
                mediate in good faith and to either attend said mediation in
                person or to have a representative having final and binding
                settlement authority,
              </p>
              <p>
                {" "}
                (vi) The CHAMBER OF ARBITRATION AND MEDIATION Mediation rules
                and procedures and the provisions of Mediation Act, 2023 shall
                apply for mediation; in the event of the mediation proceedings
                failing to achieve fruition and the consequent initiation of
                arbitration proceeding the provisions of CHAMBER OF ARBITRATION
                AND MEDIATION Arbitration rules and procedures and the
                provisions of Arbitration and Conciliation Act, 2016 shall apply
                to the said arbitration proceedings.,
              </p>
              <p>
                (vii) The mediator(s) shall assist the parties in an independent
                and impartial manner in their attempt to reach an amicable
                settlement of their dispute;,
              </p>
              <p>
                (viii) The mediator(s) is not a legal advisor and is not to
                provide legal advice to any party involved in mediation. We
                agree to hold the said mediator(s) harmless for any
                observations, suggestions or implications that he/she may make
                in the course of mediation. We specifically agree to obtain
                legal advice on any issue of interest from qualified lawyers;,
              </p>
              <p>
                {" "}
                (ix) In the mediation process, the mediator(s) may meet all the
                parties together in general sessions or at times privately with
                one of the parties. The mediator may also communicate with the
                parties in both the sessions by way of online/virtual
                platforms.,
              </p>
              <p>
                {" "}
                (x) Every document, communication or information disclosed, or
                produced by any party for the purpose of or related to the
                mediation process shall be confidential. And this
                confidentiality shall also extend to the Mediated Settlement
                Agreement, in case the parties arrive at a settlement.,
              </p>
              <p>
                {" "}
                (xi) The mediator(s) may, at times, meet privately with any of
                the parties in this dispute. The mediator(s) will not disclose
                any information received during the private meeting without the
                permission of the party making disclosure. The mediator(s) will
                not reveal information provided by participants to third parties
                without the consent of all participants;,
              </p>
              <p>
                (xii) Other than the parties to mediation, there could be
                “Non-Parties” which could include counsels/ consultants/
                advisers for each party, experts in the subject matter,
                secretary/CHAMBER OF ARBITRATION AND MEDIATION staff or any
                third party who attend mediation proceedings, with the consent
                of all parties and permission of the mediator.,
              </p>
              <p>
                {" "}
                (xiii) The mediator(s) shall not be presented by the parties as
                a witness in any arbitral or judicial proceedings;,
              </p>
              <p>
                {" "}
                (xiv) The parties shall pay the mediator(s) fee and the CHAMBER
                OF ARBITRATION AND MEDIATION Administrative charges as intimated
                by the CHAMBER OF ARBITRATION AND MEDIATION Administrator and as
                per the current CHAMBER OF ARBITRATION AND MEDIATION Rules and
                Fee schedule;,
              </p>
              <p>(xv) The mediation proceedings shall be terminated—,</p>
              <div>
                <p>
                  {" "}
                  (i) Upon the signing of Mediated Settlement agreement by the
                  parties or;,
                </p>
                <p>
                  (ii) Upon the written advice of the mediator after
                  consultation with the parties that in the mediator’s opinion
                  further attempts at mediation are no longer justified or;,
                </p>
                <p>
                  {" "}
                  (iii) Upon written notification by any party at any time to
                  the mediator and the other parties that the mediation is
                  terminated.,
                </p>
                <p>
                  Signature of Initiating party: Signature of Opposite party:
                  Accepted: Signature of Mediator(s),
                </p>
              </div>
            </div>
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Click here to accept terms & conditions
                </label>
              </div>
            </div>
            <button className="agreement-btn">Accept Agreement</button>
          </div>
        </section>
      </Fragment>
    </>
  );
};

export default Aboutus;
