import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const ContactQuery = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/list"))}
      />
      <Route
        path={`${match.url}/add`}
        component={asyncComponent(() => import("./routes/add"))}
      />
      <Route
        path={`${match.url}/edit/:faqId`}
        component={asyncComponent(() => import("./routes/add"))}
      />
      <Route
        component={asyncComponent(() =>
          import("../../../app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default ContactQuery;
