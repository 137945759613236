

import React, { Component, Fragment } from "react";


// import Filters from "./Filters";
import {
    ApiUrl,
    withRouter,
    toast,
    Axios,
    DataTablePagination,
    DialogBox,
    Loading,
    moment,
    Link,
} from "../../../../../../../components";

const { API_GET_USERS_URL } = ApiUrl;

const getStatus = (Dkey) => {
    return (
        <span
            className={
                Dkey.status == 1 ? "status-tag1 clr-grn" : "status-tag1 clr-red"
            }
        >
            {Dkey.status == 1 ? "Active" : "In Active"}
        </span>
    );
};
const getRole = (Dkey) => {
    return Dkey.roles.map((item) => {
        return <span>{item.name}</span>;
    });
};
const columnData = [
    { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
    {
        id: "name",
        align: false,
        disablePadding: true,
        label: "Name",
        order: 1,
        extra: "catImage",
    },
    {
        id: "email",
        align: true,
        disablePadding: false,
        label: "Email",
        order: 1,
    },
    {
        id: "phone",
        numeric: false,
        disablePadding: false,
        label: "Mobile",
    },
    {
        id: "created_at",
        align: true,
        disablePadding: false,
        label: "Registered At",
        order: 1,
        type: "date",
    },
    { id: "action", align: true, disablePadding: false, label: "Action" },
];

class ListAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: columnData,
            data: "",
            totalCount: 0,
            rowsPerPage: 10,
            page: 0,
            order: -1,
            fieldName: "",
            status: this.props.listType,
            fieldValue: "",
            orderBy: "created_at",
            customerSearch: "",
            open: false,
            selectedId: "",
            currentActiveTab: "1",
        };
    }

    toggle = (tab) => {
        const currentActiveTab = this.state;
        if (currentActiveTab !== tab) this.setState({ currentActiveTab: tab });
    };
    componentDidMount = () => {
        console.log(this.state.rowsPerPage, this.state.page, "page123")

        this.getTableRecords(this.state.order, this.state.orderBy);
    };

    handleChangePage = (event, page) => {
        console.log(page, "520")
        this.setState({ page: page }, () =>
            this.getTableRecords(this.state.order, this.state.orderBy)
        );
    };

    handleRequestSort = (data) => {
        if (data.key !== "action") {
            let NewColoumns = this.state.columns.map((key, index) => {
                if (key.id !== data.key) {
                    return { ...key, order: 1 };
                } else {
                    return { ...key, order: data.order };
                }
            });

            this.setState(
                {
                    loading: true,
                    columns: NewColoumns,
                    orderBy: data.key,
                    order: data.order,
                },
                () => this.getTableRecords(data.order, data.key)
            );
        }
    };

    getTableRecords = async (order, orderBy) => {
        const { fieldName, fieldValue, page, rowsPerPage, status } = this.state;
        this.setState({ loading: true });
        let body = {
            orderBy: orderBy,
            order: order,
            page: parseInt(page) + 1,
            // status: status,
            limit: 20,
        };
        if (fieldName || fieldValue) {
            body.fieldName = fieldName;
            body.fieldValue = fieldValue;
        }
        Axios.post(API_GET_USERS_URL, body)
            .then((response) => {
                response = response.data;
                this.setState({
                    totalCount: response.data.length,
                    // totalCount: response.totalcount,
                    data: response.data,
                    loading: false,
                });
            })
            .catch((err) => {
                console.log("");
                this.setState({ loading: false });
            });
    };

    handleChange = (event) => {
        console.log(event);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    filterData = (e) => {
        e.preventDefault();
        this.getTableRecords(-1, "created_at");
    };

    removefilterbutton = () => {
        this.setState(
            {
                totalCount: 0,
                rowsPerPage: 10,
                page: 0,
                order: 1,
                fieldName: "firstname",
                fieldValue: "",
                orderBy: "created_at",
            },
            () => {
                this.getTableRecords(-1, "created_at");
            }
        );
    };
    handleClickOpen = (id) => {
        this.setState({
            open: true,
            selectedId: id,
        });
    };
    handleClose = () => {
        this.setState({ open: false });
    };

    deleteAction = (id) => {
        Axios.post(id)
            .then((res) => {
                this.setState({
                    open: false,
                    selectedId: "",
                    data: [...this.state.data.filter((cate) => cate._id !== id)],
                    totalCount: --this.state.totalCount,
                });
                toast.success(res.data.message);
            })
            .catch((err) => {
                this.setState({
                    open: false,
                    selectedId: "",
                });
                toast.error("Client does not deleted!");
            });
    };

    render() {
        const {
            data,
            fieldName,
            fieldValue,
            rowsPerPage,
            page,
            totalCount,
            loading,
            status,
            currentActiveTab,
        } = this.state;

        return (
            <Fragment>
                <DialogBox
                    title={"Client"}
                    open={this.state.open}
                    onClose={this.handleClose}
                    deleteAction={() => this.deleteAction(this.state.selectedId)}
                />
                {/*
                <Filters
                    fieldName={fieldName}
                    handleChange={this.handleChange}
                    fieldValue={fieldValue}
                    filterData={this.filterData}
                    removefilter={this.removefilterbutton}
                    exportButton={{
                        file: "Client.csv",
                        columns: { columnData },
                        url: API_GET_USERS_URL,
                        params: {
                            orderBy: "createdBy",
                            order: "-1",
                            fieldName: fieldName,
                            fieldValue: fieldValue,
                            page: 0,
                            limit: totalCount,
                            status: status,
                        },
                    }}
                /> */}
                <div className="flex-auto">
                    <DataTableBody2
                        loading={loading}
                        column={this.state.columns}
                        data={data}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        actions={[
                            { label: "View", icon: "", link: "/app/users/view" },
                            { label: "Edit", icon: "", link: "/app/users/edit" },
                            { label: "Delete", icon: "", link: this.handleClickOpen },
                        ]}
                    />
                    {data.length > 0 && (
                        <div className="float-right">
                            <DataTablePagination
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                rowsPerPageOptions={[]}
                            />
                        </div>
                    )}

                </div>
            </Fragment>
        );
    }
}

class DataTableBody2 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { loading, page, rowsPerPage, data, column, actions } = this.props;
        return (
            <div>
                {loading === false && data.length === 0 && (
                    <div className="n--data text-center m-5">No data Found!</div>
                )}
                {loading === true && (
                    <div className="text-center p-8o">
                        <Loading />
                    </div>
                )}
                {loading === false &&
                    data.length > 0 &&
                    data.map((Dkey, index) => {
                        console.log(Dkey, 333);
                        return (
                            <div
                                class="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
                                key={index}
                            >

                                <div class="client-listing">
                                    <div class="mb-2">
                                        {/* {++index + page * rowsPerPage} */}
                                        <span class="main-title mr-2"> {Dkey.name} </span>
                                        <span class="is-size-7 has-text-grey-dark">
                                            {" "}
                                            &nbsp;| &nbsp;{" "}
                                        </span>
                                        <span class="sub-title">
                                            {" "}
                                            {moment(Dkey.created_at).format("DD.MM.YYYY")}{" "}
                                        </span>
                                        {/* <Link to={`/app/client/edit/${Dkey.id}`}>
                        <span
                          className="float-right"
                          style={{ color: "black", fontSize: "14px" }}
                        >
                          <i class="zmdi zmdi-edit"></i>
                        </span>
                      </Link> */}
                                    </div>
                                    {/* <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <div class="">
                          <span class="stage-con">Email :</span>
                          <span class="dis-con"> {Dkey.email} </span>
                        </div>
                      </div>
                      <div class="ml-5">
                        <div class="">
                          <span class="stage-con">Phone no. :</span>
                          <span class="dis-con"> {Dkey.phone} </span>
                        </div>
                      </div>
                    </div> */}
                                </div>

                                <div>
                                    <div class="right-unlist d-flex align-items-center justify-content-center snk-j-normal bg-transparent">
                                        {/* <div class="mb-0">
                     <span  class="dis-con">Edit </span>
                     <span class="edit-title"><a href="" class="text-secondary"><i class="fa fa-edit"></i></a></span>
                  </div>  */}
                                        {/* {`${match.url}/notes/:editId`} */}
                                        <Link
                                            to={{
                                                pathname: `/app/client/case/add/${Dkey.hash_id}`,
                                                name: `${Dkey.name}`,
                                                email: `${Dkey.email}`,
                                            }}
                                            class="make-aapbtn ml-2 py-2"
                                        >
                                            Add Case
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: `/app/client/case-list/${Dkey.hash_id}`,
                                                name: `${Dkey.name}`,
                                                email: `${Dkey.email}`,
                                                id: `${Dkey.id}`
                                            }}
                                            class="make-aapbtn ml-2 py-2"
                                        >
                                            {" "}
                                            View Cases{" "}
                                        </Link>
                                        {/* <Link
                      to={{
                        pathname: `/app/client/headersets/${Dkey.id}`,
                        name: `${Dkey.name}`,
                        email: `${Dkey.email}`,
                      }}
                      class="make-aapbtn ml-2 py-2"
                    >
                      {" "}
                      Template Header Sets{" "}
                    </Link>
                    <Link
                      to={`/app/template/list/${Dkey.hash_id}`}
                      class="make-aapbtn ml-2 py-2"
                    >
                      {" "}
                      Templates{" "}
                    </Link> */}

                                        {/* <Action actions={actions} id={Dkey._id} /> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                {/* <TableBody>
        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <TableRow>
            <TableCell align="center" colSpan={9}>
              <Loading />
            </TableCell>
          </TableRow>
        )}
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            console.log(Dkey, 333);
            return (
              <TableRow hover tabIndex={-1} key={Dkey._id}>
                <TableCell padding="none" align="left">
                  {++index + page * rowsPerPage}
                </TableCell>
                <TableCell padding="none" align="left">
                  {`${Dkey.name}`}
                </TableCell>
                <TableCell padding="none" align="left">
                  {Dkey.email}
                </TableCell>
                <TableCell padding="none" align="left">
                  {Dkey.mobileNumber}
                </TableCell>
                <TableCell padding="none" align="left">
                  <span className={`label ${Dkey.status}`}>
                    {Status[Dkey.status]}
                  </span>
                </TableCell>
                <TableCell padding="none" align="right" numeric>
                  {moment(Dkey.created_at).format("YYYY-MM-DD")}
                </TableCell>
                {actions && (
                  <TableCell padding="none" align="center" key={index}>
                    <Action actions={actions} id={Dkey._id} />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
      </TableBody> */}
            </div>
        );
    }
}

export default withRouter(ListAll);
