import React, { Fragment, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FreshCaseForm from "./FreshCaseForm";
import ExistingCase from "./ExistingCase";

import {
  withRouter,
  Row,
  Col,
  TEXTFIELD,
  SUBMIT,
  RESET,
  Link,
  Loading,
  ApiUrl,
  PASSWORDFIELD,
  Axios,
  checkError,
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const {
  API_GET_OTP,
  API_VERIFY_OTP,
  API_FORGET_PASSWORD,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
} = ApiUrl;

class Forgetpassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password_confirmation: "",
      errorMessage: "",
      userName: true,
      otpView: false,
      otp: "",
      phone: "",
      loading: "",
      disabled: true,
    };
  }
  handleChange = (event) => {
    if (event.target.name === "password") {
      if (!PASS_REGEX.test(event.target.value)) {
        this.setState({ helperText1: PASS_VALIDATION_MSG });
      } else {
        this.setState({ helperText1: "Valid Password" });
      }
    }
    if (event.target.name === "password_confirmation") {
      if (event.target.value !== this.state.password) {
        this.setState({
          helperText2: "Confirm Password should be matched to Password.",
        });
      } else {
        this.setState({ helperText2: "Password matched", error: false });
      }
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const {
      phone,
      otpView,
      userName,
      otp,
      password,
      password_confirmation,
      userId,
    } = this.state;

    this.setState({ loading: true });
    let data = {};
    let url = API_VERIFY_OTP;

    console.log(password, password_confirmation, otp, phone, "kjkljklk");

    if (otpView === true && otp) {
      data.otp = otp;
      data.phone = phone;
      url = API_VERIFY_OTP;
    }
    if (userName === true && phone) {
      data.phone = phone;
      url = API_GET_OTP;
    } else {
      data.password = password;
      data.password_confirmation = password_confirmation;
      data.otp = otp;
      data.phone = phone;
    }
    console.log(otpView, "userName before");
    Axios.post(url, data)
      .then((res) => {
        let response = res.data;
        console.log(res, response.error, "datares");
        if (response.success === true) {
          toast.success(response.message);
          this.setState({ userName: false, otpView: true, loading: false });
          if (otpView === true && otp) {
            console.log(otpView, userName, "userName after if");

            this.setState({ otpView: false, loading: false });
          }
          if (userName === false && otpView === false) {
            console.log("checkinh one");
            this.props.history.push("/signin");
          }
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  resendOtp = (e) => {
    e.preventDefault();
    const { otpView, otp, phone, userName, loading } = this.state;

    this.setState({ loading: true });
    let data = {
      phone: phone,
    };
    let url = API_GET_OTP;

    Axios.post(url, data)
      .then((res) => {
        let response = res.data;
        console.log(res, response.error, "datares");
        if (response.success === true) {
          toast.success(response.message);
          this.setState({ userName: false, otpView: true, loading: false });
          if (otpView === true && otp) {
            this.setState({ otpView: false, loading: false });
          }
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  render() {
    const {
      loading,
      password,
      password_confirmation,
      oldPassword,
      companyId,
      helperText1,
      helperText2,
      error,
      disabled,
      userName,
      phone,
      otp,
      otpView,
    } = this.state;
    console.log(otp, otpView, "disabled");
    var passwordEdit =
      !password ||
      !password_confirmation ||
      password !== password_confirmation ||
      !PASS_REGEX.test(password) ||
      error
        ? true
        : false;

    return (
      <>
        <Header />
        <ToastContainer style={{ top: "5em" }} />
        <div style={{ paddingTop: "17vh" }}></div>
        <Fragment>
          {loading && (
            <div className="text-center w-100">
              <Loading />
            </div>
          )}
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
              <div className="app-login-content w-100">
                <div className="text-center">
                  <h1>
                    {userName === true
                      ? "Forgot Password"
                      : otpView === true
                      ? "Verification Required"
                      : "Create New Password"}
                  </h1>
                  <p>
                    {userName === true ? (
                      "Enter the phone number associated with your account."
                    ) : otpView === true ? (
                      <p>
                        {" "}
                        To continue, complete this verification step.
                        <br /> we've sent an OTP to phone number. please enter
                        it below
                      </p>
                    ) : (
                      "We'll ask for this passsword whenever you Sign-In"
                    )}
                  </p>
                </div>

                <div className="app-login-forma">
                  <form onSubmit={this.onFormSubmit.bind(this)}>
                    {userName === true ? (
                      <Row>
                        <Col xm="12">
                          <TEXTFIELD
                            label="Mobile Number"
                            value={phone}
                            name={"phone"}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md="12" className="mt-2">
                          <SUBMIT
                            title="Continue"
                            disabled={false}
                            className="clrfff"
                          />
                        </Col>
                      </Row>
                    ) : otpView === true ? (
                      <>
                        <Row>
                          <Col xm="6">
                            <TEXTFIELD
                              label="Enter OTP"
                              value={otp}
                              name={"otp"}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" className="mt-2">
                            <SUBMIT
                              title="Continue"
                              disabled={false}
                              className="clrfff"
                            />
                          </Col>
                        </Row>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={this.resendOtp.bind(this)}
                        >
                          Resend OTP
                        </span>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col xm="6">
                            <PASSWORDFIELD
                              label="New Password"
                              name="password"
                              onChange={this.handleChange}
                              value={password}
                              helperText={helperText1}
                            />
                          </Col>
                          <Col md="6">
                            <PASSWORDFIELD
                              label="Confirm Password"
                              name="password_confirmation"
                              onChange={this.handleChange}
                              value={password_confirmation}
                              helperText={helperText2}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="5" className="mt-2">
                            <SUBMIT
                              title="Reset Password"
                              disabled={false}
                              className="clrfff"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        <Footer />
      </>
    );
  }
}

export default withRouter(Forgetpassword);
