import React, { useState, useEffect } from "react";
import {
  withRouter,
  toast,
  Axios,
  CircularProgress,
  Row,
  Container,
  Col,
  TEXTFIELD,
  ApiUrl,
  moment,
} from "../../../../../../components";

import { Button, Modal, ModalBody } from "reactstrap";
import Casetabs from "../SingleCase/files/tabs";
import { Link } from "react-router-dom";

const { CLIENT_SINGLE_CASE, UPLOADING_FILES, JOIN_MEETING } = ApiUrl;

const Casedetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [notfound, setNotFound] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [document_name, setDocumentName] = useState("");
  const [document_description, setDocument_description] = useState("");
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});

  const [id, setId] = useState("");

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setDocumentName("");
      setDocument_description("");
      setFiles([]);
      setErrors({});
    }
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}.${month}.${year}`;
  };
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    let formValid = true;
    const errorsCopy = { ...errors };
    if (!document_name.trim()) {
      errorsCopy.document_name = "Document name is required";
      formValid = false;
    }
    if (!document_description.trim()) {
      errorsCopy.document_description = "Document description is required";
      formValid = false;
    }
    if (files.length === 0) {
      errorsCopy.files = "Please select at least one file";
      formValid = false;
    }
    setErrors(errorsCopy);
    if (!formValid) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("document_name", document_name);
      formData.append("document_description", document_description);
      formData.append("userType", "claimant"); // Hardcoded userType as claimant
      files.forEach((file) => formData.append("files", file));
      const hash = window.location.hash;
      const hashId = hash.split("/")[4];
      const response = await Axios.post(
        `${UPLOADING_FILES}/${hashId}`,
        formData
      );
      if (response.status === 200) {
        console.log("Upload successful");
        toast.success("File uploaded successfully");
        toggleModal();
        setDocumentName("");
      } else {
        console.error("Failed to upload:", response.data);
        setErrors({ general: "Failed to upload files. Please try again." });
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setErrors({
        general: "An error occurred while uploading files. Please try again.",
      });
    }
  };
  const joinMeeting = async (meetingId) => {
    try {
      const response = await Axios.get(`${JOIN_MEETING}/${meetingId}`);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        window.open(response.data.data, "_blank");
      } else {
        toast.error("Failed to join meeting");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const hash = window.location.hash;
        const hashId = hash.split("/")[4];
        const response = await Axios.get(`${CLIENT_SINGLE_CASE}/${hashId}`);
        setDetails(response.data.data);
        console.log(response.data.data, "response.data.dataresponse.data.data");
      } catch (error) {
        console.error("Error fetching case details:", error);
        setNotFound(true);
        toast.error("Customer not found.Invalid ID.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const currentDate = new Date();
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

  let timePending = "";

  const arbit =
    details &&
    details?.case_master_users?.reduce((acc, i) => {
      console.log(acc, "acciui");
      if (
        i.related_user_role == "Arbitrator" &&
        i.related_user_type == "Arbitrator"
      ) {
        acc.push(i.related_user.name);
      }
      return acc;
    }, []);

  const client =
    details &&
    details?.case_master_users?.find(
      (i) =>
        i.related_user_role === "Client" && i.related_user_type === "claimant"
    );
  const clientVs = client ? client.related_user.name : null;
  const respondent =
    details &&
    details?.case_master_users?.reduce((acc, i) => {
      console.log(acc, "acciui");
      if (
        i.related_user_role == "Party" &&
        i.related_user_type == "respondent"
      ) {
        acc.push(i.related_user.name);
      }
      return acc;
    }, []);
  return (
    <Container style={{ paddingBottom: "10px" }}>
      {loading && (
        <div className="text-center w-100">
          <CircularProgress />
        </div>
      )}

      <section className="Sales_analytics">
        <div fluid>
          <div className="float-end">
            <div className="status-container">
              <p class="add-cd-text">
                <button
                  onClick={() => props.history.goBack()}
                  className="back-btn-arw1"
                >
                  <i
                    class="zmdi zmdi-arrow-left"
                    style={{ fontSize: "19px", marginTop: "2px" }}
                  ></i>
                </button>
                {/* <Link
                      to={`/app/client/case-list/${editId}`}
                      style={{
                        color: "grey",
                        fontSize: "19px",
                        float: "left",
                      }}
                    >
                      <i
                        class="zmdi zmdi-arrow-left"
                        style={{ fontSize: "19px", marginTop: "2px" }}
                      ></i>
                    </Link> */}
                Case Details
                {/* <span className="status-container-text">
                  {details.case_status}
                </span> */}
              </p>
            </div>
          </div>
          <hr />
          <div className="pl-5">
            <div className="case-deatils-container">
              <div className="po-box">
                <p>
                  {" "}
                  Case Number :{" "}
                  <span className="dis-con ml-2">{details?.case_number}</span>
                </p>
                <p>
                  Case Title :{" "}
                  <span className="dis-con ml-2 text-capitalize">
                    <span>{clientVs && clientVs + `${" "}` + "and"} </span>
                    <span>
                      {respondent?.length === 1
                        ? respondent
                        : respondent?.length === 2
                          ? respondent[0] + " & another"
                          : respondent?.length === 3 || respondent?.length > 3
                            ? respondent[0] + " & others"
                            : ""}
                    </span>
                  </span>
                </p>

                {/* <p>
                  {" "}
                  Tribunal/Arbitrator:{" "}
                  <span className="dis-con ml-2">
                  <span className="dis-con ml-2">
                      {arbit && arbit.length > 0
                        ? arbit
                        : arbit && arbit.length > 1
                        ? arbit + ","
                        : "No Arbitrator"}
                    </span>{" "}
                  </span>{" "}
                </p> */}

                <p>
                  Chamber of Mediation :
                  <span className="dis-con ml-2">
                    {" "}
                    Indian Chambers of Arbitration and Mediation
                  </span>{" "}
                </p>
                {/*   {details?.case_master_users?.map((i) => {
                  if (
                    i.related_user_role === "Client" &&
                    i.related_user_type === "claimant"
                  ) {
                    return (
                      <p>
                        Claimant:
                        <span className="dis-con ml-2 text-capitalize">
                          {" "}
                          {i.related_user?.name}
                        </span>
                      </p>
                    );
                  }
                  return null;
                })} */}

                {details?.managers?.casemanager?.name && (
                  <p>
                    Tribunal/Mediator :{" "}
                    <span className="dis-con ml-2 text-capitalize">
                      {
                        details?.case_master_users?.find(
                          (user) => user.related_user_role === "Mediator"
                        )?.related_user?.name
                      }
                    </span>
                  </p>
                )}

                {details?.managers?.manager?.name && (
                  <p>
                    Manager :{" "}
                    <span className="dis-con ml-2 text-capitalize">
                      {details?.managers?.manager?.name}
                    </span>
                  </p>
                )}
                {details?.managers?.casemanager?.name && (
                  <p>
                    Case Manager :{" "}
                    <span className="dis-con ml-2 text-capitalize">
                      {details?.managers?.casemanager?.name}
                    </span>
                  </p>
                )}

                <p>
                  LAN No. :{" "}
                  <span className="dis-con ml-2 text-capitalize">
                    {
                      details?.metas?.find((meta) => meta.meta_key === "LAN")
                        ?.meta_value
                    }
                  </span>
                </p>

              </div>
              <div>
                <div className="case-hearing-box ml-5">
                  {details.hearing && details.hearing.length > 0 && (
                    <>
                      {(() => {
                        let todayHearingDisplayed = false; // Flag to track if today's hearing is already displayed
                        let nextHearingDetails = null; // Variable to store the details of the next hearing
                        let previousHearingDetails = null; // Variable to store the details of the previous hearing

                        // Loop through each hearing
                        for (const hearingData of details.hearing) {
                          const hearing = hearingData.hearing;
                          const startDate =
                            hearing && hearing.start_date
                              ? new Date(hearing.start_date)
                              : null;
                          const currentDate = new Date();

                          // Check if the hearing is today's hearing and it's not already displayed
                          if (
                            !todayHearingDisplayed &&
                            startDate &&
                            startDate.getDate() === currentDate.getDate() &&
                            startDate.getMonth() === currentDate.getMonth() &&
                            startDate.getFullYear() ===
                            currentDate.getFullYear()
                          ) {
                            todayHearingDisplayed = true; // Set flag to true if today's hearing is found
                            return (
                              <React.Fragment key={hearing.id}>
                                <p className="span-1">
                                  Purpose of Today's Hearing:{" "}
                                  <span className="span-2">
                                    {hearing.agenda}
                                  </span>
                                </p>
                              </React.Fragment>
                            );
                          }

                          // Check if the hearing is in the future
                          if (startDate && startDate > currentDate) {
                            // Store the details of the next hearing
                            nextHearingDetails = (
                              <React.Fragment key={hearing.id}>
                                <p className="span-1">
                                  Next Hearing:{" "}
                                  <span className="span-2">
                                    {formatDate(startDate)}
                                  </span>
                                </p>
                                <p className="span-1">
                                  Purpose of Hearing:{" "}
                                  <span className="span-2">
                                    {hearing.agenda}
                                  </span>
                                </p>
                              </React.Fragment>
                            );
                            break; // Exit the loop since we found the next hearing
                          }

                          // If no future hearing is found, store the details of the previous hearing
                          previousHearingDetails = (
                            <React.Fragment key={hearing.id}>
                              <p className="span-1">
                                Previous Hearing:{" "}
                                <span className="span-2">
                                  {formatDate(startDate)}
                                </span>
                              </p>
                              <p className="span-1">
                                Purpose of Hearing:{" "}
                                <span className="span-2">{hearing.agenda}</span>
                              </p>
                            </React.Fragment>
                          );
                        }

                        // Return the details of the next hearing if available, otherwise return the details of the previous hearing
                        return nextHearingDetails || previousHearingDetails;
                      })()}
                    </>
                  )}

                  <p className="span-1">
                    <span className="span-2">
                      <p className="span-1">
                        Status:{" "}
                        <span className="status-container-text14">
                          {details.case_status || "--"}{" "}
                        </span>
                      </p>
                      {/* Checking if there's a zoom_join_url */}


                    </span>
                  </p>
                </div>
                {/* <div className="case-hearing-box">
                                        {details.hearing && details.hearing.length > 0 && (
                                            <>
                                                {details.hearing.map((hearingData) => {
                                                    const hearing = hearingData.hearing;
                                                    const startDate = hearing && hearing.start_date ? new Date(hearing.start_date) : null;
                                                    const currentDate = new Date();

                                                    if (startDate > currentDate) {
                                                        return (
                                                            <React.Fragment key={hearing.id}>
                                                                <p className="span-1">
                                                                    Next Hearing:{" "}
                                                                    <span className="span-2">
                                                                        {startDate.toLocaleDateString("en-US", {
                                                                            month: "long",
                                                                            day: "numeric",
                                                                            year: "numeric",
                                                                        })}
                                                                    </span>
                                                                </p>
                                                                <p className="span-1">
                                                                    Purpose of Hearing:{" "}
                                                                    <span className="span-2">
                                                                        {hearing.agenda}
                                                                    </span>
                                                                </p>
                                                            </React.Fragment>
                                                        );
                                                    } else if (startDate) {
                                                        return (
                                                            <React.Fragment key={hearing.id}>
                                                                <p className="span-1">
                                                                    Previous Hearing:{" "}
                                                                    <span className="span-2">
                                                                        {startDate.toLocaleDateString("en-US", {
                                                                            month: "long",
                                                                            day: "numeric",
                                                                            year: "numeric",
                                                                        })}
                                                                    </span>
                                                                </p>
                                                                <p className="span-1">
                                                                    Purpose of Hearing:{" "}
                                                                    <span className="span-2">
                                                                        {hearing.agenda}
                                                                    </span>
                                                                </p>
                                                            </React.Fragment>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                                <p className="span-1">
                                                    Time Pending:{" "}
                                                    <span className="span-2">
                                                        {moment(details.created_at).subtract(12, "months").fromNow()}
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                        <p className="span-1">
                                            Status:{" "}
                                            <span className="span-2 text-uppercase">
                                                {details.case_status}{' '}
                                            </span>
                                        </p>
                                            </div> */}
              </div>
            </div>
          </div>
        </div>
        <Casetabs details={details} />
      </section>
    </Container>
  );
};

export default withRouter(Casedetail);
