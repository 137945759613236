import React from 'react';
import {asyncComponent,withRouter} from '../../../../../../components';
import PageWrapper from "../../../../common/PageWrapper";
const  AddEditForm= asyncComponent(() => import('../Components/AddEditForm'))

class AddEdit extends React.Component {
  render() {
    const {match} = this.props;
    const {editId} = match.params;
        if(editId){
            var role = 'edit';
            var type = 'Edit';
        }else{
            var role = 'add';
            var type = 'Add';
        }
    
    return (
      <PageWrapper match={match} title="Profile" icon="zmdi zmdi-dot-circle zmdi-hc-fw">
        <AddEditForm role={role} editId={match.params.editId}/>
      </PageWrapper>
    );
  }
}

export default withRouter(AddEdit);