
// import React, { useState, useEffect, Fragment } from "react";
// import {
//     withRouter,
//     toast,
//     Axios,
//     DataTablePagination,
//     DialogBox,
//     Loading,
//     moment,
//     ApiUrl,
//     Link,
// } from "../../../../../../components";
// import Filters from "./Filters";

// const { API_GET_MANAGER_LIST } = ApiUrl;

// const getStatus = (Dkey) => {
//     return (
//         <span className={Dkey.status == 1 ? "status-tag1 clr-grn" : "status-tag1 clr-red"}>
//             {Dkey.status == 1 ? "Active" : "In Active"}
//         </span>
//     );
// };

// const getRole = (Dkey) => {
//     return Dkey.roles.map((item) => {
//         return <span>{item.name}</span>;
//     });
// };

// const columnData = [
//     { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
//     { id: "name", align: false, disablePadding: true, label: "Name", order: 1, extra: "catImage" },
//     { id: "email", align: true, disablePadding: false, label: "Email", order: 1 },
//     { id: "phone", numeric: false, disablePadding: false, label: "Mobile" },
//     { id: "created_at", align: true, disablePadding: false, label: "Registered At", order: 1, type: "date" },
//     { id: "action", align: true, disablePadding: false, label: "Action" },
// ];

// const ListAll = (props) => {
//     const [columns, setColumns] = useState(columnData);
//     const [data, setData] = useState([]);
//     const [totalCount, setTotalCount] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const [page, setPage] = useState(0);
//     const [order, setOrder] = useState(-1);
//     const [fieldName, setFieldName] = useState("");
//     const [fieldValue, setFieldValue] = useState("");
//     const [orderBy, setOrderBy] = useState("created_at");
//     const [loading, setLoading] = useState(false);
//     const [status, setStatus] = useState(props.listType);
//     const [open, setOpen] = useState(false);
//     const [selectedId, setSelectedId] = useState("");
//     const [currentActiveTab, setCurrentActiveTab] = useState("1");

//     useEffect(() => {
//         console.log(rowsPerPage, page, "page123");
//         getTableRecords(order, orderBy);
//     }, [page, rowsPerPage, order, orderBy]);

//     const toggle = (tab) => {
//         if (currentActiveTab !== tab) setCurrentActiveTab(tab);
//     };

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleRequestSort = (data) => {
//         if (data.key !== "action") {
//             const newColumns = columns.map((col) => ({
//                 ...col,
//                 order: col.id === data.key ? data.order : 1,
//             }));
//             setColumns(newColumns);
//             setOrder(data.order);
//             setOrderBy(data.key);
//         }
//     };
//     const getTableRecords = async (order, orderBy) => {
//         setLoading(true);
//         const body = {
//             orderBy,
//             order,
//             page: parseInt(page) + 1,
//             limit: 20,
//         };
//         if (fieldName || fieldValue) {
//             body.fieldName = fieldName;
//             body.fieldValue = fieldValue;
//         }
//         try {
//             const response = await Axios.post(API_GET_MANAGER_LIST, body);
//             setData(response.data.data);
//             console.log(response.data.data, "response.data.data");


// Object.values(response.data.data).forEach(item => {
//     console.log("Name:", item.users.name);
//     console.log("Created At:", item.users.created_at);
// });

//             setTotalCount(response.data.data.length);
//             setLoading(false);
//         } catch (err) {
//             console.error("Error fetching table records:", err);
//             setLoading(false);
//         }
//     };

//     // const getTableRecords = async (order, orderBy) => {
//     //     setLoading(true);
//     //     const body = {
//     //         orderBy,
//     //         order,
//     //         page: parseInt(page) + 1,
//     //         limit: 20,
//     //     };
//     //     if (fieldName || fieldValue) {
//     //         body.fieldName = fieldName;
//     //         body.fieldValue = fieldValue;
//     //     }
//     //     try {
//     //         const response = await Axios.post(API_GET_MANAGER_LIST, body);
//     //         setData(response.data.data);
//     //         console.log(response.data.data, "response.data.data")
//     //         setTotalCount(response.data.data.length);
//     //         setLoading(false);
//     //     } catch (err) {
//     //         console.error("Error fetching table records:", err);
//     //         setLoading(false);
//     //     }
//     // };

//     const handleChange = (event) => {
//         const { name, value } = event.target;
//         switch (name) {
//             case "fieldName":
//                 setFieldName(value);
//                 break;
//             case "fieldValue":
//                 setFieldValue(value);
//                 break;
//             default:
//                 break;
//         }
//     };

//     const filterData = (e) => {
//         e.preventDefault();
//         getTableRecords(-1, "created_at");
//     };

//     const removefilterbutton = () => {
//         setTotalCount(0);
//         setRowsPerPage(10);
//         setPage(0);
//         setOrder(1);
//         setFieldName("firstname");
//         setFieldValue("");
//         setOrderBy("created_at");
//         getTableRecords(-1, "created_at");
//     };

//     const handleClickOpen = (id) => {
//         setOpen(true);
//         setSelectedId(id);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const deleteAction = (id) => {
//         Axios.post(id)
//             .then((res) => {
//                 const updatedData = data.filter((cate) => cate._id !== id);
//                 setData(updatedData);
//                 setTotalCount(totalCount - 1);
//                 setOpen(false);
//                 toast.success(res.data.message);
//             })
//             .catch((err) => {
//                 setOpen(false);
//                 toast.error("Client was not deleted!");
//             });
//     };




//     return (
//         <Fragment>
//             <DialogBox
//                 title={"Client"}
//                 open={open}
//                 onClose={handleClose}
//                 deleteAction={() => deleteAction(selectedId)}
//             />

//             <Filters
//                 fieldName={fieldName}
//                 handleChange={handleChange}
//                 fieldValue={fieldValue}
//                 filterData={filterData}
//                 removefilter={removefilterbutton}
//                 exportButton={{
//                     file: "Client.csv",
//                     columns: { columnData },
//                     url: API_GET_MANAGER_LIST,
//                     params: {
//                         orderBy: "createdBy",
//                         order: "-1",
//                         fieldName: fieldName,
//                         fieldValue: fieldValue,
//                         page: 0,
//                         limit: totalCount,
//                         status: status,
//                     },
//                 }}
//             />
//             <div className="flex-auto">
//                 <DataTableBody2
//                     loading={loading}
//                     column={columns}
//                     data={data}
//                     page={page}
//                     rowsPerPage={rowsPerPage}
//                     actions={[
//                         { label: "View", icon: "", link: "/app/users/view" },
//                         { label: "Edit", icon: "", link: "/app/users/edit" },
//                         { label: "Delete", icon: "", link: handleClickOpen },
//                     ]}
//                 />
//                 {data.length > 0 && (
//                     <div className="float-right">
//                         <DataTablePagination
//                             count={totalCount}
//                             rowsPerPage={rowsPerPage}
//                             page={page}
//                             onChangePage={handleChangePage}
//                             rowsPerPageOptions={[]}
//                         />
//                     </div>
//                 )}
//             </div>
//         </Fragment>
//     );
// };

// const DataTableBody2 = ({ loading, page, rowsPerPage, data, column, actions }) => (
//     <div>
//         {loading === false && data.length === 0 && (
//             <div className="n--data text-center m-5">No data Found!</div>
//         )}
//         {loading === true && (
//             <div className="text-center p-8o">
//                 <Loading />
//             </div>
//         )}

//         <div>
//             {data && Object.values(data).map((item, index) => (
//                 <div
//                     key={index}
//                     className="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
//                 >
//                     <div className="client-listing">
//                         <div className="mb-2">
//                             <span className="main-title mr-2">{item.users.name}</span>
//                             <span className="is-size-7 has-text-grey-dark">&nbsp;|&nbsp;</span>
//                             <span className="sub-title">
//                                 {moment(item.users.created_at).format("DD.MM.YYYY")}
//                             </span>



//                         </div>
//                     </div>
//                     <div>
//                         <div className="right-unlist d-flex align-items-center justify-content-center snk-j-normal bg-transparent">

//                             <Link
//                                 to={{
//                                     pathname: `/app/manager/case-list/${item.users.hash_id}`,
//                                     state: {
//                                         name: item.users.name,
//                                         email: item.users.email,
//                                         hash_id: item.users.hash_id,
//                                     }
//                                 }}
//                                 className="make-aapbtn ml-2 py-2"
//                             >
//                                 View Cases
//                             </Link>


//                         </div>
//                     </div>
//                 </div>
//             ))}
//             {!data && <div>Loading...</div>}
//             {data && Object.values(data).length === 0 && <div>No data found.</div>}
//         </div>

//     </div >
// );

// export default withRouter(ListAll);



import React, { Component, Fragment } from "react";

import {
    withRouter,
    toast,
    Axios,
    DataTablePagination,
    DialogBox,
    Loading,
    moment,
    ApiUrl,
    Link,
} from "../../../../../../components";
import Filters from "./Filters";

const { API_GET_MANAGER_LIST } = ApiUrl;

const getStatus = (Dkey) => {
    return (
        <span
            className={
                Dkey.status == 1 ? "status-tag1 clr-grn" : "status-tag1 clr-red"
            }
        >
            {Dkey.status == 1 ? "Active" : "In Active"}
        </span>
    );
};
const getRole = (Dkey) => {
    return Dkey.roles.map((item) => {
        return <span>{item.name}</span>;
    });
};
const columnData = [
    { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
    {
        id: "name",
        align: false,
        disablePadding: true,
        label: "Name",
        order: 1,
        extra: "catImage",
    },
    {
        id: "email",
        align: true,
        disablePadding: false,
        label: "Email",
        order: 1,
    },
    {
        id: "phone",
        numeric: false,
        disablePadding: false,
        label: "Mobile",
    },
    {
        id: "created_at",
        align: true,
        disablePadding: false,
        label: "Registered At",
        order: 1,
        type: "date",
    },
    { id: "action", align: true, disablePadding: false, label: "Action" },
];

class ListAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: columnData,
            data: "",
            totalCount: 0,
            rowsPerPage: 10,
            page: 0,
            order: -1,
            fieldName: "",
            status: this.props.listType,
            fieldValue: "",
            orderBy: "created_at",
            customerSearch: "",
            open: false,
            selectedId: "",
            currentActiveTab: "1",
        };
    }

    toggle = (tab) => {
        const currentActiveTab = this.state;
        if (currentActiveTab !== tab) this.setState({ currentActiveTab: tab });
    };
    componentDidMount = () => {
        console.log(this.state.rowsPerPage, this.state.page, "page123")

        this.getTableRecords(this.state.order, this.state.orderBy);
    };

    handleChangePage = (event, page) => {
        console.log(page, "520")
        this.setState({ page: page }, () =>
            this.getTableRecords(this.state.order, this.state.orderBy)
        );
    };

    handleRequestSort = (data) => {
        if (data.key !== "action") {
            let NewColoumns = this.state.columns.map((key, index) => {
                if (key.id !== data.key) {
                    return { ...key, order: 1 };
                } else {
                    return { ...key, order: data.order };
                }
            });

            this.setState(
                {
                    loading: true,
                    columns: NewColoumns,
                    orderBy: data.key,
                    order: data.order,
                },
                () => this.getTableRecords(data.order, data.key)
            );
        }
    };

    getTableRecords = async (order, orderBy) => {
        const { fieldName, fieldValue, page, rowsPerPage, status } = this.state;
        this.setState({ loading: true });
        let body = {
            orderBy: orderBy,
            order: order,
            page: parseInt(page) + 1,
            limit: rowsPerPage,
        };
        if (fieldName || fieldValue) {
            body.fieldName = fieldName;
            body.fieldValue = fieldValue;
        }
        Axios.post(API_GET_MANAGER_LIST, body)
            .then((response) => {
                const data = response.data.data; // Extract the data

                // Convert the data object into an array
                const formattedData = Object.values(data);

                this.setState({
                    totalCount: response.data.totalcount, // Update totalCount
                    data: formattedData, // Use formattedData for rendering
                    loading: false,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    };


    handleChange = (event) => {
        console.log(event);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    filterData = (e) => {
        e.preventDefault();
        this.getTableRecords(-1, "created_at");
    };

    removefilterbutton = () => {
        this.setState(
            {
                totalCount: 0,
                rowsPerPage: 10,
                page: 0,
                order: 1,
                fieldName: "firstname",
                fieldValue: "",
                orderBy: "created_at",
            },
            () => {
                this.getTableRecords(-1, "created_at");
            }
        );
    };
    handleClickOpen = (id) => {
        this.setState({
            open: true,
            selectedId: id,
        });
    };
    handleClose = () => {
        this.setState({ open: false });
    };

    deleteAction = (id) => {
        Axios.post(id)
            .then((res) => {
                this.setState({
                    open: false,
                    selectedId: "",
                    data: [...this.state.data.filter((cate) => cate._id !== id)],
                    totalCount: --this.state.totalCount,
                });
                toast.success(res.data.message);
            })
            .catch((err) => {
                this.setState({
                    open: false,
                    selectedId: "",
                });
                toast.error("Client does not deleted!");
            });
    };

    render() {
        const {
            data,
            fieldName,
            fieldValue,
            rowsPerPage,
            page,
            totalCount,
            loading,
            status,
            currentActiveTab,
        } = this.state;

        return (
            <Fragment>
                <DialogBox
                    title={"Client"}
                    open={this.state.open}
                    onClose={this.handleClose}
                    deleteAction={() => this.deleteAction(this.state.selectedId)}
                />

                <Filters
                    fieldName={fieldName}
                    handleChange={this.handleChange}
                    fieldValue={fieldValue}
                    filterData={this.filterData}
                    removefilter={this.removefilterbutton}
                    exportButton={{
                        file: "Client.csv",
                        columns: { columnData },
                        url: API_GET_MANAGER_LIST,
                        params: {
                            orderBy: "createdBy",
                            order: "-1",
                            fieldName: fieldName,
                            fieldValue: fieldValue,
                            page: 0,
                            limit: totalCount,
                            status: status,
                        },
                    }}
                />
                <div className="flex-auto">
                    <DataTableBody2
                        loading={loading}
                        column={this.state.columns}
                        data={data}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        actions={[
                            { label: "View", icon: "", link: "/app/users/view" },
                            { label: "Edit", icon: "", link: "/app/users/edit" },
                            { label: "Delete", icon: "", link: this.handleClickOpen },
                        ]}
                    />
                    {console.log('datadatadata', data.length)}
                    {/*  {data.length > 0 && (
                        <div className="float-right">
                            <DataTablePagination
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                rowsPerPageOptions={[]}
                            />
                        </div>
                    )} */}

                </div>
            </Fragment>
        );
    }
}

class DataTableBody2 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { loading, page, rowsPerPage, data, column, actions } = this.props;
        return (
            <div>
                {loading === false && data.length === 0 && (
                    <div className="n--data text-center m-5">No data Found!</div>
                )}
                {loading === true && (
                    <div className="text-center p-8o">
                        <Loading />
                    </div>
                )}
                <div>
                    {data && data.map((item, index) => (
                        <div
                            key={index}
                            className="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
                        >
                            <div className="client-listing">
                                <div className="mb-2">
                                    <span className="main-title mr-2">{item.users.name}</span>
                                    <span className="is-size-7 has-text-grey-dark">&nbsp;|&nbsp;</span>
                                    <span className="sub-title">
                                        {moment(item.users.created_at).format("DD.MM.YYYY")}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className="right-unlist d-flex align-items-center justify-content-center snk-j-normal bg-transparent">
                                    <Link
                                        to={{
                                            pathname: `/app/manager/case-list/${item.users.hash_id}`,
                                            state: {
                                                name: item.users.name,
                                                email: item.users.email,
                                                hash_id: item.users.hash_id,
                                            }
                                        }}
                                        className="make-aapbtn ml-2 py-2"
                                    >
                                        View Cases
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        );
    }
}
export default withRouter(ListAll);
