import React, { Fragment, useState } from "react";
import { API_URL, API_VERSION } from "../../../../../../../ApiUrl";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  Card,
  CardBody,
  CardText,
  moment,
} from "../../../../../../../components";
import { Button } from "reactstrap";

const { API_ADD_CLIENT_NOTES, Numeric } = ApiUrl;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      listId: this.props.listId,
      process: this.props.role,
      addNotes: "",
      topic: "",
      noteAddedBy: "",
      designation: "",
      mobileNumber: "",
      files: {},
      notes: "",
      addNotesShow: false,
      listNotesShow: false,
      errors: {
        files: "",
      },
      disabled: true,
      loading: false,
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "addNotes":
        errors.addNotes = value.length < 1 ? "Required" : "";
        break;
      case "topic":
        errors.topic = value.length < 1 ? "Required" : "";
        break;

      case "mobileNumber": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.mobileNumber = err;

        break;
      }

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };

    this.setState(
      {
        files: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ promotionImageName: file.name });
  };

  downloadFile = async (id,name) => {
    try {
      const url = `${API_URL}${API_VERSION}/get-file/link/${id}`;
      const response = await fetch(url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", name); // Set custom file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });

    const {
      addNotes,
      topic,
      noteAddedBy,
      designation,
      mobileNumber,
      files,
      errors,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      addNotes: addNotes,
      topic: topic,
      noteAddedBy: noteAddedBy,
      designation: designation,
      mobileNumber: mobileNumber,
      files: files,
    };
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    let url = API_ADD_CLIENT_NOTES;

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    Axios.Post(url + this.props.id, formData)
      .then((res) => {
        this.setState({ loading: false, disabled: false });

        if (res.data.success === true) {
          toast.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error, "res.message");
      });
  };

  render() {
    const {
      loading,
      addNotes,
      topic,
      noteAddedBy,
      designation,
      mobileNumber,
      promotionImageName,
      files,
      errors,
      disabled,
      notes,
      addNotesShow,
      listNotesShow,
    } = this.state;
    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            padding: "20px 60px",
            border: "1px solid grey",
            boxShadow: "2px 2px 3px -3px",
            margin: "20px",
          }}
        >
          <button
            onClick={() =>
              this.setState({
                addNotesShow: !addNotesShow,
                listNotesShow: false,
              })
            }
            className="notesTabs-btn"
          >
            Add Notes
          </button>
          {/* <button
            onClick={() =>
              this.setState({
                listNotesShow: true,
                addNotesShow: false,
              })
            }
            className="notesTabs-btn"
          >
            Notes
          </button> */}
          {addNotesShow == true && (
            <form className="mt-2" onSubmit={this.handleSubmit} noValidate>
              <Row>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Topic"
                    value={topic}
                    name={"topic"}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Designation"
                    value={designation}
                    name={"designation"}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Mobile"
                    value={mobileNumber}
                    name={"mobileNumber"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="12" xs="12">
                  <TEXTFIELD
                    label="Notes"
                    value={addNotes}
                    name={"addNotes"}
                    onChange={this.handleChange}
                  />
                </Col>

                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Note Added By"
                    value={noteAddedBy}
                    name={"noteAddedBy"}
                    onChange={this.handleChange}
                  />
                </Col>

                <Col md="6" xs="12">
                  <div class="file-upload-cus mt-4">
                    <div class="file-select">
                      <div class="file-select-button" id="fileName">
                        Choose File
                      </div>
                      <div class="file-select-name" id="noFile">
                        {promotionImageName
                          ? promotionImageName
                          : "No file chosen..."}
                      </div>
                      <div class="importcus">
                        <input
                          type="file"
                          name={"files"}
                          id="chooseFile"
                          onChange={this.handleFile}
                          // errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3" xs="12">
                  <SUBMIT title="Submit" disabled={disabled} />
                </Col>
              </Row>
            </form>
          )}
          
            <div>
              {this.props.notes &&
                this.props.notes.length > 0 &&
                this.props.notes.map((item, index) => {
                let id = item?.attachment[0]?.fileHashId;
                let noteBy = item?.attachment[0]?.fileName;
                  return (
                    <div className="notes-tab-list" style={{whiteSpace:"pre-line"}} key={index}>
                      <Card className="m-0">
                        <CardBody className="row text-left">
                          <CardText className="col-sm-4">
                            <b>Topic </b>:{" "} {item?.description?.topic || ""}
                          </CardText>
                          <CardText className="col-sm-4">
                            <b>Designation </b>:{" "}
                            {item?.added_by?.designation || ""}
                          </CardText>
                          <CardText className="col-sm-4">
                            <b>Mobile </b>:{" "} {item?.added_by?.mobileNumber || ""}
                          </CardText>
                          <CardText className="col-sm-4">
                            <b>Notice Type </b>:{" "}
                            {item?.description?.topic || ""}
                          </CardText>
                          <CardText className="col-sm-4">
                            <b>Created At </b>:{" "}
                            {moment(item?.created_at).format("DD.MM.YYYY") || "--"}
                          </CardText>
                         
                          <CardText className="col-sm-4">
                            <b>Note Added By </b>:{" "}
                            {item?.added_by?.noteAddedBy || ""}
                          </CardText>
                          <CardText className="col-sm-10">
                            <b>Notes </b>:{" "} {item?.description?.addNotes || ""}
                          </CardText>
                          {id && 
                        <CardText className="col-sm-2">
                          <p className="order-name1 mx-2">
                            <i
                              className="zmdi zmdi-download"
                              onClick={() => this.downloadFile(id,item?.added_by?.noteAddedBy +
                                      "_" + noteBy)}
                              style={{
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </p>
                        </CardText>
                      }
                        </CardBody>
                      </Card>
                    </div>
                  );
                })}
            </div>
          
        </div>
      </Fragment>
    );
  }
}

export default withRouter(UploadFile);
