import React, { useState, useEffect } from 'react';
import { Axios, Row, Col, TEXTFIELD, SUBMIT, PASSWORDFIELD, } from "../components";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { VERIFY_CASE, EXISTING_USERS, VERIFY_OTP_USER, SIGNUP_USER_EXISTING } from "../ApiUrl";
import FreshCaseForm from './FreshCaseForm';
import { withRouter } from 'react-router-dom';


const ExistingCase = ({ history }) => {
    const [caseNumber, setCaseNumber] = useState("");
    const [caseNumberError, setCaseNumberError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [userType, setUserType] = useState(null);
    const [isCaseVerified, setIsCaseVerified] = useState(false);
    const [caseData, setCaseData] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [subType, setSubType] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState(null);
    const [isUserValidAPISuccess, setIsUserValidAPISuccess] = useState(false);
    const [otp, setOtp] = useState(null);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [aadhaarError, setAadhaarError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [aadhaarImages, setAadhaarImages] = useState([]);
    const [aadhaarImagesError, setAadhaarImagesError] = useState('');
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [topPosition, setTopPosition] = useState(0);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordConfirmationVisibility = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };
    const handleAadhaarUpload = (e) => {
        const files = e.target.files;
        const uploadedImages = [];
        if (files.length !== 2) {
            setAadhaarImagesError('Please upload exactly two images.');
            return;
        } else {
            setAadhaarImagesError('');
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/')) {
                uploadedImages.push(file);
            } else {
                console.log('Non-image file detected:', file.name);
            }
        }
        if (uploadedImages.length === 2) {
            setAadhaarImages(uploadedImages);
        } else {
            setAadhaarImagesError('Please upload valid image files.');
        }
    };


    const handleSubTypeChange = (type) => {
        setSubType(type);
        if (type !== "other_respondent") {
            setPhoneNumber("");
        }
    };
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleTypeChange = (type) => {
        setUserType(type);
        setIsCaseVerified(false);
        setCaseData(null);
        setSelectedUser(null);
        setSubType(null);
        setMobileNumber(null);
        setIsUserValidAPISuccess(false);
        setIsOtpVerified(false);
        setIsOtherSelected(false);
    };

    const handleUserSelected = (user) => {
        setSelectedUser(user);
        setSubType(null);
        setMobileNumber(null);
        setIsOtherSelected(false);

    };


    const handleUserValidApi = async () => {
        let userId;
        console.log(caseData.data[2].case_type, 'CASEDATA')
        console.log(selectedUser)
        if (caseData.data[2].case_type === 'bulk') {
            userId = selectedUser.related_user_id;
        } else {
            userId = selectedUser.user_id;
        }
        const apiPayload = {
            user_id: userId,
            user_type: userType,
            sub_type: subType,
            // case_id: caseData.case_no,
        };
        console.log(apiPayload, "apiPayload");
        try {
            const response = await Axios.post(EXISTING_USERS, apiPayload);
            console.log('API response:', response.data);
            setIsUserValidAPISuccess(true);
        } catch (error) {
            console.error('Error hitting API:', error);
        }
    };
    const handleVerifyCase = async () => {
        const caseNo = userType === 'claimant' ? caseNumber : caseNumber;
        try {
            const response = await Axios.post(VERIFY_CASE, {
                user_type: userType === 'respondent' ? 'respondent' : 'claimant',
                case_number: caseNo
            });
            console.log('Verification response:', response.data);
            if (response.status === 200) {
                if (response.data.data[3].msg) {
                    toast.info(response.data.data[3].msg);
                } else {
                    setIsCaseVerified(true);
                    setCaseData(response.data);
                }
            }

        } catch (error) {
            console.error('Error verifying case:', error);
            toast.error(" Please enter a valid case number.");
        }
    };

    const handleSendOTP = async () => {
        let userId;
        console.log(caseData.data[2].case_type, 'CASEDATA');
        console.log(selectedUser);
        if (!subType) {
            toast.error('Please select a sub-type before sending OTP');
            return;
        }
        if (!phoneNumber) {
            toast.error('Please enter a phone number before sending OTP');
            return;
        }
        if (caseData.data[2].case_type === 'bulk') {
            userId = selectedUser.related_user_id;
        } else {
            userId = selectedUser.user_id;
        }
        const apiPayload = {
            user_id: userId,
            user_type: userType,
            sub_type: subType,
            phone_number: phoneNumber,
            // case_id: caseData.case_no,
        };
        try {
            const response = await Axios.post(EXISTING_USERS, apiPayload);
            console.log('API response:', response);
            if (response.data.success) {
                console.log('OTP sent.');
                setIsUserValidAPISuccess(true);
                setOtpSent(true);
            } else {
                console.error('Error sending :', response);
                toast.error(`Error sending OTP: ${response.data.error}`);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Error sending OTP4 ' + error);
        }
    };


    const handleVerifyOtp = async () => {
        try {
            let userId;
            let caseType;
            let caseId;
            if (selectedUser && selectedUser.related_user_id) {
                userId = selectedUser.related_user_id;
                caseType = 'bulk';
                caseId = caseData.data[0].hash_id;
            } else {
                userId = selectedUser.user_id;
                caseType = 'single';
                caseId = caseData.data[0].hash_id;
            }
            const apiPayload = {
                user_id: userId,
                user_type: userType,
                sub_type: subType,
                otp: otp,
                case_type: caseType,
                case_id: caseId,
                phone_number: phoneNumber,
            };
            console.log('API Payload:', apiPayload);
            const response = await Axios.post(VERIFY_OTP_USER, apiPayload);
            console.log('API Response:', response.data);
            if (response.data.success) {
                setIsOtpVerified(true);
                setIsUserValidAPISuccess(false);
            } else {
                // toast.error("Invalid OTP");
                toast.error(`Error sending OTP: ${response.data.error}`);
            }
        } catch (error) {
            console.error('Error hitting API:', error);
            // toast.error("Error verifying OTP");
        }
    };

    const handleOtherSelected = () => {
        setIsOtherSelected(true);
        setSelectedUser(null);
        setSubType(null);
        setMobileNumber(null);
    };

    const handleSubmit = async () => {
        try {
            const userId = caseData.data[2].case_type === 'bulk' ? selectedUser.related_user_id : selectedUser.user_id;
            const caseType = caseData.data[2].case_type;
            if (!/^\d{12}$/.test(aadhaarNumber)) {
                setAadhaarError('Aadhaar number must be 12 digits long');
            } else {
                setAadhaarError('');
            }
            // Password validation
            if (password.length < 8) {
                setPasswordError('Password must be at least 8 characters long');
                return;
            } else if (!/[A-Z]/.test(password)) {
                setPasswordError('Password must contain at least one uppercase letter');
                return;
            } else if (!/[a-z]/.test(password)) {
                setPasswordError('Password must contain at least one lowercase letter');
                return;
            } else if (!/\W|_/.test(password)) {
                setPasswordError('Password must contain at least one symbol');
                return;
            } else {
                setPasswordError('');
            }
            if (password !== password_confirmation) {
                setConfirmPasswordError('Passwords do not match');
                return;
            }
            if (aadhaarImages.length !== 2) {
                setAadhaarImagesError('Please upload exactly two images.');
                return;
            }

            const formData = new FormData();
            formData.append('user_id', userId);
            formData.append('case_type', caseType);
            formData.append('aadhaarNumber', aadhaarNumber);
            formData.append('password', password);
            formData.append('password_confirmation', password_confirmation);
            formData.append('aadhaar_front_image', aadhaarImages[0]);
            formData.append('aadhaar_back_image', aadhaarImages[1]);

            const response = await Axios.post(SIGNUP_USER_EXISTING, formData, {

            });

            // Handling response
            console.log('API response:', response.data);
            if (response.data.success) {
                history.push("/");
                setAadhaarNumber('');
                setPassword('');
                setPassword_confirmation('');
                setAadhaarError('');
                setPasswordError('');
                setIsOtpVerified(false);
                setIsUserValidAPISuccess(false);
                toast.success('User created successfully');
            } else {
                console.error('Error creating user:', response.data.message);
            }
        } catch (error) {
            console.error('Error hitting API:', error);
        }
    };

    const handleModalCancel = () => {
        setMobileNumber(null);
    }

    const handleModalConfirm = () => {
        handleUserValidApi()
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Row className="mt-3 mb-4">
                    <Col md="6">
                        <input
                            type="radio"
                            id="respondent"
                            name="role"
                            value="respondent"
                            onChange={() => handleTypeChange("respondent")}
                        />
                        <label style={{ fontSize: "21px", marginLeft: " 8px" }} htmlFor="respondent">Respondent</label>
                    </Col>
                    <Col md="6">
                        <input
                            type="radio"
                            id="claimant"
                            name="role"
                            value="claimant"
                            onChange={() => handleTypeChange("claimant")}
                        />
                        <label htmlFor="claimant" style={{ fontSize: "21px", marginLeft: " 8px" }}>Claimant</label>
                    </Col>
                </Row>

                {userType && (
                    <>
                        <Col md="12">
                            <TEXTFIELD
                                label="Enter Case Number"
                                value={caseNumber}
                                onChange={(e) => setCaseNumber(e.target.value)}
                            />
                            {caseNumberError && <p style={{ color: 'red' }}>{caseNumberError}</p>}
                        </Col>
                        {(!isCaseVerified || !caseData) && (
                            <Col md="12">
                                <button style={{
                                    background: "#004281", color: "white", border: " 1px solid",
                                    padding: "8px 13px", marginTop: "5px"
                                }} onClick={handleVerifyCase}>Verify Case</button>
                            </Col>
                        )}
                    </>
                )}

                {isCaseVerified && (
                    <div>
                        <h2 className='text-left mt-3'>
                            {userType === "claimant"
                                ? "Claimant to Arbitrator"
                                : "Respondent to Arbitrator"}
                        </h2>
                        <div>
                            {caseData && (
                                <div className='d-flex mt-3'>
                                    <h2><b>Case Title:</b></h2>
                                    <h4 className='mx-2'>
                                        <b>
                                            {caseData.data[2].case_type === "bulk" ? (
                                                <>
                                                    {caseData.data[1][0].related_user_type === "claimant" ? caseData.data[1][0].related_user.name : ""} vs {caseData.data[1][0].related_user_type === "respondent" ? caseData.data[1][0].related_user.name : "Unknown Respondent"}
                                                </>
                                            ) : (
                                                <>
                                                    {caseData.data[0].claimants} vs {caseData.data[0].respondents}
                                                </>
                                            )}
                                        </b>
                                    </h4>
                                </div>
                            )}

                            {caseData?.data[1]?.map((user, index) => (
                                <>
                                    <div key={index} className='mt-3 mx-3'>
                                        <input
                                            type="radio"
                                            id={`user_${user.user_id}`}
                                            name="selectedUser"
                                            checked={
                                                isOtherSelected
                                                    ? false
                                                    : selectedUser &&
                                                    (caseData.data[2].case_type === 'bulk'
                                                        ? selectedUser?.related_user?.id
                                                        : selectedUser?.user_id)
                                            }
                                            onChange={() => handleUserSelected(user)}
                                        />
                                        <label style={{ fontSize: "21px", marginLeft: " 8px" }}>
                                            {caseData.data[2].case_type === 'bulk' ? user.related_user.name : user.user_detail.name}
                                        </label>
                                    </div>

                                    {selectedUser && (selectedUser.user_id === user.user_id || selectedUser?.user_id === user.related_user_id) && !isOtherSelected && (
                                        <>
                                            <div className="d-flex mt-2 mx-4" style={{ display: "flex" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`co_borrower_${user.user_id}`}
                                                        name={`subType_${user.user_id}`}
                                                        onChange={() => setSubType("co-borrower")}

                                                        disabled={isUserValidAPISuccess || mobileNumber === "phone" || mobileNumber === "other_respondent"}
                                                    />
                                                    <label style={{ fontSize: "15px", marginLeft: " 8px" }} htmlFor={`co_borrower_${user.user_id}`}>Co-borrower</label>
                                                </div>
                                                <div>
                                                    <input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`borrower_${user.user_id}`}
                                                        name={`subType_${user.user_id}`}
                                                        onChange={() => setSubType("borrower")}
                                                        disabled={isUserValidAPISuccess || mobileNumber === "phone" || mobileNumber === "other_respondent"}
                                                    />
                                                    <label style={{ fontSize: "15px" }} htmlFor={`borrower_${user.user_id}`}>Borrower</label>
                                                </div>
                                                <div>
                                                    <input
                                                        className='mx-2'
                                                        type="radio"
                                                        id={`guarantor_${user.user_id}`}
                                                        name={`subType_${user.user_id}`}
                                                        value="guarantor"
                                                        onChange={() => setSubType("guarantor")}
                                                        disabled={isUserValidAPISuccess || mobileNumber === "phone" || mobileNumber === "other_respondent"} />
                                                    <label style={{ fontSize: "15px" }} htmlFor={`guarantor_${user.user_id}`}>Guarantor</label>
                                                </div>
                                            </div>

                                            {subType && (
                                                <>
                                                    <div className="mt-2 mx-4" style={{ fontSize: "15px" }}>
                                                        <div className="mt-2 mx-4" style={{ fontSize: "15px" }}>
                                                            <div className="mt-2 mx-4" style={{ fontSize: "15px" }}>
                                                                <input
                                                                    type="radio"
                                                                    id={`phone_radio_${user.user_id}`}
                                                                    name={`setMobileNumber${user.user_id}`}
                                                                    onChange={() => setMobileNumber("phone")}
                                                                    checked={mobileNumber === "phone"}
                                                                />
                                                                <label htmlFor={`phone_radio_${user.user_id}`} style={{ marginLeft: "8px", fontSize: "15px" }}>
                                                                    Existing number: XXXXXX
                                                                    {caseData.data[2].case_type === 'bulk' ? user.related_user.phone.slice(-4) : user.user_detail.phone.slice(-4)}
                                                                </label>

                                                                {mobileNumber === "phone" && <>
                                                                    <div>
                                                                        <button
                                                                            onClick={handleModalCancel}
                                                                            style={{
                                                                                marginLeft: "8px",
                                                                                background: "#004281",
                                                                                color: "white",
                                                                                border: "none",
                                                                                padding: "5px",
                                                                                fontSize: "15px"
                                                                            }}
                                                                        >
                                                                            cancel
                                                                        </button>
                                                                        <button
                                                                            onClick={handleModalConfirm}
                                                                            style={{
                                                                                marginLeft: "8px",
                                                                                background: "#004281",
                                                                                color: "white",
                                                                                border: "none",
                                                                                padding: "5px",
                                                                                fontSize: "15px"
                                                                            }}
                                                                        >
                                                                            confirm
                                                                        </button>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mx-4" style={{ fontSize: "15px" }}>
                                                        <input
                                                            className='mx-2'
                                                            type="radio"
                                                            id={`other_respondent_${user.user_id}`}
                                                            name={`setMobileNumber${user.user_id}`}
                                                            value="other_respondent"
                                                            onChange={() => setMobileNumber("other_respondent")}
                                                            checked={mobileNumber === "other_respondent"}
                                                        />
                                                        <label style={{ fontSize: "15px" }} htmlFor={`other_respondent_${user.user_id}`}>Other Number</label>

                                                        {mobileNumber === "other_respondent" && (
                                                            <div className="mt-2 mx-4">
                                                                <TEXTFIELD
                                                                    placeholder="XXXX1234XX"
                                                                    label="Enter Phone Number"
                                                                    value={phoneNumber}
                                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                                />
                                                                {!otpSent && (
                                                                    <button
                                                                        onClick={handleSendOTP}
                                                                        style={{
                                                                            marginLeft: "8px",
                                                                            background: "#004281",
                                                                            color: "white",
                                                                            border: "none",
                                                                            padding: "5px",
                                                                            fontSize: "15px"
                                                                        }}
                                                                    >
                                                                        Send OTP
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>


                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}

                            {isUserValidAPISuccess && (
                                <Row>
                                    <Col md="12">
                                        <TEXTFIELD
                                            placeholder="XXXX"
                                            label="Enter otp"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                        />
                                        {!otp && <p style={{ color: 'red' }}>OTP is required</p>}
                                    </Col>

                                    <Col md="6">
                                        <button
                                            style={{
                                                background: "#004281",
                                                color: "white",
                                                border: " 1px solid",
                                                padding: "5px",
                                            }}
                                            onClick={handleVerifyOtp}
                                            disabled={!otp}
                                        >
                                            Verify otp
                                        </button>
                                    </Col>
                                </Row>
                            )}

                            {isOtpVerified && !isOtherSelected && (
                                <Row>
                                    <Col md="12">
                                        <TEXTFIELD
                                            label="Enter Aadhaar number"
                                            placeholder="XXXX1234XXXX"
                                            value={aadhaarNumber}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setAadhaarNumber(value);
                                                if (!/^\d{12}$/.test(value)) {
                                                    setAadhaarError('Aadhaar number must be 12 digits long');
                                                } else {
                                                    setAadhaarError('');
                                                }
                                            }}
                                        />
                                        {aadhaarError && <p style={{ color: 'red' }}>{aadhaarError}</p>}
                                    </Col>

                                    <Col md="12">
                                        <TEXTFIELD
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Enter Password"
                                            value={password}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setPassword(value);
                                                if (value.length < 8) {
                                                    setPasswordError('Password must be at least 8 characters long');
                                                } else {
                                                    setPasswordError('');
                                                }
                                            }}
                                        />
                                        <i className={`zmdi ${showPassword ? 'zmdi-eye' : 'zmdi-eye-off'} field-icon toggle-password`}
                                            onClick={togglePasswordVisibility}></i>
                                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}

                                    </Col>
                                    <Col md="12">
                                        <TEXTFIELD
                                            type={showPasswordConfirmation ? 'text' : 'password'}
                                            placeholder="Confirm Password"
                                            value={password_confirmation}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setPassword_confirmation(value);
                                                if (value !== password) {
                                                    setConfirmPasswordError('Passwords do not match');
                                                } else {
                                                    setConfirmPasswordError('');
                                                }
                                            }}
                                        />
                                        <i
                                            className={`zmdi ${showPasswordConfirmation ? 'zmdi-eye' : 'zmdi-eye-off'} field-icon toggle-password`}
                                            onClick={togglePasswordConfirmationVisibility}
                                        ></i>
                                        {confirmPasswordError && <p style={{ color: 'red' }}>{confirmPasswordError}</p>}

                                    </Col>

                                    <Col md="12">
                                        <label htmlFor="aadhaarUpload mt-4" style={{ marginBottom: '0.5rem', display: 'block' }}>
                                            <b>    Upload Aadhaar Card (Front + Back)</b>
                                        </label>
                                        <input
                                            type="file"
                                            id="aadhaarUpload"
                                            accept="image/*"
                                            multiple
                                            onChange={handleAadhaarUpload}
                                        />
                                        {aadhaarImagesError && <p style={{ color: 'red' }}>{aadhaarImagesError}</p>}
                                    </Col>

                                    <Col md="12">
                                        <div className='text-center mt-3 mb-3'>
                                            <button
                                                style={{
                                                    color: "#fff",
                                                    background: "#004281",
                                                    border: "1px solid",
                                                    padding: "11px 40px 10px 40px",
                                                    fontSize: "18px"
                                                }}
                                                type="submit">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default withRouter(ExistingCase);

