import React, { Fragment, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import "./privacy-policy.css"

const Trucecall = () => {
    window.scroll(0, 0);
    const [show, setShow] = useState(false);
    return (
        <>
            <Header />
            <Fragment>
                <div className="container-fluid  truce-cal-heading">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="truce-header">
                                    <h1>Truce Talks</h1>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row pt-5">
                            <div className="col-md-12 text-center">
                                <div className="truce-content-section">
                                    <div className="truce-content-container">
                                        <h2 className="truce-call-heading">Hey there, legal eagles and minds of curiosity!</h2>
                                        <p className="board">
                                            Welcome to the people on board. Alternative dispute resolution (ADR) is an exciting field of law
                                            that we will explore in our blog. You&#39;ve come to the perfect location whether you&#39;re a law
                                            student, an experienced lawyer, or just a curious person who wants to know how disputes are
                                            settled out of court! Buckle up for the roller coaster ride of knowledge and adventure.
                                        </p>
                                        <h2 className="pt-4 truce-call-heading">The Smart, Swift Solution for Settling Disputes!</h2>
                                        <p className="board">
                                            In terms of conflict resolution, alternative dispute resolution, or ADR, is comparable to a Swiss
                                            Army knife. It has all these cool features to assist people in resolving their conflicts without the
                                            lengthy procedure of a courtroom confrontation and also cuts down the time of adjudication.
                                            Consider it the quick, easy, and affordable solution to resolve conflicts. It also gives you the
                                            freedom to be inventive and customize solutions to precisely meet the demands of each
                                            individual. So, when you can settle with grace and style, why wrestle in court.
                                        </p>
                                        <p className="board pt-3">
                                            Picture resolving a dispute without the hassle of costly, lengthy legal proceedings. ADR steps in
                                            like a superhero at that point! That&#39;s where ADR swoops in like a superhero! It&#39;s all about finding
                                            solutions that keep everyone happy and playing nice, even after the dust settles. ADR is more
                                            about everyone walking away happy than it is about right or wrong. When a warm handshake
                                            will do just fine, who needs a judge&#39;s gavel.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" >
                    <div className="container">
                        <div className="row justify-content-center pt-5">
                            <div className="col-md-12 text-center">
                                <h6 className="types">Types of ADR</h6>
                            </div>
                            <div className="col-lg-10 my-5">
                                <div className="row no-gutters">
                                    <div className="col-md-6 feature-box feature-box-mediation">
                                        <h4>01</h4>
                                        <h5 className="py-2 fs-4 px-2 heading-mediation" style={{ color: "goldenrod" }}>
                                            Mediation:
                                        </h5>
                                        <p className="text-center imagine">
                                            Imagine it as a delicate tea party where a third-party mediator helps the
                                            parties uncover the truth and come up with a win-win solution.
                                        </p>
                                    </div>
                                    <div className="col-md-6 feature-box feature-box-mediation">
                                        <h4>02</h4>
                                        <h5 className="text-dark fs-4 px-2 py-2 heading-mediation">Arbitration:</h5>
                                        <p className="delicate">
                                            It resembles a courtroom! After hearing arguments from both parties, an
                                            arbitrator renders a conclusion that, depending on the circumstances, may only be a
                                            strongly suggested ruling or one that is legally enforceable.
                                        </p>
                                        <div className="step-arrow" />
                                    </div>
                                    <div className="col-md-6 feature-box feature-box-mediation">
                                        <h4>03</h4>
                                        <h5 className="py-2 fs-4 px-2 text-dark heading-mediation">Negotiation::</h5>
                                        <p className="delicate">
                                            Imagine a busy bazaar where parties are negotiating directly with one
                                            another to resolve differences and come to an agreement.
                                        </p>
                                        <div className="step-arrow" />
                                    </div>
                                    <div className="col-md-6 feature-box feature-box-mediation"  >

                                        <h4 className="bg-light" style={{ color: "goldenrod" }}>
                                            04
                                        </h4>
                                        <h5 className="fs-4 px-2 py-2 heading-mediation" style={{ color: "goldenrod" }}>
                                            Conciliation:
                                        </h5>
                                        <p className="imagine">
                                            Conciliation is like having a gold-plated mediator actively bringing the
                                            parties together for a pleasant resolution; it&#39;s mediation with extra care.
                                        </p>
                                        <div className="step-arrow " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-3">
                    <div className="container px-4">
                        <div className="row justify-content-center">
                            <div className="col-md-8 text-center">
                                <h2><b>Our Blog Journey</b> </h2>
                                <p className="text-muted" style={{ fontWeight: 500, fontSize: 17 }}>
                                    In this blog, we’ll get into the specifics of ADR. Here’s what to anticipate:
                                </p>
                            </div>
                        </div>
                        <div className="row flip-card-row truce-call-card mt-4">
                            <div className="col-md-4 position-relative">
                                <div className="boxx-why-choose">
                                    <div className="front">
                                        <div className="center">Case Studies</div>
                                    </div>
                                    <div className="back">
                                        A user-friendly interface that consolidated and display key
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 position-relative">
                                <div className="boxx-why-choose">
                                    <div className="front">
                                        <div className="center">Expert Insights</div>
                                    </div>
                                    <div className="back">
                                        A user-friendly interface that consolidated and display key
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 position-relative">
                                <div className="boxx-why-choose">
                                    <div className="front">
                                        <div className="center">Fast and Fair</div>
                                    </div>
                                    <div className="back">
                                        A user-friendly interface that consolidated and display key
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row flip-card-row truce-call-card mt-md-4">
                            <div className="col-md-6 position-relative">
                                <div className="boxx-why-choose">
                                    <div className="front">
                                        <div className="center">Legal Updates </div>
                                    </div>
                                    <div className="back">
                                        A user-friendly interface that consolidated and display key
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 position-relative">
                                <div className="boxx-why-choose">
                                    <div className="front">
                                        <div className="center">Fast and Fair</div>
                                    </div>
                                    <div className="back">
                                        A user-friendly interface that consolidated and display key
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mb-5 pb-5" style={{ marginTop: 80 }}>
                    <div className="row justify-content-center">
                        <div className="col-9">
                            <h2 className="text-center">Join the Conversation</h2>
                        </div>
                        <div className="col-md-9 text-muted">
                            <p className="text-center" style={{ fontSize: "large" }}>
                                We believe that swapping ideas and learning from each other is pure magic. So, don’t be
                                shy—ask questions, drop comments, or share your own tales of dispute resolution glory. Our
                                mission? To build a community of folks passionate about fair and fabulous conflict resolution.
                            </p>
                            <p className="pt-3 text-center" style={{ fontSize: "large" }}>So, let’s embark on this adventure together! Dive into our content, share your thoughts, and let’s
                                make the world of dispute resolution a friendlier, more effective place.</p>
                            <p className="text-center fs-5 mb-1 pt-3" style={{ fontSize: "large" }}>
                                <span>Happy readding!</span>
                            </p>
                            <p className="text-center fs-5 mb-1" style={{ fontSize: "large" }}>
                                <span>Warm regard,</span>
                            </p>
                            <p className="text-center fs-5 mb-1" style={{ fontSize: "large" }}  >
                                <span>The Trucee-Talks Blog Team.</span>
                            </p>
                        </div>
                    </div>
                </div>

            </Fragment>
            <Footer />
        </>
    );
};

export default Trucecall;
