import React, { useState, useEffect } from "react";

import { Button, Modal, ModalBody } from "reactstrap";

import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  toast,
  SUBMIT,
} from "../../../../../../components";
import "./revenueTable.css";

import { onlyNumber } from "../../../../../../util/onlyNumber";

const { API_EDIT_FOLLOW_UP, API_GET_FOLLOW_UP_DETAILS } = ApiUrl;

const EditNotes = (props) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNotes, setAddNotes] = useState("");
  const [details, setDetails] = useState("");

  console.log(details, ";;l;l;;l");
  const [topic, setTopic] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [noteAddedBy, setNoteAddedBy] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const [statusCode, setStatusCode] = useState([]);
  const [followup, setFollowup] = useState([]);
  const [amount, setAmount] = useState([]);
  const [prioriti, setPriority] = useState([]);
  const [calltime, setCalltime] = useState([]);
  const [duration, setDuration] = useState([]);
  const [email, setEmail] = useState([]);
  const [remarks, setRemarks] = useState([]);
  console.log(calltime, "list?.data?.call_date");
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!topic.trim()) {
      errors.topic = "Topic is required";
      isValid = false;
    }

    if (!mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
      isValid = false;
    }

    if (!designation.trim()) {
      errors.designation = "Designation is required";
      isValid = false;
    }

    if (!addNotes.trim()) {
      errors.addNotes = "Add Notes is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  useEffect(() => {
    getTableRecords();
  }, []);

  const formatDate = (datetime) => {
    // Split the datetime string into date and time parts
    const parts = datetime.split(" ");

    // Extract the date part
    const datePart = parts[0]; // "2024-06-19"

    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split("-");

    // Create a new Date object with adjusted timezone offset
    // Note: Months are zero-based in JavaScript Date object, so subtract 1 from month
    const adjustedDate = new Date(Date.UTC(year, month - 1, day));

    // Format the adjusted date to YYYY-MM-DD
    return adjustedDate.toISOString().split("T")[0];
  };

  const getTableRecords = async () => {
    Axios.get(API_GET_FOLLOW_UP_DETAILS + props.noteIds)
      .then((res) => {
        const list = res?.data;
        const callDate = list?.data?.call_date
          ? formatDate(list.data.call_date)
          : "";

        setDetails(list.data);
        setAmount(list?.data?.offer_price);
        setPriority(list?.data?.priority_flag);
        setFollowup(list?.data?.followup_datetime);
        setStatusCode(list?.data?.status_code);
        setCalltime(callDate);
        setDuration(list?.data?.duration);
        setEmail(list?.data?.new_email);
        setRemarks(list?.data?.remarks);
        setMobileNumber(list?.data?.new_phone);
        setRemarks(list?.data?.followup_remarks);
        setDuration(list?.data?.call_duration);
      })
      .catch((err) => {
        console.log("");
      });
  };

  const toggleModal = async () => {
    try {
      // if (!validateForm()) {
      //   return;
      // }

      const formData = new FormData();
      formData.append("new_mobileNumber", mobileNumber);
      formData.append("status_code", statusCode);
      formData.append("priority", prioriti);
      formData.append("follow_up", followup);
      formData.append("amount", amount);
      formData.append("duration", duration);
      formData.append("calltime", calltime);
      formData.append("new_email", email);
      formData.append("remarks", remarks);
      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      // Submit the form
      const url = API_EDIT_FOLLOW_UP;
      const res = await Axios.post(
        API_EDIT_FOLLOW_UP + props.noteIds,
        formData
      );
      if (res.data.success === true) {
        props.setopenEditNotes(false);
        toast.success(res?.data?.message);
        setStatusCode("");
        setPriority("");
        setFollowUp("");
        setAmount("");
        setDuration("");
        setCalltime("");
        setRemarks("");
        setEmail("");
        setFormErrors({});
        setUploadFiles([]);
      } else {
        toast.success("Does not updated");
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setUploadFiles(filesArray);
  };

  const handleChangeCode = (e) => {
    const { name, value } = e.target;

    console.log(e.target, "e.targetkjhk");
    setStatusCode(e.target.value);
  };

  const handleFollowDateChange = (e) => {
    const { value } = e.target;
    setFollowup(value);
  };
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };
  const handleCallduration = (e) => {
    const { value } = e.target;
    setDuration(value);
  };
  const handleChangeCalltime = (e) => {
    const { value } = e.target;
    setCalltime(value);
  };

  const handleChangeS = (e) => {
    const { name, value } = e.target;
    console.log(e.target, "e.targetkjhk");
    setPriority(e.target.value);
  };

  return (
    <section className="Sales_analytics">
      <div fluid>
        <div className="revenueTable-conatiner">
          <div className="modal-header">
            <h1 className="modal-title mt-3 mb-3 mx-2">
              <b>Edit Follow Up</b>
            </h1>
            <button
              type="button"
              className="close"
              onClick={() => props.setopenEditNotes(false)}
            >
              <span>×</span>
            </button>
          </div>
          <div className="" style={{ padding: "20px 20px" }}>
            {/* <Row>
              <Col md="12">
                <TEXTFIELD
                  label="Subject"
                  value={topic}
                  onKeyUp={validateForm}
                  onChange={(e) => setTopic(e.target.value)}
                />
                {formErrors.topic && (
                  <div className="error-message ">{formErrors.topic}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Add Notes"
                  value={addNotes}
                  onKeyUp={validateForm}
                  onChange={(e) => setAddNotes(e.target.value)}
                />
                {formErrors.addNotes && (
                  <div className="error-message">{formErrors.addNotes}</div>
                )}
              </Col>

              {/* <Col md="12">

                    <TEXTFIELD
                      label="Note Added By"
                      value={noteAddedBy}
                      onKeyUp={validateForm}
                      onChange={(e) => setNoteAddedBy(e.target.value)}
                    />
                    {formErrors.noteAddedBy && <div className="error-message">{formErrors.noteAddedBy}</div>}
                  </Col> *

              <Col md="12">
                <TEXTFIELD
                  label="Designation"
                  value={designation}
                  onKeyUp={validateForm}
                  onChange={(e) => setDesignation(e.target.value)}
                />
                {formErrors.designation && (
                  <div className="error-message">{formErrors.designation}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Employee Id:"
                  value={employee}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmployee(e.target.value)}
                />
                {formErrors.employee && (
                  <div className="error-message">{formErrors.employee}</div>
                )}
              </Col>

              <Col md="12">
                <TEXTFIELD
                  label="Mobile Number:"
                  value={mobileNumber}
                  onKeyUp={validateForm}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={onlyNumber}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
            </Row> */}

            <Row>
              <Col md="6" xs="12" className="mt-a-5">
                <SELECT
                  name="statusCode"
                  value={statusCode}
                  onChange={handleChangeCode}
                  label="Status Code"
                  data={props.codes}
                />
              </Col>
              {statusCode == 12 && (
                <Col md="6">
                  <TEXTFIELD
                    type="text"
                    label="Amount"
                    onChange={handleAmountChange}
                    value={amount}
                    name="amount"
                  />
                </Col>
              )}
              <Col md="6">
                <TEXTFIELD
                  type="datetime-local"
                  label="Follow Up Date & Time"
                  onChange={handleFollowDateChange}
                  value={followup}
                  name="followup"
                />
              </Col>

              <Col md="6" xs="12" className="mt-a-5">
                <SELECT
                  name="prioriti"
                  value={prioriti}
                  onChange={handleChangeS}
                  label="Priority"
                  data={[
                    { moderate: "Moderate" },
                    { high: "High" },
                    { low: "Low" },
                  ]}
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="date"
                  label="Call Time Date"
                  onChange={handleChangeCalltime}
                  value={calltime}
                  name="calltime"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  type="text"
                  label="Call Duration"
                  onChange={handleCallduration}
                  value={duration}
                  name="duration"
                />
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Mobile Number:"
                  value={mobileNumber}
                  onKeyUp={validateForm}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={onlyNumber}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="6">
                <TEXTFIELD
                  label="New Email:"
                  value={email}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {formErrors.mobileNumber && (
                  <div className="error-message">{formErrors.mobileNumber}</div>
                )}
              </Col>
              <Col md="12">
                <TEXTFIELD
                  label="Follow up & Remarks"
                  value={remarks}
                  onKeyUp={validateForm}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {formErrors.noteAddedBy && (
                  <div className="error-message">{formErrors.noteAddedBy}</div>
                )}
              </Col>
            </Row>

            <input
              className="my-3"
              type="file"
              onChange={handleFileChange}
              multiple
            />

            <div className="modal-submit-btn text-center">
              <button
                type="button"
                className="btn jr-btn CBmargin CBmarginT M add-notes w-50"
                onClick={toggleModal}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditNotes;
