import React from 'react'

const PartyConciliationFiled = () => {
    return (
        <div className='text-center mt-5'>
            Coming Soon Party Conciliation Filed Cases
        </div>
    )
}

export default PartyConciliationFiled
