import React, { useState, useEffect } from "react";
import {
  ApiUrl,
  Axios,
  Link
} from "../../components";

import { getNotificationsSuccess } from "../../actions/Auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


const Notifications = (props) => {
  const role = localStorage.getItem("role");
  
  return (
    <div className="notif-cont-family">
      <div className="notif-h-cont">
        <span className="">Notifications</span>
        <span className="float-right" onClick={props.openNotification}>
          <i class="zmdi zmdi-close"></i>
        </span>
      </div>
      <div className="notif-welcome-cont">
        <p>Welcome to JustSettle Notifications</p>
      </div>
      <div>
        <div className="notif-grid-cont">
        {(role === "LawFirm" || role === "Mediator") &&
        <>
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
            <span className="notif-grid-count"> 1 </span> Approvals
          </span>
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
           <span className="notif-grid-count"> 1</span> User Approvals
          </span>
          </>
        }
        { role === "casemanager" && 
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
           {/* <span className="notif-grid-count"> 1</span> */}
            Follow Up
          </span>
        }
          <span className="notif-grid-cont-span" onClick={props.toggel}>
          <Link to ="/app/lawfirm/notifications/">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
            {props.count && 
            <span className="notif-grid-count">{props.count}</span> }
            Notes
          </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

// export default Notifications;


const mapStateToProps = ({ auth }) => {
  console.log(auth, "auth90890");
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

// export default connect(mapStateToProps, {
//   notesNotifications,
// })(Notifications);

// const mapStateToProps = (state) => ( console.log(state,"staestae"),{
//   notifications: state.notification.notifications,
// });

// const mapDispatchToProps = (dispatch) => (
//   console.log(dispatch,"dispatchdispatch"),{
//   notesNotifications: () => dispatch(getNotifications()),
// });

export default connect( mapStateToProps,getNotificationsSuccess)(withRouter(Notifications));
