import React, { Component, Fragment } from "react";
import {
  withRouter,
  Axios,
  TableRow,
  TableCell,
  DataTablePagination,
  Loading,
  ApiUrl,
  Link,
} from "../../../../../../../components";
import Filters from "./Filters";

const { API_GET_USERS_URL } = ApiUrl;

const columnData = [
  { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
  {
    id: "name",
    align: false,
    disablePadding: true,
    label: "Name",
    order: 1,
    extra: "catImage",
  },
  {
    id: "email",
    align: true,
    disablePadding: false,
    label: "Email",
    order: 1,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "created_at",
    align: true,
    disablePadding: false,
    label: "Registered At",
    order: 1,
    type: "date",
  },
  { id: "action", align: true, disablePadding: false, label: "Action" },
];

const data = [
  {
    id: 123,
    name: " in Darkhast - Execution Petition 329/2019",
    date: " Tuesday, Jan 16th 2024 ",
  },
  {
    id: 456,
    name: " in Darkhast - Execution Petition 329/2019",
    date: " Friday, Jul 28th 2023 ",
  },
  {
    id: 567,
    name: " in Darkhast - Execution Petition 329/2019",
    date: " Saturday, Feb 4th 2023 ",
  },
];

class ListAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columnData,
      data: data,
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
      order: -1,
      fieldName: "",
      status: this.props.listType,
      fieldValue: "",
      orderBy: "created_at",
      customerSearch: "",
      open: false,
      selectedId: "",
    };
  }

  componentDidMount = () => {
    this.getTableRecords(this.state.order, this.state.orderBy);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page }, () =>
      this.getTableRecords(this.state.order, this.state.orderBy)
    );
  };

  getTableRecords = async (order, orderBy) => {
    const { fieldName, fieldValue, page, rowsPerPage, status } = this.state;
    this.setState({ loading: true });
    let body = {
      orderBy: orderBy,
      order: order,
      page: parseInt(page) + 1,
      status: status,
      limit: rowsPerPage,
    };
    if (fieldName || fieldValue) {
      body.fieldName = fieldName;
      body.fieldValue = fieldValue;
    }
    Axios.post(API_GET_USERS_URL, body)
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response.data.length,
          // totalCount: response.totalcount,
          data: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        // toast.error("No data Found.");
        this.setState({ loading: false });
      });
  };

  filterData = (e) => {
    e.preventDefault();
    this.getTableRecords(-1, "created_at");
  };

  render() {
    const {
      data,
      fieldName,
      fieldValue,
      rowsPerPage,
      page,
      totalCount,
      loading,
      status,
    } = this.state;
    return (
      <Fragment>
        <Filters
          fieldName={fieldName}
          fieldValue={fieldValue}
          filterData={this.filterData}
          exportButton={{
            file: "Orders.csv",
            columns: { columnData },
            url: API_GET_USERS_URL,
            params: {
              orderBy: "createdBy",
              order: "-1",
              fieldName: fieldName,
              fieldValue: fieldValue,
              page: 0,
              limit: totalCount,
              status: status,
            },
          }}
        />

        <div className="flex-auto">
          <DataTableBody2
            loading={loading}
            column={this.state.columns}
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
          />
          {data.length > 0 && (
            <div className="float-right">
              <DataTablePagination
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

class DataTableBody2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { loading, page, rowsPerPage, data, column, actions } = this.props;
    return (
      <div>
        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <div className="text-center p-8o">
            <Loading />
          </div>
        )}
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            console.log(Dkey, index, 333);
            return (
              <Link to="#">
                <div class="listing-main p-3 mt-3" key={index}>
                  <div class="d-flex ">
                    <div style={{width:"20%"}}>
                      <p className="order-date1 fw-23">Date</p>
                      <p className="order-date1"> {Dkey.date || "1-1-2024"} </p>
                    </div>
                    <div className="text-left">
                      <p className="order-date1 fw-23">Name</p>
                      <p className="order-name1">{Dkey.name}</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    );
  }
}

export default withRouter(ListAll);
