import React, { useEffect, useState } from "react";
import validator from "validator";
import "./ProfileSetting.css";
import { Row, Col, ApiUrl, Axios, toast } from "../../../../components";

const { API_UPDATE_PASSWORD, API_GET_PROFILE_DETAILS, API_UPDATE_PROFILE } = ApiUrl;

const ProfileSetting = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    current_password: "",
    newPassword: "",
    password_confirmation: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

 
  useEffect(() => {
    getProfiledetails();
  }, []);

  const getProfiledetails = async () => {
    Axios.Get(API_GET_PROFILE_DETAILS)
      .then((response) => {
        const resData = response?.data?.data;
        console.log(resData, "profile");

        if (response.status == 200) {
          setFormData(() => ({
            name: resData.name,
            email: resData.email,
            mobile: resData.phone,
          }));
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" && !/^[0-9]*$/.test(value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateField(name, value);
  };

  const validateField = (name, value) => {
    const errors = { ...validationErrors };

    switch (name) {
      case "name":
        errors.name = value.trim() === "" ? "Name is required." : "";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errors.email =
          value.trim() === ""
            ? "Email is required."
            : !emailRegex.test(value)
            ? "Invalid email address."
            : "";
        break;
      case "mobile":
        errors.mobile = !validator.isMobilePhone(value, "any", {
          strictMode: false,
        })
          ? "Invalid mobile number."
          : "";
        break;
     
      case "current_password":
        errors.current_password =
          value.trim() === "" ? "Old Password is required." : "";
        break;
      case "password":
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        errors.password =
          value.trim() === ""
            ? "Password is required."
            : !passwordRegex.test(value)
            ? "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, and one number."
            : "";
        break;
      case "password_confirmation":
        errors.password_confirmation =
          value.trim() === ""
            ? "Confirm Password is required."
            : formData.password !== value
            ? "Passwords do not match."
            : "";
        break;
      default:
        break;
    }

    setValidationErrors(errors);
  };


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleUpdatePassword = (event) => {
    event.preventDefault();
    
    let data = {
      current_password: formData.current_password,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    };

    let url = API_UPDATE_PASSWORD;

    Axios.Post(url, data)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;

        if (response.status === 200) {
          toast.success(response.message);
          setTimeout(() => {
            toggleModal();
          }, 1000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        toast.error(res.message);
      });
  };
  const handleUpdateGeneralInfo = () => {
    let data = {
      name: formData.name,
      email: formData.email,
      phone: formData.mobile,
    };

    let url = API_UPDATE_PROFILE;

    Axios.Post(url, data)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;

        if (response.status === 200) {
          toast.success(response.message);
          setTimeout(() => {
            getProfiledetails();
          }, 1000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        toast.error(res.message);
      });
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "currentPassword":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "password":
        setShowNewPassword(!showNewPassword);
        break;
      case "password_confirmation":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Edit Profile</h2>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="box box-solid">
            <div className="box-body">
              <form>
                <Row>
                  <Col md="6" xs="12">
                    <div className="form-group">
                      <label>Name:</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationErrors.name ? "is-invalid" : ""
                        }`}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {validationErrors.name && (
                        <div className="invalid-feedback">
                          {validationErrors.name}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="6" xs="12">
                    <div className="form-group">
                      <label>Email:</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationErrors.email ? "is-invalid" : ""
                        }`}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {validationErrors.email && (
                        <div className="invalid-feedback">
                          {validationErrors.email}
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col md="6" xs="12">
                    <div className="form-group">
                      <label>Address:</label>
                      <textarea
                        className={`form-control ${
                          validationErrors.address ? "is-invalid" : ""
                        }`}
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                      {validationErrors.address && (
                        <div className="invalid-feedback">
                          {validationErrors.address}
                        </div>
                      )}
                    </div>
                  </Col> */}
                  <Col md="6" xs="12">
                    <div className="form-group">
                      <label>Mobile Number:</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationErrors.mobile ? "is-invalid" : ""
                        }`}
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        readOnly
                      />
                      {validationErrors.mobile && (
                        <div className="invalid-feedback">
                          {validationErrors.mobile}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="6" xs="12">
                    {!isModalOpen && (
                      <div className="form-group">
                        <label>Password:</label>
                        <span
                          className=" type-password form-control password-field password-field-text"
                          onClick={toggleModal}
                        >
                          {formData.password ? "********" : "*****"}
                        </span>
                        <i
                          className="zmdi zmdi-edit field-icon toggle-password"
                          onClick={toggleModal}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

                {isModalOpen && (
                  <div className="update-password-modal">
                    <div className="password-modal">
                      <div className="modal-header  ">
                        <h1 className="modal-title mt-3 mb-3 mx-2">
                          <b>Change Password</b>
                        </h1>
                        <button
                          type="button"
                          className="close"
                          onClick={toggleModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label className="mt-3">Current Password</label>
                          <input
                            type={showCurrentPassword ? "text" : "password"}
                            className={`form-control old-pswd ${
                              validationErrors.current_password
                                ? "is-invalid"
                                : ""
                            }`}
                            name="current_password"
                            value={formData.current_password}
                            onChange={handleChange}
                          />
                          <i
                            className={`zmdi ${
                              showCurrentPassword
                                ? "zmdi-eye"
                                : "zmdi-eye-off field-icon"
                            } field-icon toggle-password`}
                            onClick={() =>
                              togglePasswordVisibility("currentPassword")
                            }
                          ></i>

                          {validationErrors.current_password && (
                            <div className="invalid-feedback">
                              {validationErrors.current_password}
                            </div>
                          )}
                        </div>

                        <div className="form-group ">
                          <label className="mt-2">New Password</label>
                          <p className="m-0 password-valid">
                            Must contain a min. of 8 characters, at least one
                            lowercase,capital letter, and a number
                          </p>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            className={`form-control old-pswd${
                              validationErrors.password ? "is-invalid" : ""
                            }`}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <i
                            className={`zmdi ${
                              showNewPassword
                                ? "zmdi-eye"
                                : "zmdi-eye-off field-icon"
                            } field-icon toggle-password`}
                            onClick={() => togglePasswordVisibility("password")}
                          ></i>

                          {validationErrors.password && (
                            <div className="invalid-feedback">
                              {validationErrors.password}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Confirm Password:</label>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className={`form-control ${
                              validationErrors.password_confirmation
                                ? "is-invalid"
                                : ""
                            }`}
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                          />
                          <i
                            className={`zmdi ${
                              showConfirmPassword
                                ? "zmdi-eye"
                                : "zmdi-eye-off field-icon"
                            } field-icon toggle-password`}
                            onClick={() =>
                              togglePasswordVisibility("password_confirmation")
                            }
                          ></i>

                          {validationErrors.password_confirmation && (
                            <div className="invalid-feedback">
                              {validationErrors.password_confirmation}
                            </div>
                          )}
                        </div>
                      </div>

                      {/*<div className='reset-password-text'>

                          </div> */}
                      <Row>
                        <Col md="6">
                          <div className="text-center">
                            <button
                              type="button"
                              className="mb-3 password-cancel-btn"
                              onClick={toggleModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                        <Col md="6">
                          <button
                            type="button"
                            className="password-update-btn mb-3"
                            onClick={handleUpdatePassword}
                          >
                            Update Password
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <div className="form-group text-start">
                  <button
                    className="password-updation mt-3"
                    type="button"
                    onClick={handleUpdateGeneralInfo}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
