import React, { Component, Fragment } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { API_URL } from "../../../../../ApiUrl";

import {
  withRouter,
  toast,
  Row,
  Col,
  Axios,
  TableBody,
  TableRow,
  TableCell,
  DataTable,
  Action,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  DialogBox,
  Loading,
  moment,
  Status,
  ApiUrl,
  Link,
  NavLinkButton,
  BUTTON,
} from "../../../../../components";
// import Filters from "./Filters";

const {
  API_GET_USER_TEMPLATES_URL,
  API_GET_SINGLE_TEMPLATE_URL,
  API_DELETE_TEMPLATE,
} = ApiUrl;

const getStatus = (Dkey) => {
  return (
    <span
      className={
        Dkey.status == 1 ? "status-tag1 clr-grn" : "status-tag1 clr-red"
      }
    >
      {Dkey.status == 1 ? "Active" : "In Active"}
    </span>
  );
};
const getRole = (Dkey) => {
  return Dkey.roles.map((item) => {
    return <span>{item.name}</span>;
  });
};
const columnData = [
  { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
  {
    id: "name",
    align: false,
    disablePadding: true,
    label: "Name",
    order: 1,
    extra: "catImage",
  },
  {
    id: "email",
    align: true,
    disablePadding: false,
    label: "Email",
    order: 1,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "created_at",
    align: true,
    disablePadding: false,
    label: "Registered At",
    order: 1,
    type: "date",
  },
  { id: "action", align: true, disablePadding: false, label: "Action" },
];

const data = [
  { id: 123, name: "Anny", phone: "1232131", email: "anny@gmail.com" },
  { id: 456, name: "John", phone: "1232131", email: "anny@gmail.com" },
  { id: 567, name: "Anny", phone: "1232131", email: "anny@gmail.com" },
];

class ListAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateId: this.props.id,
      // templateId: this.props.templateId,
      columns: columnData,
      data: "",
      limit: 20,
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
      order: -1,
      fieldName: "",
      status: this.props.listType,
      fieldValue: "",
      orderBy: "created_at",
      customerSearch: "",
      open: false,
      selectedId: "",
      previewVisible: false,
      showModal: false,
      showFile: "",
      details: {},
      mediaLink: "",
      randomLink: "",
    };
  }

  handleCancel = () => {
    console.log("closeeeeeee");
    this.setState({ previewVisible: false });
  };

  handleView = async (id) => {
    console.log(id, "case id");
    this.setState((prev) => ({ ...prev, showModal: true }));
  };

  handlePreview = (id,link) => {
    console.log(id,name, "asdasdasd");
    this.setState({
      mediaLink: id,
      previewVisible: true,
      randomLink:link
    });
  };
  componentDidMount = () => {
    this.getTableRecords(this.state.order, this.state.orderBy);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page }, () =>
      this.getTableRecords(this.state.order, this.state.orderBy)
    );
  };

  handleRequestSort = (data) => {
    if (data.key !== "action") {
      let NewColoumns = this.state.columns.map((key, index) => {
        if (key.id !== data.key) {
          return { ...key, order: 1 };
        } else {
          return { ...key, order: data.order };
        }
      });

      this.setState(
        {
          loading: true,
          columns: NewColoumns,
          orderBy: data.key,
          order: data.order,
        },
        () => this.getTableRecords(data.order, data.key)
      );
    }
  };

  getTableRecords = async (order, orderBy) => {
    const {
      fieldName,
      fieldValue,
      page,
      rowsPerPage,
      status,
      templateId,
    } = this.state;
    this.setState({ loading: true });

    const lawMediation = localStorage.getItem("lawMediation");
    
    let body = {
      orderBy: orderBy,
      order: order,
      page: parseInt(page) + 1,
      status: status,
      limit: rowsPerPage,
    };
    if (fieldName || fieldValue) {
      body.fieldName = fieldName;
      body.fieldValue = fieldValue;
    }
    const url = lawMediation === "true" || lawMediation === null ? API_GET_USER_TEMPLATES_URL + templateId:
    API_GET_USER_TEMPLATES_URL + templateId + "/mediation"
    Axios.post(url)
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response.data.length,
          // totalCount: response.totalcount,
          data: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("");
        this.setState({ loading: false });
      });
  };

  handleChange = (event) => {
    console.log(event);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterData = (e) => {
    e.preventDefault();
    this.getTableRecords(-1, "created_at");
  };

  removefilterbutton = () => {
    this.setState(
      {
        totalCount: 0,
        rowsPerPage: 10,
        page: 0,
        order: 1,
        fieldName: "firstname",
        fieldValue: "",
        orderBy: "created_at",
      },
      () => {
        this.getTableRecords(-1, "created_at");
      }
    );
  };
  handleClickOpen = (id) => {
    console.log(id, "idkalog");
    this.setState({
      open: true,
      selectedId: id,
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  deleteAction = (id) => {
    Axios.post(API_DELETE_TEMPLATE + id)
      .then((res) => {
        this.setState({
          open: false,
          selectedId: "",
          data: [...this.state.data.filter((cate) => cate._id !== id)],
          totalCount: --this.state.totalCount,
        });
        toast.success(res.data.message);
        this.getTableRecords(this.state.order, this.state.orderBy);
      })
      .catch((err) => {
        this.setState({
          open: false,
          selectedId: "",
        });
        toast.error("Template does not deleted!");
      });
  };
  editForm = (id) => {
    this.props.hide(!this.props.show);
    this.props.editTid(id);
    console.log(id, "410");
  };
  render() {
    const {
      data,
      fieldName,
      fieldValue,
      rowsPerPage,
      page,
      totalCount,
      loading,
      previewVisible,
      mediaLink,
      randomLink ,
      templateId,
      limit,
      details,
      showFile,
      status,
    } = this.state;

    return (
      <Fragment>
        <DialogBox
          title={"Template"}
          open={this.state.open}
          onClose={this.handleClose}
          deleteAction={() => this.deleteAction(this.state.selectedId)}
        />

        {/* <Row>
          <Col md={{ size: 2, offset: 10 }}>
            <NavLinkButton
              title="Add New Template"
              link={`/app/template/add/${templateId}`}
              iclass="zmdi-plus-circle"
            />
          </Col>
        </Row> */}
        <div className="flex-auto scroll-list1">
          <DataTableBody2
            loading={loading}
            column={this.state.columns}
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleClickOpen={this.handleClickOpen}
            handlePreview={this.handlePreview}
            handleView={this.handleView}
            limit={limit}
            editForm={this.editForm}
            actions={[
              { label: "View", icon: "", link: "/app/users/view" },
              { label: "Edit", icon: "", link: "/app/users/edit" },
              { label: "Delete", icon: "", link: this.handleClickOpen },
            ]}
          />
          {/* {data.length > 0 && (
            <div className="float-right">
              <DataTablePagination
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
          )} */}
        </div>
        <Modal isOpen={previewVisible} toggle={this.handleCancel}>
          <p className="text-right m-0">
            <span onClick={this.handleCancel} className="modal-cancel12">
              <i class="zmdi zmdi-close"></i>
            </span>
          </p>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${API_URL}/d/uY${mediaLink}ig?${randomLink}`}
            style={{ width: "100%", height: "500px" }}
          />
        </Modal>
      </Fragment>
    );
  }
}

class DataTableBody2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      loading,
      handleClickOpen,
      handlePreview,
      handleView,
      data,
      editForm,
      showModal,
      rowsPerPage,
      column,
      limit,
    } = this.props;
    return (
      <div>
        {loading === false && data.length === 0 && (
          <div className="n--data text-center"> No data Found!</div>
        )}
        {loading === true && (
          <div className="text-center p-8o">
            <Loading />
          </div>
        )}
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            return (
              <div
                class="listing-main p-2 mt-3 d-md-flex justify-content-between align-items-center"
                key={index}
              >
                <a href="#">
                  <div class="client-listing">
                    <div class="ml-2">
                      <span class="main-title mr-2"> {Dkey.name} </span>
                    </div>
                  </div>
                </a>
                <div>
                  <div class="right-unlist-1 d-flex align-items-center justify-content-center bg-transparent">
                    <button
                      className="make-aapbtn ml-2 py-2"
                      onClick={() => handlePreview(Dkey?.media?.id,Dkey?.media?.link)}
                    >
                      View
                    </button>

                    <button
                      onClick={() => editForm(Dkey.id)}
                      className="make-aapbtn ml-2 py-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleClickOpen(Dkey.id)}
                      className="make-aapbtn ml-2 py-2"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default withRouter(ListAll);
