import React from 'react'

const ClinetFreshCAse = () => {
    return (
        <div className='mt-5 text-center'>
            Clinet FreshCAse Coming soon
        </div>
    )
}

export default ClinetFreshCAse
