
import React, { Fragment } from "react";
import {
  withRouter,
  toast,
  Axios,
  CircularProgress,
  CardBody,
  Status,
  CardText,
  Row,
  Container,
  moment,
  Col,
  Card,
  ApiUrl,
  Link,
  Button,
} from "../../../../../../../components";

import List from "./ListAll";

const { API_GET_SINGLE_CLIENT } = ApiUrl;

class ViewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.edit,
      name: this.props.location.name || "",
      email: this.props.location.email || "",
      details: "",
      extraDetails: {},
      selectedCar: "",
      accountDetails: "",
      notfound: false,
      open: false,
    };
  }

  async componentWillMount() {
    this.setState({ loading: true });
    Axios.get(API_GET_SINGLE_CLIENT + this.state.editId)
      .then((response) => {
        response = response.data;
        if (response.status === "success") {
          const { accountDetails, selectedCar } = response.data;
          this.setState({
            details: response.data.data,
            extraDetails: response.data,
            accountDetails,
            selectedCar,
          });
        } else {
          this.setState({ notfound: true });
        }
      })
      .catch((error) => {
        console.log("error");
        // toast.error("Headersets not found. Invalid ID.");
      });

    this.setState({ loading: false });
  }
  windowOpen = () => {
    // window.open(`http://localhost:3000/#/app/client/template/list/${123}`);
    window.open(`http://localhost:3000/#/app/template/add`);
  };
  render() {
    const { loading, details, name, email } = this.state;
    return (
      <Container style={{ paddingBottom: "10px" }}>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <Row>
        {/* <Col sm="10">
        <Link
          to="/app/client/list"
          style={{
            color: "grey",
            fontSize: "32px",
          }}
        >
          <i
            class="zmdi zmdi-arrow-left"
            style={{ fontSize: "22px", marginTop: "2px" }}
          ></i>
        </Link>
        </Col> */}
          <Col sm="12">
            <Card className="mb-0">
              <CardBody className="row text-left">
                <CardText className="col-sm-6">
                  <b>Name </b>: {this.props.detail.name || "Prachi"}
                </CardText>
                <CardText className="col-sm-6">
                  <b>Email </b>: {this.props.detail.email || "prachi@gmail.com"}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <CardText className="col-sm-12">
            <div className="d-flex justify-content-between copy-text-container">
              <p className="copytext12">Copy the Name for templates</p>
            </div>
            <List />
          </CardText>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ViewForm);
