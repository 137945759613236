import React, { Fragment, useState } from "react";
import axios from "axios";

import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  TEXTFIELD,
  Link,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  NEXT,
  PREV,
  ApiUrl,
  checkValidations,
  checkError,
  PHONECODES,
  FILEUPLOAD,
  Avatar,
  Loading,
  moment,
  ImagePreview,
  PHONECODESDATA,
  SEARCHFILTER,
  GoogleAutocomplete,
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";
import Addons from "./addons/Addons";
import Experience from "./addons/Experience";
import Termscondition from "./form-steps/termscondition";
import Educationdetails from "./form-steps/educationdetails";

const {
  API_MEDIATOR_REGISTER,
  EMAIL_REGEX,
  Numeric,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
  API_GET_ALL_ROLES,
} = ApiUrl;

const SpecializationsData = [
  { name: "Agency & Distribution" },
  { name: "Engeneering/Technical" },
  { name: "Intellectual Property" },
  { name: "Joint Venture" },
  { name: "Aviation" },
  { name: "Finance Banking" },
  { name: "International Investments" },
  { name: "Maritime Shipping" },
  { name: "Telecommunications" },
  { name: "Construction" },
  { name: "Insurance" },
];

const itemsOBJ = {
  user_id: "",
  college_institute_name: "",
  university_name: "",
  course_name: "",
  year_of_passing: "",
};
const itemsOBJ2 = {
  user_id: "",
  employer_name: "",
  employer_address: "",
  position: "",
  start_year: "",
  end_year: "",
};
const itemsMED = {
  course_name: "",
  organiser_name: "",
  course_duration: "",
  course_year: "",
};
const optionObj = {
  name: "",
  price: "",
  size: "",
};

const itemsOBJ_new = Object.assign({}, itemsOBJ);
const itemsOBJ_new2 = Object.assign({}, itemsOBJ2);
const itemsOBJ_med = Object.assign({}, itemsMED);

const AddonObj = {
  items: [{ ...itemsOBJ_new }],
};
const ExperienceObj = {
  catName: "",
  // type: "single",
  // minimumSelection: 0,
  // maximumSelection: 0,
  // featuredPosition: 0,
  items: [{ ...itemsOBJ_new2 }],
  options: [{ ...optionObj }],
};
const MediationObj = {
  items: [{ ...itemsOBJ_med }],
};

const AddonObj_new = Object.assign({}, AddonObj);
const experinenceObj_new = Object.assign({}, ExperienceObj);
const mediationObj_new = Object.assign({}, MediationObj);

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      phone: "",
      date_of_birth: "",
      parent_name: "",
      age: "",
      gender: "",
      nationality: "",
      address: "",
      pin_code: "",
      occupation: "",
      college_institute_name: "",
      university_name: "",
      course_name: "",
      year_of_passing: "",
      arbitrator_panel_category_id: "",
      education_data: [{ ...AddonObj_new }],
      experience_data: [{ ...experinenceObj_new }],
      mediation_data: [{ ...mediationObj_new }],
      allchecked: [],
      list: [...SpecializationsData],
      enrollment_number: "",
      city: "",
      state: "",
      bar_license: "",
      status: "Active",
      bar_license: "",
      promotionImageName: "",
      step: 1,
      showMed: "",
      showMed: "",
      showNext: true,
      showPrev: false,
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        enrollment_number: "",
        gender: "",
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
        address: "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process } = this.state;
    this.setState({ loading: true });
    Axios.get(API_GET_ALL_ROLES, {})
      .then((res) => {
        console.log(res);
        const { status, data } = res.data;
        const categories = data.map((c) => {
          return { [c.id]: c.name };
        });
        this.setState({
          categoryList: categories,
        });
      })
      .catch((err) => {
        console.log(err, 66565);
      });
    this.setState({ loading: false });
  }

  nextStep = () => {
    this.setState((prevState) => {
      return { step: prevState.step + 1 };
    });
    if (this.state.step == 1) {
      this.setState({ showMed: "med" });
      this.setState({ showPrev: true });

    } else {
      this.setState({ showMed: "" });
    }
    if (this.state.step == 2) {
      this.setState({ showNext: false });
      
    } else {
      this.setState({ showNext: true });
    }
  };
  prevStep = () => {
    this.setState((prevState) => {
      return { step: prevState.step - 1 };
    });
  };

  handleFile = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let file = event.target.files[0];

    switch (name) {
      case "dl":
        if (value.length > 0 && !file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          errors.dl = "Image Format is not valide";
        } else {
          errors.dl = "";
        }
        break;
      default:
        break;
    }
    this.setState({ [name]: file }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
    console.log(file, "file");
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;

      case "role":
        errors.role = value.length < 1 ? "Required" : "";
        break;

      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = "Invalid email";
          }
        }
        errors.email = err;
        break;
      }
      case "phone": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.phone = err;

        break;
      }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid format";
          }
        }
        errors.password = err;

        break;
      }
      case "password_confirmation": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid format";
          }
        }
        errors.password_confirmation = err;

        break;
      }
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  updateAddons = (education_data) => {
    this.setState({ education_data, disabled: false });
  };
  updateExperience = (experience_data) => {
    this.setState({ experience_data, disabled: false });
  };
  updateMediation = (mediation_data) => {
    this.setState({ mediation_data, disabled: false });
  };

  handleAddress = (address) => {
    let errors = { ...this.state.errors };
    const data = {
      address: address.address,
      postCode: address.postal_code,
      city: address.city,
      state: address.state,
      lat: address.lat,
      lng: address.lng,
    };

    errors.address = address.address < 1 ? "Required" : "";

    this.setState(
      {
        address: data.address,
        errors,
      },
      () => {
        let err = checkError(errors);
        this.setState({ disabled: err.disabled });
      }
    );
  };

  redirectPage = () => {
    this.props.history.push("/app/signin");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      editId,
      process,
      errors,
      role,
      email,
      address,
      password,
      password_confirmation,
      phone,
      enrollment_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      status,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      enrollment_number: enrollment_number,
      gender: gender,
      city: city,
      state: state,
      pin_code: pin_code,
      bar_license: bar_license,
    };
    if (password) {
      data.password = password;
    }
    if (password_confirmation) {
      data.password_confirmation = password_confirmation;
    }
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_MEDIATOR_REGISTER;
    if (process == "edit") {
      data.userId = editId;
      url = API_EDIT_ADMIN;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.post(url, formData)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;
        toast.success(response.message);
        this.setState({ loading: false, disabled: false });

        if (response.status === 200) {
          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        // toast.error(res.message);
        // this.setState({ loading: false, disabled: false });
      });
  };
  reset = () => {
    this.setState({
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      phone: "",
      address: "",
      enrollment_number: "",
      gender: "",
      city: "",
      state: "",
      pin_code: "",
      bar_license: "",
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        address: "",
        enrollment_number: "",
        gender: [],
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
      },
      disabled: true,
    });
  };
  specialization = (e) => {
    const { allchecked } = this.state;
    if (e.target.checked) {
      this.setState({ allchecked: [...allchecked, e.target.value] });
    } else {
      this.setState({
        allchecked: allchecked.filter((item) => item !== e.target.value),
      });
    }
  };
  showSteps = () => {
    const {
      errors,
      name,
      email,
      password,
      password_confirmation,
      enrollment_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      address,
      phone,
      disabled,
      process,
      education_data,
      experience_data,
      mediation_data,
      step,
      loading,
      showMed,
      list,
      showNext, showPrev 
    } = this.state;
    console.log("checking");
    switch (step) {
      case 1:
        console.log("checking 1");

        return (
          <>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="User Name"
                value={name}
                name={"name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>

            <Col md="4" xs="12">
              <TEXTFIELD
                label="Email"
                value={email}
                name={"email"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Mobile No."
                name="phone"
                onChange={this.handleChange}
                value={phone}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Address"
                value={address}
                name={"address"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>

            <Col md="4" xs="12">
              <TEXTFIELD
                label="City"
                value={city}
                name={"city"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Pin Code"
                value={pin_code}
                name={"pin_code"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="State"
                value={state}
                name={"state"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <SELECT
                label="Gender"
                value={gender}
                name={"gender"}
                onChange={this.handleChange}
                errors={errors}
                data={[{ 1: "Male" }, { 0: "Female" }]}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Enrolment No."
                value={enrollment_number}
                name={"enrollment_number"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Password"
                value={password}
                name={"password"}
                edit={process == "edit" ? true : false}
                onChange={this.handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Confirm Password"
                value={password_confirmation}
                name={"password_confirmation"}
                edit={process == "edit" ? true : false}
                onChange={this.handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
          </>
        );
      case 2:
        console.log("checking 2");
        return (
          <>
            <Educationdetails
              data={education_data}
              updateAddons={this.updateAddons}
              dataExperience={experience_data}
              updateExperience={this.updateExperience}
              optionObj={optionObj}
              dataMediation={mediation_data}
              updateMediation={this.updateMediation}
              specialization={this.specialization}
              SpecializationsData={list}
            />
            {showMed && (
              <>
                <p className="w-100 exp-txt">Mediation Experience</p>
                <Col md="3" xs="12">
                  <TEXTFIELD
                    label="No. of cases done as Sole Mediator"
                    value={enrollment_number}
                    name={"enrollment_number"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Details of Mediation conducted"
                    value={enrollment_number}
                    name={"enrollment_number"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
              </>
            )}
          </>
        );
      case 3:
        console.log("checking 3");
        return (
          <Col md="12" xs="12">
            <Termscondition
              bar_license={bar_license}
              handleFile={this.handleFile}
              errors={errors}
            />
          </Col>
        );

      default:
    }
  };
  render() {
    const {
      errors,
      name,
      email,
      password,
      password_confirmation,
      enrollment_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      address,
      phone,
      disabled,
      process,
      education_data,
      experience_data,
      mediation_data,
      step,
      loading,
      showMed,
      showPrev,
      showNext,
      categoryList,
      status,
      role,
    } = this.state;
    return (
      <>
        <Header />
        <div style={{ paddingTop: "17vh" }}></div>
        <Fragment>
          {loading && (
            <div className="text-center w-100">
              <Loading />
            </div>
          )}
          <div className="app-login-container mediator-signup-form d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
              <div className="app-login-content w-100">
                <div className="">
                  <h1>Sign Up as Mediator</h1>
                </div>

                <div className="app-login-forma">
                  <form onSubmit={this.handleSubmit} noValidate>
                    <Row>
                      {this.showSteps()}
                      {/* {step == 1 ? (
                        <>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Name"
                              value={name}
                              name={"name"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="User Name"
                              value={name}
                              name={"name"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>

                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Email"
                              value={email}
                              name={"email"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Mobile No."
                              name="phone"
                              onChange={this.handleChange}
                              value={phone}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Address"
                              value={address}
                              name={"address"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>

                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="City"
                              value={city}
                              name={"city"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Pin Code"
                              value={pin_code}
                              name={"pin_code"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="State"
                              value={state}
                              name={"state"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <SELECT
                              label="Gender"
                              value={gender}
                              name={"gender"}
                              onChange={this.handleChange}
                              errors={errors}
                              data={[{ 1: "Male" }, { 0: "Female" }]}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <TEXTFIELD
                              label="Enrolment No."
                              value={enrollment_number}
                              name={"enrollment_number"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <PASSWORDFIELD
                              label="Password"
                              value={password}
                              name={"password"}
                              edit={process == "edit" ? true : false}
                              onChange={this.handleChange}
                              note={PASS_VALIDATION_MSG}
                              errors={errors}
                            />
                          </Col>
                          <Col md="4" xs="12">
                            <PASSWORDFIELD
                              label="Confirm Password"
                              value={password_confirmation}
                              name={"password_confirmation"}
                              edit={process == "edit" ? true : false}
                              onChange={this.handleChange}
                              note={PASS_VALIDATION_MSG}
                              errors={errors}
                            />
                          </Col>
                        </>
                      ) : step == 2 ? (
                        <>
                        <Educationdetails
                          data={education_data}
                          updateAddons={this.updateAddons}
                          dataExperience={experience_data}
                          updateExperience={this.updateExperience}
                          optionObj={optionObj}
                          dataMediation={mediation_data}
                          updateMediation={this.updateMediation}
                        />
                        {showMed && 
                         <>
                          <p className="w-100">Mediation Experience</p>
                          <Col md="3" xs="12">
                            <TEXTFIELD
                              label="No. of cases done as Sole Mediator"
                              value={enrollment_number}
                              name={"enrollment_number"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                          <Col md="3" xs="12">
                            <TEXTFIELD
                              label="Details of Mediation conducted"
                              value={enrollment_number}
                              name={"enrollment_number"}
                              onChange={this.handleChange}
                              errors={errors}
                            />
                          </Col>
                        </>
                      } 
                        </>
                     
                        
                      ) : step == 3 ? (
                        <Col md="12" xs="12">
                          <Termscondition
                            bar_license={bar_license}
                            handleFile={this.handleFile}
                            errors={errors}
                          />
                        </Col>
                      ) : " " } */}
                    </Row>
                    <Row>
                    { showPrev ===true &&
                        <Col md="3" xs="12">
                          <PREV className="next-btn1" onClick={this.prevStep}>
                            Prev
                            <i class="zmdi zmdi-arrow-right"></i>
                          </PREV>
                        </Col>
                      }
                      {showNext === true ? (
                        <Col md="3" xs="12">
                          <NEXT className="next-btn1" onClick={this.nextStep}>
                            Next
                            <i class="zmdi zmdi-arrow-right"></i>
                          </NEXT>
                        </Col>
                      ) : (
                        <>
                          <Col md="3" xs="12">
                            <SUBMIT title="Submit" disabled={disabled} />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Link to="/signin">Already have an account</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        <div style={{ paddingTop: "17vh" }}></div>
        <Footer />
      </>
    );
  }
}

export default withRouter(SignUp);
