import React from 'react';
import {Redirect, Route, Switch,asyncComponent} from '../../../components';

const Dashboard = ({match}) =>(
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={asyncComponent(()=>import('./routes/list/index')) } />
            <Route path={`${match.url}/edit/:contentPageId`} component={asyncComponent(()=>import('./routes/edit/index')) } />
            <Route component={asyncComponent(() => import('../../../app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Dashboard
