// import React, { Fragment } from "react";

// import {
//     withRouter,
//     Row,
//     Col,
//     toast,
//     Axios,
//     CircularProgress,
//     TEXTFIELD,
//     SUBMIT,
//     ApiUrl,
//     checkValidations,
//     checkError,
// } from "../../../../../../../components";

// const { API_UPADTE_DOCUMENT_DATE, API_UPADTE_DOCUMENT_NAME, API_GET_DOC_NAME } = ApiUrl;

// class Editdate extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             editId: this.props.editId,
//             listId: this.props.listId,
//             process: this.props.role,
//             showEditdate: this.props.showEditdate,
//             docName: this.props.docName,
//             start_date: "",
//             title: "",
//             errors: {
//                 start_date: "",
//             },
//             disabled: true,
//             loading: false,
//         };
//     }

//     async componentDidMount() {
//         const { id, process } = this.state;


//         Axios.get(API_GET_DOC_NAME + this.props.docId)
//             .then((response) => {
//                 var data = response.data.data;
//                 this.setState({
//                     title: data,
//                     loading: false,
//                 });
//             })
//             .catch((error) => {
//                 console.log("error")
//             });

//     }

//     handleChange = (event) => {
//         event.preventDefault();
//         const { name, value, validations } = event.target;
//         let errors = this.state.errors;
//         let finalVal = value;
//         const maxDate = new Date();
//         maxDate.setHours(0, 0, 0, 0);
//         maxDate.setDate(maxDate.getDate() + 1);
//         console.log(maxDate, value, "sssss");
//         switch (name) {
//             case "start_date":
//                 let err = "";
//                 if (value.length < 1) {
//                     err = "Required";
//                 } else {
//                     if (value > maxDate) {
//                         err = "Only numeric allowed";
//                         finalVal = "";
//                     } else {
//                         if (value < maxDate) {
//                             err = "Only numeric allowed";
//                             finalVal = "";
//                         }
//                     }
//                 }

//                 errors.start_date = err;

//                 break;

//             default:
//                 break;
//         }

//         this.setState({ errors, [name]: finalVal }, () => {
//             let err = checkError(errors);
//             this.setState({ disabled: err.disabled });
//         });
//     };

//     handleSubmit = (event) => {
//         event.preventDefault();
//         this.setState({ loading: true, disabled: true });

//         const { start_date, errors } = this.state;
//         let err = checkError(errors);
//         if (err.disabled === true) {
//             toast.error(err.error);
//             this.setState({ loading: false, disabled: false });
//             return false;
//         }

//         let data = {
//             start_date: start_date,
//         };

//         let valid = checkValidations(data);
//         if (valid.error) {
//             toast.error(valid.error);
//             this.setState({ loading: false, disabled: false });
//             return false;
//         }

//         data = valid.data;

//         let url = API_UPADTE_DOCUMENT_DATE;

//         Axios.Post(url + this.props.docId, data)
//             .then((res) => {
//                 this.setState({ loading: false, disabled: false });

//                 if (res.data.success === true) {
//                     toast.success(res.data.message);

//                     this.props.showEditdate(false);
//                     this.props.details();
//                 } else {
//                     toast.error(res.data.error);
//                 }
//             })
//             .catch((error) => {
//                 console.log(error, "res.message");
//             });
//     };
//     handleUpdate = (event) => {
//         event.preventDefault();
//         this.setState({ loading: true, disabled: true });

//         const { errors, title } = this.state;
//         let err = checkError(errors);
//         if (err.disabled === true) {
//             toast.error(err.error);
//             this.setState({ loading: false, disabled: false });
//             return false;
//         }

//         let data = {
//             title: title,
//         };

//         let valid = checkValidations(data);
//         if (valid.error) {
//             toast.error(valid.error);
//             this.setState({ loading: false, disabled: false });
//             return false;
//         }

//         data = valid.data;

//         let url = API_UPADTE_DOCUMENT_NAME;

//         Axios.Post(url + this.props.docId, data)
//             .then((res) => {
//                 this.setState({ loading: false, disabled: false });

//                 if (res.data.success === true) {
//                     toast.success(res.data.message);

//                     this.props.showEditdate(false);
//                     this.props.details();
//                 } else {
//                     toast.error(res.data.error);
//                 }
//             })
//             .catch((error) => {
//                 console.log(error, "res.message");
//             });
//     };

//     render() {
//         const {
//             loading,
//             start_date,
//             errors,
//             docName,
//             title,
//             disabled,
//         } = this.state;
//         console.log(this.props.docName, "10kljm,j");
//         return (
//             <Fragment>
//                 {loading && (
//                     <div className="text-center w-100">
//                         <CircularProgress />
//                     </div>
//                 )}
//                 <div>
//                     {docName ? (
//                         <form className="mt-2" onSubmit={this.handleUpdate} noValidate>
//                             <Row>
//                                 <Col md="12" xs="12">
//                                     <TEXTFIELD
//                                         type="text"
//                                         label="Document Name"
//                                         value={title}
//                                         name="title"
//                                         onChange={this.handleChange}
//                                         errors={errors}
//                                     />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md="12" xs="12">
//                                     <SUBMIT title="Update" disabled={disabled} />
//                                 </Col>
//                             </Row>
//                         </form>
//                     ) : (
//                         <form className="mt-2" onSubmit={this.handleSubmit} noValidate>
//                             <Row>
//                                 <Col md="12" xs="12">
//                                     <TEXTFIELD
//                                         type="date"
//                                         label="Document Date"
//                                         value={start_date}
//                                         name="start_date"
//                                         onChange={this.handleChange}
//                                         errors={errors}
//                                     />
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md="12" xs="12">
//                                     <SUBMIT title="Update" disabled={disabled} />
//                                 </Col>
//                             </Row>
//                         </form>
//                     )}
//                 </div>
//             </Fragment>
//         );
//     }
// }

// export default withRouter(Editdate);
import React, { Fragment } from "react";

import {
    withRouter,
    Row,
    Col,
    toast,
    Axios,
    CircularProgress,
    TEXTFIELD,
    SUBMIT,
    ApiUrl,
    checkValidations,
    checkError,
} from "../../../../../../../components";

const { API_UPADTE_DOCUMENT_DATE, API_UPADTE_DOCUMENT_NAME, API_GET_DOC_NAME } = ApiUrl;

class Editdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editId: this.props.editId,
            listId: this.props.listId,
            process: this.props.role,
            showEditdate: this.props.showEditdate,
            docName: this.props.docName,
            start_date: "",
            title: "",
            errors: {
                start_date: "",
            },
            disabled: true,
            loading: false,
        };
    }

    async componentDidMount() {
        const { id, process } = this.state;


        Axios.get(API_GET_DOC_NAME + this.props.docId)
            .then((response) => {
                var data = response.data.data;
                this.setState({
                    title: data,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log("error")
            });

    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value, validations } = event.target;
        let errors = this.state.errors;
        let finalVal = value;
        const maxDate = new Date();
        maxDate.setHours(0, 0, 0, 0);
        maxDate.setDate(maxDate.getDate() + 1);
        console.log(maxDate, value, "sssss");
        switch (name) {
            case "start_date":
                let err = "";
                if (value.length < 1) {
                    err = "Required";
                } else {
                    if (value > maxDate) {
                        err = "Only numeric allowed";
                        finalVal = "";
                    } else {
                        if (value < maxDate) {
                            err = "Only numeric allowed";
                            finalVal = "";
                        }
                    }
                }

                errors.start_date = err;

                break;

            default:
                break;
        }

        this.setState({ errors, [name]: finalVal }, () => {
            let err = checkError(errors);
            this.setState({ disabled: err.disabled });
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, disabled: true });

        const { start_date, errors } = this.state;
        let err = checkError(errors);
        if (err.disabled === true) {
            toast.error(err.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        let data = {
            start_date: start_date,
        };

        let valid = checkValidations(data);
        if (valid.error) {
            toast.error(valid.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        data = valid.data;

        let url = API_UPADTE_DOCUMENT_DATE;

        Axios.Post(url + this.props.docId, data)
            .then((res) => {
                this.setState({ loading: false, disabled: false });

                if (res.data.success === true) {
                    toast.success(res.data.message);

                    this.props.showEditdate(false);
                    this.props.details();
                } else {
                    toast.error(res.data.error);
                }
            })
            .catch((error) => {
                console.log(error, "res.message");
            });
    };
    handleUpdate = (event) => {
        event.preventDefault();
        this.setState({ loading: true, disabled: true });

        const { errors, title } = this.state;
        let err = checkError(errors);
        if (err.disabled === true) {
            toast.error(err.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        let data = {
            title: title,
        };

        let valid = checkValidations(data);
        if (valid.error) {
            toast.error(valid.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        data = valid.data;

        let url = API_UPADTE_DOCUMENT_NAME;

        Axios.Post(url + this.props.docId, data)
            .then((res) => {
                this.setState({ loading: false, disabled: false });

                if (res.data.success === true) {
                    toast.success(res.data.message);

                    this.props.showEditdate(false);
                    this.props.details();
                } else {
                    toast.error(res.data.error);
                }
            })
            .catch((error) => {
                console.log(error, "res.message");
            });
    };

    render() {
        const {
            loading,
            start_date,
            errors,
            docName,
            title,
            disabled,
        } = this.state;
        console.log(this.props.docName, "10kljm,j");
        return (
            <Fragment>
                {loading && (
                    <div className="text-center w-100">
                        <CircularProgress />
                    </div>
                )}
                <div>
                    {docName ? (
                        <form className="mt-2" onSubmit={this.handleUpdate} noValidate>
                            <Row>
                                <Col md="12" xs="12">
                                    <TEXTFIELD
                                        type="text"
                                        label="Document Name"
                                        value={title}
                                        name="title"
                                        onChange={this.handleChange}
                                        errors={errors}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" xs="12">
                                    <SUBMIT title="Update" disabled={disabled} />
                                </Col>
                            </Row>
                        </form>
                    ) : (
                        <form className="mt-2" onSubmit={this.handleSubmit} noValidate>
                            <Row>
                                <Col md="12" xs="12">
                                    <TEXTFIELD
                                        type="date"
                                        label="Document Date"
                                        value={start_date}
                                        name="start_date"
                                        onChange={this.handleChange}
                                        errors={errors}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" xs="12">
                                    <SUBMIT title="Update" disabled={disabled} />
                                </Col>
                            </Row>
                        </form>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Editdate);
