import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import Landingpage from "./landing-page";
import SignIn from "../../../containers/SignIn";
import Aboutus from "./aboutus";
import Whychoose from "./choose"

const Mainapp = (props) => {
  console.log(props.history.location.pathname, "history")
  return (
    <Fragment>
      <Header />

      {props.history.location.pathname == "/home" ?
        <div className="">
          <Landingpage />
          <Aboutus />
          <Whychoose />
        </div>
        : ""}
      <Footer />
    </Fragment>
  );

};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  };
};

export default withRouter(connect(mapStateToProps)(Mainapp));
