import React, { Fragment } from "react";
import {
  withRouter,
  Axios,
  CircularProgress,
  Row,
  Container,
  ApiUrl,
} from "../../../../../components";

import Casetabs from "../SingleCase/files/tabs";
const { API_GET_SINGLE_CASE } = ApiUrl;

class Casedetail extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    this.state = {
      editId: this.props.edit,
      details: "",
      notfound: false,
    };
  }

  async componentWillMount() {
    this.setState({ loading: true });
    const hash = window.location.hash;
    const hashId = hash.split("/")[4];
    Axios.get(API_GET_SINGLE_CASE + hashId)
      .then((response) => {
        response = response.data;
        this.setState({ details: response.data });
      })
      .catch((error) => {
        console.log("error");
      });
    this.setState({ loading: false });
  }
  formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}.${month}.${year}`;
  };
  render() {
    let image = ["", null, undefined, "none"];

    const { loading, details, editId, notfound } = this.state;
    console.log(editId, "editId");

    const arbit =
      details &&
      details?.case_master_users?.reduce((acc, i) => {
        console.log(acc, "acciui");
        if (
          i.related_user_role == "Arbitrator" &&
          i.related_user_type == "Arbitrator"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);

    const client =
      details &&
      details?.case_master_users?.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
    const clientVs = client ? client.related_user.name : null;

    const respondent =
      details &&
      details?.case_master_users?.reduce((acc, i) => {
        console.log(acc, "acciui");
        if (
          i.related_user_role == "Party" &&
          i.related_user_type == "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);
    return (
      <Container style={{ paddingBottom: "10px" }}>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <section className="Sales_analytics p-10">
          <div fluid>
            <div className="float-end">
              <div className="status-container">
                <p class="add-cd-text">
                  <button
                    onClick={() => this.props.history.goBack()}
                    className="back-btn-arw1"
                  >
                    <i
                      class="zmdi zmdi-arrow-left"
                      style={{ fontSize: "19px", marginTop: "2px" }}
                    ></i>
                  </button>
                  {/* <Link
                      to={`/app/client/case-list/${editId}`}
                      style={{
                        color: "grey",
                        fontSize: "19px",
                        float: "left",
                      }}
                    >
                      <i
                        class="zmdi zmdi-arrow-left"
                        style={{ fontSize: "19px", marginTop: "2px" }}
                      ></i>
                    </Link> */}
                  Case Details
                  {/* <span className="status-container-text">
                    {details.case_status}
                  </span> */}
                </p>
              </div>
            </div>
            <hr />
            <div className="">
              <h5 className="case-heading"> </h5>

              <div className="case-deatils-container">
                <div className="po-box">
                  <p>
                    Case Number :
                    <span className="dis-con ml-2">
                      {" "}
                      {details.case_number || "--"}{" "}
                    </span>{" "}
                  </p>
                  <p>
                    Case Title:{" "}
                    <span className="dis-con ml-2">
                      <span>{clientVs && clientVs + `${" "}` + "vs"} </span>
                      <span>
                        {respondent?.length === 1
                          ? respondent
                          : respondent?.length === 2
                          ? respondent[0] + " & another"
                          : respondent?.length === 3 || respondent?.length > 3
                          ? respondent[0] + " & others"
                          : ""}
                      </span>
                    </span>
                  </p>

                  <p>
                    Tribunal/Arbitrator:
                    <span className="dis-con ml-2">
                      {arbit && arbit.length > 0
                        ? arbit
                        : arbit && arbit.length > 1
                        ? arbit + ","
                        : "No Arbitrator"}
                    </span>{" "}
                  </p>
                  <p>
                    Chamber Of Arbitration:
                    <span className="dis-con ml-2">
                      Indian Chambers of Arbitration & Mediation
                    </span>{" "}
                  </p>
                  <p>
                  {details?.metas?.map((meta, index) => {
                      if (meta.meta_key === "LAN") {
                        return (
                          <p key={index}>
                            LAN :{" "}
                            <span className="dis-con ml-2">
                              {meta.meta_value}
                            </span>
                          </p>
                        );
                      }
                      return null;
                    })}
                  </p>
                  {/* <p>
                      Counsel: <span className="dis-con ml-2"> -- </span>
                    </p> */}
                  {/* <p>
                    Client:
                    {details &&
                      details.case_master_users.map((i) => {
                        return (
                          <span className="dis-con ml-2">
                            <span>
                              {i.related_user_role == "Client" &&
                              i.related_user_type == "claimant"
                                ? i.related_user?.name
                                : ""}
                            </span>
                          </span>
                        );
                      })}
                  </p> */}

                  {/* {details.metas &&
                      details.metas.map((m) => {
                        return (
                          <>
                            <p>
                              {m.meta_key}:{" "}
                              <span className="dis-con ml-2">
                                {" "}
                                {m.meta_value}
                              </span>{" "}
                            </p>
                          </>
                        );
                      })} */}
                </div>
                <div>
                  <div className="case-hearing-box">
                    {/* {details.hearing && details.hearing.length > 0 ? (
                        <>
                          <p className="span-1">
                            Next Hearing:{" "}
                            <span className="span-2"> January 11, 2024 </span>
                          </p>
                          <p className="span-1">
                            Purpose of Hearing:{" "}
                            <span className="span-2"> Settlement </span>
                          </p>
                        </>
                      ) : (
                        ""
                      )} */}

                    <div className="case-hearing-box m-0">
                      {details.hearing && details.hearing.length > 0 && (
                        <>
                          {(() => {
                            let todayHearingDisplayed = false; // Flag to track if today's hearing is already displayed
                            let nextHearingDetails = null; // Variable to store the details of the next hearing
                            let previousHearingDetails = null; // Variable to store the details of the previous hearing

                            // Loop through each hearing
                            for (const hearingData of details.hearing) {
                              const hearing = hearingData.hearing;
                              const startDate =
                                hearing && hearing.start_date
                                  ? new Date(hearing.start_date)
                                  : null;
                              const currentDate = new Date();

                              // Check if the hearing is today's hearing and it's not already displayed
                              if (
                                !todayHearingDisplayed &&
                                startDate &&
                                startDate.getDate() === currentDate.getDate() &&
                                startDate.getMonth() ===
                                  currentDate.getMonth() &&
                                startDate.getFullYear() ===
                                  currentDate.getFullYear()
                              ) {
                                todayHearingDisplayed = true; // Set flag to true if today's hearing is found
                                return (
                                  <React.Fragment key={hearing.id}>
                                    <p className="span-1">
                                      Purpose of Today's Hearing:{" "}
                                      <span className="span-2">
                                        {hearing.agenda}
                                      </span>
                                    </p>
                                  </React.Fragment>
                                );
                              }

                              // Check if the hearing is in the future
                              if (startDate && startDate > currentDate) {
                                // Store the details of the next hearing
                                nextHearingDetails = (
                                  <React.Fragment key={hearing.id}>
                                    <p className="span-1">
                                      Next Hearing:{" "}
                                      <span className="span-2">
                                        {this.formatDate(startDate)}
                                      </span>
                                    </p>
                                    <p className="span-1">
                                      Purpose of Hearing:{" "}
                                      <span className="span-2">
                                        {hearing.agenda}
                                      </span>
                                    </p>
                                  </React.Fragment>
                                );
                                break; // Exit the loop since we found the next hearing
                              }

                              // If no future hearing is found, store the details of the previous hearing
                              previousHearingDetails = (
                                <React.Fragment key={hearing.id}>
                                  <p className="span-1">
                                    Previous Hearing:{" "}
                                    <span className="span-2">
                                      {this.formatDate(startDate)}
                                    </span>
                                  </p>
                                  <p className="span-1">
                                    Purpose of Hearing:{" "}
                                    <span className="span-2">
                                      {hearing.agenda}
                                    </span>
                                  </p>
                                </React.Fragment>
                              );
                            }

                            // Return the details of the next hearing if available, otherwise return the details of the previous hearing
                            return nextHearingDetails || previousHearingDetails;
                          })()}
                        </>
                      )}
                      <p className="span-1">
                        Status:{" "}
                        <span className="status-container-text14">
                          {details.case_status || "--"}{" "}
                        </span>
                      </p>
                    </div>

                    {/* <p className="span-1">
                        {" "}
                        Status:{" "}
                        <span className="span-2">{details.case_status}</span>
                      </p> */}
                    {/* <p className="span-1">
                        {" "}
                        Time Pending: <span className="span-2">--</span>
                      </p> */}
                    {/* <p>
                        <span className="span-1">Court:</span>
                        <span className="span-2">30</span>|
                        <span className="span-1">Item:</span>
                        <span className="span-2">203</span>|
                        <span className="span-1">Bench:</span>
                        <span className="span-2">
                          {" "}
                          Mr. Justice Aman Chaudhary{" "}
                        </span>
                        |
                      </p>
                      <div>
                        <Button> Upload File </Button>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Casetabs id={editId} details={details} />
        </section>
      </Container>
    );
  }
}

export default withRouter(Casedetail);
