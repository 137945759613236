import React from 'react'

const ClinetSingleCase = () => {
    return (
        <div className='text-center mt-5'>
            Client Single Case coming soon
        </div>
    )
}

export default ClinetSingleCase
