// import React, { Fragment, useState } from "react";
// import {
//   withRouter,
//   Row,
//   Col,
//   toast,
//   Axios,
//   CircularProgress,
//   TEXTFIELD,
//   CKEditor,
//   SUBMIT,
//   ApiUrl,
//   checkValidations,
//   checkError,
//   Link,
//   FILEUPLOAD,
// } from "../../../../../../components";
// const { EMAIL_REGEX, Numeric } = ApiUrl;

// class AddEditForm extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       id: this.props.editId,
//       templateId: this.props.templateId,
//       // id: 33,
//       name: "",
//       phone: "",
//       email: "",
//       file: [],
//       fileData: "",
//       editid: "",
//       docid: "",
//       signerid: "",
//       documentid: "",
//       errors: {
//         name: "",
//         phone: "",
//         email: "",
//         file: {},
//       },
//       process: this.props.role,
//       loading: false,
//     };
//   }

//   async componentDidMount() {
//     console.log(this.props.templateId, "stateid");
//   }

//   handleChange = (event) => {
//     console.log(event, "asdasdasdasd");
//     event.preventDefault();
//     const { name, value, validations, email } = event.target;
//     let errors = this.state.errors;
//     let finalVal = value;

//     switch (name) {
//       case "name":
//         errors.name = value.length < 1 ? "Required" : "";
//         break;
//       case "email": {
//         let err = "";
//         if (value.length < 1) {
//           err = "Required";
//         } else {
//           if (!EMAIL_REGEX.test(value)) {
//             err = "Invalid email";
//           }
//         }
//         errors.email = err;
//         break;
//       }
//       case "phone": {
//         let err = "";
//         if (value.length < 1) {
//           err = "Required";
//         } else {
//           if (!Numeric.test(value)) {
//             err = "Only numeric allowed";
//             finalVal = "";
//           }

//           if (value.length <= 9 && value.length >= 0) {
//             err = "Minimum 10 digits required";
//           }
//         }
//         errors.phone = err;

//         break;
//       }
//       default:
//         break;
//     }

//     this.setState({ errors, [name]: finalVal }, () => {
//       let err = checkError(errors);
//       this.setState({ disabled: err.disabled });
//     });
//   };
//   onFormSubmit = (e) => {
//     e.preventDefault();
//     const { process, name, phone, email, file, fileData } = this.state;

//     this.setState({ loading: true });
//     const data = {
//       name: name,
//       email: email,
//       phone: phone,
//       document: file,
//     };

//     // check validations
//     let valid = checkValidations(data);
//     if (valid.error) {
//       toast.error(valid.error);
//       this.setState({ loading: false });
//       return false;
//     }

//     let formData = new FormData();
//     for (var key in data) {
//       formData.append(key, data[key]);
//     }
//     console.log(data, valid.data);

//     Axios.Post(
//       `https://justsettle.in/backend/api/v1/bulk/request/notices/e-signature`,
//       formData
//     )
//       .then((res) => {
//         let data = res.data;
//         console.log(data, "true123");

//         if (res.data.success == true) {
//           toast.success(res.data.message);

//           this.setState({ docid: data?.data?.docket_id });
//           this.setState({ signerid: data?.data?.signer_info[0]?.signer_id });
//           this.setState({
//             documentid: data?.data?.signer_info[0]?.document_id,
//           });

//           const options = {
//             id: "demo_user_1",
//             docket_id: data?.data?.docket_id,
//             signer_id: data?.data?.signer_info[0]?.signer_id,
//             document_id: data?.data?.signer_info[0]?.document_id,
//             getWidgetToken: function(result) {
//               console.log("checking>>>>>>>>>>>>>>");
//               fetch(
//                 `https://justsettle.in/backend/api/v1/bulk/widgetToken/${result}`
//               )
//                 .then((response) => response.json())
//                 .then((data) => console.log(data, "1122222"))
//                 .catch((error) => console.error(error, "before error"));
//             },
//             signatureStatus: function(result) {
//               let doc_id = result.document_id;
//               fetch(
//                 `https://justsettle.in/backend/api/v1/bulk/esignedPdf/${doc_id}`
//               )
//                 .then((response) => response.json())
//                 .then((data) => {
//                   console.log(data);
//                   var downloadUrl = data;
//                   console.log(downloadUrl);
//                   // Trigger the file download using JavaScript
//                   var link = document.createElement("a");
//                   link.href = downloadUrl;
//                   link.target = "_blank";
//                   link.download = "file123.pdf"; // Assuming 'data' contains the filename or identifier
//                   link.click();
//                 })
//                 .catch((error) => console.error(error));
//             },
//             errorLog: function(result) {
//               console.log(result, "errrrrrrrrrrrrrrrr");
//             },
//           };

//           esignWidgetCall(options);
//           this.setState({
//             name: "",
//             email: "",
//             phone: "",
//             fileName: "",
//             file: {},
//           });
//         } else {
//           console.log();
//           // toast.error(res.data.error);
//         }
//       })
//       .catch((error) => {
//         console.log("error");
//       });

//     this.setState({ loading: false });
//   };

//   handleFile = (event) => {
//     event.preventDefault();
//     const { errors } = this.state;
//     let file = event.target.files[0];
//     let newErr = { ...errors, file: "" };
//     console.log(file, "filefile");

//     this.setState(
//       {
//         file: file,
//         errors: newErr,
//       },
//       () => {
//         let err = checkError(newErr);
//         this.setState({ disabled: err.disabled });
//       }
//     );
//     this.setState({ fileName: file.name });
//   };

//   render() {
//     const {
//       loading,
//       name,
//       phone,
//       email,
//       fileName,
//       disabled,
//       errors,
//     } = this.state;
//     return (
//       <Fragment>
//         {loading && (
//           <div className="text-center w-100">
//             <CircularProgress />
//           </div>
//         )}

//         <form
//           noValidate
//           autoComplete="off"
//           onSubmit={this.onFormSubmit.bind(this)}
//           style={{ padding: "20px" }}
//         >
//           <Row>
//             <Col md="4">
//               <TEXTFIELD
//                 label="Name"
//                 value={name}
//                 name={"name"}
//                 onChange={this.handleChange}
//                 errors={errors}
//               />
//             </Col>
//             <Col md="4">
//               <TEXTFIELD
//                 label="Phone Number"
//                 value={phone}
//                 name={"phone"}
//                 onChange={this.handleChange}
//                 errors={errors}
//               />
//             </Col>
//             <Col md="4">
//               <TEXTFIELD
//                 label="Email"
//                 value={email}
//                 name={"email"}
//                 onChange={this.handleChange}
//                 errors={errors}
//               />
//             </Col>
//             <Col md="4" xs="12" className="mt-2">
//               <div class="file-upload-cus">
//                 <div class="file-select">
//                   <div class="file-select-button" id="fileName">
//                     Choose File
//                   </div>
//                   <div class="file-select-name" id="noFile">
//                     {fileName ? fileName : "No file chosen..."}
//                   </div>
//                   <div class="importcus">
//                     <input
//                       type="file"
//                       name={"file"}
//                       id="chooseFile"
//                       accept="application/pdf"
//                       onChange={this.handleFile}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </Col>

//             <Col md="3" className="mt-2">
//               <SUBMIT title="Upload" disabled={disabled} />
//             </Col>
//           </Row>
//         </form>
//       </Fragment>
//     );
//   }
// }

// export default withRouter(AddEditForm);
import React, { Fragment, useState } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  Link,
  FILEUPLOAD,
} from "../../../../../../components";
const { EMAIL_REGEX, Numeric } = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.editId,
      templateId: this.props.templateId,
      // id: 33,
      name: "",
      phone: "",
      email: "",
      file: [],
      fileData: "",
      editid: "",
      docid: "",
      signerid: "",
      documentid: "",
      errors: {
        name: "",
        phone: "",
        email: "",
        file: {},
      },
      process: this.props.role,
      loading: false,
    };
  }

  async componentDidMount() {
    console.log(this.props.templateId, "stateid");
  }

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value, validations, email } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = "Invalid email";
          }
        }
        errors.email = err;
        break;
      }
      case "phone": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.phone = err;

        break;
      }
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  onFormSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    const { process, name, phone, email, file, fileData } = this.state;

    const data = {
      name: name,
      email: email,
      phone: phone,
      document: file,
    };

    // check validations
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);

    Axios.Post(
      `https://justsettle.in/backend/api/v1/bulk/request/notices/e-signature`,
      formData
    )
      .then((res) => {
        let data = res.data;
        console.log(data, "true123");
        if (res.data.success == true) {
          toast.success(res.data.message);
          this.setState({ docid: data?.data?.docket_id });
          this.setState({ signerid: data?.data?.signer_info[0]?.signer_id });
          this.setState({
            documentid: data?.data?.signer_info[0]?.document_id,
          });
          this.setState({ loading: false });

          const options = {
            id: "demo_user_1",
            docket_id: data?.data?.docket_id,
            signer_id: data?.data?.signer_info[0]?.signer_id,
            document_id: data?.data?.signer_info[0]?.document_id,
            getWidgetToken: function (result) {
              console.log("checking>>>>>>>>>>>>>>");
              fetch(
                `https://justsettle.in/backend/api/v1/bulk/widgetToken/${result}`
              )
                .then((response) => response.json())
                .then((data) => console.log(data, "1122222"))
                .catch((error) => console.error(error, "before error"));
            },
            signatureStatus: function (result) {
              let doc_id = result.document_id;
              fetch(
                `https://justsettle.in/backend/api/v1/bulk/esignedPdf/${doc_id}`
              )
                .then((response) => response.json())
                .then((result) => {
                  var downloadUrl = `https://justsettle.in${result.data}`;
                  console.log(downloadUrl, result, "check data");
                  // Trigger the file download using JavaScript
                  var link = document.createElement("a");
                  link.href = downloadUrl;
                  link.target = "_blank";
                  link.download = "file123.pdf"; // Assuming 'data' contains the filename or identifier
                  link.click();
                })

                .catch((error) => console.error(error));
            },
            errorLog: function (result) {
              console.log(result, "errrrrrrrrrrrrrrrr");
            },
          };

          esignWidgetCall(options);
          this.setState({
            name: "",
            email: "",
            phone: "",
            fileName: "",
            file: {},
          });
        } else {
          console.log();
          // toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };

  render() {
    const {
      loading,
      name,
      phone,
      email,
      fileName,
      disabled,
      errors,
    } = this.state;
    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.onFormSubmit.bind(this)}
          style={{ padding: "20px" }}
        >
          <Row>
            <Col md="4">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4">
              <TEXTFIELD
                label="Phone Number"
                value={phone}
                name={"phone"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4">
              <TEXTFIELD
                label="Email"
                value={email}
                name={"email"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12" className="mt-2">
              <div class="file-upload-cus">
                <div class="file-select">
                  <div class="file-select-button" id="fileName">
                    Choose File
                  </div>
                  <div class="file-select-name" id="noFile">
                    {fileName ? fileName : "No file chosen..."}
                  </div>
                  <div class="importcus">
                    <input
                      type="file"
                      name={"file"}
                      id="chooseFile"
                      accept="application/pdf"
                      onChange={this.handleFile}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col md="3" className="mt-2">
              <SUBMIT title="Upload" disabled={disabled} />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
