import React, { Fragment, useState } from "react";

import {
  Link,
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  TEXTFIELD,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  Loading,
  moment,
  CHECKBOX,
} from "../../../../../components";
import Select from "react-select";
import { range } from "lodash";
import { LockTwoTone } from "@material-ui/icons";

const {
  API_GET_USER_DETAIL,
  API_SUPER_USER_LIST,
  API_ASSIGN_MANAGER,
  Numeric,
} = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      clientList: "",
      state: "",
      lawfirmList: [],
      lawfirm: [],
      client: [],
      lotList: [],
      caseList: [],
      case_manager: "",
      lot: [],
      lotList: [],
      totalCount: "",
      countryStates: [],
      casesData: [],
      lot_id: [],
      lotDetails: [],
      licenceExpDate: moment(Date.now())
        .weekday(7)
        .format("YYYY-MM-DD"),
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process, caseTypeE, clientE, lawfirm } = this.state;
    console.log(lawfirm, "caseTypeE,clientE");
    // this.setState({ loading: true });

    if (process == "edit" && editId) {
      Axios.get(API_GET_USER_DETAIL + editId)
        .then((response) => {
          var data = response?.data?.data;
          if (data.success === false) {
            toast.error(response.data.message);
            return;
          }

          this.setState({
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            roleList: roles,
            status: data?.status,
          });
        })
        .catch((err) => {
          console.log(err, 66565);
        });
    }

    Axios.Post(API_SUPER_USER_LIST + "/casemanager")
      .then((res) => {
        console.log(res);
        const { status, data } = res?.data;

        const lots = data?.users?.map((c) => {
          return { value: c.id, label: c.name };
        });
        if (res?.data.success == true) {
          this.setState({
            lotList: lots || [],
          });
        } else {
          this.setState({
            lotList: [],
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching lot data:", error);
        this.setState({
          lotList: [],
        });
      });
    Axios.Post(API_SUPER_USER_LIST + "/lawfirm-manager")
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response?.data?.total,
          loading: false,
        });
        const caseData = response?.data?.users?.map((c) => {
          return { [c.id]: c.name };
        });
        this.setState({ caseList: caseData || [] });
      })
      .catch((err) => {
        console.log("");
        this.setState({ loading: false, caseList: [] });
      });

    this.setState({ loading: false });
  }

  handleChangeLot = (selectedOptions) => {
    // Check if 'All' is selected
    const allOption = { value: "ALL", label: "All" };
  
    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options except 'All'
      const allSelected = selectedOptions.length === 1;
  
      // Filter out the 'All' option if it's the only selected option
      const filteredOptions = allSelected
        ? this.state.lotList
        : selectedOptions.filter((option) => option.value !== "ALL");
  
      this.setState({ lot: [...filteredOptions] });
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ lot: selectedOptions });
    }
  };
   

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "role":
        errors.role = value.length < 1 ? "Required" : "";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  redirectPage = () => {
    this.reset();
  };


  handleSubmit1 = (e) => {
    e.preventDefault();
    const { case_manager, lot } = this.state;
    
    const ids = lot.map((item) => item.value);
    this.setState({ lot_id: ids });
    console.log( ids, "valuesArray");
    this.setState({ loading: true });
    setTimeout(() => {
      const data1 = {
        case_managers: ids,
        manager_id: case_manager,
      };
      Axios.Post(API_ASSIGN_MANAGER, data1)
        .then((res) => {
          console.log(res, "reskjkj");
          let response = res.data;
          this.setState({ loading: false, disabled: false });

          if (response.status === 200) {
            toast.success(response.message);
            setTimeout(() => {
              this.redirectPage();
            }, 1000);
          } else {
            toast.success(response.message);
            this.redirectPage();
          }
        })
        .catch((error) => {
          let res = error.response;
          console.log(error, res.message, "res.message");
          toast.error(res.message);
          this.setState({ loading: false, disabled: false });
        });
    }, 1000);
  };

  reset = () => {
    this.setState({
      lawfirm: "",
      state: "",
      city: "",
      status: [],
      assign_case: [],
      case_manager: "",
      client: [],
      lot: [],
      lotDetails: [],
      errors: {
        lawfirm: "",
        state: "",
        city: "",
        status: [],
        assign_case: [],
        case_manager: "",
        client: [],
        lot: [],
        lotDetails: [],
      },
      disabled: true,
    });
  };

  render() {
    const {
      errors,
      disabled,
      loading,
      clientList,
      state,
      lawfirmList,
      lawfirm,
      client,
      lot,
      lotList,
      caseList,
      cases,
      countryStates,
      assign_case,
      casesData,
      case_manager,
      lot_id,
      lotDetails,
    } = this.state;
    
    const lotListWithAll = [
      this.state.lotList == ""
        ? { value: "None", label: "None" }
        : { value: "ALL", label: "All" },
      ...this.state.lotList,
    ];
    

    console.log(lot, "lawfirmList");
    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        {/* <p className="p-2">
          <Link to="/app/users/list" className="back-btn-arw1">
            <i class="zmdi zmdi-long-arrow-return"></i>
          </Link>
        </p> */}
        <div className="min-h4">
          <form
            onSubmit={this.handleSubmit1}
            noValidate
            className="form-margin"
          >
            <Row>
              <Col md="4" xs="12">
                <span>Case Manager</span>
                <Select
                  isMulti
                  value={lot}
                  name={"lot"}
                  options={lotListWithAll}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChangeLot}
                />
              </Col>

              <Col md="4" xs="12" className="my-2">
                <SELECT
                  label="Manager"
                  value={case_manager}
                  name={"case_manager"}
                  onChange={this.handleChange}
                  data={caseList}
                />
              </Col>
              <Col md="3" xs="12" className="my-2">
                <SUBMIT title="Submit" disabled={false} />
              </Col>
            </Row>

            {/* <Row className="my-3">
              <Col md="2" xs="12">
                <SUBMIT
                  title="Submit"
                  disabled={false}
                />
              </Col>
            </Row> */}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
