import React, { useState, useEffect } from "react";

import { Button, Modal, ModalBody } from "reactstrap";

import {
    Link,
    ApiUrl,
    Axios,
    moment,
    Loading,
    Row,
    Col,
    SELECT,
    TEXTFIELD,
    toast,
    SUBMIT,
} from "../../../../../../../components";
import "../../../../../Client/cases/routes/Components/revenueTable.css";

import { onlyNumber } from "../../../../../../../util/onlyNumber";

const { API_EDIT_SINGLE_NOTES, API_GET_NOTES_DETAILS } = ApiUrl;

const EditNotes = (props) => {
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addNotes, setAddNotes] = useState("");
    const [details, setDetails] = useState("");
    const [fileNames, setFileNames] = useState("");

    const [topic, setTopic] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [designation, setDesignation] = useState("");
    const [noteAddedBy, setNoteAddedBy] = useState("");
    const [uploadFiles, setUploadFiles] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    const [employee, setEmployee] = useState();

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!topic.trim()) {
            errors.topic = "Topic is required";
            isValid = false;
        }

        if (!mobileNumber.trim()) {
            errors.mobileNumber = "Mobile Number is required";
            isValid = false;
        }

        if (!designation.trim()) {
            errors.designation = "Designation is required";
            isValid = false;
        }

        if (!addNotes.trim()) {
            errors.addNotes = "Add Notes is required";
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    useEffect(() => {
        getTableRecords();
    }, []);

    const getTableRecords = async () => {
        Axios.get(API_GET_NOTES_DETAILS + props.noteIds)
            .then((res) => {
                const list = res?.data;
                console.log(list.data.attachment?.map((attachment) => attachment?.fileName), "FFFFF")
                setFileNames(list.data.attachment?.map((attachment) => attachment?.fileName) || []);
                setDetails(list);
                setTopic(list?.data?.description?.topic);
                setAddNotes(list?.data?.description?.addNotes);
                setMobileNumber(list?.data?.added_by?.mobileNumber);
                setDesignation(list?.data?.added_by?.designation);
                setEmployee(list?.data?.added_by?.employee_id);
                setNoteAddedBy(list?.data?.added_by?.noteAddedBy);
            })
            .catch((err) => {
                console.log("");
            });
    };

    const toggleModal = async () => {
        try {
            if (!validateForm()) {
                return;
            }
            const formData = new FormData();
            formData.append("addNotes", addNotes);
            formData.append("topic", topic);
            formData.append("mobileNumber", mobileNumber);
            formData.append("designation", designation);
            formData.append("noteAddedBy", noteAddedBy);
            formData.append("caseId", props.editTd);
            formData.append("noteId", props.noteIds);
            // formData.append("employee", employee);

            uploadFiles.forEach((file) => {
                formData.append("files[]", file);
            });

            // Submit the form
            const url = API_EDIT_SINGLE_NOTES;
            const res = await Axios.Post(url, formData);
            console.log(res, "res,mn,n,mn,m");
            if (res.data.success === true) {
                toast.success(res?.data?.message);
                props.setopenEditNotes(false);
                setTopic("");
                setMobileNumber("");
                setDesignation("");
                setNoteAddedBy("");
                setAddNotes("");
                setUploadFiles([]);
                setFormErrors({});
                props.notesList()
                // setEmployee("");

            } else {
                toast.success("Does not updated");
            }
        } catch (error) {
            console.error("Error sending addNotes data:", error);
        }
    };

    // const handleFileChange = (e) => {
    //     const files = e.target.files;
    //     const filesArray = Array.from(files);
    //     setUploadFiles(filesArray);
    // };
    const handleFileChange = (event) => {
        const files = event.target.files;
        const filesArray = Array.from(files);
        const newFileNames = Array.from(files).map(file => file.name);
        setFileNames(newFileNames);
        setUploadFiles(filesArray);
    };
    return (
        <section className="Sales_analytics">
            <div fluid>
                <div className="revenueTable-conatiner">
                    <div className="modal-header">
                        <h1 className="modal-title mt-3 mb-3 mx-2">
                            <b>Edit Notes</b>
                        </h1>
                        <button
                            type="button"
                            className="close"
                            onClick={() => props.setopenEditNotes(false)}
                        >
                            <span>×</span>
                        </button>
                    </div>
                    <div className="" style={{ padding: "20px 20px" }}>
                        <Row>
                            <Col md="12">
                                <TEXTFIELD
                                    label="Subject"
                                    value={topic}
                                    onKeyUp={validateForm}
                                    onChange={(e) => setTopic(e.target.value)}
                                />
                                {formErrors.topic && (
                                    <div className="error-message ">{formErrors.topic}</div>
                                )}
                            </Col>

                            <Col md="12">
                                <TEXTFIELD
                                    label="Add Notes"
                                    value={addNotes}
                                    onKeyUp={validateForm}
                                    onChange={(e) => setAddNotes(e.target.value)}
                                />
                                {formErrors.addNotes && (
                                    <div className="error-message">{formErrors.addNotes}</div>
                                )}
                            </Col>

                            {/* <Col md="12">
                    <TEXTFIELD
                      label="Note Added By"
                      value={noteAddedBy}
                      onKeyUp={validateForm}
                      onChange={(e) => setNoteAddedBy(e.target.value)}
                    />
                    {formErrors.noteAddedBy && <div className="error-message">{formErrors.noteAddedBy}</div>}
                  </Col> */}

                            <Col md="12">
                                <TEXTFIELD
                                    label="Designation"
                                    value={designation}
                                    onKeyUp={validateForm}
                                    onChange={(e) => setDesignation(e.target.value)}
                                />
                                {formErrors.designation && (
                                    <div className="error-message">{formErrors.designation}</div>
                                )}
                            </Col>

                            {/* <Col md="12">
                <TEXTFIELD
                  label="Employee Id:"
                  value={employee}
                  onKeyUp={validateForm}
                  onChange={(e) => setEmployee(e.target.value)}
                />
                {formErrors.employee && (
                  <div className="error-message">{formErrors.employee}</div>
                )}
              </Col> */}

                            <Col md="12">
                                <TEXTFIELD
                                    label="Mobile Number:"
                                    value={mobileNumber}
                                    onKeyUp={validateForm}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    onKeyPress={onlyNumber}
                                />
                                {formErrors.mobileNumber && (
                                    <div className="error-message">{formErrors.mobileNumber}</div>
                                )}
                            </Col>
                        </Row>
                        <input
                            className="my-3"
                            type="file"
                            onChange={handleFileChange}
                            multiple
                        />
                        {fileNames.length > 0 && (
                            <div>
                                {fileNames.map((fileName, index) => (
                                    <div key={index}>{fileName}</div>
                                ))}
                            </div>
                        )}

                        <div className="modal-submit-btn text-center">
                            <button
                                type="button"
                                className="btn jr-btn CBmargin CBmarginT M add-notes w-50"
                                onClick={toggleModal}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditNotes;
