import React, { useState, useEffect } from "react";
import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  toast,
  SUBMIT,
} from "../../../../../components";
import Checkbox from "../Components/checkbox";
import { Button, Modal, ModalBody } from "reactstrap";
const {
  RESPONDANT_CASES,
  // FILTER_CASE,
  // SELECTED_CASE_IDS,
} = ApiUrl;

// function formatTimestamp(timestamp) {
//   const date = new Date(timestamp);
//   const options = {
//     month: 'long',
//     day: 'numeric',
//     year: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     hour12: true
//   };
//   return date.toLocaleString('en-US', options);
// }

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [month, day, year] = formattedDate.split("/");
  return `${day}.${month}.${year}`;
};

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [showVenue, setVenue] = useState(false);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState();

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const hashId = localStorage.getItem("hash_id");
  const userId = localStorage.getItem("_id");
  const getTableRecords = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(RESPONDANT_CASES + "/mediation");
      console.log("API Response:", response.data);
      const responseData = response.data;
      if (responseData.success) {
        const { bulk_cases } = responseData.data;
        const combinedList = [...bulk_cases];

        setList(combinedList);
      } else {
        console.error("API request failed:", responseData.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // const getTableRecords = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await Axios.get(RESPONDANT_CASES);
  //     console.log("API Response:", response.data);
  //     const responseData = response.data;
  //     if (responseData.success) {
  //       const { single_cases, bulk_cases } = responseData.data;
  //       const combinedList = [...single_cases, ...bulk_cases];

  //       setList(combinedList);
  //     } else {
  //       console.error("API request failed:", responseData.message);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    getTableRecords();
  }, []);

  const refresh = () => {
    getTableRecords();
    setCount();
    setResponseData([]);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const toggleRev = (index) => {
    setShow((prev) => (prev === index ? null : index));
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const cataloge = list?.slice(0, limit)?.map((item, index) => {
    const partyRespondentVs = item?.case_master_users?.find(
      (i) =>
        i.related_user_role === "Client" && i.related_user_type === "claimant"
    );
    const respondentNameVs = partyRespondentVs
      ? partyRespondentVs.related_user.name
      : null;

    const respondentNames = item?.case_master_users?.reduce((acc, i) => {
      if (
        i.related_user_role === "Party" &&
        i.related_user_type === "respondent"
      ) {
        acc.push(i.related_user.name);
      }
      return acc;
    }, []);

    return (
      <div className="single-revenue-conatiner mt-5" key={item.id}>
        <div className="revenue-table-heading">
          <p className="checkbox-revenue">
            {" "}
            {/* <span>
              <Checkbox
                key={item.id}
                type="checkbox"
                name={item.label}
                id={item.id}
                handleClick={(e) => handleClick(e, item.id)}
                isChecked={isCheck.includes(item.id)}
              />
            </span> */}
            <Link
              to={`/app/party/case-detail/${item.hash_id}`}
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Case No."
              className="d-flex"
            >
              <div className="ml-2 text-capitalize">
                <span>{respondentNameVs} and </span>
                <span>
                  {respondentNames?.length === 1
                    ? respondentNames
                    : respondentNames?.length === 2
                    ? respondentNames[0] + " & another"
                    : respondentNames?.length === 3 ||
                      respondentNames?.length > 3
                    ? respondentNames[0] + " & others"
                    : ""}
                </span>
              </div>
            </Link>
          </p>
          <p className="checkbox-revenue-subtext">
            <span> {item.date} </span>
            <span className="revenue-status">{item.case_status}</span>
          </p>
        </div>
        <Link to={`/app/party/case-detail/${item.hash_id}`}>
          <div className="filesrevenue-conatiner">
            <div className="filesrevenue-subconatiner">
              <div className="pov123 mt-3">
                <p>
                  <span className="pov123Text">Case No.</span>
                  {item.case_number}
                </p>
                <p>
                  <span className="pov123Text">Filed On:</span>
                  {moment(item.created_at).format("DD.MM.YYYY")}
                </p>
              </div>
            </div>

            <div className="filesrevenue-subconatinerpov">
              {item.hearing &&
                item.hearing.length > 0 &&
                item.hearing.map((hearing) => {
                  const startDate = new Date(hearing.hearing.start_date);
                  const currentDate = new Date();

                  if (startDate >= currentDate) {
                    return (
                      <div
                        className="filesrevenue-povBoxes"
                        key={hearing.hearing_id}
                      >
                        <p>{formatDate(startDate)}</p>
                      </div>
                    );
                  } else if (startDate < currentDate) {
                    return (
                      <div
                        className="filesrevenue-povBoxes"
                        key={hearing.hearing_id}
                      >
                        <p>Previous</p>
                        <p>{formatDate(startDate)}</p>
                      </div>
                    );
                  }
                })}

              {!item.hearing ||
                (item.hearing.every(
                  (hearing) =>
                    new Date(hearing.hearing.start_date) >= new Date()
                ) && (
                  <div className="filesrevenue-povBoxes">
                    <p>Not assigned</p>
                  </div>
                ))}
            </div>
          </div>
        </Link>

        {showVenue === index ? (
          <div onClick={() => toggleRev1(index)}>
            <h2 class="hr-lines">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h2>
          </div>
        ) : (
          <div onClick={() => toggleRev1(index)} className="text-center">
            <span className="arrow-conainer">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                  stroke="#42526E"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        )}
        {showVenue === index &&
          item.metas.map((m) => {
            return (
              <>
                <div className="revenue-state">
                  <div className="revenue-state-box">
                    <p className="text-court text-capitalize">{m.meta_key}: </p>
                    <p className="text-state text-capitalize">
                      {" "}
                      {m.meta_value}{" "}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    );
  });

  return (
    <section className="Sales_analytics">
      <div fluid>
        <div className="revenueTable-conatiner">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              {/* <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </span> */}
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
              {/* <span className="check-po-icon" check-po-icon>
                <i class="zmdi zmdi-delete" style={{ fontSize: "20px" }}></i>
              </span> */}
            </div>
            <div className="pagination-conatinerr">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  {list && list.length > 10 && (
                    <>
                      <option selected>20</option>
                      <option value="50">50</option>
                      <option value="80">80</option>
                      <option value="100">100</option>
                    </>
                  )}
                  <option value={list && list.length}>
                    {count == 0 ? count : list && list.length}{" "}
                  </option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from {count == 0 ? count : list && list.length}{" "}
                  results{" "}
                </span>
              </div>
            </div>
          </div>
          {loading === false && list.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}

          <div className="revenue-scroll-container">{cataloge}</div>
          {/*  {filteredCatalogue && filteredCatalogue.length > 0 ? (
            <div className="revenue-scroll-container">{filteredCatalogue}</div>
          ) : count == 0 ? (
            <p className="n--data text-center mt-5">No data found</p>
          ) : (
           
          )} */}
        </div>
      </div>
    </section>
  );
};

export default Revenuetable;
