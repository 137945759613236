import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { v4 as uuidv4 } from "uuid";
import { Link } from "../../../../../../components";

const MyCalendar = (props) => {
  const [events, setEvents] = useState([]);
  const list = props.List || [];

  useEffect(() => {
    // Function to extract follow_ups dates and format them for FullCalendar
    const formattedEvents =
      list.length > 0 &&
      list.flatMap((caseItem) => {
        const hashId = caseItem.hash_id;

        return caseItem.follow_ups.map((follow_upsItem) => {
          const startDateTime = new Date(follow_upsItem.followup_datetime);

          return {
            title: follow_upsItem.followup_remarks,
            start: startDateTime,
            end: startDateTime,
            id: uuidv4(),
            hash_id: hashId,
          };
        });
      });

    setEvents(formattedEvents);
  }, [props.List]);

  const EventItem = ({ info }) => {
    const { event } = info;

    return (
      <div>
        <Link
          to={`/app/casemanager/case-detail/${event.extendedProps.hash_id}`}
        >
          <p className="event-title">{event.title}</p>
          <p className="event-details">
            Date: {event.start.toLocaleDateString()}{" "}
            {/* Example date formatting */}
          </p>
        </Link>
      </div>
    );
  };

  const handleSelect = (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Enter event name");
    if (eventNamePrompt) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: uuidv4(),
        },
      ]);
    }
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        editable
        selectable
        headerToolbar={{
          start: "today",
          center: "title",
          end: "prev,dayGridMonth,dayGridWeek,dayGridDay,next",
        }}
        titleFormat={{ year: "numeric", month: "long" }} // Custom title format to include the year
        allDayText="All Day"
        timeZone="UTC"
        events={events}
        eventContent={(info) => <EventItem info={info} />}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        dayMaxEvents={1}
      />
    </div>
  );
};

export default MyCalendar;
