import React from 'react'

const PartyConciliation = () => {
    return (
        <div className='text-center mt-5'>
            Coming Soon Party Conciliation
        </div>
    )
}

export default PartyConciliation
