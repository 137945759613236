import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";

import {
  Link,
  ApiUrl,
  Axios,
  DialogBox,
  Row,
  Col,
  SELECT,
  toast,
  Loading,
  moment,
} from "../../../../../../components";
import Tooltip from "@material-ui/core/Tooltip";
const {
  API_GET_CASE_MANAGER_ROLE_LIST,
  API_ASSIGN_MANAGER_CASES,
  API_DELETE_CASES,
  API_RE_ASSIGN_MANAGER_CASES,
} = ApiUrl;

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    temp: "",
    disabled: false,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [showVenue, setVenue] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState();
  const [list, setList] = useState([]);
  const [temp, setTemp] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  console.log(props.managerId, "before managerId");

  const handleClick1 = (e, id) => {
    setSelectedOption(id);
  };

  console.log(isCheck, "isCheckAll");
  const handleCancel = () => {
    setShow(false);
  };

  const handlePreview = (id) => {
    setShow(true);
  };

  useEffect(() => {
    getTableRecords();
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    // setId(id)
  };
  const handleClose = () => {
    setOpen(false);
  };
  //   const handleClick = (e) => {
  //     const { id, checked } = e.target;
  //     setIsCheck([...isCheck, id]);
  //     if (!checked) {
  //       setIsCheck(isCheck.filter((item) => item !== id));
  //     }
  //   };

  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const getTableRecords = async () => {
    setLoading(true);
    Axios.Post(API_GET_CASE_MANAGER_ROLE_LIST)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setList(resData?.data?.users);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  const refresh = () => {
    getTableRecords();
  };

  const assignManager = async () => {
    let data = {
      case_ids: props.casids,
      case_manager_id: selectedOption,
      // user_role: "Arbitrator",
    };
    let url = API_ASSIGN_MANAGER_CASES;

    Axios.Post(url, data)
      .then((res) => {
        let response = res.data;

        if (response.status == 200) {
          toast.success(response.message);
          props.handleCancelA();
          getTableRecords();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
      });
  };
  const reassignManager = async () => {
    console.log(props.managerId, "managerId");
    let data = {
      case_ids: props.casids,
      old_manager_id: props.managerId,
      new_manager_id: selectedOption,
      type: "case_manager",
    };
    let url = API_RE_ASSIGN_MANAGER_CASES;

    Axios.Post(url, data)
      .then((res) => {
        let response = res.data;

        if (response.status == 200) {
          toast.success(response.message);
          props.handleCancelA();
          getTableRecords();
          props.getTableRecordsAssigned();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
      });
  };

  const deleteAction = (id) => {
    Axios.post(API_DELETE_CASES + id)
      .then((res) => {
        setOpen(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        setOpen(false);
        toast.success("Cases does not deleted!");
      });
  };

  const ans = (event) => {
    console.log(event, "event212132");
    const { name } = event.target.value;
    setState({});
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };
  const cataloge =
    list &&
    list?.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      console.log(item.id, "item123");
      return (
        <div className="single-revenue-conatiner" key={item.id}>
          <div className="revenue-table-headinge">
            <p className="checkbox-revenue mb-0">
              {" "}
              <span key={item.id}>
                <input
                  type="radio"
                  name="radioGroup"
                  id={item.id}
                  checked={selectedOption === item.id}
                  onChange={(e) => handleClick1(e, item.id)}
                />
                <label htmlFor={item.id}>{item.label}</label>
              </span>
              <p className="ml-3 mb-0">{item.name}</p>
            </p>
          </div>
        </div>
      );
    });
  console.log(state.disabled, "state.disabled");
  return (
    <section className="Sales_analytics">
      <DialogBox
        title={"Cases"}
        open={open}
        onClose={handleClose}
        deleteAction={deleteAction}
      />
      <div fluid>
        <Row>
          <Col md="12" xs="12">
            {/* {isCheck.length > 0 && state.temp && ( */}
            <Tooltip title="Need to check radio button" arrow>
              <Button
                className={
                  state.disabled == true
                    ? "float-right mr-5"
                    : "float-right mr-5 generate-disabled"
                }
                onClick={
                  props.reassignText == false ? assignManager : reassignManager
                }
              >
                {props.reassignText == false
                  ? "Assign Case Manager"
                  : "Re-Assign Manager"}
              </Button>
            </Tooltip>
            {/* )} */}
          </Col>
        </Row>
        <div className="revenueTable-conatiner mt-4">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              {/* <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </span> */}
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
              {/* <span className="check-po-icon" onClick={handleClickOpen}>
                <i class="zmdi zmdi-delete"></i>
              </span>
              <span className="check-po-icon" onClick={handlePreview}>
                <i class="zmdi zmdi-filter-b-and-w"></i>
              </span> */}
            </div>
            <div className="pagination-conatinerw">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option selected>20</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                  <option value={list && list.length}>
                    {list && list.length}
                  </option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from {list && list.length} results{" "}
                </span>
              </div>
              {/* <nav>
                <ul className="pagination position-po pagination-text">
                  <span className="pto-5">|</span>
                  <li className="page-item pto-5">
                    <span> Prev</span>
                  </li>

                  <li key="" className="">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li className="page-item pto-5">
                    <span>Next</span>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
          {loading === false && list && list.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          <div className="revenue-scroll-containerq">{cataloge}</div>
        </div>
      </div>
      <Modal isOpen={show} toggle={handleCancel}>
        <p className="text-right m-0">
          <span onClick={handleCancel} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading">Case Limitation</p>
          <form>
            <Row>
              <Col md="4" xs="12">
                <SELECT
                  label="Chamber of arbitration"
                  name={"role"}
                  // value={role}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Source Law Firm"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </section>
  );
};

export default Revenuetable;
