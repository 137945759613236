import React, { Fragment } from "react";
import { Button } from "reactstrap";
import {
  withRouter,
  toast,
  Axios,
  BUTTON,
  CircularProgress,
  CardBody,
  Status,
  CardText,
  Row,
  Container,
  moment,
  Col,
  Card,
  CardImg,
  ApiUrl,
  Link,
} from "../../../../../../../components";

import Casetabs from "./tabs";
const { API_GET_SINGLE_CASE, JOIN_MEETING } = ApiUrl;

class Casedetail extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    this.state = {
      editId: this.props.edit,
      details: "",
      notfound: false,
    };
  }
  formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}.${month}.${year}`;
  };
  async componentWillMount() {
    this.setState({ loading: true });
    Axios.get(API_GET_SINGLE_CASE + this.state.editId)
      .then((response) => {
        response = response.data;
        this.setState({ details: response.data });
      })
      .catch((error) => {
        console.log("error");
        // toast.error("Customer not found.Invalid ID.");
      });
    this.setState({ loading: false });
  }
  joinMeeting = async (meetingId) => {
    try {
      const response = await Axios.get(`${JOIN_MEETING}/${meetingId}`);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        window.open(response.data.data, "_blank");
      } else {
        toast.error("Failed to join meeting");
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    let image = ["", null, undefined, "none"];

    const { loading, details, editId, notfound } = this.state;
    // const arbitrators =
    //   details &&
    //   details?.case_master_users?.length > 0 &&
    //   details?.case_master_users?.find(
    //     (user) =>
    //       user.related_user_role === "Arbitrator" &&
    //       user.related_user_type === "Arbitrator"
    //   );

    const arbit =
      details &&
      details?.case_master_users?.reduce((acc, i) => {
        if (
          i.related_user_role == "Mediator" &&
          i.related_user_type == "Mediator"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);

    console.log(arbit, "arbitrators");

    const client =
      details &&
      details?.case_master_users?.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
    const clientVs = client ? client.related_user.name : null;

    const respondent =
      details &&
      details?.case_master_users?.reduce((acc, i) => {
        console.log(acc, "acciui");
        if (
          i.related_user_role == "Party" &&
          i.related_user_type == "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);

    console.log(respondent, "loipoio");
    return (
      <Container style={{ paddingBottom: "10px" }}>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <Row>
          <section className="Sales_analytics">
            <div fluid>
              <div className="float-end">
                <div className="status-container">
                  <p class="add-cd-text">
                    <button
                      onClick={() => this.props.history.goBack()}
                      className="back-btn-arw1"
                    >
                      <i
                        class="zmdi zmdi-arrow-left"
                        style={{ fontSize: "19px", marginTop: "2px" }}
                      ></i>
                    </button>
                    {/* <Link
                      to={`/app/client/case-list/${editId}`}
                      style={{
                        color: "grey",
                        fontSize: "19px",
                        float: "left",
                      }}
                    >
                      <i
                        class="zmdi zmdi-arrow-left"
                        style={{ fontSize: "19px", marginTop: "2px" }}
                      ></i>
                    </Link> */}
                    Case Details
                    {/* <span className="status-container-text">
                      {details?.case_status}
                    </span> */}
                  </p>
                </div>
              </div>
              <hr />
              <div className="">
                <h5 className="case-heading"> </h5>
                <div className="case-deatils-container">
                  <div className="po-box">
                    <p>
                      Case Number :
                      <span className="dis-con ml-2">
                        {" "}
                        {details.case_number || "--"}{" "}
                      </span>{" "}
                    </p>
                    <p>
                      Case Title:{" "}
                      <span className="dis-con ml-2">
                        <span>{clientVs && clientVs + `${" "}`} </span>
                        <span>
                          {respondent?.length === 1
                            ? `${"and" + `${" "}` + respondent}`
                            : respondent?.length === 2
                              ? respondent[0] + " & another"
                              : respondent?.length === 3 || respondent?.length > 3
                                ? respondent[0] + " & others"
                                : ""}
                        </span>
                      </span>
                    </p>

                    <p>
                      Tribunal/Mediator:
                      <span className="dis-con ml-2">
                        <span className="dis-con ml-2">
                          {arbit && arbit.length > 0
                            ? arbit
                            : arbit && arbit.length > 1
                              ? arbit + ","
                              : "No Mediator"}
                        </span>{" "}
                        {/* {arbitrators.length > 0
                          ? arbitrators.map((i, index) => {
                              return (
                                <span className="dis-con ml-2" key={index}>
                                  {i.related_user_role === "Arbitrator" &&
                                  i.related_user_type === "Arbitrator"
                                    ? i.related_user?.name
                                    : ""}
                                  <span>
                                   
                                    {index <
                                      details.case_master_users.length - 1 &&
                                    i.related_user_role === "Arbitrator" &&
                                    i.related_user_type === "Arbitrator"
                                      ? ","
                                      : ""}
                                  </span>
                                </span>
                              );
                            }) 
                            
                          : "No Arbitrator"}
                            */}
                      </span>{" "}
                    </p>
                    <p>
                      Chamber of Mediation:
                      <span className="dis-con ml-2">
                        Indian Chambers of Arbitration & Mediation
                      </span>{" "}
                    </p>
                    {/* <p>
                      Counsel: <span className="dis-con ml-2"> -- </span>
                    </p> */}
                    <p>
                      Client:
                      {details &&
                        details.case_master_users.map((i) => {
                          return (
                            <span className="dis-con ml-2">
                              <span>
                                {i.related_user_role == "Client" &&
                                  i.related_user_type == "claimant"
                                  ? i.related_user?.name
                                  : ""}
                              </span>
                            </span>
                          );
                        })}
                    </p>
                    {details?.managers?.manager?.name &&
                      <p>
                        Manager
                        <span className="dis-con ml-2">
                          {details?.managers?.manager?.name}
                        </span>{" "}
                      </p>
                    }
                    {details?.casemanager?.manager?.name &&
                      <p>
                        Case Manager:
                        <span className="dis-con ml-2">
                          Indian Chambers of Arbitration & Mediation
                        </span>{" "}
                      </p>
                    }

                    <p>
                      LAN No. :{" "}
                      <span className="dis-con ml-2 text-capitalize">
                        {
                          details?.metas?.find((meta) => meta.meta_key === "LAN")
                            ?.meta_value
                        }
                      </span>
                    </p>

                    {/* {details.metas &&
                      details.metas.map((m) => {
                        return (
                          <>
                            <p>
                              {m.meta_key}:{" "}
                              <span className="dis-con ml-2">
                                {" "}
                                {m.meta_value}
                              </span>{" "}
                            </p>
                          </>
                        );
                      })} */}
                  </div>
                  <div>
                    <div className="case-hearing-box">
                      {/* {details.hearing && details.hearing.length > 0 ? (
                        <>
                          <p className="span-1">
                            Next Hearing:{" "}
                            <span className="span-2"> January 11, 2024 </span>
                          </p>
                          <p className="span-1">
                            Purpose of Hearing:{" "}
                            <span className="span-2"> Settlement </span>
                          </p>
                        </>
                      ) : (
                        ""
                      )} */}

                      <div className="case-hearing-box m-0">
                        {details.hearing && details.hearing.length > 0 && (
                          <>
                            {(() => {
                              let conditionMet = false; // Declare the flag outside the map function scope
                              return details.hearing.map((hearingData) => {
                                const hearing = hearingData.hearing;
                                const startDate =
                                  hearing && hearing.start_date
                                    ? new Date(hearing.start_date)
                                    : null;
                                const currentDate = new Date();

                                if (!conditionMet && startDate > currentDate) {
                                  conditionMet = true; // Set the flag to true if the condition is met
                                  return (
                                    <React.Fragment key={hearing.id}>
                                      <p className="span-1">
                                        Next Hearing:{" "}
                                        <span className="span-2">
                                          {this.formatDate(startDate)}
                                        </span>
                                      </p>
                                      <p className="span-1">
                                        Purpose of Meeting:{" "}
                                        <span className="span-2">
                                          {hearing.agenda}
                                        </span>
                                      </p>
                                    </React.Fragment>
                                  );
                                } else if (startDate && !conditionMet) {
                                  conditionMet = true;
                                  return (
                                    <React.Fragment key={hearing.id}>
                                      <p className="span-1">
                                        Previous Hearings:{" "}
                                        <span className="span-2">
                                          {this.formatDate(startDate)}
                                        </span>
                                      </p>
                                      <p className="span-1">
                                        Purpose of Meeting:{" "}
                                        <span className="span-2">
                                          {hearing.agenda}
                                        </span>
                                      </p>
                                    </React.Fragment>
                                  );
                                } else {
                                  return null;
                                }
                              });
                            })()}
                          </>
                        )}

                        <p className="span-1">
                          Status:{" "}
                          <span className="status-container-text14">
                            {details.case_status || "--"}{" "}
                          </span>
                        </p>

                        <div className="">
                          {details?.hearing?.map((hearingData) => {
                            const hearing = hearingData.hearing;
                            const startDate =
                              hearing && hearing.start_date
                                ? new Date(hearing.start_date)
                                : null;

                            const currentDate = new Date();
                            const nextDate = details.hearing.find(
                              (nextHearingData) => {
                                const nextHearing = nextHearingData.hearing;
                                const nextStartDate =
                                  nextHearing && nextHearing.start_date
                                    ? new Date(nextHearing.start_date)
                                    : null;
                                return nextStartDate > currentDate;
                              }
                            );

                            if (startDate) {
                              if (
                                startDate.setHours(0, 0, 0, 0) ==
                                currentDate.setHours(0, 0, 0, 0)
                              ) {
                                return (
                                  <a
                                    href={hearing.zoom_join_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p
                                      className="join-link7"
                                      onClick={() =>
                                        this.joinMeeting(hearing.id)
                                      }
                                    >
                                      <b>Click here to join meeting</b>
                                    </p>
                                  </a>
                                );
                              } else if (
                                startDate.setHours(0, 0, 0, 0) >
                                currentDate.setHours(0, 0, 0, 0)
                              ) {
                                return (
                                  <p className="vc-link">
                                    VC link will appear on the date of the
                                    meeting
                                  </p>
                                );
                              }
                            }
                            return null;
                          })}
                        </div>
                      </div>

                      {/* <p className="span-1">
                        {" "}
                        Status:{" "}
                        <span className="span-2">{details.case_status}</span>
                      </p> */}
                      {/* <p className="span-1">
                        {" "}
                        Time Pending: <span className="span-2">--</span>
                      </p> */}
                      {/* <p>
                        <span className="span-1">Court:</span>
                        <span className="span-2">30</span>|
                        <span className="span-1">Item:</span>
                        <span className="span-2">203</span>|
                        <span className="span-1">Bench:</span>
                        <span className="span-2">
                          {" "}
                          Mr. Justice Aman Chaudhary{" "}
                        </span>
                        |
                      </p>
                      <div>
                        <Button> Upload File </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Casetabs id={editId} details={details} />
          </section>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Casedetail);
