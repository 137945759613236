

import React from "react";

import Select from "react-select";
import { defaultTheme } from "react-select";
import { Button } from "reactstrap";

import {
    Link,
    ApiUrl,
    Axios,
    toast,
    Col,
    Row,
} from "../../../../../../../components";

const { API_SEARCH_CASE_LIST, API_SEARCH_GENERATE_DOCUMENT } = ApiUrl;

const options1 = [
    { value: "1", label: "Item 1" },
    { value: "2", label: "Item 2" },
    { value: "3", label: "Item 3" },
    { value: "4", label: "Item 4" },
    { value: "5", label: "Item 5" },
    { value: "6", label: "Item 6" },
    { value: "7", label: "Item 7" },
];

const { colors } = defaultTheme;

const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

class SelectSearch extends React.Component {
    state = {
        isOpen: false,
        value: undefined,
        options: [],
        checkedItems: {
            eservice: false,
            certified_copies: false,
        },
    };
    toggleOpen = () => {
        this.setState((state) => ({ isOpen: !state.isOpen }));
    };
    onSelectChange = (value) => {
        this.toggleOpen();
        this.setState({ value });
    };
    handleChecked = (event) => {
        const { name, checked } = event.target;
        this.setState((prevState) => ({
            checkedItems: {
                ...prevState.checkedItems,
                [name]: checked,
            },
        }));
    };
    onInputChange1 = (value) => {
        this.setState({ value });

        const data = {
            document_name: value,
            cases_id: this.props.isCheck,
        };
        Axios.Post(API_SEARCH_CASE_LIST, data)
            .then((res) => {
                if (res.data.success === true) {
                    var data = res.data.data;
                    const statedata =
                        data?.length > 0 &&
                        data.map((h) => {
                            return { value: h, label: h };
                        });
                    console.log(statedata, "5464132");

                    this.setState({ options: statedata });
                } else {
                    toast.error(res.data.error);
                }
            })
            .catch((error) => {
                console.log(error, "res.message");
            });
    };

    onInputChange = (value) => {
        if (value.length >= 3) {
            const data = {
                document_name: value,
                cases_id: this.props.isCheck,
            };

            Axios.post(API_SEARCH_CASE_LIST, data)
                .then((res) => {
                    if (res.data.success === true) {
                        var data = res?.data?.data;
                        const statedata =
                            data?.length > 0 &&
                            data.map((h) => {
                                return { value: h, label: h };
                            });
                        console.log(statedata, "5464132");
                        this.setState({ options: statedata || [] });
                    } else {
                        toast.error(res.data.error);
                    }
                })
                .catch((error) => {
                    console.log(error, "res.message");
                });
        }
    };

    handleSubmit = async () => {
        try {
            const data = {
                cases_id: this.props.isCheck,
                document_name: this.state.value.label,
                eservice: this.state.checkedItems.eservice,
                certified_copies: this.state.checkedItems.certified_copies,
            };
            const response = await Axios.Post(API_SEARCH_GENERATE_DOCUMENT, data, {
                responseType: "blob",
            });
            console.log(response, "response123");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");

            a.href = url;
            a.download = "files.zip";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            handleCancelAss(false);
        } catch (error) {
            console.error("Error downloading ZIP file:", error.message);
        }
    };

    render() {
        const { isOpen, value, options, checkedItems } = this.state;
        console.log(checkedItems, "dkjsdhffc");
        return (
            <>
                <Dropdown
                    isOpen={isOpen}
                    onClose={this.toggleOpen}
                    target={
                        <button
                            iconAfter={<ChevronDown />}
                            onClick={this.toggleOpen}
                            isSelected={isOpen}
                            className="search-certified"
                        >
                            {value ? ` ${value.label}` : "Search a document..."}
                        </button>
                    }
                >
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{ DropdownIndicator, IndicatorSeparator: null }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={this.onSelectChange}
                        onInputChange={this.onInputChange}
                        options={options}
                        placeholder="Search..."
                        styles={selectStyles}
                        tabSelectsValue={false}
                        value={value}
                    />
                </Dropdown>
                {value && (
                    <>
                        <Row>
                            <Col md="6" xs="6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value="certified_copies"
                                        id="flexCheckChecked"
                                        onChange={this.handleChecked}
                                        name="certified_copies"
                                        checked={checkedItems.certified_copies}
                                    />
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Download Certified Copies
                                    </label>
                                </div>
                            </Col>
                            <Col md="6" xs="6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value="eservice"
                                        id="flexCheckChecked"
                                        onChange={this.handleChecked}
                                        name="eservice"
                                        checked={checkedItems.eservice}
                                    />
                                    <label class="form-check-label" for="flexCheckChecked">
                                        E-Service
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <button className="gnrate-certi-btn mt-2" onClick={this.handleSubmit}>
                            Generate Document
                        </button>
                    </>
                )}
            </>
        );
    }
}

// styled components

const Menu = (props) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
        <div
            css={{
                backgroundColor: "white",
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: "absolute",
                zIndex: 2,
            }}
            {...props}
        />
    );
};
const Blanket = (props) => (
    <div
        css={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: "fixed",
            zIndex: 1,
        }}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div css={{ position: "relative" }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
const Svg = (p) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);
const DropdownIndicator = () => (
    <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);
const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
        <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </Svg>
);

export default SelectSearch;
