import React from 'react';
import {Redirect, Route, Switch,asyncComponent} from '../../../components';
const Categories = ({match}) => (
  console.log(match,"match343"),
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/>
      <Route path={`${match.url}/list/:templateId`} component={asyncComponent(() => import('./routes/list'))}/>
      <Route path={`${match.url}/add/:templateId`} component={asyncComponent(() => import('./routes/add'))}/>
      <Route path={`${match.url}/edit/:faqId`} component={asyncComponent(() => import('./routes/add'))}/>
      <Route
        path={`${match.url}/view/:editId`}
        component={asyncComponent(() => import("./routes/view"))}
      />
      <Route component={asyncComponent(() => import('../../../app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Categories;
