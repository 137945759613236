import React, { useState, useEffect, Fragment } from "react";
import {
  withRouter,
  Container,
  Row,
  Col,
  toast,
  TEXTFIELD,
  Axios,
  Button,
  ApiUrl
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { set } from "lodash";

const { API_GET_ADDONS_CATEGORY } = ApiUrl;

const Addons = ({ data, updateAddons }) => {
  // Define AddonObj_new
  const AddonObj_new = {
    college_institute_name: "",
    university_name: "",
    course_name: "",
    year_of_passing: ""
  };

  const [state, setState] = useState({
    addons: data,
    errors: {} // Initialize errors state
  });

  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setState({ addons: data, errors: {} }); // Initialize errors state when data changes
  }, [data]);

  useEffect(() => {
    updateAddons(state.addons);
    setIsChange(false);
  }, [isChange]);

  const handleChange = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;
    let newErrors = { ...state.errors };

    // Validate if the field is empty
    if (value.trim() === "") {
      newErrors[name] = "Required";
    } else {
      newErrors[name] = ""; // Clear the error if the field is not empty
    }

    setState((prev) => ({
      ...prev,
      errors: newErrors,
      addons: [
        ...prev.addons.slice(0, i),
        {
          ...prev.addons[i],
          [name]: value
        },
        ...prev.addons.slice(i + 1)
      ]
    }));

    setIsChange(true);
    console.log(` ${name} : "${value}"`);
  };

  const addRow = (e, type, i) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      addons: [...prev.addons, { ...AddonObj_new }],
      errors: {} // Clear errors when adding a new row
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index, type, rIndex) => {
    e.preventDefault();
    const addons = state.addons.filter((e, i) => i !== index);
    setState({
      addons: [...addons],
      errors: {} // Clear errors when deleting a row
    });
    setIsChange(true);
  };

  return (
    <Fragment>
      {state.addons.map((s, i) => (
        <div className="uper-multi-row" key={i}>
          <Row>
            <Col lg="12" className={"my-2"}>
              <h2 className="m-0 fw-bold font-weight-bold">Education</h2>
            </Col>
            <Col md="3" xs="3">
              <TEXTFIELD
                label="College Name"
                value={s.college_institute_name}
                name="college_institute_name"
                onChange={(e) => handleChange(e, i)}
                error={state.errors["college_institute_name"]}
                required
              />
            </Col>

            <Col md="3" xs="3">
              <TEXTFIELD
                label="University Name"
                value={s.university_name}
                name="university_name"
                onChange={(e) => handleChange(e, i)}
                error={state.errors["university_name"]}
                required
              />
            </Col>

            <Col md="3" xs="3">
              <TEXTFIELD
                label="Course Name"
                value={s.course_name}
                name="course_name"
                onChange={(e) => handleChange(e, i)}
                error={state.errors["course_name"]}
                required
              />
            </Col>

            <Col md="2" xs="3">
              <TEXTFIELD
                label="Year of passing"
                value={s.year_of_passing}
                name="year_of_passing"
                onChange={(e) => handleChange(e, i)}
                error={state.errors["year_of_passing"]}
                required
              />
            </Col>

            <Col md="1" xs="12">
              {i === 0 ? (
                <IconButton
                  aria-label="delete"
                  style={{ marginTop: 20 }}
                  onClick={(e) => addRow(e, "addons")}
                >
                  <AddCircleIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="Add"
                  onClick={(e) => deleteRow(e, i, "addons")}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </Col>
          </Row>
        </div>
      ))}
    </Fragment>
  );
};

export default React.memo(Addons);
