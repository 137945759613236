

import React, { Fragment, useState } from "react";

import {
    withRouter,
    Row,
    Col,
    toast,
    Axios,
    TEXTFIELD,
    PASSWORDFIELD,
    SELECT,
    SUBMIT,
    RESET,
    ApiUrl,
    checkValidations,
    checkError,
    Loading,
} from "../../../../../../../components";

const {
    API_ADD_HEARING_DATE,
    Numeric
} = ApiUrl;

class AddEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editId: this.props.editId,
            process: this.props.role,
            mode_of_hearing: "",
            topic: "",
            agenda: "",
            duration: "",
            start_time: "",
            seat: "",
            venue: "",
            start_date: "",
            case_id: this.props.cases,
            errors: {
                mode_of_hearing: "",
                topic: "",
                agenda: "",
                duration: "",
                start_time: "",
                seat: "",
                venue: "",
                start_date: "",
            },
            disabled: true,
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let finalVal = value;
        const maxDate = new Date();
        maxDate.setHours(0, 0, 0, 0);
        maxDate.setDate(maxDate.getDate() + 1);
        console.log(maxDate, "sssss")
        switch (name) {
            case "topic":
                errors.topic = value.length < 1 ? "Required" : "";
                break;
            case "agenda":
                errors.agenda = value.length < 1 ? "Required" : "";
                break;
            case "seat":
                errors.seat = value.length < 1 ? "Required" : "";
                break;
            case "venue":
                errors.venue = value.length < 1 ? "Required" : "";
                break;

            case "duration": {
                let err = "";
                if (value.length < 1) {
                    err = "Required";
                } else {
                    if (!Numeric.test(value)) {
                        err = "Only numeric allowed";
                        finalVal = "";
                    }
                }
                errors.duration = err;

                break;
            }
            case "start_date":
                // errors.mode_of_hearing = value.length < 1 ? "Required" : "";
                let err = "";
                if (value.length < 1) {
                    err = "Required";
                } else {
                    if (value > maxDate) {
                        err = "Only numeric allowed";
                        finalVal = "";
                    }
                }
                errors.start_date = err;


                break;
            case "start_time":
                errors.start_time = value.length < 1 ? "Required" : "";
                break;
            // case "start_date":
            //   errors.start_date = value.length < 1 ? "Required" : "";
            //   break;

            default:
                break;
        }

        this.setState({ errors, [name]: finalVal }, () => {
            let err = checkError(errors);
            this.setState({ disabled: err.disabled });
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, disabled: true });
        const {
            mode_of_hearing,
            topic,
            agenda,
            duration,
            start_time,
            seat,
            venue,
            start_date,
            case_id,
            errors,
            process,
        } = this.state;
        let err = checkError(errors);
        if (err.disabled === true) {
            toast.error(err.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        let data = {
            topic: topic,
            agenda: agenda,
            duration: duration,
            seat: seat,
            venue: venue,
            mode_of_hearing: mode_of_hearing,
            start_time: start_time,
            start_date: start_date,
            agenda: agenda,
            case_id: case_id,
        };

        let valid = checkValidations(data);
        if (valid.error) {
            toast.error(valid.error);
            this.setState({ loading: false, disabled: false });
            return false;
        }

        data = valid.data;
        let url = API_ADD_HEARING_DATE;

        Axios.Post(url, data)
            .then((res) => {
                console.log(res, "reskjkj");
                let response = res.data;
                toast.success(response.message);
                this.setState({ loading: false, disabled: false });

                if (response.status === 200) {
                    setTimeout(() => {
                        this.props.handleCancelN()
                        this.props.getTableRecords()
                    }, 1000);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((error) => {
                let res = error.response;
                console.log(error, res.message, "res.message");
                toast.error(res.message);
                this.setState({ loading: false, disabled: false });
            });
    };

    render() {
        const {
            errors,
            mode_of_hearing,
            topic,
            agenda,
            duration,
            start_time,
            seat,
            venue,
            start_date,
            disabled,
            loading,
        } = this.state;
        return (
            <Fragment>
                {loading && (
                    <div className="text-center w-100">
                        <Loading />
                    </div>
                )}
                <form onSubmit={this.handleSubmit} noValidate>
                    <Row>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                label="Topic"
                                value={topic}
                                name={"topic"}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                label="Agenda"
                                value={agenda}
                                name={"agenda"}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>

                        <Col md="6" xs="12">
                            <TEXTFIELD
                                label="Duration(in minutes)"
                                value={duration}
                                name={"duration"}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                label="Seat"
                                value={seat}
                                name={"seat"}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                label="Venue"
                                value={venue}
                                name={"venue"}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <SELECT
                                label="Mode"
                                name={"mode_of_hearing"}
                                value={mode_of_hearing}
                                data={[{ online: "Online" }, { offline: "Offline" }, { hybrid: "Hybrid" }]}
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                type="date"
                                label="Hearing Date"
                                value={start_date}
                                name="start_date"
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                        <Col md="6" xs="12">
                            <TEXTFIELD
                                type="time"
                                label="Hearing Time"
                                value={start_time}
                                name="start_time"
                                onChange={this.handleChange}
                                errors={errors}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {" "}
                        <Col md="4" xs="12">
                            <SUBMIT title="Submit" disabled={disabled} />
                        </Col>
                    </Row>
                </form>
            </Fragment>
        );
    }
}

export default withRouter(AddEditForm);
