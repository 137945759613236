import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SEND_OTP, VERIFY_OTP, FRESH_CASE_REGISTER } from "../ApiUrl";
import { Axios, Row, Col, TEXTFIELD, SUBMIT, toast } from "../components";



const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    aadhaarNumber: Yup.string()
        .matches(/^\d{12}$/, 'Aadhaar number must be exactly 12 digits')
        .required('Aadhaar number is required')
        .matches(/^[0-9]*$/, 'Aadhaar number must contain only numeric digits'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    phone: Yup.string()
        .matches(/^\d{10,13}$/, 'Mobile number must be 10-13 digits')
        .required('Mobile number is required'),
    address: Yup.string().required('Address is required'),
    otp: Yup.string()
        .when('showOtpField', {
            is: true,
            then: Yup.string().required('OTP is required').matches(/^\d{4}$/, 'OTP must be exactly 4 digits'),
        }),
    password: Yup.string()
        .when('showPasswordFields', {
            is: true,
            then: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
        }),
    password_confirmation: Yup.string()
        .when(['showPasswordFields', 'password'], {
            is: (showPasswordFields, password) => showPasswordFields && password,
            then: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
});


const FreshCaseForm = ({ initialValues = { name: '', aadhaarNumber: '', email: '', mobileNumber: '', otp: '', }, caseData, userType, subType, setUserType, setSubType }) => {
    // console.log("Received initialValues:", initialValues);
    // console.log("Received caseData:", caseData);
    // console.log("Received userType:", userType);
    // console.log("Received subType:", subType);

    const [showOtpField, setShowOtpField] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [phoneValid, setphoneValid] = useState(false);
    const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [otpVerifyButtonVisible, setOtpVerifyButtonVisible] = useState(true);
    const [showPasswordFields, setShowPasswordFields] = useState(false);



    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        aadhaarNumber: Yup.string()
            .matches(/^\d{12}$/, 'Aadhaar number must be exactly 12 digits')
            .matches(/^[0-9]*$/, 'Aadhaar number must contain only numeric digits')
            .required('Aadhaar number is required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        phone: Yup.string()
            .matches(/^\d{10,13}$/, 'Mobile number must be 10-13 digits')
            .required('Mobile number is required'),
        address: Yup.string().required('Address is required'),
        otp: Yup.string()
            .when('showOtpField', {
                is: true,
                then: Yup.string()
                    .required('OTP is required')
                    .matches(/^\d{4}$/, 'OTP must be exactly 4 digits'),
            }),
        password: Yup.string()
            .when('showPasswordFields', {
                is: true,
                then: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password must be at least 8 characters'),
            }),
        password_confirmation: Yup.string()
            .when(['showPasswordFields', 'password'], {
                is: (showPasswordFields, password) => showPasswordFields && password,
                then: Yup.string()
                    .required('Confirm Password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            }),
    });

    const sendOtpAPI = async (phone) => {
        try {
            const response = await Axios.post(SEND_OTP, { phone: phone });
            toast.success('OTP sent successfully');
            return response.data;
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Failed to send OTP. Please try again.');
            throw new Error('Failed to send OTP. Please try again.');
        }
    };

    const handleSendOtp = async (values) => {
        try {
            const response = await sendOtpAPI(values.phone);
            if (response && response.success) {
                toast.success('OTP sent successfully');
                setOtpSent(true);
                setShowOtpField(true);
                setFormSubmitEnabled(true);

            } else {
                console.error('Send OTP failed:', error);
                toast.error('Failed to send OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            setOtpError(error.message);
            toast.error('Failed to send OTP. Please try again.');
        }
    };

    const verifyOtpAPI = async (phone, otp) => {
        try {
            const response = await Axios.post(VERIFY_OTP, { phone, otp });
            toast.success('verify OTP sent successfully');
            return response.data;
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Failed to verify OTP. Please try again.', error);
            alert('Failed to verify OTP. Please try again.');
            throw new Error('Failed to verify OTP. Please try again.');
        }
    }

    const handleVerifyOtp = async (values) => {
        try {
            if (!values.otp && otpSent) {
                setOtpError("Please enter OTP.");
                return;
            }

            const response = await verifyOtpAPI(values.phone, values.otp);
            if (response && response.success) {
                toast.success('Verify OTP sent successfully');
                setFormSubmitEnabled(true);
                setOtpVerifyButtonVisible(false);
                setShowPasswordFields(true);
                setShowOtpField(true);
            } else {
                console.error('Error verifying OTP:', error);
                setOtpError("Invalid OTP.");
                toast.error('Failed to verify OTP. Please try again.');
                alert('Failed to verify OTP. Please try again.');
                throw new Error('Failed to verify OTP. Please try again.');
            }
        } catch (error) {
            setOtpError(error.message);
        }
    };


    // const handleVerifyOtp = async (values) => {
    //     try {
    //         const response = await verifyOtpAPI(values.phone, values.otp);
    //         if (response && response.success) {
    //             toast.success('verify OTP sent successfully');
    //             setFormSubmitEnabled(true);
    //             setOtpVerifyButtonVisible(false);
    //             setShowPasswordFields(true);
    //             setShowOtpField(true);
    //         } else {
    //             console.error('Error verifying OTP:', error);
    //             setOtpError("Invalid opt ");
    //             toast.error('Failed to verify OTP. Please try again.');
    //             alert('Failed to verify OTP. Please try again.');
    //             throw new Error('Failed to verify OTP. Please try again.');
    //         }
    //     } catch (error) {
    //         setOtpError(error.message);
    //     }
    // };

    const registerFreshCaseAPI = async (formData) => {
        try {
            const response = await Axios.post(FRESH_CASE_REGISTER, formData);
            return response.data;
        } catch (error) {
            console.error('Error registering fresh case:', error);
            throw new Error('Failed to register fresh case. Please try again.');
        }
    };

    // const handleSubmit = async (formData) => {
    //     console.log(formData, "formData")
    //     try {
    //         const hasValues = initialValues || caseData.id || userType || subType;
    //         const apiEndpoint = hasValues ? SIGNUP_Other_CLAIMANT : FRESH_CASE_REGISTER;
    //         if (hasValues) {
    //             formData = {
    //                 ...formData,
    //                 case_id: caseData.id,
    //                 userType: userType,
    //                 subType: subType
    //             };
    //         }
    //         const response = await Axios.post(apiEndpoint, formData);
    //         console.log("Form data submitted successfully:", response.data);
    //         setFormSubmitted(true);
    //     } catch (error) {
    //         console.error('Error submitting form data:', error);
    //     }
    // };


    const handleSubmit = async (formData) => {
        try {
            const mergedFormData = {
                ...formData,
                caseData: caseData,
                userType: userType,
                subType: subType
            };
            await registerFreshCaseAPI(mergedFormData);
            setFormSubmitted(true);
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };



    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="12">
                            <label htmlFor="name">Name</label>
                            <TEXTFIELD
                                placeholder="Name"
                                type="text"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                            />
                            {errors.name && touched.name && <div className="error">{errors.name}</div>}
                        </Col>

                        <Col md="12">
                            <label htmlFor="aadhaarNumber">Aadhaar Number</label>
                            <TEXTFIELD
                                type="text"
                                id="aadhaarNumber"
                                name="aadhaarNumber"
                                value={values.aadhaarNumber}
                                onChange={handleChange}
                            />
                            {errors.aadhaarNumber && touched.aadhaarNumber && <div className="error">{errors.aadhaarNumber}</div>}
                        </Col>

                        <Col md="12">
                            <label htmlFor="email">Email</label>
                            <TEXTFIELD
                                type="email"
                                id="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                            />
                            {errors.email && touched.email && <div className="error">{errors.email}</div>}
                        </Col>
                        <Col md="12">
                            <label htmlFor="address">Address</label>
                            <TEXTFIELD
                                type="text"
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                            />
                            {errors.address && touched.address && <div className="error">{errors.address}</div>}
                        </Col>

                        <Col md="12">
                            <label htmlFor="phone">Mobile Number</label>
                            <TEXTFIELD
                                type="text"
                                id="phone"
                                name="phone"
                                value={values.phone}
                                onChange={(e) => {
                                    handleChange(e);
                                    const isValid = /^\d{10,13}$/.test(e.target.value);
                                    setphoneValid(isValid);
                                }}
                            />
                            {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}

                        </Col>

                    </Row>

                    {!showOtpField && !otpSent && (
                        <button
                            type="button"
                            onClick={() => handleSendOtp(values)}
                            disabled={!phoneValid}
                            style={{
                                background: phoneValid ? "#004281" : "grey", // Change background color based on phoneValid
                                color: "white",
                                border: "1px solid",
                                padding: "8px 13px",
                                marginTop: "5px"
                            }}
                        >
                            Send OTP
                        </button>
                    )}


                    {showOtpField && (
                        <div>
                            <label htmlFor="otp">OTP</label>
                            <TEXTFIELD
                                type="text"
                                id="otp"
                                name="otp"
                                value={values.otp}
                                onChange={handleChange}
                            />
                            {errors.otp && touched.otp && <div className="error">{errors.otp}</div>}


                            {otpVerifyButtonVisible && (
                                <button
                                    type="button"
                                    onClick={() => handleVerifyOtp(values)}
                                    disabled={!otpSent}
                                    style={{
                                        background: otpSent ? "#004281" : "grey",
                                        color: "white",
                                        border: "1px solid",
                                        padding: "8px 13px",
                                        marginTop: "5px"
                                    }}
                                >
                                    Verify OTP
                                </button>
                            )}

                        </div>
                    )}

                    {showPasswordFields && (
                        <>
                            <div>
                                <label htmlFor="password">Password</label>
                                <TEXTFIELD
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                />
                                {errors.password && touched.password && <div className="error">{errors.password}
                                </div>}
                            </div>

                            <div>
                                <label htmlFor="password_confirmation">Confirm Password</label>
                                <TEXTFIELD
                                    type="password"
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    value={values.password_confirmation}
                                    onChange={handleChange}
                                />
                                {errors.password_confirmation && touched.password_confirmation && <div className="error">{errors.password_confirmation}</div>}

                                <Row>
                                    <Col md="12" xs="12">
                                        <div className='text-center mt-3 mb-3'>
                                            <button
                                                style={{
                                                    color: "#fff",
                                                    background: "#004281",
                                                    border: "1px solid",
                                                    padding: "11px 40px 10px 40px",
                                                    fontSize: "18px"
                                                }}


                                                type="submit">Submit</button>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </>
                    )}

                </form>
            )}
        </Formik>
    );
};

export default FreshCaseForm;
