import React from "react";
import send from "../src/assets/images/send.jpg";
import { withRouter, toast, Axios, ApiUrl } from "./components";

const { API_SEND_MESSAGE } = ApiUrl;

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      disabled: true,
      chatBox: false,
    };
  }
  toggelChatBox = () => {
    this.setState({ chatBox: !this.state.chatBox });
  };
  handleChange = (event) => {
    const message = event.target.value;
    this.setState({ message:message });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.handleSubmit(event);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { message } = this.state;

    let data = {
      message: message,
    };

    let url = "" ;

    Axios.Post(url, data)
      .then((res) => {
        let response = res.data;
        this.setState({ loading: false, disabled: false });

        if (response.status === 200) {
          toast.success(response.message);
          this.setState({ chatBox: false });
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        this.setState({ loading: false, disabled: false });
      });
  };

  render() {
    const { message } = this.state;
    return (
      <>
       {/* <div className="message-us-box" onClick={this.toggelChatBox}>
          Message us
        </div> */}
        {this.state.chatBox && (
          <div className="popup-chat">
            <p>
              Message us 
              <span
                className="close-btn-chat"
                onClick={this.toggelChatBox}
              >
                <i className="zmdi zmdi-close"></i>
              </span>
            </p>
            <div className="chat-write-us-box">
              <form noValidate onSubmit={this.handleSubmit}>
                <textarea
                  placeholder="Write Us"
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                ></textarea>

                <span onClick={this.handleSubmit}>
                  <img src={send} alt="send" />
                </span>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(MainApp);
