import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import "./list.css"
import { Link } from 'react-router-dom';
import { FILED_CASE } from "../../../ApiUrl";
import { Axios } from "../../../components";

const encodeId = ($id) => {
    return btoa(JSON.stringify({ mac: (Math.random() + 1).toString(36).slice(2, 6) + $id + (Math.random() + 1).toString(36).slice(2, 6) }));
};

const List = ({ history, location, match }) => {
    const [data, setData] = useState([]);
    const userRole = localStorage.getItem('role');
    const userId = localStorage.getItem('_id');

    const fetchData = () => {
        return Axios.get(FILED_CASE)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const downloadCSV = () => {
        const csvData = Papa.unparse({
            fields: ['S no.', 'Case no.', 'Case Title', 'Payment Status', 'Case Status', 'Award', 'Arbitrator', 'Claimant Adv.', 'Respondent Adv.', 'Client'],
            data: data.map((item, index) => ({
                'S no.': index + 1,
                'Case no.': item.case_no,
                'Case Title': `${item.claimants} vs ${item.respondents}`,
                'Payment Status': item.payment_status,
                'Case Status': item.case_status,
                'Award': item.award,
                'Arbitrator': item.case_users
                    .filter((user) => user.type === 'Arbitrator')
                    .map((arbitrator) => arbitrator.user_detail.name)
                    .join(', ') || 'Not assigned',
                'Claimant Adv.': item.case_users
                    .filter((user) => user.type === 'Advocate' && user.advocate_for === 'claimant')
                    .map((claimantAdvocate) => claimantAdvocate.user_detail.name)
                    .join(', ') || 'Not assigned',
                'Respondent Adv.': item.case_users
                    .filter((user) => user.type === 'Advocate' && user.advocate_for === 'respondent')
                    .map((respondentAdvocate) => respondentAdvocate.user_detail.name)
                    .join(', ') || 'Not assigned',
                'Client': userRole === 'Party' ? localStorage.getItem('name') : '',
            })),
        });

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'case_list.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert('Your browser does not support download functionality. Please try a different browser.');
        }
    };

    return (
        <div>
            <section className="content-header">
                <div className="row">
                    <div className="col-md-6">
                        <h1> Case List </h1>
                    </div>
                    <div className="col-md-6 text-right">

                        {/* <button
                            type="button"
                            className="btn btn-warning download-csv-btn mx-5 MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M"
                            onClick={downloadCSV}>

                            Download as CSV
                        </button> */}
                    </div>
                </div>
                <ol className="breadcrumb"></ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box box-solid">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>S no.</th>
                                            <th className='text-center'>Case no.</th>
                                            <th className='text-center'>Case Title</th>
                                            <th className='text-center'>Payment Status</th>
                                            <th className='text-center'>Case Status</th>
                                            <th className='text-center'>Award</th>
                                            <th className='text-center'>Arbitrator</th>
                                            <th className='text-center'>Claimant Adv.</th>
                                            <th className='text-center'>Respondent Adv.</th>
                                            {userRole === 'Party' && <th>Client</th>}
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td className='text-center'>{index + 1}.</td>
                                                <td className='text-center'>{item.case_no}</td>
                                                <td className='text-center'>{item.claimants} vs {item.respondents}</td>
                                                <td className='text-center'>
                                                    <label className="label label-payment">{item.payment_status}</label>
                                                </td>
                                                <td className="text-center label label-case-status">{item.case_status}</td>
                                                <td className=" text-center label label-case-status">{item.award}</td>
                                                <td>
                                                    {item.case_users
                                                        .filter((user) => user.type === 'Arbitrator')
                                                        .map((arbitrator) => arbitrator.user_detail.name)
                                                        .join(', ')
                                                        || <label className="text-center label label-danger">Not assigned</label>}
                                                </td>

                                                <td>
                                                    {item.case_users
                                                        .filter((user) => user.type === 'Advocate' && user.advocate_for === 'claimant')
                                                        .map((claimantAdvocate) => claimantAdvocate.user_detail.name)
                                                        .join(', ')
                                                        || <label className="text-center label label-danger">Not assigned</label>}
                                                </td>

                                                <td>
                                                    {item.case_users
                                                        .filter((user) => user.type === 'Advocate' && user.advocate_for === 'respondent')
                                                        .map((respondentAdvocate) => respondentAdvocate.user_detail.name)
                                                        .join(', ')
                                                        || <label className=" text-center label label-danger">Not assigned</label>}
                                                </td>

                                                {userRole === 'Party' && (
                                                    <td className='text-center'>
                                                        {localStorage.getItem('name')}
                                                    </td>
                                                )}

                                                <td className='text-center'>
                                                    <Link to="#" className="action-link btn btn-success">
                                                        Pay Now
                                                    </Link>
                                                    <Link to={`/app/party/case-detail/${encodeId(userId)}`} className="action-link btn btn-warning">
                                                        View
                                                    </Link>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default List;
