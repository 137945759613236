// import React, { useState, useEffect } from "react";
// import FullCalendar from "@fullcalendar/react";
// import daygridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";

// // import { v4 as uuid } from "uuid";
// import { v4 as uuidv4 } from "uuid";
// import { Link } from "../../../../../components";

// const MyCalendar = (props) => {
//   const [events, setEvents] = useState([]);
//   const [id, setId] = useState();
//   const list = props.List || [];
//   useEffect(() => {

//     // Function to extract hearing dates and format them for FullCalendar
//     const formattedEvents = list.reduce((acc, caseItem) => {
//       const hashId = caseItem.hash_id;

//       if (caseItem.hearing && Array.isArray(caseItem.hearing)) {
//         caseItem.hearing.forEach((hearingItem) => {
//           if (hearingItem.hearing) {
//             const hearing = hearingItem.hearing;
//             const startDateTime = `${hearing.start_date.split(" ")[0]}T${
//               hearing.start_time
//             }`;
//             acc.push({
//               title: hearing.topic,
//               start: startDateTime,
//               end: startDateTime,
//               id: uuidv4(),
//               hash_id: hashId,
//             });
//             setId(hashId);
//           }
//         });
//       }

//       return acc;
//     }, []);

//     setEvents(formattedEvents);
//   }, [props.List]);

//   const handleSelect = (info) => {
//     const { start, end } = info;
//     const eventNamePrompt = prompt("Enter event name");
//     if (eventNamePrompt) {
//       setEvents([
//         ...events,
//         {
//           start,
//           end,
//           title: eventNamePrompt,
//           id: uuidv4(),
//         },
//       ]);
//     }
//   };

//   const EventItem = ({ info }) => {
//     const { event } = info;
//     return (
//       <div>
//         <Link to={`/app/client/case-detail/${event.extendedProps.hash_id}`}>
//           <p className="event-title">{event.title}</p>
//         </Link>
//       </div>
//     );
//   };

//   return (
//     <div className="calendar-container">
//       <FullCalendar
//         editable
//         selectable
//         // select={handleSelect}
//         headerToolbar={{
//           start: "today prev next title",
//           end: "dayGridMonth dayGridWeek dayGridDay",
//         }}
//         allDayText="All Day"
//         timeZone="UTC"
//         events={events}
//         eventContent={(info) => <EventItem info={info} />}
//         initialView="dayGridMonth"
//         plugins={[daygridPlugin, interactionPlugin]}
//         dayMaxEvents={1}
//       />
//     </div>
//   );
// };

// export default MyCalendar;

import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { v4 as uuidv4 } from "uuid";
import { Link } from "../../../../../components";

const MyCalendar = (props) => {
  const [events, setEvents] = useState([]);
  const [id, setId] = useState();
  const list = props.List || [];

  useEffect(() => {
    // Function to extract hearing dates and format them for FullCalendar
    const formattedEvents = list.length > 0 && list?.reduce((acc, caseItem) => {
      const hashId = caseItem.hash_id;

      if (caseItem.hearing && Array.isArray(caseItem.hearing)) {
        caseItem.hearing.forEach((hearingItem) => {
          if (hearingItem.hearing) {
            const hearing = hearingItem.hearing;
            const startDateTime = `${hearing.start_date.split(" ")[0]}T${
              hearing.start_time
            }`;
            acc.push({
              title: hearing.topic,
              start: startDateTime,
              end: startDateTime,
              id: uuidv4(),
              hash_id: hashId,
            });
            setId(hashId);
          }
        });
      }

      return acc;
    }, []);

    setEvents(formattedEvents);
  }, [props.List]);

  const handleSelect = (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Enter event name");
    if (eventNamePrompt) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: uuidv4(),
        },
      ]);
    }
  };

  const EventItem = ({ info }) => {
    const { event } = info;
    return (
      <div>
        <Link to={`/app/client/case-detail/${event.extendedProps.hash_id}`}>
          <p className="event-title">{event.title}</p>
        </Link>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        editable
        selectable
        headerToolbar={{
          start: "today",
          center: "title",
          end: "prev,dayGridMonth,dayGridWeek,dayGridDay,next",
        }}
        titleFormat={{ year: "numeric", month: "long" }} // Custom title format to include the year
        allDayText="All Day"
        timeZone="UTC"
        events={events}
        eventContent={(info) => <EventItem info={info} />}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        dayMaxEvents={1}
      />
    </div>
  );
};

export default MyCalendar;
