import React, { useState, useEffect, Fragment } from "react";
import {
  withRouter,
  Container,
  Row,
  Col,
  toast,
  TEXTFIELD,
  SELECT,
  CHECKBOX,
  FILEUPLOAD,
  Axios,
  Button,
  ApiUrl,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { set } from "lodash";
const { FILE_UPLOAD, API_GET_ADDONS_CATEGORY } = ApiUrl;
const itemsOBJ = {
  name: "",
  price: 0,
  size: "",
};

const itemsOBJ_new = Object.assign({}, itemsOBJ);

const AddonObj = {
  catName: "",
  items: [{ ...itemsOBJ_new }],
};

const AddonObj_new = {
  user_id: "",
  course_name: "",
  organiser_name: "",
  course_duration: "",
  course_year: "",
};

const Addons = ({ dataMediation, updateMediation, courseName }) => {
  const [state, setState] = useState({
    addons: dataMediation,
  });
  const [isChange, setIsChange] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    setState({ addons: dataMediation });
  }, [dataMediation]);

  useEffect(() => {
    updateMediation(state.addons);
    setIsChange(false);
  }, [isChange]);

  const validateField = (name, value) => {
    switch (name) {
      case "college_institute_name":
      case "university_name":
      case "course_name":
        return value.trim() !== "";
      case "course_duration":
        return /^\d{4}$/.test(value);
      default:
        return true;
    }
  };

  const changeRowvalue = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;

    const isValid = validateField(name, value);
    const errors = [...validationErrors];
    if (!isValid && !errors.includes(name)) {
      errors.push(name);
    } else if (isValid && errors.includes(name)) {
      const index = errors.indexOf(name);
      errors.splice(index, 1);
    }
    setValidationErrors(errors);
    setState((prev) => ({
      ...prev,
      addons: prev.addons.map((addon, index) =>
        index === i ? { ...addon, [name]: value } : addon
      ),
    }));
    setIsChange(true);
    console.log(` ${name}: "${value}"`);
  };

  const addRow = (e) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      addons: [...prev.addons, { ...AddonObj_new }],
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const addons = state.addons.filter((_, i) => i !== index);
    setState({
      addons: [...addons],
    });
    setIsChange(true);
  };


  return (
    <Fragment>
      <h2 className="m-2 fw-bold font-weight-bold">
        {courseName} Training course
      </h2>
      {state.addons &&
        state.addons.map((s, i) => {
          return (
            <div className="uper-multi-row" key={i}>
              <Row>
                <Col lg="12" className={"my-2"}></Col>

                <Col md="3" xs="3">
                  <TEXTFIELD
                    label="Course Name"
                    value={s.course_name}
                    name={"course_name"}
                    onChange={(e) => changeRowvalue(e, i)}
                    error={validationErrors.includes("course_name")}
                  />
                </Col>

                <Col md="3" xs="3">
                  <TEXTFIELD
                    label="Organizer Name"
                    value={s.organiser_name}
                    name={"organiser_name"}
                    onChange={(e) => changeRowvalue(e, i)}
                    error={validationErrors.includes("organiser_name")}
                  />
                </Col>

                <Col md="3" xs="3">
                  <TEXTFIELD
                    label="Course Duration"
                    value={s.course_duration}
                    name={"course_duration"}
                    onChange={(e) => changeRowvalue(e, i)}
                    error={validationErrors.includes("course_duration")}
                  />
                </Col>

                
                <Col md="2" xs="3">
                  <TEXTFIELD
                    label="Course Year"
                    value={s.course_year}
                    name={"course_year"}
                    onChange={(e) => changeRowvalue(e, i)}
                    error={validationErrors.includes("course_year")}
                  />
                </Col>
                <Col md="1" xs="12">
                  {i === 0 ? (
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginTop: 20,
                      }}
                      onClick={addRow}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="Add"
                      onClick={(e) => deleteRow(e, i)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
    </Fragment>
  );
};

export default React.memo(Addons);
