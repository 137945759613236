import React, { useState, useEffect, Fragment } from "react";
import {
  withRouter,
  Container,
  Row,
  Col,
  toast,
  TEXTFIELD,
  SELECT,
  CHECKBOX,
  FILEUPLOAD,
  Axios,
  Button,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { set } from "lodash";
import { FILE_UPLOAD } from "../../ApiUrl";
import { Modal } from "@material-ui/core";

const itemsOBJ_new = {
  user_id: "",
  employer_name: "",
  employer_address: "",
  position: "",
  start_year: "",
  end_year: "",
};

const AddonObj = {
  catName: "",
  items: [{ ...itemsOBJ_new }],
};

const Customize = ({ dataExperience, updateExperience, optionObj }) => {
  const [state, setState] = useState({
    addons: dataExperience,
  });

  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setState({ addons: dataExperience });
  }, [dataExperience]);

  useEffect(() => {
    updateExperience(state.addons);
    setIsChange(false);
  }, [isChange]);

  const changeRowvalue = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(` ${name} : "${value}"`); // Log field name and value
    setState((pre) => ({
      ...pre,
      addons: [
        ...pre.addons.slice(0, i),
        {
          ...pre.addons[i],
          [name]: value,
        },
        ...pre.addons.slice(i + 1),
      ],
    }));
    setIsChange(true);
  };

  const addRow = (e, i) => {
    e.preventDefault();
    setState((pre) => ({
      ...pre,
      addons: [
        ...pre.addons,
        { ...AddonObj, options: [JSON.parse(JSON.stringify(optionObj))] },
      ],
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const addons = state.addons.filter((e, i) => i !== index);
    setState({
      addons: [...addons],
    });
    setIsChange(true);
  };

  return (
    <Fragment>
      {state.addons &&
        state.addons.map((s, i) => (
          <div className="uper-multi-row" key={i}>
            <Row>
              <Col lg="12" className={"my-2"}>
                <h2 className="m-0 fw-bold font-weight-bold">Experience</h2>
              </Col>

              <Col md="3" xs="3">
                <TEXTFIELD
                  label="Company Name"
                  value={s.employer_name}
                  name={"employer_name"}
                  onChange={(e) => changeRowvalue(e, i)}
                />
              </Col>

              <Col md="3" xs="3">
                <TEXTFIELD
                  label="Address"
                  value={s.employer_address}
                  name={"employer_address"}
                  type="text"
                  onChange={(e) => changeRowvalue(e, i)}
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD
                  label="Position"
                  value={s.position}
                  name={"position"}
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  onChange={(e) => changeRowvalue(e, i)}
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD
                  label="Start Year"
                  value={s.start_year}
                  name={"start_year"}
                  onChange={(e) => changeRowvalue(e, i)}
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD
                  label="End Year"
                  value={s.end_year}
                  name={"end_year"}
                  onChange={(e) => changeRowvalue(e, i)}
                />
              </Col>

              <Col md="2" xs="12" className={"align-self-end"}>
                <div className="d-flex align-items-center gap-10 mb-2">
                  {state.addons?.length > 1 && (
                    <IconButton
                      aria-label="Add"
                      onClick={(e) => deleteRow(e, i)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}

                  {i === state.addons.length - 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => addRow(e, i)}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))}
    </Fragment>
  );
};

export default React.memo(Customize);
