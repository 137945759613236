// import React, { Fragment, useState } from "react";

// import {
//   withRouter,
//   Row,
//   Col,
//   toast,
//   Axios,
//   CircularProgress,
//   TEXTFIELD,
//   CKEditor,
//   SUBMIT,
//   ApiUrl,
//   checkValidations,
//   checkError,
//   Card,
//   CardBody,
//   CardText,
//   moment,
// } from "../../../../../../../components";
// import { Button, Modal } from "reactstrap";
// import EditNotesclient from "./EditNotesclient"
// const { API_ADD_CLIENT_NOTES, Numeric, DELETE_NOTES } = ApiUrl;
// import EditNotes from "./editNotes"

// class UploadFile extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editId: this.props.editId,
//       listId: this.props.listId,
//       process: this.props.role,
//       addNotes: "",
//       topic: "",
//       noteAddedBy: "",
//       designation: "",
//       mobileNumber: "",
//       files: {},
//       notes: "",
//       addNotesShow: false,
//       listNotesShow: false,
//       errors: {
//         files: "",
//       },
//       disabled: true,
//       loading: false,
//     };
//   }

//   handleChange = (event) => {
//     event.preventDefault();
//     const { name, value, validations } = event.target;
//     let errors = this.state.errors;
//     let finalVal = value;
//     switch (name) {
//       case "addNotes":
//         errors.addNotes = value.length < 1 ? "Required" : "";
//         break;
//       case "topic":
//         errors.topic = value.length < 1 ? "Required" : "";
//         break;

//       case "mobileNumber": {
//         let err = "";
//         if (value.length < 1) {
//           err = "Required";
//         } else {
//           if (!Numeric.test(value)) {
//             err = "Only numeric allowed";
//             finalVal = "";
//           }

//           if (value.length <= 9 && value.length >= 0) {
//             err = "Minimum 10 digits required";
//           }
//         }
//         errors.mobileNumber = err;

//         break;
//       }

//       default:
//         break;
//     }

//     this.setState({ errors, [name]: finalVal }, () => {
//       let err = checkError(errors);
//       this.setState({ disabled: err.disabled });
//     });
//   };

//   handleFile = (event) => {
//     event.preventDefault();
//     const { errors } = this.state;
//     let file = event.target.files[0];
//     let newErr = { ...errors, file: "" };

//     this.setState(
//       {
//         files: file,
//         errors: newErr,
//       },
//       () => {
//         let err = checkError(newErr);
//         this.setState({ disabled: err.disabled });
//       }
//     );
//     this.setState({ promotionImageName: file.name });
//   };
//   downloadFile = async (id, name) => {
//     console.log(id, name, "case id");

//     try {
//       const url = `${API_URL}/get-file/link/${id}`;
//       const response = await fetch(url);
//       const blob = await response.blob();

//       const link = document.createElement("a");
//       link.href = window.URL.createObjectURL(blob);
//       link.setAttribute("download", name); // Set custom file name
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error("Error downloading file:", error);
//     }
//   };
//   handleSubmit = (event) => {
//     event.preventDefault();
//     this.setState({ loading: true, disabled: true });

//     const {
//       addNotes,
//       topic,
//       noteAddedBy,
//       designation,
//       mobileNumber,
//       files,
//       errors,
//     } = this.state;
//     let err = checkError(errors);
//     if (err.disabled === true) {
//       toast.error(err.error);
//       this.setState({ loading: false, disabled: false });
//       return false;
//     }

//     let data = {
//       addNotes: addNotes,
//       topic: topic,
//       noteAddedBy: noteAddedBy,
//       designation: designation,
//       mobileNumber: mobileNumber,
//       files: files,
//     };
//     let valid = checkValidations(data);
//     if (valid.error) {
//       toast.error(valid.error);
//       this.setState({ loading: false, disabled: false });
//       return false;
//     }

//     data = valid.data;

//     let url = API_ADD_CLIENT_NOTES;

//     let formData = new FormData();
//     for (var key in data) {
//       formData.append(key, data[key]);
//     }
//     Axios.Post(url + this.props.id, formData)
//       .then((res) => {
//         this.setState({ loading: false, disabled: false });

//         if (res.data.success === true) {
//           toast.success(res.data.message);
//           setTimeout(() => {
//             window.location.reload();
//           }, 2000);
//         } else {
//           toast.error(res.data.error);
//         }
//       })
//       .catch((error) => {
//         console.log(error, "res.message");
//       });
//   };


//   openEditModal = (caseId, noteId) => {
//     console.log(caseId, noteId, "checking no");
//     this.setState({ caseId: caseId });
//     this.setState({ noteId: noteId });
//     this.setState({ editModal: true });
//   };

//   handleDeleteNotes = async (caseId, noteId) => {
//     console.log("rsdgdsgdfsgf")
//     try {
//       const response = await Axios.Get(`${DELETE_NOTES}/${caseId}/${noteId}`);
//       console.log(
//         response.data.success === true,
//         response.data.success,
//         response.data,
//         "mnbmbnmbn"
//       );
//       if (response.data.success === true) {
//         console.log("checking");
//         this.props.getNotes();
//         toast.success("Note deleted successfully.");
//         setNotes((prevNotesData) => ({
//           ...prevNotesData,
//           notesList: prevNotesData.data.filter((note) => note.id !== noteId),
//         }));
//       } else {
//         throw new Error(response.data.message);
//       }
//     } catch (error) {
//       console.error("Error deleting note:", error);
//     }
//   };

//   toggel = () => {
//     this.setState({ editModal: !this.state.editModal })
//   }
//   render() {
//     const {
//       loading,
//       addNotes,
//       topic,
//       noteAddedBy,
//       designation,
//       mobileNumber,
//       promotionImageName,
//       files,
//       errors,
//       disabled,
//       notes,
//       addNotesShow,
//       listNotesShow,
//     } = this.state;
//     return (
//       <Fragment>
//         {loading && (
//           <div className="text-center w-100">
//             <CircularProgress />
//           </div>
//         )}
//         <div
//           style={{
//             padding: "20px 60px",
//             border: "1px solid grey",
//             boxShadow: "2px 2px 3px -3px",
//             margin: "20px",
//           }}
//         >
//           <button
//             onClick={() =>
//               this.setState({
//                 addNotesShow: !addNotesShow,
//                 listNotesShow: false,
//               })
//             }
//             className="notesTabs-btn"
//           >
//             Add Notes
//           </button>
//           {/* <button
//             onClick={() =>
//               this.setState({
//                 listNotesShow: true,
//                 addNotesShow: false,
//               })
//             }
//             className="notesTabs-btn"
//           >
//             Notes
//           </button> */}
//           {addNotesShow == true && (
//             <form className="mt-2" onSubmit={this.handleSubmit} noValidate>
//               <Row>
//                 <Col md="4" xs="12">
//                   <TEXTFIELD
//                     label="Topic"
//                     value={topic}
//                     name={"topic"}
//                     onChange={this.handleChange}
//                   />
//                 </Col>
//                 <Col md="4" xs="12">
//                   <TEXTFIELD
//                     label="Designation"
//                     value={designation}
//                     name={"designation"}
//                     onChange={this.handleChange}
//                   />
//                 </Col>
//                 <Col md="4" xs="12">
//                   <TEXTFIELD
//                     label="Mobile"
//                     value={mobileNumber}
//                     name={"mobileNumber"}
//                     onChange={this.handleChange}
//                     errors={errors}
//                   />
//                 </Col>
//                 <Col md="12" xs="12">
//                   <TEXTFIELD
//                     label="Notes"
//                     value={addNotes}
//                     name={"addNotes"}
//                     onChange={this.handleChange}
//                   />
//                 </Col>

//                 <Col md="6" xs="12">
//                   <TEXTFIELD
//                     label="Note Added By"
//                     value={noteAddedBy}
//                     name={"noteAddedBy"}
//                     onChange={this.handleChange}
//                   />
//                 </Col>

//                 <Col md="6" xs="12">
//                   <div class="file-upload-cus mt-4">
//                     <div class="file-select">
//                       <div class="file-select-button" id="fileName">
//                         Choose File
//                       </div>
//                       <div class="file-select-name" id="noFile">
//                         {promotionImageName
//                           ? promotionImageName
//                           : "No file chosen..."}
//                       </div>
//                       <div class="importcus">
//                         <input
//                           type="file"
//                           name={"files"}
//                           id="chooseFile"
//                           onChange={this.handleFile}
//                         // errors={errors}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md="3" xs="12">
//                   <SUBMIT title="Submit" disabled={disabled} />
//                 </Col>
//               </Row>
//             </form>
//           )}
//           <Modal
//             isOpen={this.state.editModal}
//             toggle={this.toggel}
//             size="md"
//             style={{ maxWidth: "700px", width: "100%" }}
//           >
//             <EditNotesclient
//               setopenEditNotes={this.toggel}
//               editTd={this.state.caseId}
//               noteIds={this.state.noteId}
//               editModal={this.state.editModal}
//               notesList={this.props.getNotes}
//             />
//           </Modal>
//           <div>
//             {this.props.notes &&
//               this.props.notes.length > 0 &&
//               this.props.notes.map((item, index) => {
//                 let id = item?.attachment[0]?.fileHashId;
//                 let noteBy = item?.attachment[0]?.fileName;
//                 console.log(item, id, noteBy, "1563240");
//                 return (
//                   <div
//                     className="notes-tab-list"
//                     style={{ whiteSpace: "pre-line" }}
//                     key={index}
//                   >
//                     <Card className="m-0">
//                       <CardBody className="row text-left">
//                         <CardText className="col-sm-4">
//                           <b>Topic </b>:{" "} {item?.description?.topic || ""}
//                         </CardText>
//                         <CardText className="col-sm-4">
//                           <b>Designation </b>:{" "}
//                           {item?.added_by?.designation || ""}
//                         </CardText>
//                         <CardText className="col-sm-4">
//                           <b>Mobile </b>:{" "} {item?.added_by?.mobileNumber || ""}
//                         </CardText>
//                         <CardText className="col-sm-4">
//                           <b>Notice Type </b>:{" "} {item?.description?.topic || ""}
//                         </CardText>
//                         <CardText className="col-sm-4">
//                           <b>Created At </b>:{" "}
//                           {moment(item?.created_at).format("DD.MM.YYYY") ||
//                             "--"}
//                         </CardText>

//                         <CardText className="col-sm-4">
//                           <b>Note Added By </b>:{" "}
//                           {item?.added_by?.noteAddedBy || ""}
//                         </CardText>
//                         <CardText className="col-sm-10">
//                           <b>Notes </b>:{" "} {item?.description?.addNotes || ""}

//                         </CardText>

//                         <CardText className="col-sm-2">
//                           <p className="order-name1 mx-2">
//                             {item?.attachment && item?.attachment?.length > 0 &&
//                               <i

//                                 onClick={() =>
//                                   this.downloadFile(item?.attachment)
//                                 }
//                                 style={{
//                                   fontSize: "25px",
//                                   cursor: "pointer",
//                                   marginTop: "4px",
//                                 }}
//                               ></i>
//                             }
//                             {/* {this.props.noteMessage === item?.created_by && ( */}
//                             <>
//                               <span>
//                                 <i
//                                   className="zmdi zmdi-edit"
//                                   style={{
//                                     fontSize: "20px",
//                                     marginLeft: "20px",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() =>
//                                     this.openEditModal(item.case_id, item.id)
//                                   }
//                                 />
//                               </span>

//                               <i
//                                 className="zmdi zmdi-delete delete-icn"
//                                 style={{
//                                   fontSize: "20px",
//                                   marginLeft: "20px",
//                                   cursor: "pointer",
//                                 }}
//                                 onClick={() =>
//                                   this.handleDeleteNotes(
//                                     item.case_id,
//                                     item.id
//                                   )
//                                 }
//                               />
//                             </>
//                             {/* )} */}
//                           </p>
//                         </CardText>
//                         {/* {id && 
//                         <CardText className="col-sm-2">
//                           <p className="order-name1 mx-2">
//                             <i
//                               className="zmdi zmdi-download"
//                               onClick={() => this.downloadFile(id,item?.added_by?.noteAddedBy +
//                                       "_" + noteBy)}
//                               style={{
//                                 fontSize: "25px",
//                                 cursor: "pointer",
//                               }}
//                             ></i>
//                           </p>
//                         </CardText>
//                       } */}
//                       </CardBody>
//                     </Card>
//                   </div>
//                 );
//               })}
//           </div>
//         </div>
//       </Fragment>
//     );
//   }
// }

// export default withRouter(UploadFile);
import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { API_URL } from "../../../../../../../ApiUrl";

import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  Card,
  CardBody,
  CardText,
  moment,
} from "../../../../../../../components";
import EditNotesclient from "./EditNotesclient"

const { API_ADD_CLIENT_NOTES, Numeric, DELETE_NOTES } = ApiUrl;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      listId: this.props.listId,
      process: this.props.role,
      addNotes: "",
      topic: "",
      noteAddedBy: "",
      designation: "",
      mobileNumber: "",
      files: [],
      caseId: "",
      noteId: "",
      notes: "",
      editModal: false,
      notesList: this.props.notes,
      addNotesShow: false,
      listNotesShow: false,
      promotionImageName: "",
      id: this.props.id,
      errors: {
        files: "",
      },
      disabled: true,
      loading: false,
    };
  }


  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "addNotes":
        errors.addNotes = value.length < 1 ? "Required" : "";
        break;
      case "topic":
        errors.topic = value.length < 1 ? "Required" : "";
        break;

      case "mobileNumber": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }

          if (value.length <= 9 && value.length >= 0) {
            err = "Minimum 10 digits required";
          }
        }
        errors.mobileNumber = err;

        break;
      }

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();

    const { errors } = this.state;
    let files = Array.from(event.target.files); // Convert FileList to an array
    let newErr = { ...errors, files: "" };

    this.setState(
      {
        files: files,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );

    // If you want to store the names of the files, you can do so like this:
    let fileNames = files.map((file) => file.name).join(", ");
    this.setState({ promotionImageName: fileNames });
  };

  downloadFile = async (files) => {
    try {
      console.log(files, "n,mvn,fkjsf before")

      for (let i = 0; i < files.length; i++) {
        console.log("n,mvn,fkjsf")
        const file = files[i];
        const url = `${API_URL}/get-file/link/${file.fileHashId}`;
        const response = await fetch(url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", file.fileName); // Set custom file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });

    const {
      addNotes,
      topic,
      noteAddedBy,
      designation,
      mobileNumber,
      files,
      errors,
    } = this.state;

    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      addNotes: addNotes,
      topic: topic,
      designation: designation,
      mobileNumber: mobileNumber,
      // noteAddedBy: noteAddedBy,
    };

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    let url = API_ADD_CLIENT_NOTES;

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    // Append each file individually
    files.forEach((file, index) => {
      formData.append(`files[]`, file);
    });

    Axios.post(url + this.props.id, formData)
      .then((res) => {
        this.setState({ loading: false, disabled: false });

        if (res.data.success === true) {
          toast.success(res.data.message);
          this.setState({
            addNotes: "",
            topic: "",
            noteAddedBy: "",
            designation: "",
            mobileNumber: "",
            promotionImageName: "",
            files: [],
            addNotesShow: false
          });
          setTimeout(() => {
            // window.location.reload();
            this.props.getNotes();
          }, 2000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error, "res.message");
        toast.error("An error occurred while uploading the files.");
        this.setState({ loading: false, disabled: false });
      });
  };

  handleDeleteNotes = async (caseId, noteId) => {
    try {
      const response = await Axios.Get(`${DELETE_NOTES}/${caseId}/${noteId}`);
      console.log(
        response.data.success === true,
        response.data.success,
        response.data,
        "mnbmbnmbn"
      );
      if (response.data.success === true) {
        console.log("checking");
        this.props.getNotes();
        toast.success("Note deleted successfully.");
        setNotes((prevNotesData) => ({
          ...prevNotesData,
          notesList: prevNotesData.data.filter((note) => note.id !== noteId),
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };
  openEditModal = (caseId, noteId) => {
    console.log(caseId, noteId, "checking no");
    this.setState({ caseId: caseId });
    this.setState({ noteId: noteId });
    this.setState({ editModal: true });
  };

  toggel = () => {
    this.setState({ editModal: !this.state.editModal })
  }

  render() {
    const {
      loading,
      addNotes,
      topic,
      noteAddedBy,
      designation,
      mobileNumber,
      promotionImageName,
      files,
      errors,
      disabled,
      notes,
      addNotesShow,
      listNotesShow,
    } = this.state;
    console.log(this.state.editModal, "this.props.notes");
    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            padding: "20px 60px",
            border: "1px solid grey",
            boxShadow: "2px 2px 3px -3px",
            margin: "20px",
          }}
        >
          <button
            onClick={() =>
              this.setState({
                addNotesShow: !addNotesShow,
                listNotesShow: false,
              })
            }
            className="notesTabs-btn"
          >
            Add Notes
          </button>
          {/* <button
            onClick={() =>
              this.setState({
                listNotesShow: true,
                addNotesShow: false,
              })
            }
            className="notesTabs-btn"
          >
            Notes
          </button> */}
          {addNotesShow == true && (
            <form className="mt-2" onSubmit={this.handleSubmit} noValidate>
              <Row>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Topic"
                    value={topic}
                    name={"topic"}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Designation"
                    value={designation}
                    name={"designation"}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col md="4" xs="12">
                  <TEXTFIELD
                    label="Mobile"
                    value={mobileNumber}
                    name={"mobileNumber"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="12" xs="12">
                  <TEXTFIELD
                    label="Notes"
                    value={addNotes}
                    name={"addNotes"}
                    onChange={this.handleChange}
                  />
                </Col>

                {/* <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Note Added By"
                    value={noteAddedBy}
                    name={"noteAddedBy"}
                    onChange={this.handleChange}
                  />
                </Col> */}

                <Col md="6" xs="12">
                  <div class="file-upload-cus mt-4">
                    <div class="file-select">
                      <div class="file-select-button" id="fileName">
                        Choose File
                      </div>
                      <div class="file-select-name" id="noFile">
                        {promotionImageName
                          ? promotionImageName
                          : "No file chosen..."}
                      </div>
                      <div class="importcus">
                        <input
                          className="my-3"
                          type="file"
                          onChange={this.handleFile}
                          multiple
                        />
                        {/* <input
                          type="file"
                          name="files"
                          id="chooseFile"
                          onChange={this.handleFile}
                          multiple
                          // errors={errors}
                        /> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3" xs="12">
                  <SUBMIT title="Submit" disabled={disabled} />
                </Col>
              </Row>
            </form>
          )}
          <Modal
            isOpen={this.state.editModal}
            toggle={this.toggel}
            size="md"
            style={{ maxWidth: "700px", width: "100%" }}
          >
            <EditNotesclient
              setopenEditNotes={this.toggel}
              editTd={this.state.caseId}
              noteIds={this.state.noteId}
              editModal={this.state.editModal}
              notesList={this.props.getNotes}
            />
          </Modal>
          <div>
            {this.props.notes &&
              this.props.notes.length > 0 &&
              this.props.notes.map((item, index) => {
                let id = item?.attachment[0]?.fileHashId;
                let noteBy = item?.attachment[0]?.fileName;
                console.log(item, item.unread_count, "1563240");
                return (
                  <div
                    className="notes-tab-list"
                    style={{ whiteSpace: "pre-line" }}
                    key={index}
                  >
                    <Card className="m-0">
                      <CardBody className="row text-left">
                        <CardText className="col-sm-4">
                          <b>Topic </b>: {item?.description?.topic || ""}
                        </CardText>
                        <CardText className="col-sm-4">
                          <b>Designation </b>:{" "}
                          {item?.added_by?.designation || ""}
                        </CardText>
                        <CardText className="col-sm-4">
                          <b>Mobile </b>: {item?.added_by?.mobileNumber || ""}
                        </CardText>
                        <CardText className="col-sm-4">
                          <b>Notice Type </b>: {item?.description?.topic || ""}
                        </CardText>
                        <CardText className="col-sm-4">
                          <b>Created At </b>:{" "}
                          {moment(item?.created_at).format("DD.MM.YYYY") ||
                            "--"}
                        </CardText>

                        <CardText className="col-sm-4">
                          <b>Note Added By </b>:{" "}
                          {item?.added_by?.noteAddedBy || ""}
                        </CardText>
                        <CardText className="col-sm-10">
                          <b>Notes </b>: {item?.description?.addNotes || ""}
                        </CardText>
                        {/* {id && ( */}
                        <CardText className="col-sm-2">
                          <p className="order-name1 mx-2">
                            {item?.attachment && item?.attachment?.length > 0 &&
                              <i
                                className="zmdi zmdi-download"
                                onClick={() =>
                                  this.downloadFile(item?.attachment)
                                }
                                style={{
                                  fontSize: "25px",
                                  cursor: "pointer",
                                  marginTop: "4px",
                                }}
                              ></i>
                            }
                            {/* {this.props.noteMessage === item?.created_by && ( */}
                            <>
                              <span>
                                <i
                                  className="zmdi zmdi-edit"
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.openEditModal(item.case_id, item.id)
                                  }
                                />
                              </span>

                              <i
                                className="zmdi zmdi-delete delete-icn"
                                style={{
                                  fontSize: "20px",
                                  marginLeft: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleDeleteNotes(
                                    item.case_id,
                                    item.id
                                  )
                                }
                              />
                            </>
                            {/* )} */}
                          </p>
                        </CardText>
                        {/* )} */}
                      </CardBody>
                    </Card>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(UploadFile);
