import React, { Fragment, useState, useRef } from "react";
import "./landingPage.css";
import HomePage from "../../../assets/images/HomePage.png"
import HomePage1 from "../../../assets/images/Home1.png"
import HomePage2 from "../../../assets/images/Home2.png"
import HomeCarsousel from "./HomeCarsousel";

const Landingpage = () => {
  const contactFormRef = useRef(null);


  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Fragment>
      <div className="container-fluid   truce-cal-heading">
        <div className="container-lg">
          <div className="row">

            <div className="col-md-6 hero-banner-Embrace-sec py-3 d-flex justify-content-center align-items-center">
              <span>
                <p className="embrace">
                  Embrace the Power of Online Dispute Resolution (ODR) Anytime,
                  Anywhere
                </p>
                <div className="hero-banner-contact-us">
                  <button onClick={scrollToContactForm}>Contact Us</button>
                </div>
              </span>
            </div>
            <div className="col-md-6 hero-banner-Logo-sec py-3 d-flex justify-content-center align-items-center">
              <div className="justice-serve">
                <span>
                  <img src={HomePage} alt="" style={{ width: "100%", height: "100%" }} />
                  <p>
                    <i>Justice Served , Justly Settled</i>
                  </p>
                </span></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-width content-home-page">
        <div className="row">
          <div className="col-md-6 bordercls d-flex justify-content-center align-items-center">
            <img src={HomePage1} alt="Image" />
          </div>
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-12 easy-affordable-section bordercls">
                <h2 className="easy-affordable-head-color">Easy and affordable</h2>
                <p className="easy-parrah">
                  Do you hesitate from pursuing justice in everyday conflicts out of
                  concern aboout high legal bills, wasted energy, high stress levels,
                  and disrupted connections with others?
                </p>
              </div>
              <div className="col-12 revolutionize-section bordercls">
                <p className="revolutionize-text">
                  If Yes, then JustSettle is here to help you. We revolutionize
                  dispute resolution by integrating traditional ODR methods with
                  cutting-edge technology and provide legally enforceable solutions
                  for businesses of all sizes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid content-home-page">
        <div className="row">
          <div className="col-md-6 just-settle-offers bordercls d-flex justify-content-center align-items-center ">
            <div className="col-lg-8 col-md-10">
              <h1 className="just-offers-head">JustSettle offers</h1>
              <p className="just-offers-parrah">
                Whether you're a startup or an international powerhouse, Justsettle
                offers a seamless, legal and tech-driven alternative dispute
                resolution experience that's efficient and planet friendly
              </p>
              <div className="pt-3">
                <button className="btn-1">
                  <p className="btn-text"> Read More</p>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 bordercls d-flex justify-content-center align-items-center py-5">
            <img src={HomePage2} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid owlcarousel-sec-classss">
        <HomeCarsousel />
      </div>

      <div className="container-fluid content-home-page  pt-3">
        <div className="container px-4 pt-5">
          <div className="row justify-content-center pb-5">
            <div className="col-md-8 text-center">
              <h2 className="why-choose">Why Choose Us</h2>
              <p className="text-muted" >
                At JustSettle, we pride ourselves on  offering an efficient, cost-effective, and environmentally sustainable  approach to dispute resolution. Here's why you should choose us:
              </p>
            </div>
          </div>
          <div className="row flip-card-row pt-3">
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">Fast and Fair</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">Expert Neutrals</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">Legal compliance</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
          </div>
          <div className="row flip-card-row flip-card-row">
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front front-secondrow">
                  <div className="center">Customized solutions</div>
                </div>
                <div className="back back-secondrow">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front front-secondrow">
                  <div className="center">Secure and Private</div>
                </div>
                <div className="back back-secondrow">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front front-secondrow">
                  <div className="center">Real Time Tracking</div>
                </div>
                <div className="back back-secondrow">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
          </div>
          <div className="row flip-card-row flip-card-row">
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">Real Time Tracking</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">24x7 Access</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
            <div className="col-md-4 position-relative">
              <div className="boxx-why-choose">
                <div className="front">
                  <div className="center">Green Justice</div>
                </div>
                <div className="back">
                  A user-friendly interface that consolidated and display key
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5" >
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row no-gutters">
              <div className="col-md-4 feature-box">
                <h4>01</h4>
                <h5 className="py-2 fs-4 px-2 heading-mediation" style={{ color: "goldenrod" }}>
                  Personalized Dashboard
                </h5>
                <p className="text-center imagine">
                  A user-friendly interface that consolidates and displays key information, tools, and resources, allowing individuals to manage their tasks, data, and activities efficiently.
                </p>
              </div>
              <div className="col-md-4 feature-box ">
                <h4>02</h4>
                <h5 className="text-dark fs-4 px-2 py-2 heading-mediation">Virtual Hearing Room</h5>
                <p className="delicate">
                  An online platform that simulates a physical courtroom, enabling participants to conduct processes by neutrals, remotely via video conferencing technology.
                </p>
                <div className="step-arrow" />
              </div>
              <div className="col-md-4 feature-box">
                <h4>03</h4>
                <h5 className="py-2 fs-4 px-2 heading-mediation" style={{ color: "goldenrod" }}>
                  e-Notice
                </h5>
                <p className="text-center imagine">
                  An electronic notification sent via email or other digital means informing recipients about important information, updates, or required actions.
                </p>
                <div className="step-arrow" />
              </div>
              <div className="col-md-4 feature-box">
                <h4>04</h4>
                <h5 className="text-dark fs-4 px-2 py-2 heading-mediation">e-Stamp</h5>
                <p className="delicate">
                  A digital version of a traditional paper stamp used for authenticating documents and transactions, ensuring legal validity and reducing the risk of fraud.
                </p>
                <div className="step-arrow d-md-none" />
              </div>
              <div className="col-md-4 feature-box">
                <h4>05</h4>
                <h5 className="py-2 fs-4 px-2 heading-mediation" style={{ color: "goldenrod" }}>
                  Digital Signature
                </h5>
                <p className="text-center imagine">
                  An encrypted electronic signature that verifies the authenticity and integrity of a digital document or message, serving as a secure and legally recognized equivalent to a handwritten signature.
                </p>
                <div className="step-arrow" />
              </div>
              <div className="col-md-4 feature-box">
                <h4>06</h4>
                <h5 className="text-dark fs-4 px-2 py-2 heading-mediation">e-Certified Copies</h5>
                <p className="delicate">
                  Digitally authenticated copies of official documents that are verified for their accuracy and legitimacy, typically used for legal and administrative purposes.
                </p>
                <div className="step-arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={contactFormRef} className="container-fluid content-home-page">
        <div className="contact-section">
          <div className="contact-image" />
          <div className="contact-form">
            <h2>GET IN TOUCH WITH US</h2>
            <form>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputFirstName">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstName"
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputLastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLastName"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPhone">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPhone"
                    placeholder="Enter your phone no"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputMessage">Your Message</label>
                <textarea
                  className="form-control"
                  id="inputMessage"
                  rows={4}
                  placeholder="Enter your message here"
                  defaultValue={""}
                />
              </div>
              <div className="contact-us-btn">
                <button type="submit" className="btn-contact-us">
                  Contact  us &nbsp;<span><i className="zmdi zmdi-arrow-right"></i></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Landingpage;
