import React, { Fragment, useState } from "react";

import {
  Link,
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  TEXTFIELD,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  Loading,
  moment,
  CHECKBOX,
} from "../../../../../components";
import Select from "react-select";
import { range } from "lodash";

const data = [
  {
    value: "LAN",
    count: "40",
    total: "40",
    id: 12,
  },
  {
    value: "LRN",
    count: "24",
    total: "30",
    id: 22,
  },
  {
    value: "Stage 1",
    count: "4",
    total: "14",
    id: 10,
  },
];

const {
  API_GET_USER_DETAIL,
  API_ADD_SUPER_USER,
  EMAIL_REGEX,
  API_EDIT_USER,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
  API_GET_ALL_ROLES,
  API_GET_ALL_SUPER_PERMISSIONS,
  API_SUPER_USER_LIST,
  API_GET_ALL_STATES,
  API_GET_COUNTRIES,
  API_GET_STATES,
  API_GET_CITIES,
  API_GET_SUP_CLIENT_LIST,
  API_GET_CLIENT_LOT,
  API_ASSIGN_NEW_CASE_MANAGER,
  API_GET_CLIENT_LOT_DATA,
  API_GET_CASES_LOT,
  Numeric,
} = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      clientList: "",
      state: "",
      lawfirmList: [],
      lawfirm: [],
      client: [],
      lotList: [],
      caseList: [],
      newCaseList: [],
      case_manager: "",
      new_case_manager: "",
      lot: [],
      lotList: [],
      totalCount: "",
      countryStates: [],
      casesData: [],
      lot_id: [],
      lotDetails: [],
      checkedLots: [],
      isChecked: false,
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process, caseTypeE, clientE, lawfirm } = this.state;
    console.log(lawfirm, "caseTypeE,clientE");
    // this.setState({ loading: true });

    if (process == "edit" && editId) {
      Axios.get(API_GET_USER_DETAIL + editId)
        .then((response) => {
          var data = response?.data?.data;
          if (data.success === false) {
            toast.error(response.data.message);
            return;
          }

          this.setState({
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            roleList: roles,
            status: data?.status,
          });
        })
        .catch((err) => {
          console.log(err, 66565);
        });
    }

    Axios.Post(API_SUPER_USER_LIST + "/Lawfirm")
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response?.data?.total,
          loading: false,
        });
        const lawfirmData = response?.data?.users?.map((c) => {
          return { value: c.id, label: c.name };
        });
        this.setState({ lawfirmList: lawfirmData });
      })
      .catch((err) => {
        console.log("");
        this.setState({ loading: false, lawfirmList: [] });
      });
    Axios.Post(API_SUPER_USER_LIST + "/casemanager")
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response?.data?.total,
          loading: false,
        });
        const caseData = response?.data?.users?.map((c) => {
          return { [c.id]: c.name };
        });
        this.setState({ caseList: caseData || [] });
      })
      .catch((err) => {
        console.log("");
        this.setState({ loading: false, caseList: [] });
      });

    Axios.get(API_GET_STATES + 1)
      .then((res) => {
        console.log(res);
        const { data } = res.data;

        const states = data.map((c) => {
          return { value: c.id, label: c.name };
        });

        this.setState({
          countryStates: states,
        });
      })
      .catch((err) => {
        console.log(err, 66565);
      });

    this.setState({ loading: false });
  }

  handleChangeKey = (selectedOptions) => {
    // Check if 'All' is selected
    const allOption = { value: "ALL", label: "All" };

    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options
      if (selectedOptions.length === 1) {
        // If 'All' is the only selected option, select all options
        this.setState({ lawfirm: [allOption, ...this.state.lawfirmList] });
      } else {
        // If 'All' is selected along with other options, deselect 'All'
        this.setState({
          lawfirm: selectedOptions.filter((option) => option.value !== "ALL"),
        });
      }
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ lawfirm: selectedOptions });
    }

    setTimeout(() => {
      console.log(this.state.lawfirm, "lawfirmlawfirm");
      const data = {
        lawfirm_ids: this.state.lawfirm.map((i) => i.value),
      };
      Axios.Post(API_GET_SUP_CLIENT_LIST, data)
        .then((res) => {
          console.log(res);
          const { status, data } = res;

          const clients = data.map((c) => {
            return { value: c.id, label: c.name };
          });

          this.setState({
            clientList: clients,
          });
        })
        .catch((err) => {
          this.setState({
            clientList: [],
            client: "",
            lot: "",
            lotDetails: [],
            state: "",
            case_manager: "",
            assign_case: "",
          });
        });
    }, 100);
  };
  handleChangeClient = (selectedOptions) => {
    // Check if 'All' is selected
    const allOption = { value: "ALL", label: "All" };

    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options
      if (selectedOptions.length === 1) {
        // If 'All' is the only selected option, select all options
        this.setState({ client: [allOption, ...this.state.clientList] });
      } else {
        // If 'All' is selected along with other options, deselect 'All'
        this.setState({
          client: selectedOptions.filter((option) => option.value !== "ALL"),
        });
      }
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ client: selectedOptions });
    }

    setTimeout(() => {
      const data = {
        client_ids: this.state.client.map((i) => i.value),
      };
      Axios.Post(API_GET_CLIENT_LOT, data)
        .then((res) => {
          console.log(res);
          const { status, data } = res?.data;

          const lots = data?.map((c) => {
            return { value: c.id, label: c.title };
          });
          if (res?.data.success == true) {
            this.setState({
              lotList: lots || [],
            });
          } else {
            this.setState({
              lotList: [],
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching lot data:", error);
          this.setState({
            lotList: [],
          });
        });
    }, 100);
  };
  handleChangeLot1 = (selectedOptions) => {
    // Check if 'All' is selected
    const allOption = { value: "ALL", label: "All" };

    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options
      if (selectedOptions.length === 1) {
        // If 'All' is the only selected option, select all options
        this.setState({ lot: [allOption, ...this.state.lotList] });
      } else {
        // If 'All' is selected along with other options, deselect 'All'
        this.setState({
          lot: selectedOptions.filter((option) => option.value !== "ALL"),
        });
      }
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ lot: selectedOptions });
    }
    setTimeout(() => {
      console.log(this.state.client, "lawfirmlawfirm");
      const data = {
        lot_ids: this.state.lot.map((i) => i.value),
      };
      Axios.Post(API_GET_CLIENT_LOT_DATA, data).then((res) => {
        if (res?.data?.success == true) {
          this.setState({
            lotDetails: res?.data?.data || [],
          });
        } else {
          this.setState({
            lotDetails: [],
          });
        }
      });
    }, 100);
  };

  handleChangeLot = (selectedOptions) => {
    const allOption = { value: "ALL", label: "All" };

    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options
      if (selectedOptions.length === 1) {
        // If 'All' is the only selected option, select all options
        const selectedValues = this.state.lotList.map((option) => option.value);
        this.setState({ lot: [allOption, ...this.state.lotList] });

        // Perform asynchronous operation after state update
        setTimeout(() => {
          const data = {
            lot_ids: selectedValues, // Use selectedValues array here
          };
          Axios.post(API_GET_CLIENT_LOT_DATA, data)
            .then((res) => {
              if (res?.data?.success) {
                this.setState({
                  lotDetails: res.data.data || [],
                });
              } else {
                this.setState({
                  lotDetails: [],
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching lot data:", error);
              this.setState({
                lotDetails: [],
              });
            });
        }, 100);

        return selectedValues; // Return selectedValues array
      } else {
        // If 'All' is selected along with other options, deselect 'All'
        const filteredOptions = selectedOptions.filter(
          (option) => option.value !== "ALL"
        );
        this.setState({ lot: filteredOptions });

        // Perform asynchronous operation after state update
        setTimeout(() => {
          console.log(this.state.client, "lawfirmlawfirm");
          const data = {
            lot_ids: filteredOptions.map((option) => option.value),
          };
          Axios.post(API_GET_CLIENT_LOT_DATA, data)
            .then((res) => {
              if (res?.data?.success) {
                this.setState({
                  lotDetails: res.data.data || [],
                });
              } else {
                this.setState({
                  lotDetails: [],
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching lot data:", error);
              this.setState({
                lotDetails: [],
              });
            });
        }, 100);

        return filteredOptions.map((option) => option.value); // Return filtered values array
      }
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ lot: selectedOptions });

      // Perform asynchronous operation after state update
      setTimeout(() => {
        console.log(this.state.client, "lawfirmlawfirm");
        const data = {
          lot_ids: selectedOptions.map((option) => option.value),
        };
        Axios.post(API_GET_CLIENT_LOT_DATA, data)
          .then((res) => {
            if (res?.data?.success) {
              this.setState({
                lotDetails: res.data.data || [],
              });
            } else {
              this.setState({
                lotDetails: [],
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching lot data:", error);
            this.setState({
              lotDetails: [],
            });
          });
      }, 100);

      return selectedOptions.map((option) => option.value); // Return selected values array
    }
  };

  handleChangeState = (selectedOptions) => {
    // Check if 'All' is selected
    const allOption = { value: "ALL", label: "All" };

    // Check if the 'All' option is in the selectedOptions
    if (selectedOptions.some((option) => option.value === "ALL")) {
      // If 'All' is selected, select/deselect all options
      if (selectedOptions.length === 1) {
        // If 'All' is the only selected option, select all options
        this.setState({ state: [allOption, ...this.state.countryStates] });
      } else {
        // If 'All' is selected along with other options, deselect 'All'
        this.setState({
          state: selectedOptions.filter((option) => option.value !== "ALL"),
        });
      }
    } else {
      // If 'All' is not selected, update the state normally
      this.setState({ state: selectedOptions });
    }

    setTimeout(() => {
      console.log(this.state.client, "lawfirmlawfirm");
      const data = {
        lot_ids: selectedOptions.map((option) => option.value),
      };
      Axios.post(API_GET_CASES_LOT, data)
        .then((res) => {
          if (res?.data?.success) {
            this.setState({
              lotDetails: res.data.data || [],
            });
          } else {
            this.setState({
              lotDetails: [],
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching lot data:", error);
          this.setState({
            lotDetails: [],
          });
        });
    }, 100);
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let finalVal = value;
    switch (name) {
      case "role":
        errors.role = value.length < 1 ? "Required" : "";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });

    setTimeout(() => {
      console.log(this.state.case_manager, "lawfirmlawfirm");

      Axios.Get(API_GET_CASES_LOT + this.state.case_manager)
        .then((res) => {
          if (res?.data?.success) {
            this.setState({
              lotDetails: res.data.data || [],
            });
          } else {
            this.setState({
              lotDetails: [],
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching lot data:", error);
          this.setState({
            lotDetails: [],
          });
        });
    }, 100);

    setTimeout(() => {
      const selectedValue = this.state.caseList.find(
        (item) => Object.keys(item)[0] === this.state.case_manager
      );
      const caseData = this.state.caseList.filter(
        (item) => Object.keys(item)[0] !== this.state.case_manager
      );
      this.setState({ newCaseList: caseData });
      console.log(selectedValue, caseData, "remainingData");
    }, 1000);
  };

  redirectPage = () => {
    this.reset();
  };

  handleClick = (e, id) => {
    const { isCheck } = this.state;
    const isChecked = isCheck.includes(id);

    if (isChecked) {
      this.setState((prevState) => ({
        isCheck: prevState.isCheck.filter((item) => item !== id),
      }));
    } else {
      this.setState((prevState) => ({
        isCheck: [...prevState.isCheck, id],
      }));
    }
  };

  handleClickPermission = (e, id) => {
    const { isPermissioncheck } = this.state;
    const isChecked = isPermissioncheck.includes(id);

    if (isChecked) {
      this.setState((prevState) => ({
        isPermissioncheck: prevState.isPermissioncheck.filter(
          (item) => item !== id
        ),
      }));
    } else {
      this.setState((prevState) => ({
        isPermissioncheck: [...prevState.isPermissioncheck, id],
      }));
    }
  };

  handleCaseChange1 = (e, index) => {
    const { name, value } = e.target;
    const newData = [...data];
    newData[index][name] = value;
    console.log(newData, "newData");
    this.setState({
      assign_case: newData.assign_case,
    });
  };

  handleCaseChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target, "eeeeeeeeeeeeeeeeee");
    this.setState({
      [name]: value,
    });
    this.setState({ assign_case: value });
  };

  handleSubmit1 = (e) => {
    e.preventDefault();
    const {
      new_case_manager,
      case_manager,
      lotDetails,
      checkedLots,
    } = this.state;

    const ids = lotDetails.map((item, index) => item.id);
    this.setState({ lot_id: ids });
    this.setState({ loading: true });
    setTimeout(() => {
      const data1 = {
        assignment_ids: checkedLots,
        case_manager: case_manager,
        new_case_manager: new_case_manager,
      };
      Axios.Post(API_ASSIGN_NEW_CASE_MANAGER, data1)
        .then((res) => {
          console.log(res, "reskjkj");
          let response = res.data;
          this.setState({ loading: false, disabled: false });

          if (response.status === 200) {
            toast.success(response.message);
            setTimeout(() => {
              this.redirectPage();
            }, 1000);
          } else {
            toast.success(response.message);
            this.redirectPage();
          }
        })
        .catch((error) => {
          let res = error.response;
          console.log(error, res.message, "res.message");
          toast.error(res.message);
          this.setState({ loading: false, disabled: false });
        });
    }, 1000);
  };

  handleCheckboxChange = (lotId) => {
    const { checkedLots } = this.state;
    console.log(lotId, "lotId");
    const currentIndex = checkedLots.indexOf(lotId);
    const newCheckedLots = [...checkedLots];

    if (currentIndex === -1) {
      // Add the lot ID to checkedLots if it's not already present
      newCheckedLots.push(lotId);
    } else {
      // Remove the lot ID from checkedLots if it's already present
      newCheckedLots.splice(currentIndex, 1);
    }

    // Update the state with the new array of checked lots
    this.setState({ checkedLots: newCheckedLots });
  };

  reset = () => {
    this.setState({
      lawfirm: "",
      state: "",
      city: "",
      status: [],
      assign_case: [],
      case_manager: "",
      client: [],
      lot: [],
      lotDetails: [],
      new_case_manager: "",
      errors: {
        lawfirm: "",
        state: "",
        city: "",
        status: [],
        assign_case: [],
        case_manager: "",
        client: [],
        lot: [],
        lotDetails: [],
      },
      disabled: true,
    });
  };

  render() {
    const {
      errors,
      disabled,
      loading,
      clientList,
      state,
      lawfirmList,
      lawfirm,
      client,
      lot,
      lotList,
      caseList,
      new_case_manager,
      cases,
      countryStates,
      assign_case,
      casesData,
      case_manager,
      lot_id,
      lotDetails,
      isChecked,
      checkedLots,
      newCaseList,
    } = this.state;

    const lawfirmListWithAll = [
      this.state.lawfirmList == ""
        ? { value: "None", label: "None" }
        : { value: "ALL", label: "All" },
      ...this.state.lawfirmList,
    ];
    const clientListWithAll = [
      this.state.clientList == ""
        ? { value: "None", label: "None" }
        : { value: "ALL", label: "All" },
      ...this.state.clientList,
    ];
    const lotListWithAll = [
      this.state.lotList == ""
        ? { value: "None", label: "None" }
        : { value: "ALL", label: "All" },
      ...this.state.lotList,
    ];
    const stateListWithAll = [
      this.state.countryStates == ""
        ? { value: "None", label: "None" }
        : { value: "ALL", label: "All" },
      ...this.state.countryStates,
    ];

    console.log(newCaseList, "lawfirmList");
    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        {/* <p className="p-2">
          <Link to="/app/users/list" className="back-btn-arw1">
            <i class="zmdi zmdi-long-arrow-return"></i>
          </Link>
        </p> */}
        <div className="min-h4">
          <form onSubmit={this.handleSubmit1} noValidate>
            <Row>
              {/* <Col md="4" xs="12" className="mt-4">
                <span>Lawfirm</span>
                <Select
                  isMulti
                  value={lawfirm}
                  name={"lawfirm"}
                  options={lawfirmListWithAll}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChangeKey}
                />
              </Col>
              <Col md="4" xs="12" className="mt-4">
                <span>Client</span>
                <Select
                  isMulti
                  value={client}
                  name={"client"}
                  options={clientListWithAll}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChangeClient}
                />
              </Col>
              <Col md="4" xs="12" className="mt-4">
                <span>Lot</span>
                <Select
                  isMulti
                  value={lot}
                  name={"lot"}
                  options={lotListWithAll}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChangeLot}
                />
              </Col>
              <Col md="4" xs="12" className="mt-4">
                <span>State</span>
                <Select
                  isMulti
                  value={state}
                  name={"state"}
                  options={stateListWithAll}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChangeState}
                />
              </Col> */}

              <Col md="4" xs="12" className="my-3">
                <SELECT
                  label="Case Manager"
                  value={case_manager}
                  name={"case_manager"}
                  onChange={this.handleChange}
                  data={caseList}
                />
              </Col>
              <Col md="4" xs="12" className="my-3">
                <SELECT
                  label="New Manager"
                  value={new_case_manager}
                  name={"new_case_manager"}
                  onChange={this.handleChange}
                  data={newCaseList}
                />
              </Col>
            </Row>
            {lotDetails.length > 0 && (
              <Row>
                <Col md="10" xs="12" className="my-3">
                  <table class="table table-responsive table-striped mt-3 table-box">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Lot</th>
                        <th scope="col">Assign Case</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lotDetails &&
                        lotDetails?.map((i, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div class="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={checkedLots.includes(i.id)}
                                    onChange={() =>
                                      this.handleCheckboxChange(i.id)
                                    }
                                    id={`flexCheckDefault-${index}`}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  ></label>
                                </div>
                              </td>
                              <td>{i?.lot?.title}</td>
                              <td>
                                {i.start_range} to {i.end_range}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}

            <Row className="my-3">
              <Col md="2" xs="12">
                <SUBMIT title="Submit" disabled={false} />
              </Col>
            </Row>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
