// // import React, { Fragment, useState } from "react";
// // import { Link } from "react-router-dom";

// // const Header = () => {
// //   return (
// //     <Fragment>
// //       <nav className="navbar fixed-top landing-page-navbar">
// //         <div className="container-fluid landing-page-header">
// //           <Link to="/home" className="landing-page-logo">
// //             <img
// //               src={require("../../../assets/images/logo1-old.png")}
// //               alt="logo"
// //               className="imageClass"
// //             />
// //           </Link>
// //           <div>
// //             <div className="">
// //               <ul className="landing-page-ul">
// //                 <Link to="/home" className="login-conariner-right">
// //                   <li>Home</li>
// //                 </Link>
// //                 <Link to="/aboutus" className="login-conariner-right">
// //                   <li>About Us</li>
// //                 </Link>
// //                 <Link to="/services" className="login-conariner-right">
// //                   <li>Service</li>
// //                 </Link>
// //                 <Link to="/signin" className="login-conarine">
// //                   <li>Login</li>
// //                 </Link>
// //               </ul>
// //             </div>
// //           </div>
// //         </div>
// //       </nav>
// //     </Fragment>
// //   );
// // };

// // export default Header;

// import React, { useEffect, useState } from 'react';
// import { Link, withRouter } from "react-router-dom";
// import "./navbar.css";

// const Header = ({ location }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [scrollBackground, setScrollBackground] = useState(false);
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const isActive = (path) => {
//     return location.pathname === path ? 'active' : '';
//   };
//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 0) {
//         setScrollBackground(true);
//       } else {
//         setScrollBackground(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//   return (
//     <div className='header'>
//       <div className='container-fluid'>
//         <div className='continer'>
//           <div className='row' style={{ justifyContent: "center" }}>
//             <div className={`text-center header-fix ${scrollBackground ? 'scroll-background' : ''}`}>

//               <Link to="/home" className="landing-page-logo">
//                 <img
//                   src={require("../../../assets/images/logo11.png")}
//                   alt="logo"
//                   className="imageClass"
//                 />
//               </Link>
//               <button className="toggle-button" onClick={toggleMenu}>
//                 &#9776;
//               </button>
//               <ul className={`landing-page-ul ${isOpen ? "show" : ""}`}>
//                 <Link to="/home" className={`login-conariner-rights ${isActive('/home')}`}>
//                   <li>Home</li>
//                 </Link>
//                 <Link to="/aboutus" className={`login-conariner-rights ${isActive('/aboutus')}`}>
//                   <li>About Us</li>
//                 </Link>
//                 <Link to="/services" className={`login-conariner-rights ${isActive('/services')}`}>
//                   <li>Service</li>
//                 </Link>
//                 <Link to="/privacy" className={`login-conariner-rights ${isActive('/privacy')}`}>
//                   <li>Truce Talks</li>
//                 </Link>
//                 <Link to="/#" className="login-conariner-rights mx-3 link-img">
//                   <li>
//                     <span>
//                       <img
//                         src={require("../../../assets/images/link.png")}
//                         alt="logo"
//                         className="linkimageClass"
//                       />
//                     </span> Web App
//                   </li>
//                 </Link>
//                 <Link to="/signin" className={`login-conarine ${isActive('/signin')}`}>
//                   <li>
//                     <button className='login-register-btn'>Login/Register</button>
//                   </li>
//                 </Link>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div >
//   );
// }

// export default withRouter(Header);

import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import "./navbar.css";

const Header = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };
  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event detected!');
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {

    window.addEventListener('scroll', function () {
      console.log('Scroll event detected!');
    });
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      console.log('Scroll position:', scrollPosition); // Debug log for scroll position
      if (scrollPosition > 0) {
        setScrollBackground(true);
        console.log('Set background to true'); // Debug log for setting background
      } else {
        setScrollBackground(false);
        console.log('Set background to false'); // Debug log for resetting background
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <div className='container-fluid'>
      <div className='continer'>
        <div className='row' style={{ justifyContent: "center" }}>
          <div id="header1" className={`text-center header-fix ${scrollBackground ? 'scroll-background' : ''}`}>
            <Link to="/home" className="  landing-page-logo">
              <img
                src={require("../../../assets/images/logo11.png")}
                alt="logo"
                className="imageClass"
              />
            </Link>
            <button className="toggle-button" onClick={toggleMenu}>
              &#9776;
            </button>
            <ul className={` landing-page-ul list-header ${isOpen ? "show" : ""}`}>
              <Link to="/home" id="home" className={`login-conariner-rights ${isActive('/home')}`}>
                <li>Home</li>
              </Link>
              <Link to="/aboutus" id="aboutUs" className={`login-conariner-rights ${isActive('/aboutus')}`}>
                <li>About Us</li>
              </Link>
              <Link to="/services" id="service" className={`login-conariner-rights ${isActive('/services')}`}>
                <li>Service</li>
              </Link>
              {/*<Link to="/privacy" id="privacy" className={`login-conariner-rights ${isActive('/privacy')}`}>
                <li>Truce Talks</li>
              </Link> */}


              <Link to="/truce-talk" id="truce-talk" className={`login-conariner-rights ${isActive('/truce-talk')}`}>
                <li>Truce Talks</li>
              </Link>

              <li>
                <a
                  href="https://justsettle.in/webapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-phone-image-link web-app-link"
                  style={{ color: 'black' }} // Inline style for black color
                >
                  <span>
                    <img
                      src={require("../../../assets/images/link.png")}
                      alt="logo"
                      className="linkimageClass"
                    />
                  </span> Web App
                </a>
              </li>


              <Link to="/signin" className={`login-conarine ${isActive('/signin')}`}>
                <li>
                  <button className='login-register-btn'>Login/Register</button>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div >

  );
}

export default withRouter(Header);
