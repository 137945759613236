import React, { Fragment, useState } from "react";
import banner from "../../../assets/images/about.jpeg";
import choice from "../../../assets/images/choose.webp";
import Header from "./header";
import Footer from "./footer";
import "./section-about.css";

const Aboutus = () => {
  window.scroll(0, 0);
  const [show, setShow] = useState(false);
  return (
    <>
      <Header />
      <Fragment>

        <div className="container-fluid  truce-cal-heading">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12">
                <div class="truce-header">
                  <h1>About Us</h1>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="about-section">
          <div className="container px-lg-5">
            <div className="about-content px-lg-5 px-md-2">
              <h2>About Us</h2>
              <p className="interconnected-world">
                In today’s interconnected world, implementing legal systems with technology is not only wise, but also essential. At Just Settle, our goal is to transform the dispute resolution process by combining the traditional processes of Arbitration, Mediation, and Conciliation with modern technology.
              </p>
              <p className="interconnected-world">
                Imagine that we are the legal counterpart of the superheroes of the law, always on board to deliver technologically advanced solutions that adhere to the established guidelines of the UNCITRAL Rules for Arbitration, the Arbitration and Conciliation Act of 1996, and the latest laws such as the Mediation Act of 2023. We’ve got a global outlook because, let's face it, disputes don’t respect borders.

              </p>
              <p className="interconnected-world">
                Our secret model? Continuous improvement. We are always fine tuning our procedures to comply with national and international legal requirements. In this way, we can consistently fulfill the practical requirements of companies. We understand that being relevant in the ever-evolving business world is essential, which is why we maintain our approaches innovative, practical, affordable, and yes, even environmentally friendly.
              </p>
              <p className="interconnected-world">
                JustSettle is your go-to for a smooth, tech-driven alternative dispute resolution experience that’s easy on the wallet and the planet. We offer solutions that are legally enforceable and equipped to manage any situation, whether you're a small startup or an international powerhouse. So let us settle this with ‘Just Settle.’
              </p>
            </div>
          </div>
        </div>

        <div className="container my-5" >
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6 feature-box">
                  <h4>01</h4>
                  <h5 className="py-2 fs-4 px-2 heading-mediation" style={{ color: "goldenrod" }}>
                    Vision
                  </h5>
                  <p className="text-center imagine" style={{ fontSize: 20 }}>

                    To revolutionise global  dispute resolution by seamlessly integrating traditional methods with  cutting-edge technology, ensuring quick, fair, and environmentally  sustainable solutions accessible to all. At JustSettle, we envision a  world where resolving conflicts is as simple as a click, enabling  businesses and individuals to thrive in a harmonious, digitally advanced  environment.
                  </p>
                </div>
                <div className="col-md-6 feature-box justify-content-start">
                  <h4>02</h4>
                  <h5 className="text-dark fs-4 px-2 py-2 heading-mediation">Motto</h5>
                  <p className="delicate">
                    Innovating Justice - Sustainable Solutions, Seamless Resolutions
                  </p>
                  <div className="step-arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>

        { /* <div style={{ height: "60px" }}></div> */}

      </Fragment>
      <Footer />
    </>
  );
};

export default Aboutus;
