import React, { Fragment, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import "./privacy-policy.css"

const Privacy = () => {
  window.scroll(0, 0);
  const [show, setShow] = useState(false);

  return (
    <>
      <Header />
      <Fragment>
        <div className="container-fluid truce-cal-heading">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12">
                <div className="truce-header committed">
                  <h2 className="protecting ">
                    At Just Settle, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our online dispute resolution platform.
                  </h2>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container">
            <div className="row pt-5">
              <div className="col-12">
                <h3 style={{ textDecoration: "underline" }}><b>1. Information We Collect:</b></h3>
                <ul>
                  <li className="privacy-info "><strong >Personal Information:</strong> When you register an account with Just Settle, we may collect personal information such as your name, email address, contact details, and other information necessary to facilitate the dispute resolution process.</li>
                  <li className="privacy-info"><strong>Dispute Information:</strong> To facilitate the resolution process, we collect information related to the dispute, including but not limited to documents, messages, and other relevant details shared during the dispute resolution process.</li>
                  <li className="privacy-info"><strong>Usage Data:</strong> We may collect information about how you interact with our platform, including your IP address, device information, browser type, pages visited, and other usage data.</li>
                </ul>

                <h3 style={{ textDecoration: "underline" }}><b>2. Use of Information:</b></h3>
                <p className="board privacy-info">We use the information collected to facilitate the dispute resolution process, communicate with you about your case, provide customer support, and improve our platform's functionality and user experience. Your personal information may also be used for administrative purposes, such as billing, account management, and compliance with legal obligations.</p>

                <h3 style={{ textDecoration: "underline" }}><b>3. Data Security:</b></h3>
                <p className="board privacy-info">We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Information shared during the dispute resolution process is treated with the utmost confidentiality, in line with legal and ethical standards.</p>
                <p className="board privacy-info">Despite our best efforts, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

                <h3 style={{ textDecoration: "underline" }}><b>4. Disclosure of Information:</b></h3>
                <p className="board privacy-info">We may disclose your personal information to third-party service providers and partners who assist us in operating our platform, providing services to you, or conducting our business activities.</p>
                <p>We may also disclose your information when required by law, in response to legal process, or to protect our rights, property, or safety, or the rights, property, or safety of others.</p>

                <h3 style={{ textDecoration: "underline" }}><b>5. Your Choices:</b></h3>
                <p className="board privacy-info">You have the right to access, update, or delete your personal information held by Just Settle. You can do this by logging into your account settings or contacting us directly. You may also choose to opt-out of certain communications or marketing materials by following the instructions provided in such communications.</p>

                <h3 style={{ textDecoration: "underline" }}><b>6. Children's Privacy:</b></h3>
                <p className="board privacy-info">Just Settle is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18 years of age. If you become aware that a child has provided us with personal information without parental consent, please contact us, and we will take steps to remove such information from our systems.</p>

                <h3 style={{ textDecoration: "underline" }}><b>7. Changes to this Privacy Policy:</b></h3>
                <p className="board privacy-info">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. Your continued use of our platform after the posting of any modifications to the Privacy Policy will constitute your acceptance of such changes.</p>

                <h3 style={{ textDecoration: "underline" }}><b>8. Contact Us:</b></h3>
                <p className="board privacy-info mb-5">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at [contact email or address]. By using the Just Settle platform, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default Privacy;
