import React, { useState } from "react";

import {
  Row,
  Col,
} from "../../../../../../../components";

import FileExport from "../../../../../fileExport/";

const Filters = (props) => {
  const {
    fieldName,
    fieldValue,
    filterData,
    exportButton,
  } = props;
  const { file, url, params, columns } = exportButton;
  let disabled = !fieldName || !fieldValue ? true : false;
  return (
    <form onSubmit={filterData} noValidate className="filter">
      <Row>
      <Col md="10" xs="12"></Col>
        <Col md="2" xs="12">
          <FileExport
            file={file}
            columns={[
              ...columns.columnData,
              { id: "address", label: "Address" },
            ]}
            url={url}
            params={params}
          />
        </Col>
      </Row>
    </form>
  );
};

export default Filters;
