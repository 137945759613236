import React, { useState, useEffect, Fragment } from "react";
import {
    withRouter,
    Container,
    Row,
    Col,
    toast,
    TEXTFIELD,
    SELECT,
    CHECKBOX,
    FILEUPLOAD,
    Axios,
    Button,
    ApiUrl,
} from "../../../../../../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const itemsOBJ = {
    name: "",
    price: 0,
    size: "",
};

const itemsOBJ_new = Object.assign({}, itemsOBJ);

const AddonObj_new = {
    name: "",
    email: "",
    phone: "",
    address: "",
    borrower_type: ""
};

const Addons = ({ data, updateAddons, headerList, borowerList }) => {
    const [state, setState] = useState({
        addons: data,
    });

    const [isChange, setIsChange] = useState(false);

    useEffect(() => {
        setState({ addons: data });
        //setIsstateChange(true);
    }, [data]);

    useEffect(() => {
        updateAddons(state.addons);
        setIsChange(false);
    }, [isChange]);

    const changeRowvalue = (e, i, type, rIndex) => {
        e.preventDefault();

        const { name, value } = e.target;
        console.log(name, value, "74585");


        setState((pre) => ({
            ...pre,
            addons: [
                ...pre.addons.slice(0, i),
                {
                    ...pre.addons[i],
                    [name]: value,
                },
                ...pre.addons.slice(i + 1),
            ],
        }));

        setIsChange(true);
    };

    const addRow = (e, type, i) => {
        e.preventDefault();

        setState((pre) => ({
            ...pre,
            addons: [...pre.addons, { ...AddonObj_new }],
        }));
        setIsChange(true);
    };

    const deleteRow = (e, index, type, rIndex) => {
        e.preventDefault();
        const addons = state.addons.filter((e, i) => i !== index);
        setState({
            addons: [...addons],
        });
        setIsChange(true);
    };

    return (
        <Fragment>
            {state.addons &&
                state.addons.map((s, i) => {
                    return (
                        <div className="uper-multi-rowe" key={i}>
                            <Row>
                                {/* <Col md="1" xs="2">
                        <CHECKBOX
                          value={
                            s.items[index]["status"] === "inactive"
                              ? "active"
                              : "inactive"
                          }
                          checked={
                            s.items[index]["status"] === "active" ? true : false
                          }
                          name={"status"}
                          onChange={(e) => changeRowvalue(e, index, "items", i)}
                        />
                      </Col> */}
                                <Col md="4" xs="3">
                                    <SELECT
                                        name={"name"}
                                        value={s.name}
                                        onChange={(e) => changeRowvalue(e, i, "addons")}
                                        label="Name"
                                        data={headerList}
                                    />
                                </Col>
                                <Col md="4" xs="3">
                                    <SELECT
                                        name={"email"}
                                        value={s.email}
                                        onChange={(e) => changeRowvalue(e, i, "addons")}
                                        label="Email"
                                        data={headerList}
                                    />
                                </Col>
                                <Col md="4" xs="3">
                                    <SELECT
                                        name={"phone"}
                                        value={s.phone}
                                        onChange={(e) => changeRowvalue(e, i, "addons")}
                                        label="Mobile No."
                                        data={headerList}
                                    />
                                </Col>

                                <Col md="4" xs="3">
                                    <SELECT
                                        name={"address"}
                                        value={s.address}
                                        onChange={(e) => changeRowvalue(e, i, "addons")}
                                        label="Address"
                                        data={headerList}
                                    />
                                </Col>
                                <Col md="4" xs="3">
                                    <SELECT
                                        name={"borrower_type"}
                                        value={s.borrower_type}
                                        onChange={(e) => changeRowvalue(e, i, "addons")}
                                        label="Borrower Type"
                                        data={borowerList}
                                    />
                                </Col>

                                {/* <Col md="3" xs="12 text-center">
                  <FILEUPLOAD
                    label="Image"
                    value={s.addonImage}
                    name={"addonImage"}
                    onChange={(e) => handleFile(e, i)}
                  />
                  {s.addonImage && (
                    <img src={s.addonImage} height="100" width="100" />
                  )}
                </Col> */}
                                <Col md="1" xs="12">
                                    {i === 0 ? (
                                        <IconButton
                                            aria-label="delete"
                                            style={{
                                                marginTop: 20,
                                            }}
                                            onClick={(e) => addRow(e, "addons")}
                                        >
                                            <AddCircleIcon color="primary" />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Add"
                                            onClick={(e) => deleteRow(e, i, "addons")}
                                            className="mt-4"
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    );
                })}
        </Fragment>
    );
};

export default React.memo(Addons);
