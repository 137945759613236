import React, { Fragment } from "react";
// import {
//   withRouter,
//   CircularProgress,
//   Container,
// } from "../../../../../../components";

import {
  withRouter,
  CircularProgress,
  Container,
} from "../../../../../components";

import Revenuetable from "./revenueTable";


class ViewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.edit,
      id: this.props.location.hashId,
    };
  }

  render() {
    const { loading, editId, id } = this.state;
    localStorage.setItem("hash", id)
    const a = localStorage.getItem("hash")
    console.log(a, "rrrrrrrrr")
    return (
      <Container className="p-mob" style={{ paddingBottom: "10px" }}>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <Revenuetable editId={editId} hash={id} />


      </Container>
    );
  }
}

export default withRouter(ViewForm);
