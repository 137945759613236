import React, { useEffect, useState, useRef } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalBody, ModalHeader
} from "reactstrap";
import CsvDownloader from "react-csv-downloader";
import tingtong from "../../../../../../../assets/images/ting-tong.gif";
import moment from "moment-timezone";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Tooltip from "@material-ui/core/Tooltip";
import { API_URL, API_VERSION } from "../../../../../../../ApiUrl";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { ApiUrl, Axios } from "../../../../../../../components";
import Indiapost from "../../../../../../../assets/images/svgviewer-png-output.png";
import Global from "../../../../../../../assets/images/global.jpg";
import Azad from "../../../../../../../assets/images/azad.png";
import Emb from "../../../../../../../assets/images/emb.jpg";

import classnames from "classnames";
import EditorForm from "./editor";

import UploadFile from "./upload";
import Followups from "./followup"
import Editdate from "./editDate";


import Post from "./post";
import Orders from "./orders";
import Listing from "./listing";

const {
  API_GET_TIMELINE,
  API_GET_NOTES_LIST,
  API_GET_SHIPMENT_STATUS,
} = ApiUrl;

const Casetabs = (props) => {
  const pdfref = useRef();
  const [postShow, setpostShow] = useState(false);
  const [noteMessage, setMessage] = useState("");
  const [notesCount, setNotesCount] = useState();
  const handleDownloadPdf = async () => {
    setpostShow(true);

    const element = pdfref.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 2, 2, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [trackingActiveTab, setTrackingActiveTab] = useState("1");
  const [list, setList] = useState();
  const [notes, setNotes] = useState();
  const [shipment, setShipment] = useState();
  const [id, setId] = useState();
  const [detailId, setDetailId] = useState();
  const [showVenue, setVenue] = useState(false);
  const [showpre, setShowPre] = useState(false);
  const [docId, setdocId] = useState();

  const [showEditdate, setShowEdit] = useState(false);
  const [docName, setdocName] = useState();


  const [link, setLink] = useState();

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const [state, setState] = useState({
    trackingShow: true,
    shipmentShow: false,
  });
  const details = props.details;
  const respondentNames = details?.case_master_users?.reduce((acc, i) => {
    if (
      i.related_user_role === "Party" &&
      i.related_user_type === "respondent"
    ) {
      acc.push(i.related_user?.address?.address);
    }
    return acc;
  }, []);
  const claimantNames = details?.case_master_users?.reduce((acc, i) => {
    if (
      i.related_user_role === "Client" &&
      i.related_user_type === "claimant"
    ) {
      acc.push({
        name: i.related_user?.name,
        address: i.related_user?.address?.address,
      });
    }
    return acc;
  }, []);

  const getStatus = (Dkey) => {
    console.log(Dkey, "321323");
    return (
      <span
        className={
          Dkey.status == 1 ? "status-tag1 clr-grn" : "status-tag1 clr-red"
        }
      >
        {Dkey.status == 1 ? "Active" : "In Active"}
      </span>
    );
  };
  const [showMsgStatus, setShowMsgStatus] = useState({});
  const docs = [
    {
      uri: link,
    },
  ];
  const mailData =
    props.details.msg &&
    props.details.msg
      .map((message) => {
        return (
          message.msg_type == "email" && {
            created_at: message.created_at,
            stage_name: message.stage_name,
            status:
              message.msg_type == "email" &&
              message.msg_details.map((detail) => detail.status),
          }
        );
      })
      .flat();
  const whatsappData =
    props.details.msg &&
    props.details.msg
      .map((message) => {
        return (
          message.msg_type == "whatsapp" && {
            created_at: message.created_at,
            stage_name: message.stage_name,
            status:
              message.msg_type == "whatsapp" &&
              message.msg_details.map((detail) => detail.status),
          }
        );
      })
      .flat();
  const smsData =
    props.details.msg &&
    props.details.msg
      .map((message) => {
        return (
          message.msg_type == "sms" && {
            created_at: message.created_at,
            stage_name: message.stage_name,
            status:
              message.msg_type == "sms" &&
              message.msg_details.map((detail) => detail.status),
          }
        );
      })
      .flat();

  const columns = [
    {
      id: "created_at",
      displayName: "Date",
      type: "date",
    },
    {
      id: "stage_name",
      displayName: "Document Name",
    },

    {
      id: "status",
      displayName: "Status",
    },
  ];

  const toggleMsgStatus = (msgIndex, msgType, notice_id) => {
    setShowMsgStatus((prevState) => ({
      ...prevState,
      [msgType]:
        prevState[msgType] === msgIndex + notice_id
          ? null
          : msgIndex + notice_id,
    }));
  };

  useEffect(() => {
    setId(props.id);
    setDetailId(props.details.id);
  }, [props.id, props.details.id]);

  useEffect(() => {
    getTableRecords();
  }, [id]);

  useEffect(() => {
    getNotes();
    getShipmentStatus();
  }, [detailId]);

  const getNotes = async () => {
    setLoading(true);
    Axios.Get(API_GET_NOTES_LIST + detailId)
      .then((response) => {
        const resData = response.data;
        if (response.status === 200) {
          const unreadSum = resData?.data?.reduce((sum, note) => {
            return note?.unread_count === 1 ? sum + note?.unread_count : sum;
          }, 0);

          setMessage(resData?.message);
          setNotes(resData?.data);
          setNotesCount(unreadSum);
          setLoading(false);
          // if (response.status == 200) {
          //   // toast.success(resData.message);

          //   setNotes(resData?.data);
          //   setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };
  const getShipmentStatus = async () => {
    setLoading(true);
    Axios.Get(API_GET_SHIPMENT_STATUS + detailId)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setShipment(resData?.data);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };
  const getTableRecords = async () => {
    setLoading(true);

    Axios.Get(API_GET_TIMELINE + id)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setList(resData?.data);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  const handleCancelEdit = (id, name) => {
    console.log(id, name, "documentid");
    setShowEdit(!showEditdate);
    setdocId(id);
    setdocName(name);
  };


  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const toggleTracking = (tab) => {
    if (trackingActiveTab !== tab) setTrackingActiveTab(tab);
    setActive(true);
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };
  const handleCancelPre = () => {
    setShowPre(false);
  };
  const downloadFile = async (id, name) => {
    console.log(id, name, "case id");

    try {
      const url = `${API_URL}${API_VERSION}/get-file/link/${id}`;
      const response = await fetch(url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", name); // Set custom file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const previewFile = async (id) => {
    try {
      const url = `${API_URL}${API_VERSION}/get-file/link/${id}`;
      const response = await fetch(url);
      console.log(response, "response123");
      setLink(response.url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setShowPre(true);
  };

  // const downloadFile = async (id)=> {
  //    try {
  //      const encodedId = encodeId(id);
  //      const url = `${API_URL}/get-file/link/${id}`;
  //      const response = await fetch(url);
  //      const blob = await response.blob();
  //      const urlObject = window.URL.createObjectURL(blob);
  //      window.open(urlObject, '_blank');
  //    } catch (error) {
  //      console.error("Error opening file in new tab:", error);
  //    }
  //  };

  console.log(details.msg, details.msg_type, "details.msg");
  const role = localStorage.getItem("role");
  return (
    <>
      <div style={{ padding: 30 }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              Case Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "6",
              })}
              onClick={() => {
                toggle("6");
              }}
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "5",
              })}
              onClick={() => {
                toggle("5");
              }}
            >
              Upload Documents
            </NavLink>
          </NavItem>
          {/* <NavItem>
          <NavLink
            className={classnames({
              active: currentActiveTab === "2",
            })}
            onClick={() => {
              toggle("2");
            }}
          >
            Listing/History
          </NavLink>
        </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              MOM
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "9",
              })}
              onClick={() => {
                toggle("9");
              }}
            >
              MOM Details
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "8",
              })}
              onClick={() => {
                toggle("8");
              }}
            >
              Timeline
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "7",
              })}
              onClick={() => {
                toggle("7");
              }}
            >
              Tracking Status
            </NavLink>
          </NavItem>
          {role !== "Advocate" && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "4",
                })}
                onClick={() => {
                  toggle("4");
                }}
              >
                Notes
                {/*{notesCount > 0 && (
                  <>
                    <span>
                      <img src={tingtong} style={{ width: "20px" }} />
                    </span>
                    <span className="approval-notif-count">{notesCount}</span>
                  </>
                )} */}
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "10",
              })}
              onClick={() => {
                toggle("10");
              }}
            >
              Follow Up
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <div className="listing-main p-3 mt-3">
                  <div className=" d-md-flex justify-content-between align-items-center">
                    <div className="case-tab-de">
                      {details?.metas ? (
                        details?.metas.map((document, index) => {
                          return (
                            <>
                              {document?.meta_key == "claim_amt" && (
                                <p
                                  className="order-name1 mx-2"
                                  style={{ fontSize: "15px", width: "50%" }}
                                >
                                  <b>
                                    Claim Amount : Rs {document?.meta_value}
                                  </b>
                                </p>
                              )}
                              {/* {document?.meta_key == "section_17" && (
                              <p
                                className="order-name1 mx-2"
                                style={{ fontSize: "15px", width: "50%" }}
                              >
                                <b>Section 17 : {document?.meta_value}</b>
                              </p>
                            )} */}
                              {document?.meta_key == "LAN" && (
                                <p
                                  className="order-name1 mx-2"
                                  style={{ fontSize: "15px", width: "50%" }}
                                >
                                  <b>LAN : {document?.meta_value}</b>
                                </p>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="text-center">No data found</div>
                      )}
                    </div>
                  </div>
                  <p className="order-name1 mx-2 mt-2">
                    {claimantNames &&
                      claimantNames.length > 0 &&
                      claimantNames.map((claimant, index) => (
                        <p key={index}>
                          <b>First Party : </b> {claimant.name},{" "}
                          {claimant.address}
                        </p>
                      ))}
                  </p>
                  <p className="order-name1 mx-2 mt-1">
                    {respondentNames &&
                      respondentNames.length > 0 &&
                      respondentNames.map((i, index) => {
                        return (
                          <p key={index}>
                            <b>Second Party : </b> {i}
                          </p>
                        );
                      })}
                  </p>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="9">
            <table class="table table-striped mt-3 table-box">
              <thead>
                <tr>
                  <th scope="col">Particulars</th>
                  <th scope="col">Agenda</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Zoom VC Id</th>
                  <th scope="col">Zoom VC Pwd</th>
                  <th scope="col">Recording Link</th>
                  {/* <th scope="col">Status</th> */}
                </tr>
                </thead>
              <tbody style={{ whiteSpace: "pre-line" }}>
                {details.hearing ? (
                  details.hearing.map((hearingItem, index) => {
                    const currentDate = new Date();
                    const hearing = hearingItem.hearing;
                    let shouldShowLink = false;
                    let startDate = null;
                    let status = "";

                    if (hearing.start_date) {
                      startDate = new Date(hearing.start_date);

                      shouldShowLink =
                        startDate.toDateString() === currentDate.toDateString();
                      status =
                        startDate > currentDate ? "Yet to come" : "Completed";
                    } else {
                      console.error(
                        "start_date is missing in the hearing:",
                        hearing
                      );
                    }

                    return (
                      <tr key={index}>
                        <td>{hearing.topic}</td>
                        <td>{hearing.agenda}</td>
                        <td>
                          {moment(hearing.start_date).format("DD.MM.YYYY")}
                        </td>
                        <td>
                          {moment(hearing.start_time, "HH:mm").format("HH:mm")}
                        </td>
                        <td>{hearing.zoom_host_id}</td>
                        <td>{hearing.zoom_password}</td>
                        <td>
                          <div className="date">
                            {shouldShowLink ? (
                              <div className="d-flex">
                                <a href={hearing.zoom_join_url} target="_blank">
                                  Click here to join meeting
                                </a>
                              </div>
                            ) : (
                              <span>--</span>
                            )}
                          </div>
                        </td>
                        {/* <td>
                          <span
                            className="status-container-text14"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {status}
                          </span>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <div className="text-center">No data found</div>
                )}
              </tbody>
            </table>

            {/* {details.hearing &&
              details.hearing.map((document, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
                    >
                      <div style={{whiteSpace:"pre-line"}}>
                        <Row>
                          <Col md="4">
                            <p
                              className="order-name1 mx-2"
                              style={{ fontSize: "14px" }}
                            >
                              <b>Topic: {document.hearing.topic}</b>
                            </p>
                          </Col>
                          <Col md="4">
                            <p
                              className="order-name1"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              <b className="mx-2">
                                Agenda:{document.hearing.agenda}
                              </b>
                            </p>
                          </Col>
                          <Col md="4">
                            <p
                              className="order-date1 "
                              style={{ fontSize: "14px" }}
                            >
                              <b>
                                Date:
                                {moment(document.hearing.start_date).format(
                                  "LL"
                                )}{" "}
                                <span
                                  className="ml-2"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <b>{document.hearing.start_time}</b>
                                </span>
                              </b>
                            </p>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md="4">
                            <p
                              className="order-name1"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              <b className="mx-2">
                                Zoom VC Id: {document.hearing.zoom_host_id}
                              </b>
                            </p>
                          </Col>
                          <Col md="4">
                            <p
                              className="order-name1"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              <b className="mx-2">
                                Zoom VC Pwd: {document.hearing.zoom_password}
                              </b>
                            </p>
                          </Col>
                          <Col md="12">
                            <p
                              className="order-name1 mt-2"
                              style={{
                                fontSize: "14px",
                                // whiteSpace: "pre-line",
                              }}
                            >
                              <b className="mx-2">
                                Recording Link: {document.hearing.zoom_join_url}
                              </b>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                );
              })} */}
          </TabPane>
          {/* <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <Listing />
            </Col>
          </Row>
        </TabPane> */}
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                {details?.documents &&
                  details?.documents?.map((document, index) => {
                    const fileName = document?.media?.name.split("_")[0];
                    if (
                      document.type === "order" &&
                      document.is_approved === 1
                    ) {
                      return (
                        <div
                          key={index}
                          className="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
                        >
                          <div className="d-flex" style={{ flex: 1 }}>
                            <p className="order-name1 mx-2 fixed-odr-tab1">
                              <b>{fileName}</b>
                            </p>

                            <p className="order-date1 mx-2 fixed-odr-tab1">
                              {" "}
                              {moment(document.created_at).format("DD.MM.YYYY")}
                            </p>
                            <div className="d-flex fixed-odr-tab1">
                              <span
                                className="preview-pdf34"
                                onClick={() =>
                                  previewFile(document?.media?.hash_id)
                                }
                              >
                                <b> Preview Pdf </b>
                              </span>

                              <p className="order-name1 mx-2">
                                <i
                                  className="zmdi zmdi-download"
                                  onClick={() =>
                                    downloadFile(
                                      document?.media?.hash_id,
                                      document?.media?.alt +
                                      "_" +
                                      details.case_number ??
                                      document?.media?.name
                                    )
                                  }
                                  style={{
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <EditorForm
                  id={details.id}
                  notes={notes}
                  getNotes={() => getNotes()}
                  noteMessage={noteMessage}
                  setNotes={setNotes}
                />


                {/*  <EditorForm id={details.id} notes={notes} /> */}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <UploadFile id={details.id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            {details.documents ? (
              details.documents.map((document, index) => {
                const fileName = document?.media?.name.split("_")[0];
                return (
                  <>
                    {((document.type !== "order" &&
                      document.is_generated !== 1) ||
                      (document.type !== "order" &&
                        document.is_generated === 1 &&
                        document.is_approved === 1)) && (
                        <div
                          key={index}
                          className="listing-main p-3 mt-3 d-md-flex justify-content-between align-items-center"
                        >
                          <div className="d-flex" style={{ flex: 1 }}>
                            <p className="order-name1 mx-2 fixed-doc-tab1 text-capitalize">
                              <b>{document?.media?.alt || "--"}</b>

                              {role === "LawFirm" && (
                                <Tooltip title="Edit the document name" arrow>
                                  <span
                                    className=""
                                    onClick={() =>
                                      handleCancelEdit(
                                        document?.media.id,
                                        document?.media?.alt
                                      )
                                    }
                                  >
                                    <i class="zmdi zmdi-edit"></i>
                                  </span>
                                </Tooltip>
                              )}
                            </p>

                            <p className="order-name1 mx-5 fixed-doc-tab1">
                              Uploaded By :
                              <b className="mx-2">{document?.role?.name}</b>
                            </p>

                            <p className="order-date1 fixed-doc-tab1">
                              <b>
                                {moment(document?.document_date).format(
                                  "DD.MM.YYYY"
                                )}
                              </b>
                              {role === "LawFirm" && (
                                <Tooltip title="Edit the document date" arrow>
                                  <span
                                    className=""
                                    onClick={() => handleCancelEdit(document?.id)}
                                  >
                                    <i class="zmdi zmdi-edit"></i>
                                  </span>
                                </Tooltip>
                              )}
                            </p>
                            <div className="d-flex fixed-doc-tab1">
                              <span
                                className="preview-pdf34"
                                onClick={() =>
                                  previewFile(document?.media?.hash_id)
                                }
                              >
                                <b> Preview Pdf </b>
                              </span>
                              <p className="order-name1 mx-2">
                                <i
                                  className="zmdi zmdi-download"
                                  onClick={() =>
                                    downloadFile(
                                      document?.media?.hash_id,
                                      document?.media?.alt +
                                      "_" +
                                      details.case_number ??
                                      document?.media?.name
                                    )
                                  }
                                  style={{ fontSize: "25px", cursor: "pointer" }}
                                ></i>
                              </p>
                            </div>
                          </div>
                          {/* <div className="d-flex">
                          <span
                            className="preview-pdf34"
                            onClick={() =>
                              previewFile(document?.media?.hash_id)
                            }
                          >
                            <b> Preview Pdf </b>
                          </span>
                          <p className="order-name1 mx-2">
                            <i
                              className="zmdi zmdi-download"
                              onClick={() =>
                                downloadFile(document?.media?.hash_id)
                              }
                              style={{ fontSize: "25px", cursor: "pointer" }}
                            ></i>
                          </p>
                        </div> */}
                        </div>
                      )}
                  </>
                );
              })
            ) : (
              <div className="text-center">No data found</div>
            )}
          </TabPane>
          <TabPane tabId="7">
            <Row>
              <Col sm="12">
                <div className="tracking-tabs-cont1">
                  <Nav tabs className="tabs-tracking">
                    <NavItem>
                      <NavLink
                        className={
                          trackingActiveTab === "1"
                            ? "tabs-tracking_active"
                            : "tabs-tracking_active1"
                        }
                        onClick={() => {
                          toggleTracking("1");
                        }}
                      >
                        Whats App
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          trackingActiveTab === "2"
                            ? "tabs-tracking_active"
                            : "tabs-tracking_active1"
                        }
                        onClick={() => {
                          toggleTracking("2");
                        }}
                      >
                        SMS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          trackingActiveTab === "3"
                            ? "tabs-tracking_active"
                            : "tabs-tracking_active1"
                        }
                        onClick={() => {
                          toggleTracking("3");
                        }}
                      >
                        E-Mail
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          trackingActiveTab === "4"
                            ? "tabs-tracking_active"
                            : "tabs-tracking_active1"
                        }
                        onClick={() => {
                          toggleTracking("4");
                        }}
                      >
                        Shipment
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div></div>
                </div>
                <TabContent activeTab={trackingActiveTab}>
                  <TabPane
                    tabId="1"
                    className={
                      details.msg && details?.msg?.length > 0 ? "w-70" : ""
                    }
                  >
                    <Row>
                      <Col sm="12">
                        {/* {details.msg && details.msg.length > 0 && (
                          <div className="text-right">
                            <p className="order-name1 mx-2">
                              <i
                                className="zmdi zmdi-download"
                                onClick={() =>
                                  downloadFile(document?.media?.hash_id)
                                }
                                style={{
                                  fontSize: "25px",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </p>
                          </div>
                        )} */}

                        <div class="main-content-inner scrolled-table">
                          {details.msg && details.msg.length > 0 && (
                            <div className="text-right">
                              {" "}
                              <p className="order-name1 mx-2">
                                <CsvDownloader
                                  filename="Whatsapp Tracking"
                                  extension=".csv"
                                  separator=","
                                  columns={columns}
                                  datas={whatsappData}
                                  text="Download"
                                  className="whtsap-dwn"
                                />
                              </p>
                            </div>
                          )}

                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Document Name</th>
                                <th scope="col">Recipient Name</th>
                                <th scope="col">Recipient Number</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details.msg &&
                                details?.msg.map((data, index) => {
                                  return (
                                    <>
                                      {data.msg_type &&
                                        data.msg_type == "whatsapp" && (
                                          <tr key={index}>
                                            <td style={{ minWidth: "180px" }}>
                                              {" "}
                                              <p class="mt-3">
                                                {moment(data.created_at).format(
                                                  "DD.MM.YYYY"
                                                )}
                                              </p>
                                            </td>
                                            <td style={{ minWidth: "250px" }}>
                                              <p class="mt-3">
                                                {data?.stage_name}
                                              </p>
                                            </td>
                                            <td style={{ minWidth: "250px" }}>
                                              <p class="mt-3">
                                                {data?.user?.name}
                                              </p>
                                            </td>
                                            <td style={{ minWidth: "250px" }}>
                                              <p class="mt-3">
                                                {data?.user?.phone}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="d-flex">
                                                {data.msg_type &&
                                                  data.msg_type == "whatsapp" &&
                                                  data.msg_details &&
                                                  data.msg_details.length > 0
                                                  ? data.msg_details.map(
                                                    (msg) => {
                                                      return (
                                                        <div className="filestatus-povstBoxes">
                                                          <p
                                                            className={
                                                              msg.status ==
                                                                "sent"
                                                                ? " sent mr-2"
                                                                : msg.status ==
                                                                  "read"
                                                                  ? " seen mr-2"
                                                                  : msg.status ==
                                                                    "delivered"
                                                                    ? "delivered mr-2"
                                                                    : msg.status ==
                                                                      "failed"
                                                                      ? "red mr-2"
                                                                      : ""
                                                            }
                                                          >
                                                            {msg.status ==
                                                              "sent"
                                                              ? "Sent"
                                                              : msg.status ==
                                                                "read"
                                                                ? "Read"
                                                                : msg.status ==
                                                                  "delivered"
                                                                  ? "Delivered"
                                                                  : msg.status ==
                                                                    "failed"
                                                                    ? "Failed"
                                                                    : ""}
                                                          </p>
                                                          <p
                                                            className={
                                                              msg.status ==
                                                                "sent"
                                                                ? "st-font-pov sent mr-2"
                                                                : msg.status ==
                                                                  "read"
                                                                  ? "st-font-pov seen mr-2"
                                                                  : msg.status ==
                                                                    "delivered"
                                                                    ? "st-font-pov delivered mr-2"
                                                                    : msg.status ==
                                                                      "failed"
                                                                      ? "st-font-pov failed mr-2"
                                                                      : ""
                                                            }
                                                          >
                                                            {" "}
                                                            {moment
                                                              .unix(
                                                                msg.timestamp
                                                              )
                                                              .utcOffset(
                                                                "+05:30"
                                                              )
                                                              .format(
                                                                "DD.MM.YYYY hh:mm A"
                                                              ) || "--"}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )
                                                  : "--"}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        {details.msg && details.msg.length > 0 && (
                          <div className="text-right">
                            {" "}
                            <p className="order-name1 mx-2">
                              <CsvDownloader
                                filename="SMS Tracking"
                                extension=".csv"
                                separator=","
                                columns={columns}
                                datas={smsData}
                                text="DOWNLOAD"
                                className="whtsap-dwn"
                              />
                            </p>
                          </div>
                        )}
                        <div class="main-content-inner">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Document Name</th>
                                <th scope="col">Recipient Name</th>
                                <th scope="col">Recipient Number</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details.msg &&
                                details.msg.map((data, index) => {
                                  return (
                                    <>
                                      {data.msg_type && data.msg_type == "sms" && (
                                        <tr key={index}>
                                          <td>
                                            {" "}
                                            <p class="mt-3">
                                              {moment(data.created_at).format(
                                                "DD.MM.YYYY"
                                              )}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mt-3">
                                              {data?.stage_name}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mt-3">
                                              {data?.user?.name}
                                            </p>
                                          </td>
                                          <td>
                                            <p class="mt-3">
                                              {data?.user?.phone}
                                            </p>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              {data.msg_type &&
                                                data.msg_type == "sms" &&
                                                data.msg_details &&
                                                data.msg_details.length > 0
                                                ? data.msg_details.map(
                                                  (msg) => {
                                                    return (
                                                      <div className="filestatus-povstBoxes">
                                                        <p
                                                          className={
                                                            msg.status ==
                                                              "sent"
                                                              ? " sent mr-2"
                                                              : msg.status ==
                                                                "read"
                                                                ? " seen mr-2"
                                                                : msg.status ==
                                                                  "delivered"
                                                                  ? "delivered mr-2"
                                                                  : msg.status ==
                                                                    "failed"
                                                                    ? "red mr-2"
                                                                    : ""
                                                          }
                                                        >
                                                          {msg.status ==
                                                            "sent"
                                                            ? "Sent"
                                                            : msg.status ==
                                                              "read"
                                                              ? "Read"
                                                              : msg.status ==
                                                                "delivered"
                                                                ? "Delivered"
                                                                : msg.status ==
                                                                  "failed"
                                                                  ? "Failed"
                                                                  : ""}
                                                        </p>
                                                        <p
                                                          className={
                                                            msg.status ==
                                                              "sent"
                                                              ? "st-font-pov sent mr-2"
                                                              : msg.status ==
                                                                "read"
                                                                ? "st-font-pov seen mr-2"
                                                                : msg.status ==
                                                                  "delivered"
                                                                  ? "st-font-pov delivered mr-2"
                                                                  : msg.status ==
                                                                    "failed"
                                                                    ? "st-font-pov failed mr-2"
                                                                    : ""
                                                          }
                                                        >
                                                          {" "}
                                                          {moment
                                                            .unix(
                                                              msg.timestamp
                                                            )
                                                            .utcOffset(
                                                              "+05:30"
                                                            )
                                                            .format(
                                                              "DD.MM.YYYY hh:mm A"
                                                            ) || "--"}
                                                        </p>
                                                      </div>
                                                    );
                                                  }
                                                )
                                                : "--"}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        {details.msg && details.msg.length > 0 && (
                          <div className="text-right">
                            {" "}
                            <p className="order-name1 mx-2">
                              <CsvDownloader
                                filename="Mail Tracking"
                                extension=".csv"
                                separator=","
                                columns={columns}
                                datas={mailData}
                                text="DOWNLOAD"
                                className="whtsap-dwn"
                              />
                            </p>
                          </div>
                        )}
                        <div class="main-content-inner">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Document Name</th>
                                <th scope="col">Recipient Name</th>
                                <th scope="col">Recipient Email</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details.msg &&
                                details.msg.length > 0 &&
                                details.msg.map((data, index) => {
                                  return (
                                    <>
                                      {data.msg_type &&
                                        data.msg_type == "email" && (
                                          <tr key={index}>
                                            <td>
                                              {" "}
                                              <p class="mt-3">
                                                {moment(data.created_at).format(
                                                  "DD.MM.YYYY"
                                                )}
                                              </p>
                                            </td>
                                            <td>
                                              <p class="mt-3">
                                                {data?.stage_name}
                                              </p>
                                            </td>
                                            <td>
                                              <p class="mt-3">
                                                {data?.user?.name}
                                              </p>
                                            </td>
                                            <td>
                                              <p class="mt-3">
                                                {data?.user?.email}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="d-flex">
                                                {data.msg_type &&
                                                  data.msg_type == "email" &&
                                                  data.msg_details &&
                                                  data.msg_details.length > 0
                                                  ? data.msg_details.map(
                                                    (msg) => {
                                                      return (
                                                        <div className="filestatus-povstBoxes">
                                                          <p
                                                            className={
                                                              msg.status ==
                                                                "sent"
                                                                ? " sent mr-2"
                                                                : msg.status ==
                                                                  "read"
                                                                  ? " seen mr-2"
                                                                  : msg.status ==
                                                                    "delivered"
                                                                    ? "delivered mr-2"
                                                                    : msg.status ==
                                                                      "failed"
                                                                      ? "red mr-2"
                                                                      : ""
                                                            }
                                                          >
                                                            {msg.status ==
                                                              "sent"
                                                              ? "Sent"
                                                              : msg.status ==
                                                                "read"
                                                                ? "Read"
                                                                : msg.status ==
                                                                  "delivered"
                                                                  ? "Delivered"
                                                                  : msg.status ==
                                                                    "failed"
                                                                    ? "Failed"
                                                                    : ""}
                                                          </p>
                                                          <p
                                                            className={
                                                              msg.status ==
                                                                "sent"
                                                                ? "st-font-pov sent mr-2"
                                                                : msg.status ==
                                                                  "read"
                                                                  ? "st-font-pov seen mr-2"
                                                                  : msg.status ==
                                                                    "delivered"
                                                                    ? "st-font-pov delivered mr-2"
                                                                    : msg.status ==
                                                                      "failed"
                                                                      ? "st-font-pov failed mr-2"
                                                                      : ""
                                                            }
                                                          >
                                                            {" "}
                                                            {moment
                                                              .unix(
                                                                msg.timestamp
                                                              )
                                                              .utcOffset(
                                                                "+05:30"
                                                              )
                                                              .format(
                                                                "DD.MM.YYYY hh:mm A"
                                                              ) || "--"}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )
                                                  : "--"}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                    </>
                                  );
                                })}
                              {/* {data.map((i) => {
                              return (
                                <tr>
                                  <td>
                                    <p className="mt-3">{i.date}</p>
                                  </td>
                                  
                                  <td>
                                    <p className="mt-3">{i.name}</p>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="filestatus-povstBoxes">
                                        <p className="delivered mr-2">
                                          Delivered
                                        </p>
                                        <p className="delivered">
                                          {" "}
                                          Oct 19, 2023{" "}
                                        </p>
                                      </div>
                                      <div className="filestatus-povstBoxes">
                                        <p className="sent mr-2">Sent</p>
                                        <p className="sent"> Oct 19, 2023 </p>
                                      </div>
                                      <div className="filestatus-povstBoxes">
                                        <p className="seen">Seen</p>
                                        <p className="seen"> Oct 19, 2023 </p>
                                      </div>
                                    </div>
 
                                  </td>
                                </tr>
                              );
                            })} */}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        {/* <p class="article12">Listing</p> */}
                        {/* <div className="text-right">
                          <button onClick={handleDownloadPdf}>
                            Generate PDF
                          </button>
                        </div> */}
                        {shipment && shipment.length > 0 ? (
                          shipment.map((shipment, index) => {
                            return (
                              <div class="main-content-inner" key={index}>
                                <div class="mt-2o">
                                  <div>
                                    <p
                                      class="registered12"
                                      onClick={() => toggleRev1(index)}
                                    >
                                      {shipment.document_name ||
                                        "Registered Letter"}
                                      {showVenue === index ? (
                                        <span className="float-right">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                                              stroke="#42526E"
                                              stroke-width="1.75"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      ) : (
                                        <span className="float-right">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                                              stroke="#42526E"
                                              stroke-width="1.75"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                {showVenue === index && (
                                  <>
                                    <div>
                                      <div className="text-right">
                                        <button
                                          onClick={handleDownloadPdf}
                                          className="whtsap-dwn"
                                        >
                                          Generate PDF
                                        </button>
                                      </div>
                                      <div ref={pdfref}>
                                        <div className="w3-row-padding w3-container">
                                          <div className="w3-container">
                                            <div className="">
                                              <div className="b-0p0">
                                                <h1 className="img-left">
                                                  <img
                                                    src={Indiapost}
                                                    alt="post"
                                                    width="250"
                                                    height="100"
                                                  />
                                                </h1>
                                                <div className="b-0p">
                                                  <img
                                                    src={Global}
                                                    alt="globe"
                                                    width="80"
                                                    height="90"
                                                  />
                                                  <img
                                                    src={Azad}
                                                    alt="azadi"
                                                    width="100"
                                                    height="120"
                                                  />
                                                  <img
                                                    src={Emb}
                                                    alt="emb"
                                                    width="80"
                                                    height="90"
                                                  />
                                                </div>
                                              </div>

                                              <p className="img-left track-heading">
                                                Track Consignment
                                              </p>
                                              <p className="indicate-text img-left">
                                                *Indicates a required field
                                              </p>
                                              <div className="consgnmnt-cont">
                                                <div className="b-0p">
                                                  <p className="indicate-text">
                                                    *Consignment Number
                                                  </p>
                                                  <div className="consgnmnt-sub-cont">
                                                    <p className="indicate-texts">
                                                      {shipment.tracking_number}
                                                    </p>
                                                  </div>
                                                  <div className="rgt98">
                                                    <button className="track-more">
                                                      Track More
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="mt-0o">
                                                <div>
                                                  <div>
                                                    <table
                                                      cellspacing="0"
                                                      rules="all"
                                                      className="responsivetable MailArticleOER "
                                                      border="1"
                                                      style={{
                                                        borderCollapse:
                                                          "collapse",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr align="center">
                                                          <th scope="col">
                                                            Booked At
                                                          </th>
                                                          <th scope="col">
                                                            Booked On
                                                          </th>
                                                          <th scope="col">
                                                            Destination Pincode
                                                          </th>
                                                          <th scope="col">
                                                            Tariff
                                                          </th>
                                                          <th scope="col">
                                                            Article Type
                                                          </th>
                                                          <th scope="col">
                                                            Delivery Location
                                                          </th>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Chandigarh BNPL SP
                                                            Hub
                                                          </td>
                                                          <td>
                                                            19/03/2024 16:45:07
                                                          </td>
                                                          <td>122001</td>
                                                          <td>29.50</td>
                                                          <td>
                                                            Registered Letter
                                                          </td>
                                                          <td>Gurgaon HO</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <small className="text-danger"></small>
                                                </div>
                                                <div
                                                  style={{ marginTop: "5px" }}
                                                >
                                                  <div className="row mt-2 mb-2">
                                                    <div className="col-xs-12 col-md-5">
                                                      <strong>
                                                        <span className="e56">
                                                          Event Details For :{" "}
                                                          {
                                                            shipment.tracking_number
                                                          }
                                                        </span>
                                                      </strong>
                                                    </div>
                                                    <div className="col-xs-12 col-md-5">
                                                      <strong>
                                                        <span className="text-capitalize e56">
                                                          Current Status :{" "}
                                                          {
                                                            shipment.delivery_status
                                                          }
                                                        </span>
                                                      </strong>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <table
                                                        cellspacing="0"
                                                        rules="all"
                                                        className="responsivetable MailArticleOER "
                                                        border="1"
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                        }}
                                                      >
                                                        <thead>
                                                          <tr align="center">
                                                            <th scope="col">
                                                              Date
                                                            </th>
                                                            <th scope="col">
                                                              Time
                                                            </th>
                                                            <th scope="col">
                                                              Office
                                                            </th>
                                                            <th scope="col">
                                                              Event
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {shipment?.history?.map(
                                                            (s) => {
                                                              console.log(
                                                                s,
                                                                "ssssssssss1"
                                                              );
                                                              if (
                                                                s.latest_event
                                                              ) {
                                                                const eventParts = s.latest_event.split(
                                                                  ","
                                                                );

                                                                // Extracting data from eventParts
                                                                const eventType =
                                                                  eventParts[0]?.trim() ||
                                                                  "";
                                                                const location =
                                                                  eventParts[1]?.trim() ||
                                                                  "";
                                                                const timestamp =
                                                                  eventParts[2]?.trim() ||
                                                                  "";

                                                                // Create an object with extracted data
                                                                const eventObject = {
                                                                  eventType,
                                                                  location,
                                                                  timestamp,
                                                                };

                                                                return (
                                                                  <tr>
                                                                    <td>
                                                                      {moment(
                                                                        eventObject?.timestamp
                                                                      ).format(
                                                                        "DD.MM.YYYY"
                                                                      )}
                                                                    </td>
                                                                    <td>
                                                                      {moment(
                                                                        eventObject?.timestamp
                                                                      ).format(
                                                                        "HH:mm"
                                                                      )}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        eventObject?.location
                                                                      }
                                                                    </td>

                                                                    <td>
                                                                      <span>
                                                                        {
                                                                          eventObject?.eventType
                                                                        }
                                                                      </span>
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              } else {
                                                                return null;
                                                              }
                                                            }
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div class="event12">
                                      <p>
                                        Event Details For :{" "}
                                        {shipment.tracking_number}
                                      </p>
                                      <p>
                                        Current Status :
                                        <span className="status-container-text14 ml-2">
                                          {shipment.delivery_status || "--"}
                                        </span>
                                      </p>
                                    </div>
                                    {shipment?.history &&
                                      shipment?.history.length > 0 && (
                                        <div className="brdr-1px">
                                          <table class="table table-striped">
                                            <thead>
                                              <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Time</th>
                                                <th scope="col">Office</th>
                                                <th scope="col">Event</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {shipment?.history.map((s) => {
                                                if (s.latest_event) {
                                                  const eventParts = s.latest_event.split(
                                                    ","
                                                  );

                                                  // Extracting data from eventParts
                                                  const eventType =
                                                    eventParts[0]?.trim() || "";
                                                  const location =
                                                    eventParts[1]?.trim() || "";
                                                  const timestamp =
                                                    eventParts[2]?.trim() || "";

                                                  // Create an object with extracted data
                                                  const eventObject = {
                                                    eventType,
                                                    location,
                                                    timestamp,
                                                  };

                                                  console.log(
                                                    eventObject,
                                                    "sssssssssssssssss"
                                                  );

                                                  return (
                                                    <tr>
                                                      <td>
                                                        {moment(
                                                          eventObject?.timestamp
                                                        ).format("DD-MM-YYYY")}
                                                      </td>
                                                      <td>
                                                        {moment(
                                                          eventObject?.timestamp
                                                        ).format("HH:mm")}
                                                      </td>
                                                      <td>
                                                        {eventObject?.location}
                                                      </td>

                                                      <td>
                                                        <span
                                                          className={
                                                            s.delivery_status ===
                                                            "sent"
                                                              ? "st-font-pov sent mr-2 text-capitalize"
                                                              : s.delivery_status ===
                                                                "read"
                                                              ? "st-font-pov seen mr-2 text-capitalize"
                                                              : s.delivery_status ===
                                                                "delivered"
                                                              ? "st-font-pov delivered mr-2 text-capitalize"
                                                              : s.delivery_status ===
                                                                "failed"
                                                              ? "st-font-pov failed mr-2 text-capitalize"
                                                              : ""
                                                          }
                                                        >
                                                          {
                                                            eventObject?.eventType
                                                          }
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      )} */}
                                    {/* {shipment?.history &&
                                      shipment?.history.length > 0 && (
                                        <div className="brdr-1px">
                                          <table class="table table-striped">
                                            <thead>
                                              <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Time</th>
                                                <th scope="col">Office</th>
                                                <th scope="col">Event</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {shipment?.history.map((s) => {
                                                console.log(
                                                  s.latest_event,
                                                  "sssssssssssssssss"
                                                );
                                                return (
                                                  <tr>
                                                    <td>
                                                      {moment(
                                                        s.created_at_vendor
                                                      ).format("L")}
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        s.created_at_vendor
                                                      ).format("HH:mm")}
                                                    </td>
                                                    <td>
                                                      <span
                                                        className={
                                                          s.delivery_status ==
                                                          "sent"
                                                            ? "st-font-pov sent mr-2 text-capitalize"
                                                            : s.delivery_status ==
                                                              "read"
                                                            ? "st-font-pov seen mr-2 text-capitalize"
                                                            : s.delivery_status ==
                                                              "delivered"
                                                            ? "st-font-pov delivered mr-2 text-capitalize"
                                                            : s.delivery_status ==
                                                              "failed"
                                                            ? "st-font-pov failed mr-2 text-capitalize"
                                                            : ""
                                                        }
                                                      >
                                                        {s.delivery_status}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      )} */}
                                  </>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <p className="no-shipment">No Shipment</p>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="8">
            <Row>
              <Col sm="12">
                <div id="js-timeline" className="timeline-container">
                  {list && list.length > 0 && (
                    <>
                      {list.map((item, index) => {
                        if (item.hasOwnProperty("stage_id")) {
                          return (
                            <div key={index} className="wrapper ">
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box">
                                  <div className="mb-2">
                                    <b>
                                      {" "}
                                      {moment(item.created_at).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </b>
                                  </div>

                                  {item.type && (
                                    <div className="mt-2">
                                      <span
                                        style={{ cursor: "pointer" }}
                                        // className="preview-pdf34"
                                        onClick={() =>
                                          previewFile(item?.media?.hash_id)
                                        }
                                      >
                                        <u> {item?.media?.alt}</u>
                                      </span>{" "}
                                      has been uploaded by {item?.role?.name}
                                    </div>
                                  )}
                                  {/* <div className="mt-2">
                                    <b>Stage:</b> {item?.stage?.stage}
                                  </div> */}
                                  {item.message.map((msg, msgIndex) => (
                                    <div key={msgIndex}>
                                      <div
                                        className="js-msg-title"
                                        onClick={() =>
                                          toggleMsgStatus(
                                            msgIndex,
                                            msg.msg_type,
                                            msg.notice_id
                                          )
                                        }
                                      >
                                        Via{" "}
                                        <b className="mb-2">{msg.msg_type}:</b>
                                      </div>
                                      <div
                                        className={`js-msg-status ${showMsgStatus[msg.msg_type] ===
                                          msgIndex + msg.notice_id
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <div>
                                          {moment(item.created_at).format(
                                            "DD.MM.YYYY"
                                          )}
                                        </div>
                                        {msg?.msg_details.map(
                                          (msgDetail, index) => (
                                            <div key={index}>
                                              <b>
                                                {msgDetail.status.toLowerCase()}
                                                :{" "}
                                              </b>
                                              {item?.stage?.stage}{" "}
                                              {msgDetail.status} to{" "}
                                              <b className="text-capitalize">
                                                {msg?.user?.name}
                                              </b>{" "}
                                              via {msg?.msg_type} on{" "}
                                              {msg?.msg_type === "sms" ||
                                                (msg?.msg_type === "whatsapp" &&
                                                  msg?.user?.phone)}{" "}
                                              at{" "}
                                              {moment
                                                .unix(msgDetail.timestamp)
                                                .utcOffset("+05:30")
                                                .format("DD.MM.YYYY hh:mm A") ||
                                                "--"}
                                              {/* {msgDetail.timestamp ?? "-"} */}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          );
                        } else if (item.hasOwnProperty("hearing_id")) {
                          return (
                            <div
                              key={index}
                              className="wrapper text-capitalize"
                            >
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1">
                                  <div>
                                    <b> Meeting Date:  </b>
                                    {moment(item.hearing.start_date).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </div>
                                  <div>
                                    <b> Meeting Link :</b>{" "}
                                    {item.hearing.zoom_join_url}
                                  </div>
                                  <div>
                                    <b>Purpose :  </b>
                                    {item.hearing.topic}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.hasOwnProperty("table_name") &&
                          item.table_name === "case_history"
                        ) {
                          return (
                            <div
                              key={index}
                              className="wrapper text-capitalize"
                            >
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1">
                                  <div className="mb-2">
                                    <b>
                                      {" "}
                                      {moment(item.created_at).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </b>
                                  </div>
                                  <div>
                                    Mediation Process status has been changed to{" "}
                                    <b>{item.case_status}</b>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.hasOwnProperty("related_user_role") &&
                          item.related_user_role !== "Arbitrator"
                        ) {
                          return (
                            <div
                              key={index}
                              className="wrapper text-capitalize"
                            >
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1">
                                  <div className="mb-2">
                                    <b>
                                      {" "}
                                      {moment(item.created_at).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </b>
                                  </div>
                                  <div>
                                    {item.related_user_type == "respondent"
                                      ? "Second Party"
                                      : item.related_user_type}{" "}
                                    has been added{" "}
                                  </div>
                                  <div>
                                    {item.related_user.name} has been added for
                                    mediation no. <b>{list[0].case_number}</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.hasOwnProperty("related_user_role") &&
                          item.related_user_role === "Arbitrator"
                        ) {
                          return (
                            <div key={index} className="wrapper">
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1">
                                  <div className="mb-2">
                                    <b>
                                      {" "}
                                      {moment(item.created_at).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </b>
                                  </div>
                                  <div>Appointment of Arbitrator</div>
                                  <div className="mt-2">
                                    <b>{item.related_user.name}</b> appointed as
                                    the <b>Arbitrator</b> for case no.{" "}
                                    {list[0].case_number}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          item.hasOwnProperty("stage_id") &&
                          item.type === "Order"
                        ) {
                          return (
                            <div
                              key={index}
                              className="wrapper text-capitalize"
                            >
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1"></div>
                              </div>
                            </div>
                          );
                        } else if (item.hasOwnProperty("case_number")) {
                          return (
                            <div
                              key={index}
                              className="wrapper text-capitalize"
                            >
                              <div className="center-line"></div>
                              <div className="row">
                                <div className="timeline-box w-brk1">
                                  <div className="mb-2">
                                    <b>
                                      {" "}
                                      {moment(item.created_at).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </b>
                                  </div>
                                  <div>
                                    {" "}
                                    Mediation Process Initiated, awaiting
                                    approval.
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="10">
            <Row>
              <Col sm="12">
                <Followups detailId={detailId} />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="8">
          <Row>
            <Col sm="12">
              <div className="timeline-container">
                {list &&
                  list.length > 0 &&
                  list.map((item, index) => {
                    return (
                      <>
                        {index % 2 === 0 ? (
                          <div class="wrapper text-capitalize">
                            <div class="center-line"></div>
                            <div class="row">
                              <section class="shadow-0 timeline-sec">
                                <div class="">
                                  <div class="text-left left-side1">
                                    <span class="time-linetitle text-left">
                                      {moment(item.created_at).format(
                                        "YYYY-MM-DD"
                                      ) || "2024-01-18"}{" "}
                                    </span>
                                    <p className="mt-0p">
                                      <div className="space-67">
                                        <span>
                                          {item.related_user_role == "Client" &&
                                          item.related_user_type == "claimant"
                                            ? `${item.related_user.name}   Versus`
                                            : ""}
                                        </span>

                                        <span>
                                          {item.related_user_role == "Party" &&
                                          item.related_user_type == "respondent"
                                            ? item.related_user.name
                                            : ""}
                                        </span>
                                      </div>
                                    </p>
                                    <span class="time-linetitle text-left">
                                      {item.case_number &&
                                      item.case_status == "registered"
                                        ? "Case has been registered"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        ) : (
                          <div class="wrapper text-capitalize">
                            <div class="center-line"></div>
                            <div class="row row-2">
                              <section class="shadow-0 timeline-sec">
                                <div class="">
                                  <div class="text-left right-side1">
                                    <span class="time-linetitle text-left">
                                      {moment(item.created_at).format(
                                        "YYYY-MM-DD"
                                      ) || "2024-01-18"}{" "}
                                    </span>
                                    <p className="mt-0p">
                                      <div className="space-67">
                                        <span>
                                          {item.related_user_role == "Client" &&
                                          item.related_user_type == "claimant"
                                            ? `${item.related_user.name}   vs`
                                            : ""}
                                        </span>

                                        <span>
                                          {item.related_user_role == "Party" &&
                                          item.related_user_type == "respondent"
                                            ? item.related_user.name
                                            : ""}
                                        </span>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              <p className="mt-0p">
                      Word{" "}
                      <a href="/public/uploads/document/notice/65a8c3146d0d5_pratcing excel 2.xlsx">
                        {" "}
                        Document{" "}
                      </a>{" "}
                      was uploaded on 2024-01-18{" "}
                    </p>  
                              </section>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </TabPane> */}
        </TabContent>
      </div>
      <Modal isOpen={showpre} toggle={handleCancelPre}>
        <p className="text-right m-0">
          <span onClick={handleCancelPre} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            style={{ height: "450px" }}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showEditdate}
        toggle={handleCancelEdit}
        size="sm"
        style={{ maxWidth: "400px", width: "96%" }}
      >
        <ModalHeader>
          {docName ? "Edit document name" : "Edit document date"}
          <p className="text-right m-0">
            <span onClick={handleCancelEdit} className="modal-cancel12">
              <i class="zmdi zmdi-close"></i>
            </span>
          </p>
        </ModalHeader>

        <ModalBody>
          <Editdate
            showEditdate={setShowEdit}
            details={props.caseDetails}
            docId={docId}
            docName={docName}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Casetabs;
