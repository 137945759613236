import React from 'react'

const ClinetBooking = () => {
    return (
        <div className='text-center mt-5'>
            Client Booking coming soon!!
        </div>
    )
}

export default ClinetBooking
