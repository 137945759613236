import React from 'react'

const PartyConciliationSecond = () => {
    return (
        <div className='text-center mt-5'>
            Coming Soon Party Conciliation Second Cases
        </div>
    )
}

export default PartyConciliationSecond

