import React, { Fragment, useState } from "react";
import banner from "../../../assets/images/about.jpeg";
import choice from "../../../assets/images/choose.webp";
import Header from "./header";
import Footer from "./footer";

const Aboutus = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Header />
      <Fragment>
        { /* <div style={{ height: "60px" }}></div> */}
        <section className="aboutsec">
          <div className="container">
            <div className="row">
              <div className=""></div>
            </div>
          </div>
        </section>
        <section
          className="missionsec container"
          id="about"
          style={{ marginBottom: "75px" }}
        >
          <div className="" style={{ margin: "20px 40px" }}>
            <div className="row align-items-center mt-5">
              <div>
                <div className="ourmissiondiv">
                  <h2
                    className="mb-md-3 mb-1 mt-3 mt-md-0"
                    style={{ color: "#1c1819" }}
                  >
                    OUR ODR SERVICES
                  </h2>
                  <p>
                    At Just Settle, we offer a range of services tailored to
                    meet the evolving needs of individuals and businesses in
                    dispute resolution. Leveraging technology and established
                    methods such as Arbitration, Mediation, and Conciliation,
                    our services are designed to provide efficient,
                    cost-effective, and environmentally sustainable solutions.
                    Embracing the digital age, our online platform enables
                    parties to engage in dispute resolution processes remotely,
                    offering convenience, accessibility, and efficiency. With
                    our paperless approach, we minimize our ecological footprint
                    while delivering seamless resolution experiences.
                  </p>
                </div>
              </div>
            </div>

            <div className="container mt-5">
              <div class="row d-block">
                <div class="ourmissiondiv">
                  <div class="">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            backgroundColor: "#f0ebe8",
                            height: "530px",
                          }}
                        >
                          <img class="card-img-top" src={choice} alt="legal" />
                          <div class="card-body">
                            <h3 class="card-title">
                              <b>Arbitration :</b>
                            </h3>

                            <p class="card-text">
                              Expert independent Arbitrators ensure fair and
                              impartial resolution of disputes, adhering to the
                              relevant provisions of the Arbitration and
                              Conciliation Act of 1996 and the UNCITRAL Rules
                              for Arbitration. With a focus on timely
                              resolution, we prioritize the swift conclusion of
                              arbitration proceedings.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            backgroundColor: "#f0ebe8",
                            height: "530px",
                          }}
                        >
                          <img class="card-img-top" src={choice} alt="legal" />

                          <div class="card-body">
                            <h3 class="card-title">
                              <b>Mediation :</b>
                            </h3>

                            <p class="card-text">
                              Independent Mediators facilitate constructive
                              dialogue between parties, guiding them towards
                              mutually acceptable solutions while preserving
                              business relationships. Through our mediation
                              services, we empower parties to resolve disputes
                              amicably and efficiently.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          class="card"
                          style={{
                            width: "18rem",
                            backgroundColor: "#f0ebe8",
                            height: "530px",
                          }}
                        >
                          <img class="card-img-top" src={choice} alt="legal" />

                          <div class="card-body">
                            <h3 class="card-title">
                              <b>Conciliation :</b>
                            </h3>

                            <p class="card-text">
                              Conciliators assist parties in exploring common
                              ground and reaching mutually agreeable terms to
                              settle disputes. With a facilitative approach, we
                              strive to achieve resolution while minimizing
                              conflict and preserving goodwill.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row align-items-center ourmissiondiv">
                <div class="col-md-12">
                  <div class="chooseusdiv">
                    <p
                      class="mb-4"
                      style={{ fontSize: "25px", fontWeight: "600" }}
                    >
                      Key features of our Online Dispute Resolution Process are:
                    </p>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>Personal Dashboards – </b>Just Settle has a facility
                        of personalized dashboards for its users which include
                        Arbitrators, Mediators, Advocate(s), Claimant(s) and
                        Respondent(s).{" "}
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>Virtual Hearing Room – </b> Just Settle features a
                        fully equipped virtual hearing room for every hearing,
                        providing a secure and reliable platform for conducting
                        arbitration proceedings remotely. The virtual hearing
                        room includes audio and video conferencing capabilities,
                        document sharing and annotation tools, and real-time
                        transcription services.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>Breakout rooms –</b> Just Settle Virtual hearing
                        rooms offer a breakout room feature, enabling effective
                        collaboration and private discussions during the
                        arbitration and mediation process. The breakout room
                        functionality enhances communication and allows parties,
                        their litigants, arbitrators, and mediators to engage in
                        confidential discussions, mediation, or settlement
                        negotiations.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>Digital Signature –</b> Just Settle provides an
                        Aadhar-based digital signature feature, providing a
                        secure and convenient method for signing and
                        authenticating documents. The digital signature
                        functionality ensures the integrity, authenticity, and
                        legal validity of arbitration-mediation related
                        documents.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>E-Stamp – </b> Just Settle provides an E-stamp which
                        is an electronic or digital form, typically as a unique
                        code, barcode, or image that represents the postage
                        value. They can be stored and transmitted electronically
                        by using Just Settle.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>E-certified copies – </b> Just Settle provides a
                        comprehensive e-certified copies service, ensuring the
                        authenticity and reliability of electronically certified
                        documents. The e-certified copies feature enables
                        parties involved in arbitration and mediation to obtain
                        and share digitally authenticated copies of important
                        documents through Just Settle.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>Transcription – </b> Just Settle offers live
                        transcription services to enable the availability of
                        oral proceedings in written format for future use that
                        are gauged during the course of the proceedings. 24*7
                        Access– Users of the Just Settle tool can upload
                        documents and get progress sheets, among other things,
                        from the comfort of their home or place of business.
                        They can use the service at any time, from any location.
                      </p>
                    </div>
                    <div class="d-flex">
                      <i class="fa fa-check-circle"></i>
                      <p>
                        <b>E-Notices – </b> The Just Settle tool contains this
                        function that allows the system to prepare and send out
                        periodical e-notices as needed. The Mailing and other
                        Contact information will be retrieved by the Software
                        from the Metadata supplied by the Parties. E-Notice is
                        the fastest mode of service and is rather most of the
                        time instant. This is a clear edge over conventional
                        methods. It is the most user-friendly method of service
                        and adds to the value for customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p><b>CONTACT US: -</b>	E-mail – info@justsettle.in</p>
            </div>
          </div>
        </section>
      </Fragment>
      <Footer />
    </>
  );
};

export default Aboutus;
