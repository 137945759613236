import React, { useState } from "react";

const Bookings = () => {

  return (
    <div className="text-center mt-5"> Coming Soon Bookings</div>
  );
};

export default Bookings;
