import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  Row,
  Col,
  Axios,
  TEXTFIELD,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  NEXT,
  PREV,
  ApiUrl,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Loading,
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";
import Termscondition from "./form-steps/termscondition";
import Educationdetails from "./form-steps/educationdetails";
const {
  API_ARBITRATOR_REGISTER,
  EMAIL_REGEX,
  Numeric,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
} = ApiUrl;

const SignUp = (props) => {

  const [editId, setEditId] = useState(props.editId);
  const [process, setProcess] = useState(props.role);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showNext, setShowNext] = useState(true);
  const [showPrev, setShowPrev] = useState(false);
  const [showMed, setShowMed] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [parent_name, setParentName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [address, setAddress] = useState("");
  const [pin_code, setPin_Code] = useState("");
  const [occupation, setOccupation] = useState("");
  const [collegeInstituteName, setCollegeInstituteName] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [yearOfPassing, setYearOfPassing] = useState("");
  const [casesDoneAsSoleArbitrator, setCasesDoneAsSoleArbitrator] = useState('');
  const [allChecked, setAllChecked] = useState([]);
  const [detailsOfArbitratorConducted, setDetailsOfArbitratorConducted] = useState('');
  const [arbitrator_Panel_Category_Id, setArbitrator_Panel_Category_Id] = useState("");
  const [fieldOfSpecializations, setFieldOfSpecializations] = useState([]);
  const [arbitratorPanelCategoryId, setArbitratorPanelCategoryId] = useState(null);

  // const [field_of_specializations, setFieldOfSpecializations] = useState([]);

  const handleCategoryChange = (categoryId) => {
    setArbitratorPanelCategoryId(categoryId);
  };

  const handleSpecializationChange = (specializations) => {
    setFieldOfSpecializations(specializations);
    console.log("Field of Specializations in SignUp:", specializations);
  };

  const itemsOBJ = {
    user_id: "",
    college_institute_name: "",
    university_name: "",
    course_name: "",
    year_of_passing: "",
  };

  const itemsOBJ2 = {
    user_id: "",
    employer_name: "",
    employer_address: "",
    position: "",
    start_year: "",
    end_year: "",
  };

  const itemsMED = {
    course_name: "",
    organiser_name: "",
    course_duration: "",
    course_year: "",
  };

  const SpecializationsData = [
    { id: 1, name: "Agency & Distribution" },
    { id: 2, name: "Engeneering/Technical" },
    { id: 3, name: "Intellectual Property" },
    { id: 4, name: "Joint Venture" },
    { id: 5, name: "Aviation" },
    { id: 6, name: "Finance Banking" },
    { id: 7, name: "International Investments" },
    { id: 8, name: "Maritime Shipping" },
    { id: 9, name: "Telecommunications" },
    { id: 10, name: "Construction" },
    { id: 11, name: "Insurance" },
  ];

  const optionObj = {
    name: "",
    price: "",
    size: "",
  };
  const itemsOBJ_new = Object.assign({}, itemsOBJ);
  const itemsOBJ_new2 = Object.assign({}, itemsOBJ2);
  const itemsOBJ_med = Object.assign({}, itemsMED);
  const AddonObj = {
    items: [{ ...itemsOBJ_new }],
  };

  const ExperienceObj = {
    catName: "",
    items: [{ ...itemsOBJ_new2 }],
    options: [{ ...optionObj }],
  };

  const MediationObj = {
    items: [{ ...itemsOBJ_med }],
  };

  const AddonObj_new = Object.assign({}, AddonObj);
  const experinenceObj_new = Object.assign({}, ExperienceObj);
  const mediationObj_new = Object.assign({}, MediationObj);

  const [education_data, setEducationData] = useState([{ ...AddonObj_new }]);
  const [experience_data, setExperienceData] = useState([
    { ...experinenceObj_new },
  ]);
  const [mediation_data, setMediationData] = useState([
    { ...mediationObj_new },
  ]);
  const [enrollment_number, setEnrollmentNumber] = useState("");
  const [list, setList] = useState([...SpecializationsData]);
  const [date_of_birth, setDateOfBirth] = useState("");
  const [state, setState] = useState("");
  const [bar_license, setBarLicense] = useState("");
  const [promotionImageName, setPromotionImageName] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
    enrollmentNumber: "",
    gender: "",
    date_of_birth: "",
    // state: "",
    pinCode: "",
    barLicense: "",
    address: "",
  });

  const specialization = (e) => {
    if (e.target && e.target.checked) {
      setAllChecked((prevChecked) => [...prevChecked, e.target?.value]);
    } else if (e.target) {
      setAllChecked((prevChecked) =>
        prevChecked.filter((item) => item !== e.target?.value)
      );
    }
  };
  
  const updateDisabled = (value) => {
    setDisabled(value);
  };

  const nextStep = () => {
    // Check if step 1 is completed
    if (step === 1 && !isStep1Completed()) {
      toast.info("Step 1 is not completed yet.");
      return;
    }

    if (step === 2 && !isStep2Completed()) {
      toast.info("Step 2 is not completed yet.");
      return;
    }
    // Move to the next step
    setStep(step + 1);
    // Update state based on the current step
    if (step === 1) {
      setShowMed("med");
      setShowPrev(true);
    } else {
      setShowMed("");
    }
    if (step === 3) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  // Function to check if step 2 is completed

  const isStep2Completed = () => {
    const isEducationCompleted = education_data.every(
      item =>
        item && // Check if item is not undefined or null
        item.college_institute_name && // Check if college_institute_name is not undefined or null
        item.college_institute_name.trim() !== "" &&
        item.university_name && // Check if university_name is not undefined or null
        item.university_name.trim() !== "" &&
        item.course_name && // Check if course_name is not undefined or null
        item.course_name.trim() !== "" &&
        item.year_of_passing && // Check if year_of_passing is not undefined or null
        item.year_of_passing.trim() !== ""
    );

    const isExperienceCompleted = experience_data.every(
      exp =>
        exp && // Check if exp is not undefined or null
        exp.employer_name && // Check if employer_name is not undefined or null
        exp.employer_name.trim() !== "" &&
        exp.employer_address && // Check if employer_address is not undefined or null
        exp.employer_address.trim() !== "" &&
        exp.position && // Check if position is not undefined or null
        exp.position.trim() !== "" &&
        exp.start_year && // Check if start_year is not undefined or null
        exp.start_year.trim() !== "" &&
        exp.end_year && // Check if end_year is not undefined or null
        exp.end_year.trim() !== ""
    );

    const isDetailsCompleted =
      casesDoneAsSoleArbitrator && // Check if casesDoneAsSoleArbitrator is not undefined or null
      casesDoneAsSoleArbitrator.trim() !== "" &&
      detailsOfArbitratorConducted && // Check if detailsOfArbitratorConducted is not undefined or null
      detailsOfArbitratorConducted.trim() !== "";

    // Check if arbitrator_Panel_Category_Id has a value
    const isArbitratorPanelIdSelected = arbitratorPanelCategoryId !== null;

    const isFieldOfSpecializationsSelected = Array.isArray(fieldOfSpecializations) && fieldOfSpecializations.length > 0;

    // Check if mediation data is completed
    // const isMediationCompleted = mediation_data.every(
    //     item =>
    //         item && // Check if item is not undefined   or null
    //         item.course_name && // Check if course_name is not undefined or null
    //         item.course_name.trim() !== "" &&
    //         item.organiser_name && // Check if organiser_name is not undefined or null
    //         item.organiser_name.trim() !== "" &&
    //         item.course_duration && // Check if course_duration is not undefined or null
    //         item.course_duration.trim() !== "" &&
    //         item.course_year && // Check if course_year is not undefined or null
    //         item.course_year.trim() !== ""
    // );
    // && isMediationCompleted

    // Combine all conditions
    console.log(isEducationCompleted && isExperienceCompleted && isDetailsCompleted && isArbitratorPanelIdSelected && isFieldOfSpecializationsSelected)
    return isEducationCompleted && isExperienceCompleted && isDetailsCompleted && isArbitratorPanelIdSelected && isFieldOfSpecializationsSelected;
  };



  const isStep1Completed = () => {
    return (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      parent_name !== "" &&
      age !== "" &&
      gender !== "" &&
      address !== "" &&
      pin_code !== "" &&
      occupation !== "" &&
      date_of_birth !== ""
    );
  };
  const prevStep = () => {
    setStep(step - 1);
    if (step == 1 && step == 2) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  const handleFile = (event) => {
    const { name, value } = event.target;
    let file = event.target.files[0];
    switch (name) {
      case "dl":
        if (value.length > 0 && !file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          errors.dl = "Image Format is not valid";
        } else {
          errors.dl = "";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors });
    console.log(file, "file");
  };
  ``
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target; // Destructure event object
    let newErrors = { ...errors };

    switch (name) {
      case "name":
        newErrors.name = value.trim().length < 1 ? "Required" : "";
        setName(value.trim()); // Update name state
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "address":
        newErrors.address = value.trim().length < 1 ? "Required" : "";
        setAddress(value.trim()); // Update address state
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "role":
        newErrors.role = value.trim().length < 1 ? "Required" : "";
        // Update role state if applicable
        break;
      case "email":
        newErrors.email = value.trim().length < 1 ? "Required" : !EMAIL_REGEX.test(value) ? "Invalid email" : "";
        setEmail(value.trim()); // Update email state
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "phone":
        if (value.trim().length < 1) {
          newErrors.phone = "Required";
        } else if (!Numeric.test(value)) {
          newErrors.phone = "Only numeric allowed";
        } else if (value.trim().length < 10) {
          newErrors.phone = "Minimum 10 digits required";
        } else {
          newErrors.phone = "";
        }
        setPhone(value.trim()); // Update phone state
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "password":
        newErrors.password = value.trim().length < 1 ? "Required" : !PASS_REGEX.test(value) ? "Invalid format" : "";
        setPassword(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "password_confirmation":
        newErrors.passwordConfirmation = value.trim().length < 1 ? "Required" : !PASS_REGEX.test(value) ? "Invalid format" : "";
        setPasswordConfirmation(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "date_of_birth":
        // Validation for date of birth
        if (value.trim().length < 1) {
          newErrors.date_of_birth = "Required";
        } else if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(value)) {
          newErrors.date_of_birth = "Invalid date format (YYYY-MM-DD)";
        } else {
          newErrors.date_of_birth = "";
        }
        setDateOfBirth(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "parent_name":
        // Validation for parent name
        newErrors.parent_name = value.trim().length < 1 ? "Required" : "";
        setParentName(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "age":
        // Validation for age
        if (value.trim().length < 1) {
          newErrors.age = "Required";
        } else if (!Numeric.test(value.trim())) {
          newErrors.age = "Only numeric allowed";
        } else {
          newErrors.age = "";
        }
        setAge(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "occupation":
        newErrors.occupation = value.trim().length < 1 ? "Required" : "";
        setOccupation(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "pin_code":
        if (value.trim().length < 1) {
          newErrors.pin_code = "Required";
        } else if (!/^\d{6}$/.test(value.trim())) {
          newErrors.pin_code = "Pin code must be 6 digits";
        } else {
          newErrors.pin_code = "";
        }
        setPin_Code(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "gender":
        newErrors.gender = value.trim().length < 1 ? "Required" : "";
        setGender(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "nationality":
        newErrors.nationality = value.trim().length < 1 ? "Required" : "";
        setNationality(value.trim());
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "cases_done_as_sole_arbitrator":
        setCasesDoneAsSoleArbitrator(value.trim());
        newErrors.cases_done_as_sole_arbitrator = value.trim().length === 0 ? "Required" : "";
        console.log(`${name}: "${value.trim()}"`);
        break;

      case "details_of_arbitrator_conducted":
        setDetailsOfArbitratorConducted(value.trim());
        newErrors.details_of_arbitrator_conducted = value.trim().length === 0 ? "Required" : "";
        console.log(`${name}: "${value.trim()}"`);
        break;

      case "arbitrator_Panel_Category_Id":
        newErrors.arbitrator_Panel_Category_Id = value.trim().length < 1 ? "Required" : "";
        console.log(`${name}: "${value.trim()}"`);
        break;
      case "field_of_specializations":
        newErrors.field_of_specializations = value.trim().length < 1 ? "Required" : "";
        console.log(`${name}: "${value.trim()}"`);
        break;

      case "education_data":
        const educationErrors = value.map((item, index) => {
          const itemErrors = {};
          if (!item.college_institute_name || item.college_institute_name.trim() === "") {
            itemErrors[`college_institute_name_${index}`] = "College/Institute name is required";
          }
          if (!item.university_name || item.university_name.trim() === "") {
            itemErrors[`university_name_${index}`] = "University name is required";
          }
          if (!item.course_name || item.course_name.trim() === "") {
            itemErrors[`course_name_${index}`] = "Course name is required";
          }
          if (!item.year_of_passing || item.year_of_passing.trim() === "") {
            itemErrors[`year_of_passing_${index}`] = "Year of passing is required";
          }
          return itemErrors;
        });
        newErrors = {
          ...newErrors,
          ...educationErrors.reduce((acc, error) => ({ ...acc, ...error }), {})
        };
        break;
      case "experience_data":
        const experienceErrors = value.map((exp, index) => {
          const expErrors = {};
          if (!exp.employer_name || exp.employer_name.trim() === "") {
            expErrors[`employer_name_${index}`] = "Employer name is required";
          }
          if (!exp.employer_address || exp.employer_address.trim() === "") {
            expErrors[`employer_address_${index}`] = "Employer address is required";
          }
          if (!exp.position || exp.position.trim() === "") {
            expErrors[`position_${index}`] = "Position is required";
          }
          if (!exp.start_year || exp.start_year.trim() === "") {
            expErrors[`start_year_${index}`] = "Start year is required";
          }
          if (!exp.end_year || exp.end_year.trim() === "") {
            expErrors[`end_year_${index}`] = "End year is required";
          }
          return expErrors;
        });
        newErrors = {
          ...newErrors,
          ...experienceErrors.reduce((acc, error) => ({ ...acc, ...error }), {})
        };
        break;
      case "mediation_data":
        const mediationErrors = value.map((item, index) => {
          const itemErrors = {};
          if (!item.course_name || item.course_name.trim() === "") {
            itemErrors[`course_name_${index}`] = "Course name is required";
          }
          if (!item.organiser_name || item.organiser_name.trim() === "") {
            itemErrors[`organiser_name_${index}`] = "Organiser name is required";
          }
          if (!item.course_duration || item.course_duration.trim() === "") {
            itemErrors[`course_duration_${index}`] = "Course duration is required";
          }
          if (!item.course_year || item.course_year.trim() === "") {
            itemErrors[`course_year_${index}`] = "Course year is required";
          }
          return itemErrors;
        });
        newErrors = {
          ...newErrors,
          ...mediationErrors.reduce((acc, error) => ({ ...acc, ...error }), {})
        };
        break;
      default:
        break;
    }

    setErrors(newErrors);
    setDisabled(checkError(newErrors).disabled);
  };


  const updateAddons = (education_data) => {
    setEducationData(education_data);
    setDisabled(false);
  };

  const updateExperience = (experience_data) => {
    setExperienceData(experience_data);
    setDisabled(false);
  };

  const updateMediation = (mediation_data) => {
    setMediationData(mediation_data);
    setDisabled(false);
  };

  const handleAddress = (address) => {
    let newErrors = { ...errors };
    const data = {
      address: address.address,
      postCode: address.postal_code,
      date_of_birth: address.date_of_birth,
      state: address.state,
      lat: address.lat,
      lng: address.lng,
    };

    newErrors.address = address.address < 1 ? "Required" : "";

    setErrors(newErrors);
    setDisabled(checkError(newErrors).disabled);
  };

  const redirectPage = () => {
    props.history.push("/app/signin");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabled(true);
    if (!updateDisabled) {

      toast.info('Please agree to the terms and conditions.');
      return;
    }

    console.log("Mediation Data:", mediation_data);
    // Construct the payload object
    const data = {
      name: name,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      phone: phone,
      date_of_birth: date_of_birth,
      parent_name: parent_name,
      age: age,
      gender: gender,
      nationality: nationality,
      address: address,
      pin_code: pin_code,
      occupation: occupation,
      arbitrator_Panel_Category_Id: arbitratorPanelCategoryId,
      field_of_specializations: fieldOfSpecializations,
      cases_done_as_sole_arbitrator: casesDoneAsSoleArbitrator,
      details_of_arbitrator_conducted: detailsOfArbitratorConducted,
      education_data: education_data.map(item => ({
        user_id: "", // Update user_id with the appropriate value
        college_institute_name: item.college_institute_name,
        university_name: item.university_name,
        course_name: item.course_name,
        year_of_passing: item.year_of_passing
      })),
      experience_data: experience_data.map(exp => ({
        user_id: "", // Update user_id with the appropriate value
        employer_name: exp.employer_name,
        employer_address: exp.employer_address,
        position: exp.position,
        start_year: exp.start_year,
        end_year: exp.end_year
      })),
      mediation_data: mediation_data.map(item => ({
        course_name: item.course_name,
        organiser_name: item.organiser_name,
        course_duration: item.course_duration,
        course_year: item.course_year,
      }))
    };

    // Perform any necessary validation on the data object here

    Axios.post(API_ARBITRATOR_REGISTER, data)
      .then((res) => {
        let response = res.data;
        toast.success(response.message);
        setLoading(false);
        setDisabled(false);
        if (response.status === 200) {
          setTimeout(() => {
            redirectPage();
          }, 1000);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res.message, "res.message");
        // toast.error(res.message);
        // setLoading(false);
        // setDisabled(false);
      });
  };


  const reset = () => {
    setName("");
    setPassword("");
    setPasswordConfirmation("");
    setEmail("");
    setPhone("");
    setAddress("");
    setEnrollmentNumber("");
    setGender("");
    setdate_of_birth("");
    setState("");
    setPin_Code("");
    setBarLicense("");
    setErrors({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      phone: "",
      address: "",
      enrollment_number: "",
      gender: [],
      date_of_birth: "",
      state: "",
      pin_code: "",
      bar_license: "",
    });
    setDisabled(true);
  };

  const showSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Email"
                value={email}
                name={"email"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Mobile No."
                name="phone"
                onChange={handleChange}
                value={phone}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Address"
                value={address}
                name={"address"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Date of Birth"
                placeholder="XXXX-XX-XX"
                value={date_of_birth}
                name={"date_of_birth"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Parent Name"
                value={parent_name}
                name={"parent_name"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Age"
                placeholder="age in years"
                value={age}
                name={"age"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Occupation"
                value={occupation}
                name={"occupation"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                placeholder="XXXXXX"
                label="Pin Code"
                value={pin_code}
                name={"pin_code"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <SELECT
                label="Gender"
                value={gender}
                name={"gender"}
                onChange={handleChange}
                errors={errors}
                data={[{ 1: "Male" }, { 0: "Female" }]}
              />
            </Col>

            <Col md="4" xs="12">
              <SELECT
                label="Nationality"
                value={nationality}
                name="nationality"
                onChange={handleChange}
                errors={errors}
                data={[{ 1: "Indian" }]}
                className="mt-2"
              />
            </Col>

            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Password"
                value={password}
                name={"password"}
                edit={process === "edit"}
                onChange={handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Confirm Password"
                value={password_confirmation}
                name={"password_confirmation"}
                edit={process === "edit"}
                onChange={handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
          </>
        );
      case 2:
        return (
          <>
            <Educationdetails
              data={education_data}
              updateAddons={updateAddons}
              dataExperience={experience_data}
              updateExperience={updateExperience}
              optionObj={optionObj}
              dataMediation={mediation_data}
              updateMediation={updateMediation}
              specialization={specialization}
              SpecializationsData={list}
              arbitratorPanelCategoryId={arbitratorPanelCategoryId}
              fieldOfSpecializations={fieldOfSpecializations}
              onCategoryChange={handleCategoryChange}
              onSpecializationChange={handleSpecializationChange}
              handleChange={handleChange}
            />


            <p className="w-100 exp-txt">Arbitration Experience</p>
            <Col md="3" xs="12">
              <TEXTFIELD
                label="No. of cases done as Sole Arbitrator"
                value={casesDoneAsSoleArbitrator}
                name={"cases_done_as_sole_arbitrator"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="6" xs="12">
              <TEXTFIELD
                label="Details of Arbitrator conducted"
                value={detailsOfArbitratorConducted}
                name={"details_of_arbitrator_conducted"} // Unique name for the second input field
                onChange={handleChange}
                errors={errors}
              />
            </Col>

          </>
        );
      case 3:
        return (
          <Col md="12" xs="12">
            <Termscondition
              updateDisabled={updateDisabled}
              bar_license={bar_license}
              handleFile={handleFile}
              errors={errors}
            />
          </Col>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div style={{ paddingTop: "17vh" }}></div>
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        <div className="app-login-container mediator-signup-form d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-login-content w-100">
              <div className="">
                <h1>Sign Up as Arbitrator</h1>
              </div>

              <div className="app-login-forma">
                <form onSubmit={handleSubmit} noValidate>

                  <ToastContainer style={{ top: "5em" }} />
                  <Row>{showSteps()}</Row>
                  <Row>

                    {step > 1 && (
                      <Col md="3" xs="12">
                        <PREV className="next-btn1" onClick={prevStep}>
                          Prev
                          <i class="zmdi zmdi-arrow-right"></i>
                        </PREV>
                      </Col>
                    )}
                    {step === 3 ? (
                      <Col md="3" xs="12">
                        <SUBMIT title="Submit" disabled={disabled} />
                      </Col>
                    ) : (
                      <Col md="3" xs="12">
                        <NEXT className="next-btn1" onClick={nextStep}>
                          Next <i class="zmdi zmdi-arrow-right"></i>
                        </NEXT>
                      </Col>
                    )}


                  </Row>
                  <Link to="/signin">Already have an account</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <div style={{ paddingTop: "17vh" }}></div>
      <Footer />
    </>
  );
};

export default withRouter(SignUp);


