import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  ApiUrl,
  toast,
  SELECT,
  TEXTFIELD,
  Axios,
  CircularProgress, moment
} from "../../../../../../../components";
import { onlyNumber } from "../../../../../../../util/onlyNumber";

import {
  Button,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { API_URL } from "../../../../../../../ApiUrl";
import EditNotes from "./editNotes";

const {
  API_ADD_FOLLOW_UP,
  API_GET_FOLLOW_UP_CODES,
  API_VIEW_FOLLOW_UP,
  API_SEND_REPLY,
  API_DELETE_FOLLOW_UP
} = ApiUrl;

const Followups = (props) => {
  const [statusCode, setStatusCode] = useState([]);
  const [followup, setFollowup] = useState([]);
  const [amount, setAmount] = useState([]);
  const [prioriti, setPriority] = useState([]);
  const [calltime, setCalltime] = useState([]);
  const [duration, setDuration] = useState([]);
  const [email, setEmail] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [codes, setCodes] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewAddform, setViewAddForm] = useState(false);
  const [viewList, setViewList] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [notesData, setNotesData] = useState("");
  const [replyBox, setreplyBox] = useState(false);
  const [noteIds, setSelectedNoteId] = useState("");
  const [reply, setReply] = useState("");

  const [editTd, setSelectedEditId] = useState("");
  const [openEditNotes, setopenEditNotes] = useState(false);


  const getCodes = async () => {
    Axios.get(API_GET_FOLLOW_UP_CODES)
      .then((response) => {
        const codes = response.data;
        const stages = codes.data.map((s) => {
          return { [s.id]: s.name };
        });
        console.log(stages, "9898989");
        setCodes(stages);
      })
      .catch((err) => {
        console.log("");
      });
  };

  useEffect(() => {
    getCodes();
  }, []);

  useEffect(() => {
    if (props.detailId !== null) {
      handleViewNotes();
    }
  }, [props.detailId]);

  const handleViewNotes = async () => {
    try {
      const response = await Axios.Get(
        `${API_VIEW_FOLLOW_UP}${props.detailId}`
      );
      console.log(response.data, "response.dataresponse.data");
      setNotesData(response.data);
    } catch (error) {
      console.error("Error fetching notes for case", ":", error);
    }
  };

  const handleReply = (e) => {
    setReply(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      submitReply(event);
    }
  };

  const handleDeleteNotes = async (caseId, noteId) => {
    try {
      const response = await Axios.Post(`${API_DELETE_FOLLOW_UP}/${noteId}`);
      if (response.data.success) {
        setNotesData((prevNotesData) => ({
          ...prevNotesData,
          data: prevNotesData.data.filter((note) => note.id !== noteId),
        }));
        toast.success("Note deleted successfully.");
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      toast.error("Failed to delete note.");
    }
  };
  const openEditModal = (caseId, noteId) => {
    console.log(caseId, noteId, "checking no");
    setSelectedEditId(caseId);
    setSelectedNoteId(noteId);
    setopenEditNotes(true);
  };

  const submitReply = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("reply", reply);

      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const res = await Axios.post(API_SEND_REPLY + noteIds, formData);
      if (res.data.success === true) {
        toast.success(res?.data?.message);
        setReply("");
        setreplyBox(false);
        setUploadFiles([]);
        handleViewNotes()
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!String(statusCode).trim()) {
      errors.statusCode = "Status Code is required";
      isValid = false;
    }
    if (!String(followup).trim()) {
      errors.followup = "Follow Up date is required";
      isValid = false;
    }
    if (!String(calltime).trim()) {
      errors.calltime = "Call time date is required";
      isValid = false;
    }
    if (!String(duration).trim()) {
      errors.duration = "Call Duration is required";
      isValid = false;
    }
    if (!String(prioriti).trim()) {
      errors.prioriti = "Priority is required";
      isValid = false;
    }
    if (!String(remarks).trim()) {
      errors.remarks = "Remarks are required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleChangeCode = (e) => {
    setStatusCode(e.target.value);
  };
  const handleFollowDateChange = (e) => {
    const { value } = e.target;
    setFollowup(value);
  };
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };
  const handleCallduration = (e) => {
    const { value } = e.target;
    setDuration(value);
  };
  const handleChangeCalltime = (e) => {
    const { value } = e.target;
    setCalltime(value);
  };
  const handleChangeS = (e) => {
    setPriority(e.target.value);
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setUploadFiles(filesArray);
  };
  const downloadFile = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = `${API_URL}/get-file/link/${file.fileHashId}`;
        const response = await fetch(url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", file.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("new_mobileNumber", mobileNumber);
      formData.append("status_code", statusCode);
      formData.append("priority", prioriti);
      formData.append("follow_up", followup);
      formData.append("amount", amount);
      formData.append("duration", duration);
      formData.append("calltime", calltime);
      formData.append("new_email", email);
      formData.append("remarks", remarks);
      uploadFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      // Submit the form
      const url = `${API_ADD_FOLLOW_UP}${props.detailId}`;
      const response = await Axios.post(url, formData); // Changed from Axios.Post to Axios.post

      // Log the response to console
      console.log("Add Notes API Response:", response.data);

      if (response.data.success == true) {
        toast.success(response.data.message);
        setStatusCode(" ");
        setPriority(" ");
        // setFollowUp([]);
        setDuration(" ");
        setCalltime(" ");
        setRemarks(" ");
        setEmail(" ");
        setAmount(" ");
        setUploadFiles([]);
        setFormErrors({});
        setViewAddForm(false), setViewList(true);
        setMobileNumber("");
        handleViewNotes()
      }
    } catch (error) {
      console.error("Error sending addNotes data:", error);
      // toast.error("Failed to add notes.");
    }
  };
  const adminId = localStorage.getItem("_id")

  return (
    <Fragment>
      {loading && (
        <div className="text-center w-100">
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          padding: "20px 60px",
          border: "1px solid grey",
          boxShadow: "2px 2px 3px -3px",
          margin: "20px",
        }}
      >
        <div>
          <div className="">
            <button
              onClick={() => {
                setViewAddForm(!viewAddform);
              }}
              className="notesTabs-btn"
            >
              Add Follow Up
            </button>
          </div>
          {viewAddform == true && (
            <div className="" style={{ padding: "20px 20px" }}>
              <Row>
                <Col md="6" xs="12" className="mt-a-5">
                  <SELECT
                    name="statusCode"
                    value={statusCode}
                    onChange={handleChangeCode}
                    label="Status Code"
                    data={codes}
                    onKeyUp={validateForm}
                  />
                  {formErrors.statusCode && (
                    <div className="error-message">{formErrors.statusCode}</div>
                  )}
                </Col>

                {statusCode == 12 && (
                  <Col md="6">
                    <TEXTFIELD
                      type="text"
                      label="Amount"
                      onChange={handleAmountChange}
                      value={amount}
                      name="amount"
                    />
                  </Col>
                )}
                <Col md="6">
                  <TEXTFIELD
                    type="datetime-local"
                    label="Follow Up Date & Time"
                    onChange={handleFollowDateChange}
                    value={followup}
                    name="followup"
                    onKeyUp={validateForm}
                  />
                  {formErrors.followup && (
                    <div className="error-message">{formErrors.followup}</div>
                  )}
                </Col>

                <Col md="6" xs="12" className="mt-a-5">
                  <SELECT
                    name="prioriti"
                    value={prioriti}
                    onChange={handleChangeS}
                    label="Priority"
                    data={[
                      { moderate: "Moderate" },
                      { high: "High" },
                      { low: "Low" },
                    ]}
                  />
                  {formErrors.prioriti && (
                    <div className="error-message">{formErrors.prioriti}</div>
                  )}
                </Col>
                <Col md="6">
                  <TEXTFIELD
                    type="date"
                    label="Call Time Date"
                    onChange={handleChangeCalltime}
                    value={calltime}
                    name="calltime"
                  />
                  {formErrors.calltime && (
                    <div className="error-message">{formErrors.calltime}</div>
                  )}
                </Col>

                <Col md="6">
                  <TEXTFIELD
                    type="text"
                    label="Call Duration"
                    onChange={handleCallduration}
                    value={duration}
                    name="duration"
                  />
                  {formErrors.duration && (
                    <div className="error-message">{formErrors.duration}</div>
                  )}
                </Col>
                <Col md="6">
                  <TEXTFIELD
                    label="New Mobile Number:"
                    value={mobileNumber}
                    onKeyUp={validateForm}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    onKeyPress={onlyNumber}
                  />
                </Col>
                <Col md="6">
                  <TEXTFIELD
                    label="New Email:"
                    value={email}
                    onKeyUp={validateForm}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col md="12">
                  <TEXTFIELD
                    label="Follow up & Remarks"
                    value={remarks}
                    onKeyUp={validateForm}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                  {formErrors.remarks && (
                    <div className="error-message">{formErrors.remarks}</div>
                  )}
                </Col>
              </Row>
              <input
                className="my-3"
                type="file"
                onChange={handleFileChange}
                multiple
              />

              <div className="modal-submit-btn text-center">
                <button
                  type="button"
                  className="btn jr-btn CBmargin CBmarginT M add-notes w-50"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}

          <div>
            {notesData.data && notesData.data?.length > 0 ? (
              notesData.data.map((note) => (
                <div key={note.id}>
                  {console.log(notesData.data[0].status_code?.name, "notesData.data")}
                  {console.log(notesData.data, "notesData.data")}
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Status :</b> {note.status_code?.name} {" "}

                            {/*  <b>Status :</b> {note?.followup_code?.name}{" "} */}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Follow Up Date & Time : </b>
                            {moment(note.followup_datetime).format(
                              "DD.MM.YYYY, HH:mm"
                            )}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>Priority :</b> {note.priority_flag}
                          </p>
                        </Col>

                        <Col md="6">
                          {" "}
                          <p>
                            <b>Call Date : </b>
                            {moment(note.call_date).format("DD.MM.YYYY")}
                          </p>
                        </Col>

                        <Col md="6">
                          {" "}
                          <p>
                            <b>Call Duration :</b> {note.call_duration}{" "}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>New Mobile Number :</b> {note.new_phone}{" "}
                          </p>
                        </Col>
                        <Col md="6">
                          {" "}
                          <p>
                            <b>New Email :</b> {note.new_email}{" "}
                          </p>
                        </Col>

                        {/* <Col md="6">
                          {" "}
                          <p>
                            <b>Priority :</b>{" "}
                            {note.priority_flag == 1
                              ? "High"
                              : note.priority_flag == 2
                              ? "Moderate"
                              : "Low"}{" "}
                          </p>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md="12">
                          <div>
                            <p>
                              {" "}
                              <b>Remarks :</b> {note.followup_remarks}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="delete-btn"
                        style={{ textAlign: "right" }}
                      >
                        {" "}
                        {adminId == note?.created_by && (
                          <>
                            <span>
                              <i
                                className="zmdi zmdi-edit"
                                style={{
                                  fontSize: "20px",
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openEditModal(note.case_id, note.id)
                                }
                              />
                            </span>

                            <i
                              className="zmdi zmdi-delete delete-icn"
                              style={{
                                fontSize: "20px",
                                marginRight: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteNotes(note.case_id, note.id)
                              }
                            />
                          </>
                        )}
                        {note?.attachments?.length > 0 && (
                          <span>
                            <i
                              className="zmdi zmdi-download"
                              onClick={() => downloadFile(note?.attachments)}
                              style={{
                                fontSize: "25px",
                                cursor: "pointer",
                                marginTop: "4px",
                              }}
                            ></i>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {note.replies.length > 0 &&
                      note?.replies?.map((data, index) => {
                        const firstLetter = data?.replied_by?.name.charAt(0);
                        return (
                          <div key={index}>
                            <div>
                              <span className="firstletter-box color-giu">
                                {firstLetter}
                              </span>
                              <span className="sdfs">
                                {" "}
                                {data?.replied_by?.name}
                              </span>
                              <span className="SfeR">
                                {" "}
                                {moment(data.created_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                              </span>
                            </div>
                            <div className="reply-list-box">
                              <p>
                                {" "}
                                {data.reply_text}
                                <span>
                                  <i
                                    className="zmdi zmdi-download"
                                    onClick={() =>
                                      downloadFile(data?.attachment)
                                    }
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                  ></i>
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div>
                    {replyBox == true && (
                      <div>
                        <form onSubmit={submitReply} noValidate>
                          <div className="mt-1 p-r-div">
                            <div class="file-upload-r reply-box-span">
                              <label for="fileInput" class="custom-file-upload">
                                <i class="zmdi zmdi-attachment-alt reply-box-span-i"></i>
                              </label>
                              <input
                                id="fileInput"
                                class="hidden-file"
                                type="file"
                                onChange={handleFileChange}
                                accept=".jpg,.png,.doc,.docx,.pdf"
                                multiple
                              />
                            </div>
                            <input
                              placeholder="Write Us"
                              name="reply"
                              value={reply}
                              onChange={handleReply}
                              onKeyDown={handleKeyDown}
                              className="reply-msg-box"
                            ></input>
                          </div>
                          <p className="m-1">{uploadFiles[0]?.name}</p>
                          <button className="reply-send" type="submit">
                            Send
                          </button>
                        </form>
                      </div>
                    )}
                    {replyBox == false && (
                      <span
                        className="reply"
                        onClick={() => {
                          setreplyBox(true), setSelectedNoteId(note.id);
                        }}
                      >
                        Reply
                      </span>
                    )}
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <p>No follow up available.</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openEditNotes}
        toggle={() => setopenEditNotes(false)}
        size="md"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        { /*<EditNotes
          setopenEditNotes={setopenEditNotes}
          editTd={editTd}
          noteIds={noteIds}
        /> */}
        <EditNotes
          noteIds={noteIds}
          editTd={editTd}
          setopenEditNotes={setopenEditNotes}
          // notesList={notesList}
          handleViewNotes={handleViewNotes}
          codes={codes}
        />
      </Modal>
    </Fragment>
  );
};

export default Followups;
