import React, { Fragment, useState } from "react";

import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  TEXTFIELD,
} from "../../../../../../../components";

const { API_ADD_EXCEL, API_EDIT_NOTES } = ApiUrl;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      listId: this.props.listId,
      process: this.props.role,
      document_name: "",
      document_description: "",
      file: {},
      promotionImage: "",
      promotionImageName: "",
      type: "",
      data: {},
      caseId: this.props.match.params.editId,
      errors: {
        file: "",
      },
      disabled: true,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ promotionImageName: file.name });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });

    const {
      process,
      errors,
      file,
      document_name,
      document_description,
      caseId,
      editId,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      document_name: document_name,
      document_description: document_description,
      files: file,
    };
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    let url = API_ADD_EXCEL;
    if (process == "edit") {
      url = API_EDIT_NOTES;
    }
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    // Axios.post(url + caseId, formData)
    Axios.Post(
      "/bulk/arbitration/filed/cases/" + `${this.props.hash}` + "/document-upload/",
      formData
    )
      .then((res) => {
        console.log(res, res.data.success, "response123");
        let response = res.data;
        this.setState({ loading: false, disabled: false });
        if (res.data.success == true) {
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        let res = error.response;
        // toast.error(res.data.message);
        // this.setState({ loading: false, disabled: false });
      });
  };

  render() {
    const {
      errors,
      promotionImageName,
      disabled,
      document_name,
      document_description,
    } = this.state;

    return (
      <Fragment>
        <div
          style={{
            padding: "20px 60px",
            border: "1px solid grey",
            boxShadow: "2px 2px 3px -3px",
            margin: "20px",
          }}
        >
          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="5" xs="12">
                <TEXTFIELD
                  label="Type of Document"
                  value={document_name}
                  name={"document_name"}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="7" xs="12">
                <TEXTFIELD
                  label="Description"
                  value={document_description}
                  name={"document_description"}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="12" xs="12">
                <div class="file-upload-cus mt-4">
                  <div class="file-select">
                    <div class="file-select-button" id="fileName">
                      Choose File
                    </div>
                    <div class="file-select-name" id="noFile">
                      {promotionImageName
                        ? promotionImageName
                        : "No file chosen..."}
                    </div>
                    <div class="importcus">
                      <input
                        type="file"
                        name={"file"}
                        id="chooseFile"
                        onChange={this.handleFile}
                        errors={errors}
                        accept="application/pdf"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="3" xs="12">
                <SUBMIT title="Submit" disabled={disabled} />
              </Col>
            </Row>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(UploadFile);
