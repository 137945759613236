import React from 'react'

const ClientSecondCasess = () => {
    return (
        <div className='mt-5 text-center'>
            Conciliation  Client SecondCases coming soon
        </div>
    )
}

export default ClientSecondCasess
