import React, { Fragment, useState } from "react";

import {
  withRouter,
  Row,
  Col,
  FILEUPLOAD,
} from "../../components";

const Termscondition = ({ updateDisabled }) => {

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setTermsAccepted(checked);
    updateDisabled(!checked);
  };

  return (
    <Fragment>
      <div fluid>
        <Row>
          <h4 className="terms-txt">Terms & Conditions</h4>
          <Col md="12" xs="12">
            <div>
              <p className="undertaking1">Undertaking:-</p>
              <ul className="undertaking1-list">
                <li>
                  I agree to abide by the rules of Mediation of the Just Settle
                  including Guidelines and Code of Conduct.
                </li>
                <li>
                  I shall take up and complete the Mediation assigned to me with
                  utmost deligence and expedition.
                </li>
                <li>
                  All mediators being of equal status, I shall not object to my
                  appointment to as mediator in a particular case on the basis
                  of my previous status or that of any appointed mediator.
                </li>
                <li>
                  I have not been convicted or charged of any offence o any
                  criminal investigation or vegilance enquiry pending against
                  me.
                </li>
                <li>
                  I declare that i have not been removed as mediator in
                  circumstances where moral probity or incompetence were an
                  issue.{" "}
                </li>
                <li>I declare that above particulars furnished are true and correct. </li>
              </ul>
              <div class="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  I Agree
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Termscondition;
