import React from 'react'

const PartyConciliationFresh = () => {
    return (
        <div className='text-center mt-5'>
            Coming Soon Party Conciliation Fresh Cases
        </div>
    )
}

export default PartyConciliationFresh
