import React from "react";
import FaqForm from "../Components/FaqForm";
import PageWrapper from "../../../../common/PageWrapper";

class AddEdit extends React.Component {
  render() {
    const { match } = this.props;
    console.log(match,"werwerwerewr")
    if (match.params.faqId) {
      var role = "edit";
      var type = "Edit";
    } else {
      var role = "add";
      var type = "Add";
    }
    return (
      <PageWrapper match={match} title={`${type} Template`} icon="zmdi-comments">
        <FaqForm role={role} editId={match.params.faqId} templateId={match.params.templateId}/>
      </PageWrapper>
    );
  }
}

export default AddEdit;
