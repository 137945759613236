import React, { useEffect, useState } from "react";
import "./ClientMeeting.css";
import { Link, Axios, toast, ApiUrl } from "../../../../components";

const { MEETING_API } = ApiUrl;

const ClientMeeting = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleMeetings = async () => {
    try {
      const response = await Axios.get(MEETING_API);
      if (response.status === 200) {
        setList(response.data.data.bulkCaseHearing);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching meetings:", error);
      toast.error("An error occurred while fetching meetings.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleMeetings();
  }, []);

  return (
    <div style={{width:"98%"}}>
      {/* <div className="timline-content  d-flex justify-content-center align-items-center flex-column">
                <div className="">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="zmdi zmdi-search"></i>
                            </span>
                        </div>
                        <input
                            type="text"
                            placeholder="Search"
                            className="form-control search-bar"
                        />
                    </div>
                </div>
            </div> */}
      {loading ? (
        <div className="timline-content d-flex justify-content-center align-items-center mt-5">
          Loading...
        </div>
      ) : list.length === 0 ? (
        <div className="timline-content d-flex justify-content-center align-items-center mt-5">
          No meeting available
        </div>
      ) : (
        list?.map((item, index) => {
          const startDate = new Date(item.start_date);
          const currentDate = new Date();
          const shouldShowLink =
            startDate.getFullYear() === currentDate.getFullYear() &&
            startDate.getMonth() === currentDate.getMonth() &&
            startDate.getDate() === currentDate.getDate();
          return (
            <div
              className="timline-content d-flex justify-content-center align-items-center"
              key={index}
            >
              <div className="abbreviation mr-2">
                <div className="circle">
                  {item?.relation_table.map((relation, relationIndex) => (
                    <div key={relationIndex} className="d-flex">
                      {relation?.case?.case_master_users.map(
                        (user, userIndex) => (
                          <div className="ml-2 " key={userIndex}>
                            <span>
                              {user?.related_user_type === "LawFirm"
                                ? "LF"
                                : ""}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className=" mr-auto mx-3">
                <div className="d-flex">
                  <p className="clinet-meet mt-3 d-flex">
                  <span className="kl41"> By :</span> {" "}
                    <span className="added-by-text">
                      {item?.relation_table.map((relation, relationIndex) => (
                        <div key={relationIndex} className="d-flex">
                          {relation?.case?.case_master_users.map(
                            (user, userIndex) => (
                              <div className="ml-2 " key={userIndex}>
                                <span className="by_text">
                                  {user?.related_user_type === "LawFirm"
                                    ? `${user?.related_user.name}`
                                    : ""}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </span>
                  </p>
                </div>
                <div className="date-added d-flex">
                  <b>Case Title :</b>
                  {item?.relation_table.map((relation, relationIndex) => (
                    <div key={relationIndex} className="d-flex">
                      {relation?.case?.case_master_users.map(
                        (user, userIndex) => (
                          <div className="ml-2 " key={userIndex}>
                            <span>
                              {user?.related_user_type === "claimant"
                                ? `${user?.related_user?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                    user.related_user.name
                                      .slice(1)
                                      .toLowerCase()}   vs`
                                : ""}
                            </span>
                            <span>
                              {user?.related_user_role === "Party" &&
                              user?.related_user_type === "respondent"
                                ? user?.related_user?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  user.related_user.name.slice(1).toLowerCase()
                                : ""}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
                <div className="date-added ">
                  <b>Topic:</b> {item.topic}
                </div>
                <div className="date-added ">
                  <b>Date: </b>
                  {item.start_date}
                </div>
                <div className="date mt-4">
                  {shouldShowLink && (
                    <a href={item.zoom_join_url} target="_blank">
                      <b>click here to join </b>
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ClientMeeting;
