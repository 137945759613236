// import React, { Fragment, useState } from "react";
// import { Link } from 'react-router-dom';

// import banner from "../../../assets/images/about.jpeg";
// import choice from "../../../assets/images/choose.webp";

// const Aboutus = () => {
//   const [show, setShow] = useState(false);
//   return (
//     <Fragment>
//       <section className="missionsec" id="about">
//         <div className="ourmissiondiv" style={{ margin: "70px" }}>
//           <h2
//             className="mb-md-3 mb-1 mt-3 mt-md-0 text-center"
//             style={{ color: "#1c1819" }}
//           >
//             OUR VISION
//           </h2>

//           <p>
//             At Just Settle, our vision is to redefine access to justice by
//             transcending traditional barriers. We aim to integrate technology
//             with established mechanisms of alternate dispute resolution,
//             including arbitration, mediation, and conciliation, to revolutionize
//             the way disputes are resolved.{" "}
//             {show == false && (
//               <span
//                 onClick={() => setShow(!show)}
//                 style={{ cursor: "pointer" }}
//               >
//                 <i className="zmdi zmdi-chevron-down"></i>
//               </span>
//             )}
//             <span id="dots"></span>
//             {show == true ? (
//               <>
//                 <span id="more">
//                   {" "}
//                   We envision an efficient ADR mechanism that operates outside
//                   the confines of traditional courtrooms, bringing together
//                   neutrals such as arbitrators and mediators with litigants
//                   through the use of technology. Our goal is to deliver
//                   seamless, cost-effective, and time-bound solutions that
//                   prioritize the needs of all parties involved.In pursuit of
//                   this vision, Just Settle is dedicated to fostering an
//                   environment and culture where individuals are encouraged to
//                   peacefully resolve disputes using technology. We believe that
//                   justice delivery should be viewed as an accessible and robust
//                   service, rather than a process confined to a specific physical
//                   location such as a courtroom.
//                 </span>{" "}
//                 <span
//                   onClick={() => setShow(!show)}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <i className="zmdi zmdi-chevron-up"></i>
//                 </span>
//               </>
//             ) : (
//               ""
//             )}
//           </p>
//           <span id="about_us"></span>
//         </div>
//         <div className="" style={{ margin: "20px 40px" }}>
//           <div
//             className="row align-items-center mt-5"
//             style={{ marginBottom: "75px" }}
//           >
//             <div className="col-md-6">
//               <div className="ourmissiondiv">
//                 <h2
//                   className="mb-md-3 mb-1 mt-3 mt-md-0"
//                   style={{ color: "#1c1819" }}
//                 >
//                   ABOUT US
//                 </h2>
//                 <p className="text-justify">
//                   In today's interconnected global economy, the integration of
//                   technology into justice delivery systems has become an
//                   inevitable evolution. At Just Settle, we are committed to
//                   driving change in dispute resolution mechanisms by combining
//                   technology with established methods such as Arbitration,
//                   Mediation, and Conciliation. Our mission is to adapt to this
//                   evolving global landscape while upholding the procedural
//                   nuances of these methods on a global scale.
//                   <span id="about_us"></span>
//                   {show == true ? (
//                     <span id="aboutus_more">
//                       {" "}
//                       Aligned with the relevant provisions of the Arbitration
//                       and Conciliation Act of 1996, as well as the Mediation Act
//                       of 2023, and following the UNCITRAL Rules for Arbitration,
//                       Just Settle adopts a forward-looking approach to provide a
//                       much-needed global perspective. Our platform operates on
//                       the principle of continual improvement, constantly
//                       refining our processes to align with both domestic and
//                       international laws governing dispute resolution through
//                       arbitration, mediation, and conciliation. We understand
//                       the importance of staying relevant to international
//                       commercial realities and global expectations. Therefore,
//                       our working methods and processes are regularly reviewed
//                       to ensure they meet the practical needs of businesses
//                       worldwide. Just Settle offers a seamless,
//                       technology-driven alternative dispute resolution
//                       experience that is not only business-friendly and
//                       cost-effective but also environmentally friendly and
//                       legally enforceable.
//                     </span>
//                   ) : (
//                     ""
//                   )}
//                 </p>
//                 {/* <button
//                   onClick={() => setShow(!show)}
//                   id="aboutBtn"
//                   className="btn rounded-pill readmore-btn"
//                 >
//                   Read more
//                 </button> */}

//                 <Link to="/aboutus" className="btn rounded-pill readmore-btn">
//                   {" "}
//                   Read more
//                 </Link>
//               </div>
//             </div>
//             <div className="col-md-6">
//               <img
//                 src={banner}
//                 width="100%"
//                 alt=""
//                 style={{ borderRadius: "50%" }}
//               />
//             </div>
//           </div>

//           {/* <div className="row align-items-center mt-5">
//             <div className="col-md-6">
//               <div className="ourmissiondiv">
//                 <h2 className="mb-md-3 mb-1 mt-3 mt-md-0">OUR VALUES</h2>
//                 <p>
//                   We live up to our principles of humanity, independence,
//                   tenacity, and transformation in everything we do.
//                   <span id="about_us"></span>

//                 </p>

//               </div>
//             </div>
//             <div className="col-md-6">
//               <img src={choice} width="100%" alt="" />
//             </div>
//           </div> */}
//         </div>
//       </section>
//     </Fragment>
//   );
// };

// export default Aboutus;
import React from 'react'

const aboutus = () => {
  return (
    <div>

    </div>
  )
}

export default aboutus