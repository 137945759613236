import React, { Fragment, useState } from "react";
import "./footer.css"
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <Fragment>
      <div className="container-fluid pb-5" style={{ background: '#D5EBF8' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pt-5 footer-loggo ">
                <img
                  src={require("../../../assets/images/footer-logo.png")}
                  alt="footer-logo"
                  className="footer-loggo"
                />
              </div>
            </div>
            <div className="col-md-3 pt-3">
              <div className="disclaimer">
                <h4 className="disclaimer-heading">Disclaimer</h4>
                <p className="provide-legal-advice"><b>JustSettle  </b>is not a law firm and does not provide legal advice. The use  of any materials or services is not a substitute for legal advice.</p>
              </div>
            </div>
            <div className="col-md-3  pt-3">
              <div className="disclaimer">
                <h4 className="disclaimer-heading">Important Links</h4>
              </div>
              <div className="footer-links">
                <ul className="footer-list">
                  <Link to="/" className="footre-links"> <li className=""> Home</li> </Link>
                  <Link to="/aboutus" className="footre-links"><li> About Us</li> </Link>
                  <Link to="/services" className="footre-links">  <li> Services</li> </Link>
                  <Link to="/truce-talk" className="footre-links">  <li> Truce Talks</li> </Link>
                  <Link to="/privacy" className="footre-links">  <li> Privacy</li> </Link>
                </ul>
              </div>
            </div>
            <div className="col-md-3  pt-3">
              <div className="disclaimer">
                <h4 className="disclaimer-heading">Contact Us</h4>
              </div>
              <div className="d-flex footer-location pt-3">
                <div className="footer-phone-image">
                  <img
                    src={require("../../../assets/images/location.png")}
                    alt="logo"
                    className=""
                  />
                </div>
                <div className="mx-2">
                  #101, F-549, Teneil IT Tower, opposite Judicial Court, Industrial Area, Sector 75, Sahibzada Ajit Singh
                </div>
              </div>




              <div className="d-flex footer-location pt-3">
                <div className="footer-phone-image">
                  <img
                    src={require("../../../assets/images/phone.png")}
                    alt="logo"
                    className=""
                  />
                </div>
                <div className="mx-2">
                  +91 89685 20297
                </div>
              </div>



              <div className="d-flex footer-location pt-3">
                <div className="footer-phone-image">
                  <img
                    src={require("../../../assets/images/message.png")}
                    alt="logo"
                    className=""
                  />
                </div>
                <div className="mx-2">
                  info@justsettle.in
                </div>
              </div>


            </div>
            <div className="col-md-3  pt-3">
              <div className="d-flex footer-playstore-links">
                <a
                  href="https://justsettle.in/webapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-phone-image-link"
                >
                  <div className="footer-phone-image">
                    <img
                      src={require("../../../assets/images/link.png")}
                      alt="logo"
                      className=""
                    />
                    &nbsp;
                    <span className="play-store">Web App</span>
                  </div>
                </a>
              </div>

              <div className="pt-5 palystore-image">
                <a
                  href="https://play.google.com/store/apps/details?id=com.justsettle.android.abc&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-phone-image-link"
                >
                  <img
                    src={require("../../../assets/images/playstore.png")}
                    alt="logo"
                    className=""
                  />
                </a>
              </div>

              <div className="pt-5 stay-connecte">
                <p className="stay-connected-text">Stay Connected</p>

                <div className="pt-2 d-flex socials-links">
                  <img
                    src={require("../../../assets/images/linkedin.png")}
                    alt="logo"
                    className=""
                  />

                  <img
                    src={require("../../../assets/images/twitter.png")}
                    alt="logo"
                    className=""
                  />
                  <img
                    src={require("../../../assets/images/facebook.png")}
                    alt="logo"
                    className=""
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <nav className="navbar fixed-bottom landing-page-navbar-footer">
        <div className="container-fluid footer-ethos">
          <div className="footer-ethos">
            <span className="footer-ethos-text">© JustSettle powered by Ethos Innotech Pvt. Ltd. </span>
          </div>

        </div>
      </nav>
    </Fragment>
  );
};

export default Footer;
