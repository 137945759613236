import React from "react";
import ListAll from "../Components/list";
import PageWrapper from "../../../../common/PageWrapper";
 
class List extends React.Component {
  render() {
    const { match } = this.props;
    console.log(match, "hj54list")

    return (
      <PageWrapper match={match} title={"Template List"} icon="zmdi-comments">
        <ListAll templateId={match.params.templateId} />
      </PageWrapper>
    );
  }
}

export default List;
