import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";

import Indiapost from "../../assets/images/svgviewer-png-output.png";
import Global from "../../assets/images/global.jpg";
import Azad from "../../assets/images/azad.png";
import Emb from "../../assets/images/emb.jpg";

const IndiaPost = (props) => {
  const location = props?.location;
  const data = props;
console.log(data?.shipment?.tracking_number,"shipment123")
  return (
    <div>
      <div className="w3-row-padding w3-container mx-3">
        <div className="w3-container">
          <div className="">
            <div className="b-0p0">
              <h1 className="img-left">
                <img src={Indiapost} alt="post" width="250" height="100" />
              </h1>
              <div className="b-0p">
                <img src={Global} alt="globe" width="80" height="90" />
                <img src={Azad} alt="azadi" width="100" height="120" />
                <img src={Emb} alt="emb" width="80" height="90" />
              </div>
            </div>

            <p className="img-left track-heading">Track Consignment</p>
            <p className="indicate-text img-left">
              *Indicates a required field
            </p>
            <div className="consgnmnt-cont">
              <div className="b-0p">
                <p className="indicate-text">*Consignment Number</p>
                <div className="consgnmnt-sub-cont">
                  <p className="indicate-texts">
                    {data?.shipment?.tracking_number}
                  </p>
                </div>
                <div className="rgt98">
                  <button className="track-more">Track More</button>
                </div>
              </div>
            </div>
            <div className="mt-0o">
              <div>
                <div>
                  <table
                    cellspacing="0"
                    rules="all"
                    className="responsivetable MailArticleOER "
                    border="1"
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr align="center">
                        <th scope="col">Booked At</th>
                        <th scope="col">Booked On</th>
                        <th scope="col">Destination Pincode</th>
                        <th scope="col">Tariff</th>
                        <th scope="col">Article Type</th>
                        <th scope="col">Delivery Location</th>
                      </tr>
                      <tr>
                        <td>{data?.shipment?.booked_at}</td>
                        <td>
                          {moment(data?.shipment?.booked_on).format(
                            "DD/MM/YYYY h:mm:ss"
                          )}
                        </td>
                        <td>{data?.shipment?.destination_pincode}</td>
                        <td>{data?.shipment?.tariff}</td>
                        <td>{data?.shipment?.article_type}</td>
                        <td>{data?.shipment?.delivery_location}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <small className="text-danger"></small>
              </div>
              <div style={{ marginTop: "5px" }}>
                <div className="row mt-2 mb-2">
                  <div className="col-xs-12 col-md-5">
                    <strong>
                      <span className="e56">
                        Event Details For : {data?.shipment?.tracking_number}
                      </span>
                    </strong>
                  </div>
                  <div className="col-xs-12 col-md-5">
                    <strong>
                      <span className="text-capitalize e56">
                        Current Status : {data?.shipment?.delivery_status}
                      </span>
                    </strong>
                  </div>
                </div>
                <div>
                  <div>
                    <table
                      cellspacing="0"
                      rules="all"
                      className="responsivetable MailArticleOER "
                      border="1"
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Office</th>
                          <th scope="col">Event</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.shipment?.history?.map((s) => {
                          console.log(s, "ssssssssss1");
                          if (s.latest_event) {
                            const eventParts = s.latest_event.split(",");

                            // Extracting data from eventParts
                            const eventType = eventParts[0]?.trim() || "";
                            const location = eventParts[1]?.trim() || "";
                            const timestamp = eventParts[2]?.trim() || "";

                            // Create an object with extracted data
                            const eventObject = {
                              eventType,
                              location,
                              timestamp,
                            };

                            return (
                              <tr>
                                <td>
                                  {moment(eventObject?.timestamp).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(eventObject?.timestamp).format(
                                    "HH:mm"
                                  )}
                                </td>
                                <td>{eventObject?.location}</td>

                                <td>
                                  <span>{eventObject?.eventType}</span>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IndiaPost);
